import React, { Component, useState, useEffect } from "react";

import { Tooltip } from 'primereact/tooltip';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { confirmAlert } from "react-confirm-alert";

import { AgentService } from "../service/agent.service";

import {
    numTomoeny,
    converBetStatus,
    convertIP,
    ConvertDate,
    getDepositColor,
    getWithdrawColor,
    getDeposiOrWithdrawColor,
    ConvertNick
} from "../utility/help";



interface Props {
    onClose: () => any
}

interface State {
    balance: string
    agent: any
}




export class ShareInbalance extends Component<Props, State> {
    agentService: any = new AgentService();

    constructor(props: Props) {
        super(props);
        this.state = {
            balance: '0',
            agent: {}
        }

        this.handelTopinfo()
    }

    componentDidMount() {

    }


    handelTopinfo = async () => {

        const health = await this.agentService.agent_check_health();
        if (health.status == "success") {
            this.setState({ agent: health.admin });
        }
    };


    handleChangeBalance = () => {

        const balance = this.state.balance.replace(/,/g, '')

        if ((Number(balance) % 10000) > 0) {
            confirmAlert({
                title: "에이전트 벨런스",
                message: "충전할 금액은  10,000 단위 로 입력해주세요.",
                buttons: [
                    {
                        label: "확인",
                        onClick: () => { },
                    },
                ],
            });
            return;
        }


        confirmAlert({
            title: "에이전트 벨런스",
            message: "에이전트 충전신청합니다.",
            buttons: [
                {
                    label: "확인",
                    onClick: () => {
                        this.agentService.agent_change_balance({ chBalance: balance, type: 'balance' }).then(s => {
                            if (s.status == "success") {
                                window.location.reload();
                            }
                        });
                    }
                }
            ]
        });
    };



    render() {


        return (

            <div id="tradeOrderModal" className="modal fade in" role="dialog" aria-labelledby="tradeOrderModalLabel" aria-hidden="true" style={{ display: 'block', paddingRight: '17px' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => this.props.onClose()}>×</button>
                            <h4 className="modal-title" id="tradeOrderModalLabel" style={{ color: 'blue' }}>머니 입금하기</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-horizontal">
                                <div className="form-group">
                                    <div className="col-sm-3">

                                        <label className="control-label" >보유머니</label>
                                    </div>
                                    <div className="col-sm-9">
                                        {
                                            this.state.agent && <input type="text" className="form-control" name="curMoney" readOnly value={this.state.agent.balance} />
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-horizontal">
                                <div className="form-group">
                                    <div className="col-sm-3">
                                        <label className="control-label " >입금자</label>
                                    </div>
                                    <div className="col-sm-9">
                                        {
                                            this.state.agent && <input type="text" className="form-control" name="curMoney" readOnly value={this.state.agent.bankowner} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-horizontal">
                                <div className="form-group">
                                    <div className="col-sm-3">

                                        <label className="control-label " >입금금액(머니)</label>
                                    </div>

                                    <div className="col-sm-9">
                                        <InputText type="text" className="form-control number" value={this.state.balance}
                                            onChange={(e: any) => {
                                                let moeny = Number(e.target.value
                                                    .replace(/[^0-9]/g, ''))
                                                    .toLocaleString()

                                                this.setState({ balance: moeny })
                                            }}
                                            onFocus={() => this.setState({ balance: '' })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-horizontal">
                                <div className="form-group">
                                    <div className="form-group">
                                        <label className="control-label col-sm-12 text-left">* 반드시 입금 되어야 입금이 완료 됩니다</label>
                                        <label className="control-label col-sm-12 text-left">* 각 은행의 사정에 따라 처리 시간이 다소 차이가 있을 수 있습니다.</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default btn-sm" data-dismiss="modal" aria-hidden="true" onClick={() => this.props.onClose()}>cancel</button>
                            <button type="button" className="btn btn-primary btn-sm" id="doOnlineOrder" onClick={() => this.handleChangeBalance()}>입금하기</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
