

import React, { Component } from "react";
import { InputText } from "primereact/inputtext";

interface Prop {
    value: any
    onChange: (event) => any
    onKeyPress?: (event) => any
}

interface State {
}

export class CInputText extends Component<Prop, State> {

    constructor(props: any) {
        super(props);
        this.state = {
        };


        // this.GetUserList(0, "", "", "regdate", 1);
    }

    componentDidMount() { }


    render() {
        return (
            <InputText
                value={this.props.value}

                onKeyPress={this.props.onKeyPress}
                onChange={this.props.onChange}

                style={{ width: "180px" }}

                placeholder="검색어를 입력해 주세요"

            />
        );
    }
}
