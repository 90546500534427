

import React, { Component } from "react";

// import { Messages } from "primereact/messages";
// import { Message } from "primereact/message";
import { CountryService } from "../service/CountryService";
import { CarService } from "../service/CarService";
import { NodeService } from "../service/NodeService";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
// // import { Spinner } from "primereact/spinner";
import { Slider } from "primereact/slider";
import { ListBox } from "primereact/listbox";
import { RadioButton } from "primereact/radiobutton";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import { BetService } from "../service/bet.service";
import { BalanceService } from "../service/balance.service";



import { ColorPicker } from 'primereact/colorpicker';
import { CDropdown } from "./custom/cdropdown";
import { UserService } from "../service/user.service";
import { ShareCasinoBetTable, ShareSlotBetTable } from "./share.historylog.table";
import { ShareBalanceTable } from "./share.balance.table";

import { SubPaging } from "../share/sub-paging";

import { confirmAlert } from "react-confirm-alert";

import {
    numTomoeny,
    converBetStatus,
    convertIP,
    ConvertDate,
    getDepositColor,
    getWithdrawColor,
    getDeposiOrWithdrawColor,
    ConvertNick,
    ConvertBenefitName,
    GetToday
} from "../utility/help";

import { CCalendarTime } from "./custom/ccalendartime";


interface Props {
    user: any
}

interface State {
    user: any
}

export class UserInfoTable extends Component<Props, State> {
    userService: UserService = new UserService();

    constructor(props: Props) {
        super(props);
        this.state = {
            user: null,
        };

        this.handleSave = this.handleSave.bind(this);
    }
    componentDidMount() {
    }




    handleSave = () => {

        const user = this.state.user
        if (user == null) {
            confirmAlert({
                title: "에이전트",
                message: "변경된 정보가 없습니다",
                buttons: [
                    {
                        label: "확인",
                        onClick: () => {
                            window.location.reload();
                        },
                    },
                    {
                        label: "취소",
                        onClick: () => {
                            window.location.reload();
                        },
                    },
                ],
            });
            return
        }

        if (user.pass !== user.expass) {
            confirmAlert({
                title: "에이전트",
                message: "변경된 정보가 없습니다",
                buttons: [
                    {
                        label: "확인",
                        onClick: () => {
                            window.location.reload();
                        },
                    },
                    {
                        label: "취소",
                        onClick: () => {
                            window.location.reload();
                        },
                    },
                ],
            });
            return
        }


        confirmAlert({
            title: "확인",
            message: "에이전트 정보가 변경 하시겠습니까 ?",
            buttons: [
                {
                    label: "확인",
                    onClick: async () => {

                        // this.userService
                        //     .save_user_detail({
                        //         id: user.id,
                        //         pw: user.pw,
                        //         nick: user.nick,
                        //         exchange_pw: user.exchange_pw,
                        //         level: user.level,
                        //         phone: user.phone,
                        //         friendId: user.friendId,
                        //         bankname: user.bankname,
                        //         banknum: user.banknum,
                        //         bankowner: user.bankowner,
                        //         color: user.color,
                        //         simplememo: user.simplememo,
                        //         memo: user.memo,
                        //         status: user.status,
                        //         agent: user.agent,

                        //         rollingSlot: user.rollingSlot,
                        //         rollingCasino: user.rollingCasino,
                        //         lockedminngame: user.lockedminngame,
                        //         lockedcasino: user.lockedcasino,
                        //         lockedslot: user.lockedslot,
                        //     })
                        //     .then((data) => {
                        //         if (data.status == "success") {
                        //             confirmAlert({
                        //                 title: "확인",
                        //                 message: "유저 정보가 변경되었습니다.",
                        //                 buttons: [
                        //                     {
                        //                         label: "확인",
                        //                         onClick: () => {
                        //                             window.location.reload();
                        //                         },
                        //                     },
                        //                 ],
                        //             });
                        //         } else if (data.status == "params") {
                        //             confirmAlert({
                        //                 title: "확인",
                        //                 message: "잘못된 데이터가 포함되어 있습니다",
                        //                 buttons: [
                        //                     {
                        //                         label: "확인",
                        //                         onClick: () => {
                        //                             window.location.reload();
                        //                         },
                        //                     },
                        //                 ],
                        //             });
                        //         } else if (data.status == "roll") {
                        //             confirmAlert({
                        //                 title: "확인",
                        //                 message: "롤링 프로테이지를 확인해주세요",
                        //                 buttons: [
                        //                     {
                        //                         label: "확인",
                        //                         onClick: () => {
                        //                             window.location.reload();
                        //                         },
                        //                     },
                        //                 ],
                        //             });
                        //         } else {
                        //             confirmAlert({
                        //                 title: "확인",
                        //                 message: "관리자에게 문의 부탁드림니다",
                        //                 buttons: [
                        //                     {
                        //                         label: "확인",
                        //                         onClick: () => {
                        //                             window.location.reload();
                        //                         },
                        //                     },
                        //                 ],
                        //             });
                        //         }
                        //     });
                    },
                },
                {
                    label: "취소",
                    onClick: () => {

                    },
                },
            ],
        });
    }

    render() {
        let user = this.props.user;
        if (user == undefined) {
            return <div></div>;
        }

        return (
            <>

                <div className="form-group">
                    <div className="input-group">
                        <div className="input-group-addon">비밀번호</div>
                        <InputText
                            value={user.pass}

                            className="form-control"
                            maxLength={20}
                            onChange={(e: any) => {
                                user.pass = e.target.value
                                this.setState({ user: user })
                            }}
                        />
                        <div className="input-group-addon">비밀번호 확인</div>
                        <InputText
                            value={user.expass}
                            className="form-control"
                            maxLength={20}
                            onChange={(e: any) => {
                                user.expass = e.target.value
                                this.setState({ user: user })
                            }}
                        />
                    </div>
                </div>


                <div className="form-group">
                    <div className="input-group">
                        <div className="input-group-addon">전화번호</div>
                        <InputText
                            value={user.phone}
                            className="form-control"
                            maxLength={20}
                            onChange={(e: any) => {
                                user.phone = e.target.value
                                this.setState({ user: user })
                            }}
                        />
                        <div className="input-group-addon">은행</div>
                        <InputText
                            value={user.bankname}
                            onChange={(e: any) => {
                                user.bankname = e.target.value
                                this.setState({ user: user })
                            }}
                            className="form-control input-sm"
                        />
                    </div>
                </div>

                <div className="form-group">
                    <div className="input-group">
                        <div className="input-group-addon">예금주</div>
                        <InputText
                            value={user.bankowner}
                            onChange={(e: any) => {
                                user.bankowner = e.target.value
                                this.setState({ user: user })
                            }}

                            className="form-control"
                            maxLength={20}
                        />
                        <div className="input-group-addon">계좌번호</div>
                        <InputText
                            value={user.banknum}
                            onChange={(e: any) => {
                                user.banknum = e.target.value
                                this.setState({ user: user })
                            }}
                            className="form-control"
                            maxLength={100}
                        />
                    </div>
                </div>


                <div className="form-group">
                    <div className="input-group">
                        <div className="input-group-addon">슬롯 롤링</div>
                        <InputText
                            value={user.rollingSlot}
                            onChange={(e: any) => {
                                user.rollingSlot = e.target.value
                                this.setState({ user: user })
                            }}

                            className="form-control"
                            maxLength={20}
                        />
                        <div className="input-group-addon">카지노 롤링</div>
                        <InputText
                            value={user.rollingCasino}
                            onChange={(e: any) => {
                                user.rollingCasino = e.target.value
                                this.setState({ user: user })
                            }}
                            className="form-control"
                            maxLength={100}
                        />
                    </div>
                </div>


                <div className="form-group ">
                    <div className="input-group">
                        <div className="input-group-addon" style={{ color: `${user.color}`, fontWeight: 'bold' }}>아이디 색상</div>


                        <Button
                            style={{ display: 'inline', background: '#007bff' }}
                            label={'007bff'}
                            onClick={() => {
                                user.color = `#007bff`
                                this.setState({ user: user })
                            }}
                        />

                        <Button
                            style={{ display: 'inline', background: '#28a745' }}
                            label={'28a745'}
                            onClick={() => {
                                user.color = `#28a745`
                                this.setState({ user: user })
                            }}
                        />


                        <Button
                            style={{ display: 'inline', background: '#fd7e14' }}
                            label={'fd7e14'}
                            onClick={() => {
                                user.color = `#fd7e14`
                                this.setState({ user: user })
                            }}
                        />

                        <Button
                            style={{ display: 'inline', background: '#dc3545' }}
                            label={'dc3545'}
                            onClick={() => {
                                user.color = `#dc3545`
                                this.setState({ user: user })
                            }}
                        />

                        <Button
                            style={{ display: 'inline', background: '#000000' }}
                            label={'000000'}
                            onClick={() => {
                                user.color = `#000000`
                                this.setState({ user: user })
                            }}
                        />


                        <Button
                            style={{ display: 'inline', background: '#ffc107' }}
                            label={'ffc107'}
                            onClick={() => {
                                user.color = `#ffc107`
                                this.setState({ user: user })
                            }}
                        />


                        <Button
                            style={{ display: 'inline', background: '#9c27b0' }}
                            label={'9c27b0'}
                            onClick={() => {
                                user.color = `#9c27b0`
                                this.setState({ user: user })
                            }}
                        />


                        <Button
                            style={{ display: 'inline', background: '#cddc39' }}
                            label={'cddc39'}
                            onClick={() => {
                                user.color = `#cddc39`
                                this.setState({ user: user })
                            }}
                        />
                        <Button
                            style={{ display: 'inline', background: '#795548' }}
                            label={'795548'}
                            onClick={() => {
                                user.color = `#795548`
                                this.setState({ user: user })
                            }}
                        />
                        <Button
                            style={{ display: 'inline', background: '#607d8b' }}
                            label={'607d8b'}
                            onClick={() => {
                                user.color = `#607d8b`
                                this.setState({ user: user })
                            }}
                        />

                    </div>
                </div>

                <div className="form-group">
                    <label id="calc_group_d">카지노</label>
                    <div className="input-group">
                        <div className="input-group-addon">사용</div>
                        <RadioButton
                            value="n"
                            name="city"
                            className="form-control"
                            checked={user.lockedcasino !== "y"}
                            onChange={() => {
                                user.lockedcasino = "n"
                                this.setState({ user: user })
                            }}
                        />
                        <div className="input-group-addon">미사용</div>
                        <RadioButton
                            value="y"
                            className="form-control"
                            checked={user.lockedcasino === "y"}
                            onChange={() => {
                                user.lockedcasino = "y"
                                this.setState({ user: user })
                            }}
                        />
                    </div>
                </div>


                <div className="form-group ">
                    <div className="input-group">

                    </div>
                </div>


                <div className="form-group">
                    <label id="calc_group_d">슬롯</label>
                    <div className="input-group">
                        <div className="input-group-addon">사용</div>
                        <RadioButton
                            className="form-control"
                            value="n"
                            checked={user.lockedslot !== "y"}
                            onChange={() => {
                                user.lockedslot = "n"
                                this.setState({ user: user })
                            }}
                        />
                        <div className="input-group-addon">미사용</div>
                        <RadioButton
                            value="y"
                            className="form-control"
                            checked={user.lockedslot === "y"}
                            onChange={() => {
                                user.lockedslot = "y"
                                this.setState({ user: user })
                            }}

                        />
                    </div>
                </div>



                <div className="form-group ">
                    <div className="input-group">

                    </div>
                </div>


                <div className="form-group " style={{ height: '100px' }}>
                    <div className="input-group"
                    >
                        <div className="input-group-addon">심플 메모</div>

                        <InputTextarea
                            value={user.simplememo}
                            onChange={(e: any) => {
                                user.simplememo = e.target.value
                                this.setState({ user: user })
                            }}
                            rows={5}
                            cols={30}
                            className="form-control"
                            style={{ height: '100%' }}
                        />

                    </div>
                </div>


                <div className="form-group " style={{ height: '100px' }}>
                    <div className="input-group" >
                        <div className="input-group-addon">메모</div>

                        <InputTextarea
                            value={user.memo}
                            onChange={(e: any) => {
                                user.memo = e.target.value
                                this.setState({ user: user })
                            }}
                            rows={5}
                            cols={30}
                            className="form-control"
                            style={{ height: '100%' }}
                        />

                    </div>
                </div>

                <div className="form-group ">
                    <div className="input-group">
                        <div className="input-group-addon">심플</div>


                    </div>
                </div>


                <div className="form-group ">
                    <div className="input-group">

                    </div>
                </div>

                <div className="form-group ">
                    <div className="input-group">

                        <Button
                            className="form-control p-button-success"

                            label={'저장하기'}
                            onClick={() => this.handleSave()}
                        />
                    </div>
                </div>
            </>
        )
    }
}





enum RenderBetTable {
    BetSlot, // 0
    BetCasino, // 0

}
interface StateBet {
    Render: RenderBetTable
    logs: any

    begin: any;
    end: any;
    maxCount: number
    activePage: number
}

export class UserBetTable extends Component<Props, StateBet> {
    betService = new BetService();


    constructor(props: Props) {
        super(props);
        let today = GetToday();

        this.state = {
            Render: RenderBetTable.BetSlot,
            logs: [],
            begin: today.begin,
            end: today.end,
            maxCount: 1,
            activePage: 1,
        };

        this.handleGetCasino = this.handleGetCasino.bind(this);
        this.handleGetSlot = this.handleGetSlot.bind(this);
        this.handleGetSlot(1)
    }
    componentDidMount() {
    }




    handleGetCasino = async (page: any) => {
        const data = await this.betService
            .get_casino_bets({
                skip: page,
                begin: this.state.begin.toLocaleString("sv-SE"),
                end: this.state.end.toLocaleString("sv-SE"),
                selectSort: 'betTime', // sort,
                findText: this.props.user.id, // sort,
                searchText: 'id', // sort,
            })


        if (data.status === 'success') {
            this.setState({
                logs: data.casinos,
                maxCount: data.maxCount,
                activePage: page
            });
        }

    }

    handleGetSlot = async (page: any) => {
        const data = await this.betService
            .get_slot_bets({
                skip: page,
                begin: this.state.begin.toLocaleString("sv-SE"),
                end: this.state.end.toLocaleString("sv-SE"),
                selectSort: 'betTime', // sort,
                findText: this.props.user.id, // sort,
                searchText: 'id', // sort,
            })


        if (data.status === 'success') {
            this.setState({
                logs: data.slots,
                maxCount: data.maxCount,
                activePage: page
            });
        }

    }



    render() {
        let user = this.props.user;
        if (user == undefined) {
            return <div></div>;
        }

        return (
            <>
                <div id="tabs" className="nav-tabs-custom">
                    <ul className="nav nav-tabs">
                        <li className="active"><a data-toggle="tab" onClick={() => {
                            this.setState({ Render: RenderBetTable.BetSlot })
                            this.handleGetSlot(1)
                        }}>슬롯 정보</a></li>
                        <li><a data-toggle="tab" onClick={() => {
                            this.setState({ Render: RenderBetTable.BetCasino })
                            this.handleGetCasino(1)
                        }}>카지노 정보</a></li>


                    </ul>


                    <div
                        id="searchForm"
                        className="form-inline"
                        role="form"
                    >
                        <div className="form-group">
                            <CCalendarTime
                                placeholder="시작일"
                                value={this.state.begin}
                                onChange={(e) => {
                                    this.setState({ begin: e })
                                }}

                            />
                        </div>

                        <div className="form-group">
                            <CCalendarTime
                                placeholder="종료일"
                                value={this.state.end}
                                onChange={(e) => {
                                    this.setState({ end: e })
                                }}

                            />
                        </div>

                        <div className="form-group">
                            <Button
                                label="조회"
                                onClick={() => {
                                    this.state.Render === RenderBetTable.BetSlot ? this.handleGetSlot(1) : this.handleGetCasino(1)
                                }}
                                className="btn btn-primary btn-sm"
                            />
                        </div>

                    </div>

                    {
                        this.state.Render === RenderBetTable.BetSlot && (
                            <div className="tab-pane active">
                                <ShareCasinoBetTable logs={this.state.logs}></ShareCasinoBetTable>
                            </div>
                        )
                    }

                    {
                        this.state.Render === RenderBetTable.BetCasino && (
                            <div className="tab-pane active">
                                <ShareSlotBetTable logs={this.state.logs}></ShareSlotBetTable>
                            </div>
                        )
                    }

                    <div className="text-center">
                        <SubPaging
                            activePage={this.state.activePage}
                            maxCount={this.state.maxCount}
                            limit={30}
                            MoveSel={(page: number) => {
                                this.state.Render === RenderBetTable.BetSlot ? this.handleGetSlot(page) : this.handleGetCasino(page)
                            }}
                        ></SubPaging>
                    </div>

                </div>
            </>
        )
    }
}



interface PropsBalance {
    userId: string
    user: any
}





interface StateBalance {
    Render: RenderBetTable
    balances: any

    begin: any;
    end: any;
    maxCount: number
    activePage: number
    total: any
    searchText: string
}



export class UserBalanceTable extends Component<PropsBalance, StateBalance> {
    balanceService = new BalanceService();


    constructor(props: PropsBalance) {
        super(props);

        let today = GetToday();

        this.state = {
            Render: RenderBetTable.BetSlot,
            balances: [],
            begin: today.begin,
            end: today.end,
            maxCount: 1,
            activePage: 1,
            total: {},
            searchText: this.props.userId,

        };

        console.log(props)

        setTimeout(() => this.GetBalanceList(1))

    }
    componentDidMount() {
    }


    GetBalanceList = async (page) => {
        // let data = await this.balanceService
        //     .get_deposit_and_withdraw_total_balance({
        //         page: page,
        //         findText: 'id',
        //         searchText: this.state.searchText,
        //         begin: this.state.begin.toLocaleString('sv-SE'),
        //         end: this.state.end.toLocaleString('sv-SE')
        //     });
        // this.setState({
        //     maxCount: data.maxCount,
        //     balances: data.balances,
        //     total: data.total,
        //     activePage: page,
        // });

    };





    render() {
        let user = this.props.user;
        if (user == undefined) {
            return <div></div>;
        }

        return (
            <>
                <div id="tabs" className="nav-tabs-custom">
                    <ul className="nav nav-tabs">

                        <li className="">
                            <div className="form-group">

                                <CCalendarTime
                                    placeholder="시작일"
                                    value={this.state.begin}
                                    onChange={(e) => {
                                        this.setState({ begin: e })
                                    }}

                                />
                            </div>


                        </li>
                        <li className="">

                            <div className="form-group">

                                <CCalendarTime
                                    placeholder="종료일"
                                    value={this.state.end}
                                    onChange={(e) => {
                                        this.setState({ end: e })
                                    }}

                                />
                            </div>

                        </li>
                        <li className="">
                            <Button
                                label="조회"
                                onClick={() => this.GetBalanceList(1)}
                                className="btn btn-primary "
                            />
                        </li>

                    </ul>


                    <div
                        id="searchForm"
                        className="form-inline"
                        role="form"
                    >

                    </div>

                    <div className="tab-pane active">
                        <ShareBalanceTable balances={this.state.balances}></ShareBalanceTable>
                    </div>

                    <div className="text-center">
                        <SubPaging
                            activePage={this.state.activePage}
                            maxCount={this.state.maxCount}
                            limit={30}
                            MoveSel={(page: number) => {
                                this.GetBalanceList(page)
                                // this.state.Render === RenderBetTable.BetSlot ? this.handleGetSlot(page) : this.handleGetCasino(page)
                            }}
                        ></SubPaging>
                    </div>

                </div>
            </>
        )
    }
}