import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { TabView, TabPanel } from "primereact/tabview";
import { Editor } from "primereact/editor";
import { RadioButton } from "primereact/radiobutton";
import { ColorPicker } from "primereact/colorpicker";
import { useCookies } from 'react-cookie';


// import locale from "react-json-editor-ajrm/locale/en";

import { AgentService } from "../service/agent.service";
// import { CustomPagination } from "../share/custom-pagination";
import { GetTimeStemp } from "../utility/help";

import { constrainMarkerToRange } from "@fullcalendar/core/datelib/date-range";

const isMobile = () => {
    return window.innerWidth <= 1024;
}

function RenderPopup(pop: any) {
    // console.log(pop)
    pop = pop.pop
    // let cookie = this.props.GetCookie(`pop_${pop.idx}`);

    const name = `popuptime_${pop.idx}`

    const [cookies, setCookie] = useCookies([name]);
    const [value, setClose] = React.useState(false)

    if (value == true) {
        return <></>
    }

    if (cookies[name] != null || GetTimeStemp() - Number(cookies[name]) > 1000 * 60 * 60 * 8) {
        return (<></>);
    }


    return (
        <div className="pop01_popup1 draggable02" style={{ position: 'absolute', top: isMobile() ? `200px` : `${pop.posX}px`, left: isMobile() ? `0px` : `${pop.posY}px`, zIndex: 1000 }} >
            <div className="pop01_popup_wrap">
                <div className="pop01_popup_btn_wrap">
                    <ul>
                        <li><span className="pop01_popup_btn" onClick={() => {
                            try {
                                setCookie(name, GetTimeStemp())
                                setClose(true)
                            } catch { }
                        }}>8시간동안 창을 열지 않음</span></li>
                        <li><span className="pop01_popup_btn" onClick={() => { setClose(true) }}> 닫기 X</span></li>
                    </ul>
                </div>
                <div className="pop01_popup_box">
                    <div className="pop01_popup_text" style={{ padding: '30px', width: '500px' }}>
                        <span className="pop01_popup_font1" style={{ borderBottom: '2px solid #fff', marginBottom: '15px', color: pop.titleColor }}>{pop.title}</span>
                        <span className="pop01_popup_font2" >
                            <div dangerouslySetInnerHTML={{ __html: pop.contents }}></div>
                        </span>

                    </div>



                </div>
            </div>
        </div >)
};


interface State {
    items: any[];
    agents: any[];
    sel_agent: string;
    notice: any;
    activePage: number;
    maxCount: number;
    close_popup: any;
    note_popup: any[];
}

export class NoticeAgent extends Component<any, State> {
    agentService = new AgentService();

    constructor(props: any) {
        super(props);

        this.state = {
            items: [],
            agents: [{ name: "모두", value: "" }],
            sel_agent: "",
            activePage: 1,
            maxCount: 1,
            notice: {
                del: "n",
                contents: "",
                title: ""
            },

            note_popup: [],
            close_popup: {}
        }
        // this.handleGetMarkets = this.handleGetMarkets.bind(this);
        //    this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentDidMount() {
        this.handleGetNotice();
        // this.handleGetAgent();
    }

    handleGetNotice = () => {
        this.agentService.admin_get_notice_agent().then(data => {
            if (data.status == "success") {
                this.setState({
                    items: data.notices
                });
            }
        });
    };


    render() {
        let items = this.state.items;

        return <div style={{ width: '300px' }}>{items.map((pop, index) => <RenderPopup pop={pop}></RenderPopup>)}</div>;

    }
}
