
import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { confirmAlert } from "react-confirm-alert";

import {
  GetNow,
  ConvertCalendar,
  numTomoeny,
} from "../utility/help";
import { SubPaging } from "../share/sub-paging";
import { AgentService } from "../service/agent.service";
import { Message } from "primereact/message";
import { ShareBalanceRequestedMenu } from "./share.balance.requested.menu";
import { ShareAgentBalanceRequestedTable } from "./share.balance.requested.table";


import { SharePopupBalanceLog } from "./share.popup.balancelog";



interface State {
  page: number;
  maxCount: number;
  balances: any[];
  selectedCustomers: any[];

  findDay: any;
  begin: any;
  end: any;

  seach: string;
  seach2: string;

  selectAll: boolean;
  ProcessCancelPopup: boolean;
  SearchLogPopup: boolean;
}

export class RequestShopTransList extends Component<any, State> {
  userService = new UserService();
  fileService = new FileService();
  agentService = new AgentService();

  menu: any;

  constructor(props: any) {
    super(props);

    let begin = GetNow();
    let end = GetNow();

    this.state = {
      page: 1,
      maxCount: 1,
      balances: [],

      findDay: "5",
      begin: begin,
      end: end,

      selectedCustomers: [],
      seach: "",
      seach2: "",

      selectAll: false,
      ProcessCancelPopup: false,
      SearchLogPopup: false,
    };

    this.onSelectAllChange = this.onSelectAllChange.bind(this);
    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.GetBalanceList = this.GetBalanceList.bind(this);
    this.WaitAllUser = this.WaitAllUser.bind(this);
    this.SendMessageServer = this.SendMessageServer.bind(this);





    this.GetBalanceList(1);
  }


  componentDidMount() { }


  GetBalanceList = async (page: number) => {
    // const data = await this.agentService.admin_get_agent_wait_withdraw({ skip: page })
    // this.setState({ balances: data.balance });
  };

  onSelectionChange = (event) => {
    const value = event.value;
    this.setState({ selectedCustomers: value, selectAll: value.length === this.state.balances.length })
  }

  onSelectAllChange = (info) => {

    const { checked } = info
    this.state.balances.map(s => (s.checked = checked))

    if (checked) {
      this.setState({ balances: this.state.balances, selectedCustomers: this.state.balances, selectAll: checked })
    } else {
      this.setState({ balances: this.state.balances, selectedCustomers: [], selectAll: checked })
    }
  }

  WaitAllUser = () => {

    this.setState({ selectedCustomers: this.state.balances })

  }


  SendMessageServer = (mode: any) => {
    let ex: any[] = [];


    for (let deposit of this.state.selectedCustomers) {
      ex.push(deposit);
    }

    if (ex.length === 0) {
      confirmAlert({
        title: "에이전트 관리",
        message: `아이템을 선택해주세요.`,
        buttons: [
          {
            label: "확인'",
            onClick: () => this.GetBalanceList(0),
          },
        ],
      });

      return
    }

    if (mode == "success" && ex.length > 0) {

    } else if (mode == "wait" && ex.length > 0) {

    } else if (mode == "cancel" && ex.length > 0) {

    }
  };


  render() {
    return (
      <>
        <section className="content-header">
          <h1>에이전트 출금 요청</h1>
        </section>

        <section className="content">



          <div style={{ backgroundColor: "#F5A9A9" }}>
            마지막 입금 3일이상 환전시 빨간색
          </div>
          <div style={{ backgroundColor: "#F2F5A9" }}>
            마지막 입금 7일이상 환전시 노란색
          </div>
          <div style={{ backgroundColor: "#A9F5A9" }}>
            등록된 계좌이름과 입금자 이름이 다를때 녹색
          </div>
          <div style={{ backgroundColor: "#F5D0A9" }}>
            두개 이상조건 중복시 주황색
          </div>

          <ShareBalanceRequestedMenu

            onWaitAllUser={this.WaitAllUser}
            onSendMessage={this.SendMessageServer}

          ></ShareBalanceRequestedMenu>

          {/* 메인컨텐츠 */}
          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>

            <ShareAgentBalanceRequestedTable
              balances={this.state.balances}
              onSelectAllChange={this.onSelectAllChange}
              onSelectionChange={this.onSelectionChange}
              onSendMessage={this.SendMessageServer}


              selectAll={this.state.selectAll}
              selectedCustomers={this.state.selectedCustomers}

            ></ShareAgentBalanceRequestedTable>

          </div>
          <div className="text-center">
            <SubPaging
              activePage={this.state.page}
              maxCount={this.state.maxCount}
              limit={20}
              MoveSel={(page: number) => {
              }}
            ></SubPaging>
          </div>
        </section>


        {/* ################################################################# */}
        {/* 처리취소 레이어 */}
        {/* ################################################################# */}
        <div
          className="modal fade in"
          tabIndex={-1}
          role="dialog"
          style={{
            display: this.state.ProcessCancelPopup === true ? "block" : "none",
            paddingRight: "17px",
          }}
        >
          <div
            className="modal-content"
            style={{
              minWidth: "300px",
              maxWidth: "600px",
              minHeight: "300px",
              maxHeight: "300px",
              overflow: "auto",
              top: "100px",
              left: "30%",
            }}
          >
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              // onClick={this.CloseProcessCancel}
              >
                ×
              </button>

              <h4 className="modal-title">거래 취소</h4>
            </div>
            <div className="modal-body">
              <div id="getForm" className="form-horizontal">
                <div className="form-group">
                  <label className="control-label col-sm-3">아이디</label>
                  <div className="col-sm-9">
                    <InputText
                      value=""
                      onChange={(e: any) => { }}
                      className="form-control input-sm"
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-3">입금금액</label>
                  <div className="col-sm-9">
                    <InputText
                      value=""
                      onChange={(e: any) => { }}
                      className="form-control input-sm"
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-3">취소사유</label>
                  <div className="col-sm-9">
                    <InputText
                      value=""
                      onChange={(e: any) => { }}
                      className="form-control input-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="pull-right">
                <div className="form-inline">
                  <div className="form-group">
                    <Button
                      label="닫기"
                      onClick={() => {
                        // this.CloseProcessCancel();
                      }}
                      className="btn btn-default btn-sm"
                    />
                  </div>
                  <div className="form-group">
                    <Button
                      label="거래취소"
                      onClick={() => { }}
                      className="btn btn-primary btn-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ################################################################# */}
        {/* 기존이력조회 레이어 */}
        {/* ################################################################# */}

        {<SharePopupBalanceLog></SharePopupBalanceLog>}
      </>
    );
  }
}

// import React, { Component } from "react";
// import { Button } from "primereact/button";
// import { UserService } from "../service/user.service";
// import { FileService } from "../service/file.service";
// import { Dropdown } from "primereact/dropdown";
// import { InputText } from "primereact/inputtext";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { Calendar } from "primereact/calendar";
// import { GetNow, ConvertCalendar, numTomoeny } from "../utility/help";
// import { SubPaging } from "../share/sub-paging";

// interface State {
//   page: number;
//   maxCount: number;

//   findDay: any;
//   begin: any;
//   end: any;

//   userTableValue: any[];
//   userTableValue2: any[];
//   dropdownItem: string;
//   seach: string;
//   seach2: string;

//   SearchLogPopup: boolean;
// }

// export class RequestShopTransList extends Component<any, State> {
//   userService = new UserService();
//   fileService = new FileService();

//   menu: any;

//   constructor(props: any) {
//     super(props);

//     let begin = GetNow();
//     let end = GetNow();

//     this.state = {
//       page: 1,
//       maxCount: 1,

//       findDay: "5",
//       begin: begin,
//       end: end,

//       userTableValue: [],
//       userTableValue2: [],
//       dropdownItem: "user_id",
//       seach: "",
//       seach2: "",

//       SearchLogPopup: false,
//     };

//     this.ShopTransList(
//       this.state.page,
//       this.state.dropdownItem,
//       this.state.seach
//     );

//     this.GetUserList(0, "", "", "regdate", 1);
//   }

//   selectData = [
//     { label: "아이디", value: "user_id" },
//     { label: "담당자", value: "admin_id" },
//     { label: "에이전트(하부포함X)", value: "only_grp_admin_id" },
//     { label: "에이전트(하부포함)", value: "all_grp_admin_id" },
//     { label: "입금자", value: "bank_owner" },
//   ];

//   componentDidMount() { }

//   GetUserList = (
//     page: number,
//     find: string,
//     search: string,
//     sortType: string,
//     sort: number
//   ) => {
//     this.userService
//       .get_user_list(page, find, search, sortType, sort, "used")
//       .then((data) => {
//         this.setState({
//           userTableValue: data.users,
//         });
//       });
//   };

//   //조회
//   SearchData = () => {
//     this.setState({ page: 1 });
//     this.ShopTransList(
//       this.state.page,
//       this.state.dropdownItem,
//       this.state.seach
//     );
//   };

//   MoveSel = (page: any) => {
//     console.log(page);
//     this.setState({ page: page });
//     this.ShopTransList(page, this.state.dropdownItem, this.state.seach);
//   };

//   //에이전트출금요청 조회
//   ShopTransList = (page: number, dropdownItem: string, seach: string) => { };

//   // 재입력
//   ReloadPage = () => {
//     console.log("ReloadPage : 재입력");
//   };

//   // 회원리스트 화면으로 이동
//   MoveUserList = () => {
//     console.log("MoveUserList : 회원리스트 화면으로 이동");
//   };

//   // 알람 중단
//   StopAlarm = () => {
//     console.log("StopAlarm : 알람 중단");
//   };

//   // 기존이력조회
//   SearchLog = () => {
//     console.log("SearchLog : 기존이력조회");
//   };

//   // 처리 확인
//   ProcessConfirm = () => {
//     console.log("ProcessConfirm : 처리 확인");
//   };

//   // 처리 취소
//   ProcessCancel = () => {
//     console.log("ProcessCancel : 처리 취소");
//   };

//   //기존이력조회 팝업 호출
//   OpenSearchLog = () => {
//     console.log("OpenSearchLog : 기존이력조회 팝업 호출");
//     this.setState({ SearchLogPopup: true });
//   };

//   //기존이력조회 팝업 닫기
//   CloseSearchLog = () => {
//     console.log("CloseSearchLog : 기존이력조회 팝업 닫기");
//     this.setState({ SearchLogPopup: false });
//   };

//   render() {
//     return (
//       <>
//         <section className="content-header">
//           <h1>에이전트 출금 요청</h1>
//         </section>

//         <section className="content">
//           <form
//             id="searchForm"
//             name="searchForm"
//             className="form-inline"
//             role="form"
//           >
//             <div className="form-group">
//               <Dropdown
//                 options={this.selectData}
//                 value={this.state.dropdownItem}
//                 onChange={(event) =>
//                   this.setState({ dropdownItem: event.value })
//                 }

//                 className="form-control input-sm"
//                 style={{ width: "120px" }}
//               />
//             </div>
//             <div className="form-group">
//               <InputText
//                 value=""
//                 onChange={(e: any) => this.setState({ seach: e.target.value })}
//                 onKeyPress={(e: any) => {
//                   if (e.key === "Enter") {
//                     this.SearchData();
//                   }
//                 }}
//                 className="form-control input-sm"
//                 style={{ width: "200px" }}
//                 placeholder="검색어를 입력해 주세요"
//               />
//             </div>
//             <div className="form-group">
//               <Button
//                 label="조회"
//                 onClick={() => {
//                   this.SearchData();
//                 }}
//                 className="btn btn-primary btn-sm"
//               />
//             </div>
//             <div className="form-group">
//               <Button
//                 label="재입력"
//                 onClick={() => { }}
//                 className="btn btn-default btn-sm"
//               />
//             </div>
//             <div className="form-group">
//               <Button
//                 label="알람중단"
//                 onClick={() => {
//                   this.StopAlarm();
//                 }}
//                 className="btn btn-success btn-sm"
//               />
//             </div>
//           </div>

//           <div style={{ backgroundColor: "#F5A9A9" }}>
//             마지막 입금 3일이상 환전시 빨간색
//           </div>

//           <div style={{ backgroundColor: "#F5A9A9" }}>
//             계좌변경 하루 이내 환전시 빨간색
//           </div>
//           <div style={{ backgroundColor: "#F2F5A9" }}>
//             계좌변경 7일 이내 환전시 노란색
//           </div>
//           <div style={{ backgroundColor: "#A9F5A9" }}>
//             하루이상 입금없이 환전했을때 녹색
//           </div>
//           <div style={{ backgroundColor: "#A9A9F5" }}>
//             입금자이름과 출금자이름이 다르게 환전요청시 보라색
//           </div>
//           <div style={{ backgroundColor: "#F5D0A9" }}>
//             두개 이상조건 중복시 주황색
//           </div>

//           {/* 메인컨텐츠 */}
//           <div className="table-responsive" style={{ marginTop: "10px" }}>
//             <DataTable
//               value={this.state.userTableValue}
//               sortField="agentCode"
//               sortOrder={1}

//               rowGroupMode="rowspan"
//               style={{ marginTop: "30px", border: "1px solid #c8c8c8" }}
//             >
//               <Column
//                 field=""
//                 header="구분"
//                 style={{
//                   border: "1px solid #c8c8c8",

//                   height: "25px",
//                 }}
//                 headerStyle={{
//                   textAlign: "center",
//                   background: "#dee2e6",
//                   color: "#212529",
//                   borderRight: "1px solid #d8d8d8",
//                   height: "40px",
//                 }}
//               />
//               <Column
//                 field=""
//                 header="에이전트등급"
//                 style={{ border: "1px solid #c8c8c8" }}
//                 headerStyle={{
//                   textAlign: "center",
//                   background: "#dee2e6",
//                   color: "#212529",
//                   borderRight: "1px solid #d8d8d8",
//                   height: "40px",
//                 }}
//               />
//               <Column
//                 field=""
//                 header="에이전트"
//                 style={{ border: "1px solid #c8c8c8" }}
//                 headerStyle={{
//                   textAlign: "center",
//                   background: "#dee2e6",
//                   color: "#212529",
//                   borderRight: "1px solid #d8d8d8",
//                   height: "40px",
//                 }}
//               />
//               <Column
//                 style={{ textAlign: "center" }}
//                 headerStyle={{
//                   textAlign: "center",
//                   background: "#dee2e6",
//                   color: "#212529",
//                   borderRight: "1px solid #d8d8d8",
//                 }}
//                 header="환전금액"
//                 body={(rowData) => {
//                   return (
//                     <>
//                       <div>{numTomoeny(rowData.balance)}</div>
//                     </>
//                   );
//                 }}
//               />
//               <Column
//                 field=""
//                 header="은행명"
//                 style={{ border: "1px solid #c8c8c8" }}
//                 headerStyle={{
//                   textAlign: "center",
//                   background: "#dee2e6",
//                   color: "#212529",
//                   borderRight: "1px solid #d8d8d8",
//                   height: "40px",
//                 }}
//               />
//               <Column
//                 field=""
//                 header="계좌번호"
//                 style={{ border: "1px solid #c8c8c8" }}
//                 headerStyle={{
//                   textAlign: "center",
//                   background: "#dee2e6",
//                   color: "#212529",
//                   borderRight: "1px solid #d8d8d8",
//                   height: "40px",
//                 }}
//               />
//               <Column
//                 field=""
//                 header="예금주명"
//                 style={{ border: "1px solid #c8c8c8" }}
//                 headerStyle={{
//                   textAlign: "center",
//                   background: "#dee2e6",
//                   color: "#212529",
//                   borderRight: "1px solid #d8d8d8",
//                   height: "40px",
//                 }}
//               />
//               <Column
//                 field=""
//                 header="신청IP"
//                 style={{ border: "1px solid #c8c8c8" }}
//                 headerStyle={{
//                   textAlign: "center",
//                   background: "#dee2e6",
//                   color: "#212529",
//                   borderRight: "1px solid #d8d8d8",
//                   height: "40px",
//                 }}
//               />
//               <Column
//                 style={{ textAlign: "center" }}
//                 headerStyle={{
//                   textAlign: "center",
//                   background: "#dee2e6",
//                   color: "#212529",
//                   borderRight: "1px solid #d8d8d8",
//                 }}
//                 header="처리확인"
//                 body={(rowData) => {
//                   return (
//                     <>
//                       <Button
//                         type="button"
//                         label={"확인"}
//                         onClick={(e: any) => {
//                           this.OpenSearchLog();
//                         }}
//                         aria-haspopup
//                         aria-controls="overlay_panel"
//                         className="btn btn-primary btn-xs"
//                       />
//                     </>
//                   );
//                 }}
//               />
//               <Column
//                 style={{ textAlign: "center" }}
//                 headerStyle={{
//                   textAlign: "center",
//                   background: "#dee2e6",
//                   color: "#212529",
//                   borderRight: "1px solid #d8d8d8",
//                 }}
//                 header="처리취소"
//                 body={(rowData) => {
//                   return (
//                     <>
//                       <Button
//                         type="button"
//                         label={"취소"}
//                         onClick={(e: any) => {
//                           this.ProcessCancel();
//                         }}
//                         aria-haspopup
//                         aria-controls="overlay_panel"
//                         className="btn btn-primary btn-xs"
//                       />
//                     </>
//                   );
//                 }}
//               />
//               <Column
//                 field=""
//                 header="신청시각"
//                 style={{ border: "1px solid #c8c8c8" }}
//                 headerStyle={{
//                   textAlign: "center",
//                   background: "#dee2e6",
//                   color: "#212529",
//                   borderRight: "1px solid #d8d8d8",
//                   height: "40px",
//                 }}
//               />
//               <Column
//                 style={{ textAlign: "center" }}
//                 headerStyle={{
//                   textAlign: "center",
//                   background: "#dee2e6",
//                   color: "#212529",
//                   borderRight: "1px solid #d8d8d8",
//                 }}
//                 header="기존이력 조회"
//                 body={(rowData) => {
//                   return (
//                     <>
//                       <Button
//                         type="button"
//                         label={"조회"}
//                         onClick={(e: any) => {
//                           this.SearchLog();
//                         }}
//                         aria-haspopup
//                         aria-controls="overlay_panel"
//                         className="btn btn-primary btn-xs"
//                       />
//                     </>
//                   );
//                 }}
//               />
//               <Column
//                 field=""
//                 header="메모"
//                 style={{ border: "1px solid #c8c8c8" }}
//                 headerStyle={{
//                   textAlign: "center",
//                   background: "#dee2e6",
//                   color: "#212529",
//                   borderRight: "1px solid #d8d8d8",
//                   height: "40px",
//                 }}
//               />
//             </DataTable>
//           </div>
//           <div className="text-center">
//             <SubPaging
//               activePage={this.state.page}
//               maxCount={this.state.maxCount}
//               limit={20}
//               MoveSel={(page: number) => {
//                 this.MoveSel(page);
//               }}
//             ></SubPaging>
//           </div>
//         </section>

//         {/* ################################################################# */}
//         {/* 기존이력조회 레이어 */}
//         {/* ################################################################# */}
//         <div
//           className="modal fade in"
//           tabIndex={-1}
//           role="dialog"
//           style={{
//             display: this.state.SearchLogPopup === true ? "block" : "none",
//             paddingRight: "17px",
//           }}
//         >
//           <div
//             className="modal-content"
//             style={{
//               minWidth: "300px",
//               maxWidth: "600px",
//               minHeight: "300px",
//               maxHeight: "300px",
//               overflow: "auto",
//               top: "100px",
//               left: "30%",
//             }}
//           >
//             <div className="modal-header">
//               <button
//                 type="button"
//                 className="close"
//                 data-dismiss="modal"
//                 aria-hidden="true"
//                 onClick={this.CloseSearchLog}
//               >
//                 ×
//               </button>

//               <h4 className="modal-title">거래이력</h4>
//             </div>
//             <div className="modal-body">
//               <DataTable
//                 value={this.state.userTableValue2}
//                 sortField=""
//                 sortOrder={1}

//                 rowGroupMode="rowspan"
//                 style={{ marginTop: "30px", border: "1px solid #c8c8c8" }}
//               >
//                 <Column
//                   field=""
//                   header="종류"
//                   style={{
//                     border: "1px solid #c8c8c8",

//                     height: "25px",
//                   }}
//                   headerStyle={{
//                     textAlign: "center",
//                     background: "#dee2e6",
//                     color: "#212529",
//                     borderRight: "1px solid #d8d8d8",
//                     height: "40px",
//                   }}
//                 />
//                 <Column
//                   field="connectYn"
//                   header="아이디"
//                   style={{ border: "1px solid #c8c8c8" }}
//                   headerStyle={{
//                     textAlign: "center",
//                     background: "#dee2e6",
//                     color: "#212529",
//                     borderRight: "1px solid #d8d8d8",
//                     height: "40px",
//                   }}
//                 />
//                 <Column
//                   field=""
//                   header="닉네임"
//                   style={{ border: "1px solid #c8c8c8" }}
//                   headerStyle={{
//                     textAlign: "center",
//                     background: "#dee2e6",
//                     color: "#212529",
//                     borderRight: "1px solid #d8d8d8",
//                     height: "40px",
//                   }}
//                 />
//                 <Column
//                   field=""
//                   header="은행명"
//                   style={{ border: "1px solid #c8c8c8" }}
//                   headerStyle={{
//                     textAlign: "center",
//                     background: "#dee2e6",
//                     color: "#212529",
//                     borderRight: "1px solid #d8d8d8",
//                     height: "40px",
//                   }}
//                 />
//                 <Column
//                   field=""
//                   header="계좌번호"
//                   style={{ border: "1px solid #c8c8c8" }}
//                   headerStyle={{
//                     textAlign: "center",
//                     background: "#dee2e6",
//                     color: "#212529",
//                     borderRight: "1px solid #d8d8d8",
//                     height: "40px",
//                   }}
//                 />
//                 <Column
//                   field=""
//                   header="입금자명"
//                   style={{ border: "1px solid #c8c8c8" }}
//                   headerStyle={{
//                     textAlign: "center",
//                     background: "#dee2e6",
//                     color: "#212529",
//                     borderRight: "1px solid #d8d8d8",
//                     height: "40px",
//                   }}
//                 />
//                 <Column
//                   style={{ textAlign: "center" }}
//                   headerStyle={{
//                     textAlign: "center",
//                     background: "#dee2e6",
//                     color: "#212529",
//                     borderRight: "1px solid #d8d8d8",
//                   }}
//                   header="금액"
//                   body={(rowData) => {
//                     return (
//                       <>
//                         <div>{numTomoeny(rowData.balance)}</div>
//                       </>
//                     );
//                   }}
//                 />
//                 <Column
//                   field=""
//                   header="상태"
//                   style={{ border: "1px solid #c8c8c8" }}
//                   headerStyle={{
//                     textAlign: "center",
//                     background: "#dee2e6",
//                     color: "#212529",
//                     borderRight: "1px solid #d8d8d8",
//                     height: "40px",
//                   }}
//                 />
//                 <Column
//                   field=""
//                   header="처리시각"
//                   style={{ border: "1px solid #c8c8c8" }}
//                   headerStyle={{
//                     textAlign: "center",
//                     background: "#dee2e6",
//                     color: "#212529",
//                     borderRight: "1px solid #d8d8d8",
//                     height: "40px",
//                   }}
//                 />
//                 <Column
//                   field=""
//                   header="담당자"
//                   style={{ border: "1px solid #c8c8c8" }}
//                   headerStyle={{
//                     textAlign: "center",
//                     background: "#dee2e6",
//                     color: "#212529",
//                     borderRight: "1px solid #d8d8d8",
//                     height: "40px",
//                   }}
//                 />
//                 <Column
//                   field=""
//                   header="메모"
//                   style={{ border: "1px solid #c8c8c8" }}
//                   headerStyle={{
//                     textAlign: "center",
//                     background: "#dee2e6",
//                     color: "#212529",
//                     borderRight: "1px solid #d8d8d8",
//                     height: "40px",
//                   }}
//                 />
//                 <Column
//                   field=""
//                   header="일반/수기"
//                   style={{ border: "1px solid #c8c8c8" }}
//                   headerStyle={{
//                     textAlign: "center",
//                     background: "#dee2e6",
//                     color: "#212529",
//                     borderRight: "1px solid #d8d8d8",
//                     height: "40px",
//                   }}
//                 />
//               </DataTable>
//             </div>
//             <div className="modal-footer">
//               <div className="pull-right">
//                 <div className="form-inline">
//                   <div className="form-group">
//                     <Button
//                       label="닫기"
//                       onClick={() => {
//                         this.CloseSearchLog();
//                       }}
//                       className="btn btn-default btn-sm"
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </>
//     );
//   }
// }
