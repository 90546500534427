import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { GetToday, ConvertCalendar, numTomoeny, ConvertTreeToAgents, uuidv4 } from "../utility/help";
import { SubPaging } from "../share/sub-paging";
import { BetService } from "../service/bet.service";
import { CCalendarTime } from "./custom/ccalendartime";
import { ShareTooltip } from "./share.tooltip";
import { TotalUserSlot, TotalUserCasino } from "./history_4_userbet.share";
import { CDropdown } from "./custom/cdropdown";

import { TooltipTree } from "../utility/optionpack";

enum RenderMode {
  Slot,
  Casino
}

interface State {
  page: number;
  maxCount: number;

  begin: any;
  end: any;

  betSummary: any;
  searchText: string;
  findText: string;

  totalSummary: any;
  render: RenderMode
}

export class HistoryUserBet extends Component<any, State> {
  userService = new UserService();
  fileService = new FileService();
  betService = new BetService();

  menu: any;

  constructor(props: any) {
    super(props);
    let today = GetToday();


    this.state = {
      render: RenderMode.Slot,
      page: 1,
      maxCount: 1,


      begin: today.begin,
      end: today.end,

      betSummary: [],
      findText: "",
      searchText: "",

      totalSummary: {}

    };

    this.Sort = this.Sort.bind(this);

    this.GetUserTotalBetting(1);

    // this.GetUserList(0, "", "", "regdate", 1);
  }


  componentDidMount() { }

  GetUserTotalBetting = async (page: number) => {
    const summarys = await this.betService
      .get_user_total_betting({
        skip: page,
        begin: this.state.begin.toLocaleString("sv-SE"),
        end: this.state.end.toLocaleString("sv-SE"),
        findText: this.state.findText,
        searchText: this.state.searchText
      })


    if (summarys.status === 'success') {

      let totalSummary = {
        totalCasinoBet: 0,
        totalCasinoBetCount: 0,
        totalCasinoWin: 0,
        totalCasinoWinCount: 0,
        totalSlotBet: 0,
        totalSlotBetCount: 0,
        totalSlotWin: 0,
        totalSlotWinCount: 0,
        totalUser: summarys.totals.length
      }

      const totals: any = []
      for (const summary of summarys.totals) {


        totalSummary.totalCasinoBet += summary.totalCasinoBet
        totalSummary.totalCasinoBetCount += summary.totalCasinoBetCount
        totalSummary.totalCasinoWin += summary.totalCasinoWin
        totalSummary.totalCasinoWinCount += summary.totalCasinoWinCount
        totalSummary.totalSlotBet += summary.totalSlotBet
        totalSummary.totalSlotBetCount += summary.totalSlotBetCount
        totalSummary.totalSlotWin += summary.totalSlotWin
        totalSummary.totalSlotWinCount += summary.totalSlotWinCount

        const info = Object.assign(summary, summary._id)

        info._id = uuidv4()
        info.totalSlotBetWin = summary.totalSlotBet - summary.totalSlotWin
        info.totalCasinoBetWin = summary.totalCasinoBet - summary.totalCasinoWin

        totals.push(info)

      }
      this.setState({ betSummary: totals, totalSummary: totalSummary });
    }

  }


  Sort = (name, isReverse) => {

    const betSummary = this.state.betSummary
    betSummary.sort(function (a, b) {
      if (a[name] > b[name]) return 1;
      if (a[name] < b[name]) return -1;
      return 0;
    });

    if (isReverse) {
      betSummary.reverse()
    }

    this.setState({ betSummary: betSummary })
  }


  makeTable = (rowData) => {
    const trees: any = []
    const tree = ConvertTreeToAgents(rowData.agent_tree)
    const tree_ko = ConvertTreeToAgents(rowData.agent_tree_ko)


    for (let ii = 0; ii < tree.length; ii++) {
      trees.push({ level: ii + 1, agent: tree[ii], nick: tree_ko[ii] })
    }



    return trees
  }



  render() {

    const totalSummary = this.state.totalSummary
    return (
      <>
        <section className="content-header">
          <h1>회원베팅 내역</h1>
        </section>

        <section className="content">
          <div className="form-inline">
            <div className="form-group">

              <CCalendarTime
                placeholder="종료일"
                value={this.state.begin}
                onChange={(e) => {
                  this.setState({ begin: e })
                }}

              />
            </div>

            <div className="form-group">
              <CCalendarTime
                placeholder="종료일"
                value={this.state.end}
                onChange={(e) => {
                  this.setState({ end: e })
                }}

              />
            </div>

            <div className="form-group">
              <Button
                label="기간재입력"
                onClick={() => {
                  let today = GetToday();

                  this.setState({
                    begin: today.begin,
                    end: today.end,
                  });
                }}
                className="btn btn-default btn-sm"
              />
            </div>

            <div className="form-group">
              <CDropdown
                options={[
                  { label: "아이디", value: "id" },
                  { label: "닉네임", value: "nick" },
                  { label: "에이전트(하부포함X)", value: "agent" },
                  { label: "에이전트(하부포함)", value: "tree" },
                ]}
                value={this.state.searchText}
                onChange={(event) => this.setState({ searchText: event.value })}

              />
            </div>
            <div className="form-group">
              <InputText
                value={this.state.findText}
                onChange={(e: any) => this.setState({ findText: e.target.value })}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    this.GetUserTotalBetting(1)
                  }
                }}
                className="form-control input-sm"
                style={{ width: "200px" }}
                placeholder="검색어를 입력해 주세요"
              />
            </div>

            <div className="form-group">
              <Button
                label="조회"
                onClick={() => {
                  this.GetUserTotalBetting(1)
                }}
                className="btn btn-primary btn-sm"
              />
            </div>
            <div className="form-group">
              <Button
                label="재입력"
                onClick={() => this.setState({ findText: '' })}
                className="btn btn-default btn-sm"
              />
            </div>
          </div>

          {/* 설명 */}


          <ul className="nav nav-tabs" style={{ marginBottom: "20px" }}>

            <li className={this.state.render === RenderMode.Slot ? "active" : ""}>
              <a
                onClick={() => {
                  this.setState({ render: RenderMode.Slot });
                }}
              >
                슬롯
              </a>
            </li>
            <li className={this.state.render === RenderMode.Casino ? "active" : ""}>
              <a
                onClick={() => {
                  this.setState({ render: RenderMode.Casino });
                }}
              >
                카지노
              </a>
            </li>
          </ul>
          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
            <table className="table table-bordered table-condensed">
              <tbody>
                <tr style={{ backgroundColor: "#DDDDDD" }}>
                  <th colSpan={7} className="text-center">
                    기간별 회원배팅 요약 정보
                  </th>
                </tr>
                <tr style={{ backgroundColor: "#DDDDDD" }}>
                  <th colSpan={1} className="text-center">
                    회원수
                  </th>
                  <th colSpan={1} className="text-center">총 베팅건수</th>
                  <th colSpan={1} className="text-center">총 베팅금액</th>
                  <th colSpan={1} className="text-center">WIN 금액</th>
                  <th colSpan={1} className="text-center">이익 금액</th>
                </tr>
                {
                  this.state.render === RenderMode.Casino ? (
                    <tr>
                      <td
                        style={{ backgroundColor: "#EEEEEE" }}
                        className="text-right"
                        colSpan={1}>{numTomoeny(totalSummary.totalUser)}</td>
                      <td
                        style={{ backgroundColor: "#EEEEEE", }}
                        className="text-right"
                        colSpan={1}
                      >{numTomoeny(totalSummary.totalCasinoBetCount)}</td>
                      <td
                        style={{ backgroundColor: "#EEEEEE", color: 'blue' }}
                        className="text-right"
                        colSpan={1}
                      >{numTomoeny(totalSummary.totalCasinoBet)}</td>
                      <td
                        style={{ backgroundColor: "#EEEEEE", color: 'red' }}
                        className="text-right"
                        colSpan={1}
                      >
                        {numTomoeny(totalSummary.totalCasinoWin)}
                      </td>


                      <td
                        style={{ backgroundColor: "#EEEEEE", color: (totalSummary.totalCasinoBet - totalSummary.totalCasinoWin) > 0 ? 'blue' : 'red' }}
                        className="text-right"
                        colSpan={1}
                      >
                        {numTomoeny(totalSummary.totalCasinoBet - totalSummary.totalCasinoWin)}
                      </td>


                    </tr>
                  ) : (
                    <tr>
                      <td
                        style={{ backgroundColor: "#EEEEEE" }}
                        className="text-right"
                        colSpan={1}>{numTomoeny(totalSummary.totalUser)}</td>
                      <td
                        style={{ backgroundColor: "#EEEEEE", }}
                        className="text-right"
                        colSpan={1}
                      >{numTomoeny(totalSummary.totalSlotBetCount)}</td>
                      <td
                        style={{ backgroundColor: "#EEEEEE", color: 'blue' }}
                        className="text-right"
                        colSpan={1}
                      >{numTomoeny(totalSummary.totalSlotBet)}</td>
                      <td
                        style={{ backgroundColor: "#EEEEEE", color: 'red' }}
                        className="text-right"
                        colSpan={1}
                      >
                        {numTomoeny(totalSummary.totalSlotWin)}
                      </td>


                      <td
                        style={{ backgroundColor: "#EEEEEE", color: (totalSummary.totalSlotBet - totalSummary.totalSlotWin) > 0 ? 'blue' : 'red' }}
                        className="text-right"
                        colSpan={1}
                      >
                        {numTomoeny(totalSummary.totalSlotBet - totalSummary.totalSlotWin)}
                      </td>
                    </tr>
                  )
                }


              </tbody>
            </table>
          </div>

          {/* 메인컨텐츠 */}
          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
            {
              this.state.render === RenderMode.Slot && <TotalUserSlot betSummary={this.state.betSummary} Sort={this.Sort}></TotalUserSlot>
            }
            {
              this.state.render === RenderMode.Casino && <TotalUserCasino betSummary={this.state.betSummary} Sort={this.Sort}></TotalUserCasino>
            }
          </div>

        </section >
      </>
    );
  }
}
