import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import {
  GetNow,
  GetToday,
  ConvertCalendar,
  numTomoeny,
  convertSimpleDate,
  ConvertDate,
} from "../utility/help";
import { SubPaging } from "../share/sub-paging";
import { ShareBalanceTable } from "./share.balance.table";
import { ShareBalanceMenu } from "./share.balance.menu";


import { Link } from "react-router-dom";
import { AgentService } from "../service/agent.service";


interface State {
  page: number;
  maxCount: number;
  total: any;


  balances: any[];
  dropdownItem: string;
  dropdownItem2: string;
  dropdownItem3: string;
  viewType: string;
  selectViewType: string;
  selectBalanceType: string;

  cloneParams: any

  begin: any
  end: any
}

export class OrderManageUserCmpl extends Component<any, State> {
  userService = new UserService();
  fileService = new FileService();
  agentService = new AgentService()

  menu: any;

  constructor(props: any) {
    super(props);

    console.log('startGetToday')
    let today = GetToday()
    console.log(today)
    console.log('end GetToday')
    this.state = {
      page: 1,
      maxCount: 1,
      total: null,
      begin: today.begin,
      end: today.end,

      balances: [],
      dropdownItem: "id",
      dropdownItem2: "ALL",
      dropdownItem3: "",

      viewType: "all",
      selectViewType: "deposit",
      selectBalanceType: "user",

      cloneParams: {}
    };


    this.handleUserBalance = this.handleUserBalance.bind(this)

    this.handleUserBalance({ skip: 1, selectView: this.state.selectViewType, selectBalanceType: this.state.selectBalanceType });
  }



  componentDidMount() { }

  handleUserBalance = async (params) => {

    // this.state.begin.toLocaleString('sv-SE'),
    // this.state.end.toLocaleString('sv-SE'), skip, selectView, '', '').then((s) => {
    //   this.setState({
    //     total: s.total,
    //     maxCount: s.maxCount,
    //     balances: s.balance,
    //   })
    this.agentService.agent_get_deposit_and_withdraw(Object.assign({
      begin: this.state.begin.toLocaleString('sv-SE'),
      end: this.state.begin.toLocaleString('sv-SE'),
      balanceType: this.state.viewType
    }, params)).then((s) => {
      this.setState({
        total: s.total,
        maxCount: s.maxCount,
        balances: s.balances,
      });
    });

    // begin: any, end: any, skip: any, selectView: string, find: string, text: string
    // let data = await this.balanceService
    //   .get_deposit_and_withdraw_total_balance(params);
    // this.setState({
    //   maxCount: data.maxCount,
    //   balances: data.balances,
    //   total: data.total,
    // });

  };


  // handleUserBalance = (skip: any, selectView: string) => {
  //   this.setState({ page: skip });
  //   this.agentService.agent_get_agent_balance_list(this.state.begin.toLocaleString('sv-SE'),
  //     this.state.end.toLocaleString('sv-SE'), skip, selectView, '', '').then((s) => {
  //       this.setState({
  //         total: s.total,
  //         maxCount: s.maxCount,
  //         balances: s.balance,
  //       });
  //     });
  // };

  render() {
    return (
      <>
        <section className="content-header">
          <h1>회원 입출금 내역</h1>
        </section>

        <section className="content">
          <ul className="nav nav-tabs" style={{ marginBottom: "20px" }}>

            <li className={(this.state.selectBalanceType === "user") ? "active" : ""}>
              <a
                onClick={() => {

                  this.state.cloneParams.viewType = "all"
                  this.state.cloneParams.selectBalanceType = "user"

                  this.setState({ selectBalanceType: "user", viewType: "all", cloneParams: this.state.cloneParams });
                  setTimeout(() => this.handleUserBalance(this.state.cloneParams));
                }}
              >
                입금/출금
              </a>
            </li>

            <li className={this.state.selectBalanceType === 'agent' ? "active" : ""}>
              <a
                onClick={() => {

                  this.state.cloneParams.viewType = "all"
                  this.state.cloneParams.selectBalanceType = "agent"

                  this.setState({ selectBalanceType: 'agent', viewType: "all", cloneParams: this.state.cloneParams });
                  setTimeout(() => this.handleUserBalance(this.state.cloneParams));
                }}
              >
                에이전트 지급/회수
              </a>
            </li>

            <li className={(this.state.selectBalanceType === 'center') ? "active" : ""}>
              <a
                onClick={() => {

                  this.state.cloneParams.viewType = "all"
                  this.state.cloneParams.selectBalanceType = "center"

                  this.setState({ selectBalanceType: 'center', viewType: "all", cloneParams: this.state.cloneParams });
                  setTimeout(() => this.handleUserBalance(this.state.cloneParams));
                }}
              >
                관리자 지급/회수
              </a>
            </li>

            <li className={this.state.selectBalanceType === "rolling" ? "active" : ""}>
              <a
                onClick={() => {

                  this.state.cloneParams.viewType = "all"
                  this.state.cloneParams.selectBalanceType = "rolling"

                  this.setState({ selectBalanceType: 'rolling', viewType: "all", cloneParams: this.state.cloneParams });
                  setTimeout(() => this.handleUserBalance(this.state.cloneParams));
                }}
              >
                콤프전환
              </a>
            </li>
          </ul>

          <ul className="nav nav-tabs" style={{ marginBottom: "20px" }}>
            <li className={this.state.viewType === "all" ? "active" : ""}>
              <a
                onClick={() => {

                  this.state.cloneParams.viewType = "all"

                  this.setState({ viewType: "all", cloneParams: this.state.cloneParams });
                  setTimeout(() => this.handleUserBalance(this.state.cloneParams));
                }}
              >
                모두
              </a>
            </li>
            <li className={this.state.viewType === "deposit" ? "active" : ""}>
              <a
                onClick={() => {

                  this.state.cloneParams.viewType = "deposit"

                  this.setState({ viewType: "deposit", cloneParams: this.state.cloneParams });
                  setTimeout(() => this.handleUserBalance(this.state.cloneParams));

                }}
              >
                충전
              </a>
            </li>
            <li className={this.state.viewType === "withdraw" ? "active" : ""}>
              <a
                onClick={() => {

                  this.state.cloneParams.viewType = "withdraw"

                  this.setState({ viewType: "withdraw", cloneParams: this.state.cloneParams });
                  setTimeout(() => this.handleUserBalance(this.state.cloneParams));
                }}
              >
                환전
              </a>
            </li>
          </ul>

          <ShareBalanceMenu GetInfo={(params: any) => {
            params.selectViewType = this.state.selectViewType
            params.selectBalanceType = this.state.selectBalanceType
            params.viewType = this.state.viewType

            this.setState({ cloneParams: params })
            this.handleUserBalance(params)
          }}></ShareBalanceMenu>


          {/* 설명 */}
          <div className="table-responsive" style={{ marginTop: "10px" }}>
            <table className="table table-bordered table-condensed">
              <tbody>
                <tr>
                  <th colSpan={12}>일일 통합 거래 요약 정보</th>
                </tr>
                {
                  (this.state.total != null && this.state.total[0] != null) && (
                    <tr>
                      <th className="col-md-1">거래 건수</th>
                      <td className="col-md-1 text-right">{this.state.total[0].depositCount + this.state.total[0].withdrawCount}</td>
                      <th className="col-md-1">거래 총 합계</th>
                      <td className="col-md-1 text-right">{numTomoeny(this.state.total[0].deposit - this.state.total[0].withdraw)}</td>
                      <th className="col-md-1" style={{ color: 'blue' }}>입금 건수</th>
                      <td className="col-md-1 text-right" style={{ color: 'blue' }}>{this.state.total[0].depositCount}</td>
                      <th className="col-md-1" style={{ color: 'blue' }}>입금 총 합계</th>
                      <td className="col-md-1 text-right">{numTomoeny(this.state.total[0].deposit)}</td>
                      <th className="col-md-1" style={{ color: 'red' }}>출금 건수</th>
                      <td className="col-md-1 text-right">{this.state.total[0].withdrawCount}</td>
                      <th className="col-md-1" style={{ color: 'red' }}>출금 총 합계</th>
                      <td className="col-md-1 text-right" style={{ color: 'red' }}>{numTomoeny(this.state.total[0].withdraw)}</td>
                    </tr>
                  )
                }회원 입출금 내역



              </tbody>
            </table>
          </div>

          {/* 메인컨텐츠 */}

          <ShareBalanceTable balances={this.state.balances}></ShareBalanceTable>
          <div className="text-center">
            <SubPaging
              activePage={this.state.page}
              maxCount={this.state.maxCount}
              limit={20}
              MoveSel={(page: number) => {
                this.setState({ page: page })
                this.handleUserBalance(Object.assign(this.state.cloneParams, { skip: page }))
              }}
            ></SubPaging>
          </div>
        </section>
      </>
    );
  }
}
