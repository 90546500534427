
export default (name) => {

    document.getElementById(name).innerHTML = "1"

}

// import React, { Component } from "react";

// import {
//     connect, JPlayer, Gui, SeekBar, BufferBar,
//     Poster, Audio, Title, FullScreen, Mute, Play, PlayBar, Repeat,
//     VolumeBar, Duration, CurrentTime, Download, BrowserUnsupported,
// } from 'react-jplayer';

// const defaultOptions = {
//     id: 'AudioPlayer',
//     keyEnabled: true,
//     verticalVolume: true,
//     media: {
//         title: 'Bubble',
//         artist: 'Miaow',
//         sources: {
//             m4a: 'http://jplayer.org/audio/m4a/Miaow-07-Bubble.m4a',
//             oga: 'http://jplayer.org/audio/ogg/Miaow-07-Bubble.ogg',
//         },
//         free: true,
//     },
// };

// const AudioPlayer = props => (
//     <JPlayer className="jp-sleek">
//         <Audio events={props.events} />
//         <Gui>
//             <div className="jp-controls jp-icon-controls">
//                 <Play><i className="fa">{/* Icon set in css*/}</i></Play>
//                 <Repeat><i className="fa fa-repeat" /></Repeat>
//                 <div className="jp-progress">
//                     <SeekBar>
//                         <BufferBar />
//                         <PlayBar />
//                         <CurrentTime />
//                         <Duration />
//                     </SeekBar>
//                 </div>
//                 <div className="jp-volume-container">
//                     {/* <Mute
//                         aria-haspopup
//                         onTouchStart={isVolumeBarVisible}
//                         onClick={() => setMuteIfVolumeVisible(props.setMute, props.id, props.options.muted)}
//                     >
//                     </Mute> */}
//                     <div className="jp-volume-slider">
//                         <div className="jp-volume-bar-container">
//                             <VolumeBar />
//                         </div>
//                     </div>
//                 </div>
//                 <FullScreen><i className="fa fa-expand" /></FullScreen>
//                 <Download><i className="fa fa-download" /></Download>
//                 {/* <div className="jp-title-container">
//                     <Poster />
//                     <Title />
//                 </div> */}
//             </div>
//             <BrowserUnsupported />
//         </Gui >
//     </JPlayer >
// );

// const options = {
//     id: 'AudioPlayer',
//     keyEnabled: true,
//     verticalVolume: true,
//     media: {
//         title: 'Bubble',
//         artist: 'Miaow',
//         sources: {
//             m4a: 'http://jplayer.org/audio/m4a/Miaow-07-Bubble.m4a',
//             oga: 'http://jplayer.org/audio/ogg/Miaow-07-Bubble.ogg',
//         },
//         free: true,
//     },
// };

// export default connect(AudioPlayer, options);