

import React, { Component } from "react";
import { Dropdown } from 'primereact/dropdown';

interface Prop {
    width?: any
    optionLabel?: any
    options: any
    value: any
    onChange: (event) => any


}

interface State {
}

export class CDropdown extends Component<Prop, State> {

    constructor(props: any) {
        super(props);
        this.state = {
        };


        // this.GetUserList(0, "", "", "regdate", 1);
    }

    componentDidMount() { }


    render() {
        return (
            <Dropdown
                value={this.props.value}
                options={this.props.options}
                onChange={this.props.onChange}

                optionLabel={this.props.optionLabel}
                placeholder="선택해주세요"
                style={{ width: this.props.width ? this.props.width : "120px" }}

            />
        );
    }
}
