import React, { Component, useState, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";


import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { confirmAlert } from "react-confirm-alert";

import { CDropdown } from "./custom/cdropdown";



import { BalanceService } from "../service/balance.service";


import { ConvertDate, convertToCSV, ConverBalanceStatus, ConvertAgentLogText, GetNow, numTomoeny, OpenUserDetail, OpenAgentDetail, ConvertBalanceType } from "../utility/help";


interface Prop {
    balances: any
}


interface State {
    balance: string

    userInfo: any
    dropdownItem: string
    search: any
    find: string

    status: string
}

export class ShareBalanceTable extends Component<Prop, State> {
    balanceService: BalanceService = new BalanceService();



    constructor(props: Prop) {
        super(props);

        this.state = {
            balance: '',
            userInfo: null,
            dropdownItem: "user_id",
            search: "",
            status: "",
            find: "",

        }


    }


    render() {

        const rowClass = (data) => {
            if (data.logType === "deposit" || data.logType === "agentdeposit" || data.logType === "admindeposit") return 'row-balance-deposit'
            if (data.logType === "withdraw" || data.logType === "agentwithdraw" || data.logType === "adminwithdraw") return 'row-balance-withdraw '


            return ' '
        }

        return (
            <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
                <DataTable

                    rowClassName={rowClass}

                    value={this.props.balances}
                    sortField="agentCode"
                    sortOrder={1}

                    rowGroupMode="rowspan"
                    style={{ marginTop: "30px", border: "1px solid #c8c8c8" }}
                    scrollable
                    scrollHeight="800px"
                >
                    <Column
                        header="종류"
                        headerStyle={{ textAlign: "center", width: "200px", flex: 'none', height: "40px" }}
                        bodyStyle={{ textAlign: "center", width: "200px", flex: 'none' }}

                        body={(rowData: any, column: any) => {
                            if (rowData.logType == "deposit") {
                                return <div style={{ color: "blue" }}>유저 충전 </div>;
                            }

                            if (rowData.logType == "agentdeposit") {
                                return <div style={{ color: "blue" }}>에이전트 지급</div>;
                            }

                            if (rowData.logType == "admindeposit") {
                                return <div style={{ color: "blue" }}>센터 지급</div>;
                            }

                            if (rowData.logType == "withdraw") {
                                return <div style={{ color: "red" }}>유저 환전</div>;
                            }

                            if (rowData.logType == "agentwithdraw") {
                                return <div style={{ color: "red" }}>에이전트 회수</div>;
                            }

                            if (rowData.logType == "adminwithdraw") {
                                return <div style={{ color: "red" }}>센터 회수</div>;
                            }

                            if (rowData.logType == "withdrawcancel") {
                                return <div style={{ color: "blue" }}>환전 취소</div>;
                            }
                            if (rowData.logType == "depositcancel") {
                                return <div style={{ color: "blue" }}>충전 취소</div>;
                            }

                            if (rowData.logType == "agent_withdraw_agent") {
                                return <div style={{ color: "red" }}>에이전트회수</div>;
                            }
                            if (rowData.logType == "agent_deposit_agent") {
                                return <div style={{ color: "blue" }}>에이전트지급</div>;
                            }

                            if (rowData.logType == "exchange_rolling_to_balance") {
                                return <div style={{ color: "blue" }}>롤링전환</div>;
                            }

                            return "-";
                        }}
                    />

                    <Column
                        field="id"
                        header="아이디"
                        headerStyle={{ textAlign: "center", width: "200px", height: "40px", flex: 'none' }}
                        bodyStyle={{ textAlign: "center", width: "200px", flex: 'none' }}

                        body={(rowData) => {
                            return (
                                <div>{rowData.id}</div>
                            );
                        }}

                    />

                    <Column
                        field="nick"
                        header="닉네임"
                        headerStyle={{ textAlign: "center", width: "200px", height: "40px", flex: 'none' }}
                        bodyStyle={{ textAlign: "center", width: "200px", flex: 'none' }}

                    />
                    <Column
                        field="agent"
                        header="에이전트"
                        headerStyle={{ textAlign: "center", width: "200px", height: "40px", flex: 'none' }}
                        bodyStyle={{ textAlign: "center", width: "200px", flex: 'none' }}


                    />
                    {/* <Column
                        field="bankname"
                        header="은행명"
                        headerStyle={{ textAlign: "center", width: "100px", height: "40px", }}

                    />
                    <Column
                        field="banknum"
                        header="계좌번호"
                        headerStyle={{ textAlign: "center", width: "100px", height: "40px", }}

                    />
                    <Column
                        field="owner"
                        header="입금자명"
                        headerStyle={{ textAlign: "center", width: "100px", height: "40px", }}

                    /> */}
                    <Column
                        field="owner"
                        header="이름"
                        headerStyle={{ textAlign: "center", width: "200px", height: "40px", flex: 'none' }}

                        bodyStyle={{ textAlign: "center", width: "200px", flex: 'none' }}

                    />
                    <Column
                        headerStyle={{ textAlign: "center", width: "200px", height: "40px" }}

                        bodyStyle={{ textAlign: "center", width: "200px" }}
                        header="금액"
                        body={(rowData) => {
                            if (rowData.balance <= 0) {
                                return (
                                    <div>{numTomoeny(rowData.balance * -1)}</div>
                                );
                            }
                            return (
                                <div>{numTomoeny(rowData.balance)}</div>
                            );
                        }}
                    />

                    <Column
                        header="머니이력"

                        headerStyle={{ textAlign: "center", width: "350px", height: "40px" }}
                        bodyStyle={{ textAlign: "center", width: "350px" }}
                        body={(rowData: any, column: any) => {

                            return <span style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: ConvertBalanceType(rowData.agent, rowData.id, rowData.giveId, rowData.targetId, rowData.logType) }} ></span>
                        }}
                    />

                    {/* <Column
                        field=""
                        header="상태"
                        body={(rowData) => {
                            return ConverBalanceStatus(rowData.type, rowData.status)
                        }}
                    /> */}
                    <Column
                        header="처리시각"

                        headerStyle={{ textAlign: "center", width: "200px", height: "40px" }}
                        bodyStyle={{ textAlign: "center", width: "200px" }}

                        body={(rowData) => {
                            return (
                                <span>{ConvertDate(rowData.regdate)}</span>
                            );
                        }}
                    />

                    {/*
                    <Column
                        header="처리IP"

                        body={(rowData) => {
                            if (rowData.ip == null || rowData.ip === '') return '-'

                            return (
                                <span>{rowData.ip.replace('"::ffff:', '')}</span>
                            );
                        }}
                    /> */}


                </DataTable>
            </div>
        )
    }
}



export class ShareAgentBalanceTable extends Component<Prop, State> {
    balanceService: BalanceService = new BalanceService();



    constructor(props: Prop) {
        super(props);

        this.state = {
            balance: '',
            userInfo: null,
            dropdownItem: "user_id",
            search: "",
            status: "",
            find: "",

        }


    }


    render() {

        const rowClass = (data) => {
            if (data.logType === "deposit" || data.logType === "agentdeposit" || data.logType === "admindeposit") return 'row-balance-deposit'
            if (data.logType === "withdraw" || data.logType === "agentwithdraw" || data.logType === "adminwithdraw") return 'row-balance-withdraw '


            return ' '
        }

        return (
            <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
                <DataTable

                    rowClassName={rowClass}

                    value={this.props.balances}
                    sortField="agentCode"
                    sortOrder={1}
                    scrollable
                    scrollHeight="800px"
                >
                    <Column
                        header="종류"
                        headerStyle={{ textAlign: "center", width: "150px", height: "40px" }}
                        bodyStyle={{ textAlign: "center", width: "150px" }}


                        body={(rowData: any, column: any) => {
                            if (rowData.logType == "change_to_balance" || rowData.logType == "admin_do_deposit") {
                                return <div style={{ color: "blue" }}>에이전트 입금 </div>;
                            }


                            if (rowData.logType == "exchange_to_balance") {
                                return <div style={{ color: "red" }}>에이젼트 환전</div>;
                            }

                            if (rowData.logType == "agent_deposit_agent") {
                                return <div style={{ color: "blue" }}>에이전트 {"=>"} 에이전트 지급</div>;
                            }

                            if (rowData.logType == "agent_withdraw_agent") {
                                return <div style={{ color: "red" }}>에이전트 {"=>"} 에이전트 회수</div>;
                            }

                            if (rowData.logType == "admin_give_agent_balance_deposit") {
                                return <div style={{ color: "blue" }}>센터 지급</div>;
                            }

                            if (rowData.logType == "admin_give_agent_balance_withdraw") {
                                return <div style={{ color: "red" }}>센터 회수</div>;
                            }



                            if (rowData.logType == "admin_do_withdraw") {
                                return <div style={{ color: "red" }}>에이전트 환전</div>;
                            }

                            if (rowData.logType == "agentwithdraw" || rowData.logType == "withdraw_agent" || rowData.logType == "agent_withdraw_agent") {
                                return <div style={{ color: "red" }}>에이전트 회수</div>;
                            }

                            if (rowData.logType == "adminwithdraw") {
                                return <div style={{ color: "red" }}>환전 완료</div>;
                            }

                            if (rowData.logType == "admin_give_agent_balance_withdraw") {
                                return <div style={{ color: "red" }}>센터 회수</div>;
                            }

                            if (rowData.logType == "withdrawcancel") {
                                return <div style={{ color: "orange" }}>환전 취소</div>;
                            }
                            if (rowData.logType == "depositcancel") {
                                return <div style={{ color: "orange" }}>충전 취소</div>;
                            }
                            if (rowData.logType == "agent_withdraw_agent") {
                                return <div style={{ color: "red" }}>에이전트회수</div>;
                            }
                            if (rowData.logType == "agent_deposit_agent") {
                                return <div style={{ color: "blue" }}>에이전트지급</div>;
                            }
                            if (rowData.type == "admin_give_agent_balance_withdraw") {
                                return <div style={{ color: "green" }}>센터회수</div>;
                            }



                            return `미정의`;
                        }}
                    />
                    <Column
                        field="agent"
                        header="에이전트"
                        headerStyle={{ textAlign: "center", width: "150px", height: "40px", flex: 'none' }}
                        bodyStyle={{ textAlign: "center", width: "150px", flex: 'none' }}

                        body={(rowData) => {
                            return (
                                <div>{rowData.agent}</div>
                            );
                        }}
                    />

                    <Column
                        field="nick"
                        header="닉네임"
                        headerStyle={{ textAlign: "center", width: "150px", height: "40px", flex: 'none' }}
                        bodyStyle={{ textAlign: "center", width: "150px", flex: 'none' }}
                    />

                    <Column
                        field="owner"
                        header="이름"
                        headerStyle={{ textAlign: "center", width: "150px", height: "40px", flex: 'none' }}
                        bodyStyle={{ textAlign: "center", width: "150px", flex: 'none' }}
                    />

                    <Column
                        style={{ textAlign: "center" }}
                        headerStyle={{ textAlign: "center", width: "150px", height: "40px", }}
                        bodyStyle={{ textAlign: "center", width: "150px", }}
                        header="금액"
                        body={(rowData) => {
                            if (rowData.balance < 0) {
                                return (
                                    <div>{numTomoeny(rowData.balance * -1)}</div>
                                );
                            }
                            return (
                                <div>{numTomoeny(rowData.balance)}</div>
                            );
                        }}
                    />


                    <Column
                        header="내용"
                        headerStyle={{ textAlign: "center", width: "350px", height: "40px", }}
                        bodyStyle={{ textAlign: "center", width: "350px", }}
                        body={(rowData: any, column: any) => {

                            return <span dangerouslySetInnerHTML={{ __html: ConvertBalanceType(rowData.agent, rowData.id, rowData.giveId, rowData.targetId, rowData.logType) }} ></span>
                        }}
                    />

                    <Column
                        header="처리시각"
                        headerStyle={{ textAlign: "center", width: "200px", height: "40px" }}
                        bodyStyle={{ textAlign: "center", width: "200px" }}

                        body={(rowData) => {
                            return (
                                <span>{ConvertDate(rowData.regdate)}</span>
                            );
                        }}
                    />

                </DataTable>
            </div>
        )
    }
}
