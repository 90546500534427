import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { GetNow, ConvertCalendar, numTomoeny } from "../utility/help";
import { SubPaging } from "../share/sub-paging";
import { AgentService } from "../service/agent.service";

// import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

interface State {
  casionRolling: number;
  slotRolling: number;
  totalBalance: number;
  realBetCount: number;
  realMiniCount: number;
  deposit: number;
  withdraw: number;

  depositAgent: number;
  withdrawAgent: number;

  userCount: number;
  todayCount: number;
  connectCount: number;
  losing: number;
  rolling: number;

  losingBenefits: number;
  slotBenefits: number;
  casinoBenefits: number;

  regCount: number;
  totalBetCount: number;

  matchBetCount: number;
  matchWaitCount: number;
  miniBetCount: number;
  miniWaitCount: number;

  pieData: any;

  total: any;
  mini: any;
  miniWin: any;

  match: any;
  matchWait: any;

  slot: any;
  casino: any;
  casinoInOut: any;
  management: any;
  outsideBalance: any;
}

export class MainView extends Component<any, State> {
  agentService = new AgentService();

  menu: any;

  constructor(props: any) {
    super(props);

    let begin = GetNow();
    let end = GetNow();

    this.state = {
      management: null,
      outsideBalance: 0,
      casionRolling: 0,
      slotRolling: 0,
      totalBalance: 0,
      realBetCount: 0,
      realMiniCount: 0,
      deposit: 0,
      withdraw: 0,
      regCount: 0,
      userCount: 0,
      todayCount: 0,
      connectCount: 0,
      losing: 0,
      rolling: 0,
      losingBenefits: 0,
      slotBenefits: 0,
      casinoBenefits: 0,

      depositAgent: 0,
      withdrawAgent: 0,


      matchBetCount: 0,
      matchWaitCount: 0,
      miniBetCount: 0,
      miniWaitCount: 0,
      totalBetCount: 0,

      total: {},
      mini: {},
      match: {},
      matchWait: {},
      miniWin: {},
      casinoInOut: {},

      slot: {},
      casino: {},
      pieData: {
        labels: ["A", "B"],
        datasets: [
          {
            data: [300, 50],
            backgroundColor: ["#FFC107", "#03A9F4"],
            hoverBackgroundColor: ["#FFE082", "#81D4FA"],
          },
        ],
      },
    };

    setInterval(
      function (dash) {
        if (window.location.hash.indexOf("dashboard") >= 0) {
          dash.updateDashborad();
        }
      },
      30000,
      this
    );
    this.updateDashborad();

  }



  updateDashborad = async () => {
    var dash = await this.agentService.get_dashboard()
    if (dash.status == "success") {

      this.setState({
        outsideBalance: dash.outsideBalance,
        userCount: dash.userCount,
        deposit: dash.deposit,
        withdraw: dash.withdraw,

        depositAgent: dash.depositAgent,
        withdrawAgent: dash.withdrawAgent,


        todayCount: dash.todayCount,
        connectCount: dash.connectCount,
        losing: dash.losing,
        rolling: dash.rolling,
        losingBenefits: dash.losingBenefits,
        slotBenefits: dash.slotBenefits,
        casinoBenefits: dash.casinoBenefits,


      })
    }
  }


  componentDidMount() { }

  //조회
  SearchData = () => {
    this.MainInfo();
  };

  //메인 정보 조회
  MainInfo = () => {
    console.log("MainInfo : 대쉬보드 정보 조회");
  };

  render() {
    // let calendar = new Calendar2(calendarEl, {
    //   plugins: [dayGridPlugin],
    //   // initialView: 'dayGridMonth'
    // });

    return (
      <>
        <section className="content-header">
          <h1>에이전트</h1>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-md-4">
              <div className="box box-solid">
                <div className="box-header with-border">
                  <h4 className="box-title">일일 현황</h4>
                </div>
                <div className="box-body">

                  <table
                    style={{
                      border: "1px",
                      width: "100%",
                      borderWidth: "1px",
                      borderColor: "#d2d6de",
                    }}
                    className="table table-bordered table-condensed"
                  >
                    <tbody>
                      <tr>
                        <td style={{ width: "100px" }}>현재접속</td>
                        <td className="daily_stat_val">{numTomoeny(this.state.connectCount)}</td>
                      </tr>
                      <tr>
                        <td style={{ width: "100px" }}>유저 입금</td>
                        <td className="daily_stat_val">{numTomoeny(this.state.deposit)}</td>
                      </tr>
                      <tr>
                        <td>유저 출금</td>
                        <td className="daily_stat_val">{numTomoeny(this.state.withdraw)}</td>
                      </tr>
                      <tr>
                        <td>유저 입출 합계</td>
                        <td className="daily_stat_val">{numTomoeny(Number(this.state.deposit == null ? 0 : this.state.deposit) - Number(this.state.withdraw == null ? 0 : this.state.withdraw))}</td>
                      </tr>
                      <tr>
                        <td>에이전트 입금</td>
                        <td className="daily_stat_val">{numTomoeny(this.state.depositAgent)}</td>
                      </tr>
                      <tr>
                        <td>에이전트 출금</td>
                        <td className="daily_stat_val">{numTomoeny(this.state.withdrawAgent)}</td>
                      </tr>
                      <tr>
                        <td>에이전트 입출 합계</td>
                        <td className="daily_stat_val">{numTomoeny(Number(this.state.depositAgent) - Number(this.state.withdrawAgent))}</td>
                      </tr>
                      {/* <tr>
                        <td>전체 합계</td>
                        <td className="daily_stat_val">-</td>
                      </tr>
                      <tr>
                        <td>윈로스</td>
                        <td className="daily_stat_val">-</td>
                      </tr>
                      <tr>
                        <td>유효베팅</td>
                        <td className="daily_stat_val">-</td>
                      </tr> */}
                      <tr>
                        <td>롤비 합계</td>
                        <td className="daily_stat_val">{numTomoeny(this.state.rolling)}</td>
                      </tr>
                      {
                        this.state.slotBenefits > 0 && (
                          <tr>
                            <td>슬롯 롤링퍼센트</td>
                            <td className="daily_stat_val">{this.state.slotBenefits}</td>
                          </tr>
                        )
                      }

                      {
                        this.state.casinoBenefits > 0 && (
                          <tr>
                            <td>카지노 롤링퍼센트</td>
                            <td className="daily_stat_val">{this.state.casinoBenefits}</td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </div>


            </div>

          </div>
        </section>
      </>
    );
  }
}
