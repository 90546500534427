

import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { ConvertDate, convertToCSV, GetNow, numTomoeny } from "../utility/help";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { confirmAlert } from "react-confirm-alert";

import { AgentService } from "../service/agent.service";

import { TableAgentShare } from "./share.agent.table";
import { ShareAgentMenu } from "./share.agent.menu";

interface State {

  searchText: any
  findText: any

  newAgent: any;
  agents: any;
  management: any;
  serverTime: any;
  total: any
  myLevel: any

}

export class AgentMngManagerList extends Component<any, State> {
  userService = new UserService();
  fileService = new FileService();
  agentService = new AgentService();

  menu: any;
  modal1: any = React.createRef();

  constructor(props: any) {
    super(props);
    const { searchText, findText } = props.match.params

    console.log(searchText, findText)
    this.state = {

      total: {
        balance: 0,
        userCount: 0,
      },

      searchText: searchText,
      findText: findText,

      newAgent: {},
      agents: [],
      management: {},
      serverTime: new Date(),
      myLevel: 5
    };


    this.getAgentList({
      searchText: searchText,
      findText: findText
    });
  }

  componentDidMount() { }


  //본사리스트 조회
  getAgentList = async (params) => {
    const data = await this.agentService
      .admin_get_agent_by_level(Object.assign({ level: 2 }, params))
      .then((s) => s);

    if (data.status === 'success') {

      const total = { balance: 0, userCount: 0, rolling: 0 }

      for (const agent of data.agents) {
        total.balance += agent.balance
        total.userCount += agent.userCount
        total.rolling += agent.rolling
      }

      this.setState({ agents: data.agents, serverTime: data.serverTime, management: data.management, total: total, myLevel: data.myLevel });
    }

  };



  handleNewSave = async (newAgent) => {

    this.setState({ newAgent: newAgent })

    if (newAgent.agent.length <= 3) {
      confirmAlert({
        title: "에이전트",
        message: "아이디는 4자리 부터 사용가능합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },

        ],
      });
      return
    }

    const regex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/
    if (regex.test(newAgent.agent)) {
      confirmAlert({
        title: "에이전트",
        message: "에이전트 아이디에는 한글을 사용할수 없습니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },

        ],
      });
      return
    }



    if (newAgent.nick == null || newAgent.nick.length < 2) {
      confirmAlert({
        title: "에이전트",
        message: "닉네임을 입력해주세요. 닉네임은 최소 2자입니다",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },

        ],
      });
      return
    }



    var pattern1 = /[0-9]/; // 숫자
    var pattern2 = /[a-zA-Z]/;// 문자
    // var pattern3 = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자
    if (!pattern1.test(newAgent.pass) || !pattern2.test(newAgent.pass)) {
      confirmAlert({
        title: "에이전트",
        message: "비밀번호는 영문, 숫자, 특수문자로 구성하여야 합니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },

        ],
      });
      return;
    }

    var regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/gi;
    newAgent.agent = newAgent.agent.replace(regExp, "");


    const response = await this.agentService.admin_make_agent_top(newAgent)
    if (response.status == "success") {
      confirmAlert({
        title: "에이전트",
        message: "에이전트 생성 / 수정 에 성공하셨습니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },
        ],
      });
    } else if (response.status == "agent") {
      confirmAlert({
        title: "에이전트",
        message: "에이전트 아이디를 입력해주세요 또는 최소 입력은 4자리 입니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },
        ],
      });
    } else if (response.status == "already") {
      confirmAlert({
        title: "에이전트",
        message: "해당 아이디는 사용할수 없는 아이디입니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },
        ],
      });
    } else if (response.status == "nick") {
      confirmAlert({
        title: "에이전트",
        message: "해당 닉네임은 사용할수 없습니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },
        ],
      });
    } else if (response.status == "pass") {
      confirmAlert({
        title: "에이전트",
        message: "비밀번호를 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },
        ],
      });
    } else if (response.status == "percent") {
      confirmAlert({
        title: "에이전트",
        message: "프로테이지를 확인해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
            },
          },
        ],
      });
      // } else if (response.status == "select_calcuatetype") {
      //   confirmAlert({
      //     title: "에이전트",
      //     message: "정산 타입을 입력해주세요 .",
      //     buttons: [
      //       {
      //         label: "확인",
      //         onClick: () => {
      //         },
      //       },
      //     ],
      //   });
      // } else if (response.status == "calcuatetype") {
      //   confirmAlert({
      //     title: "에이전트",
      //     message: "상위와 동일한 정산 타입을 입력해주세요 .",
      //     buttons: [
      //       {
      //         label: "확인",
      //         onClick: () => {
      //         },
      //       },
      //     ],
      //   });
    } else {

      confirmAlert({
        title: "에이전트",
        message: "관리자에게 문의 부탁드림니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => { },
          },
        ],
      });
    }
  };


  render() {


    return (
      <>
        <section className="content-header">
          <h1>부본사 리스트</h1>
        </section>

        <section className="content">


          <ShareAgentMenu
            newAgent={this.state.newAgent}
            myLevel={this.state.myLevel + 1}

            getAgentList={this.getAgentList}
            saveNewAgent={(newAgent) => {
              this.handleNewSave(newAgent)
            }}
            level={2}
            management={this.state.management}></ShareAgentMenu >

          <div className="form-group">
            <span id="shop_hierarchy">
              * 회원수 : <span style={{ color: 'red' }}>{numTomoeny(this.state.total.userCount)}</span>
              / 하부에이전트 합계 : <span style={{ color: 'red' }}>{numTomoeny(this.state.total.balance)}</span>
              / 하부보유롤링 합계: <span style={{ color: 'red' }}>{numTomoeny(this.state.total.rolling)}</span>
            </span>
          </div>

          <div>
            <span>소속 : </span>
            <span id="shop_hierarchy">
              - 에이전트 코드를 선택하면 상위 에이전트 정보를 알 수 있습니다.
            </span>
          </div>

          {/* 메인컨텐츠 */}
          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>

            <TableAgentShare
              agents={this.state.agents}
              serverTime={this.state.serverTime}
              newAgent={this.state.newAgent}
              management={this.state.management}

              saveNewAgent={this.handleNewSave}

            ></TableAgentShare>

          </div>
        </section>

      </>
    );
  }
}
