

import React, { Component, useState, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";


import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { confirmAlert } from "react-confirm-alert";
import { Tooltip } from 'primereact/tooltip';

import { CDropdown } from "./custom/cdropdown";

import { ShareTooltip } from "./share.tooltip";


import { BalanceService } from "../service/balance.service";
import { SlotService } from "../service/slot.service";


import { ConvertDate, convertToCSV, GetNow, numTomoeny, RenderAgent, ConvertBalanceType, OpenUserDetail, ConvertTreeToAgents } from "../utility/help";
import { TooltipTree } from "../utility/optionpack";


interface Prop {
    logs: any
}


interface State {

}

export class ShareHistoryLogTable extends Component<Prop, State> {
    balanceService: BalanceService = new BalanceService();



    constructor(props: Prop) {
        super(props);

        this.state = {}

        this.makeTable = this.makeTable.bind(this);


    }

    makeTable = (rowData) => {
        const trees: any = []
        const tree = ConvertTreeToAgents(rowData.agent_tree)
        const tree_ko = ConvertTreeToAgents(rowData.agent_tree_ko)


        for (let ii = 0; ii < tree.length; ii++) {
            trees.push({ level: ii + 1, agent: tree[ii], nick: tree_ko[ii] })
        }



        return trees
    }


    render() {

        const rowClass = (data) => {
            if (data.logType === "deposit" || data.logType === "agentdeposit" || data.logType === "admindeposit") return 'row-balance-deposit'
            if (data.logType === "withdraw" || data.logType === "agentwithdraw" || data.logType === "adminwithdraw") return 'row-balance-withdraw '


            return ' '
        }

        return (
            <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
                <DataTable

                    rowClassName={rowClass}

                    value={this.props.logs}
                    sortField="agentCode"
                    sortOrder={1}

                    rowGroupMode="rowspan"
                    style={{ marginTop: "30px", border: "1px solid #c8c8c8" }}
                >

                    <Column
                        field="id"
                        header="아이디"

                    />
                    <Column
                        field="nick"
                        header="닉네임"

                    />
                    <Column
                        field="agent"
                        header="에이전트"

                        body={(rowData) => {

                            const tooltipId = `tooltip-button_${rowData._id}`
                            return (

                                <>
                                    <ShareTooltip
                                        tooltipId={`${tooltipId}`}
                                        tables={this.makeTable(rowData)}
                                        columns={TooltipTree({})}></ShareTooltip>

                                    <span className={tooltipId}>{rowData.agent}</span>

                                </>
                            );

                        }}

                    />
                    <Column
                        header="벨런스"
                        body={(rowData) => {
                            return (
                                <div>{numTomoeny(rowData.balance)}</div>
                            );
                        }}

                    />
                    <Column
                        header="보유금"
                        body={(rowData) => {
                            return (
                                <div>{numTomoeny(rowData.afterbalance)}</div>
                            );
                        }}

                    />

                    <Column
                        header="상태"
                        body={(rowData: any, column: any) => {
                            if (rowData.logType == "deposit") {
                                return <div style={{ color: "red" }}>유저 충전 </div>;
                            }

                            if (rowData.logType == "agentdeposit") {
                                return <div style={{ color: "red" }}>에이전트 충전</div>;
                            }

                            if (rowData.logType == "admindeposit") {
                                return <div style={{ color: "red" }}>관리자 충전 </div>;
                            }

                            if (rowData.logType == "withdraw") {
                                return <div style={{ color: "blue" }}>유저 환전</div>;
                            }

                            if (rowData.logType == "agentwithdraw") {
                                return <div style={{ color: "blue" }}>에이전트 환전</div>;
                            }

                            if (rowData.logType == "adminwithdraw") {
                                return <div style={{ color: "blue" }}>관리자 환전</div>;
                            }

                            if (rowData.logType == "withdrawcancel") {
                                return <div style={{ color: "blue" }}>환전 취소</div>;
                            }
                            if (rowData.logType == "depositcancel") {
                                return <div style={{ color: "blue" }}>충전 취소</div>;
                            }

                            return `미정의 ${rowData.logType}`
                        }}
                    />
                    <Column
                        header="처리시각"

                        body={(rowData) => {
                            return (
                                <div>
                                    <span>{ConvertDate(rowData.regdate)}</span>
                                </div>
                            );
                        }}
                    />



                    <Column
                        field="memo"
                        header="메모"

                    />
                </DataTable>
            </div>
        )
    }
}




export class ShareMoveLogTable extends Component<Prop, State> {
    balanceService: BalanceService = new BalanceService();



    constructor(props: Prop) {
        super(props);

        this.state = {


        }
    }



    makeTable = (rowData) => {
        const trees: any = []
        const tree = ConvertTreeToAgents(rowData.agent_tree)
        const tree_ko = ConvertTreeToAgents(rowData.agent_tree_ko)


        for (let ii = 0; ii < tree.length; ii++) {
            trees.push({ level: ii + 1, agent: tree[ii], nick: tree_ko[ii] })
        }



        return trees
    }


    render() {

        const rowClass = (data) => {
            if (data.logType === "deposit" || data.logType === "agentdeposit" || data.logType === "admindeposit") return 'row-balance-deposit'
            if (data.logType === "withdraw" || data.logType === "agentwithdraw" || data.logType === "adminwithdraw") return 'row-balance-withdraw '


            return ' '
        }

        return (
            <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
                <DataTable
                    value={this.props.logs}
                    rowClassName={rowClass}
                    paginatorPosition="both"
                    rows={99999}
                    sortOrder={-1}
                    scrollable
                >

                    <Column
                        field="id"
                        header="아이디"

                        headerStyle={{
                            textAlign: "center",
                            minWidth: "60px",
                            height: "40px",
                        }}
                        bodyStyle={{ minWidth: "60px", textAlign: "center" }} />
                    <Column
                        field="nick"
                        header="닉네임"

                        headerStyle={{
                            textAlign: "center",
                            minWidth: "60px",
                            height: "40px",
                        }}
                        bodyStyle={{ minWidth: "60px", textAlign: "center" }} />
                    <Column
                        field="agent"
                        header="에이전트"

                        headerStyle={{
                            textAlign: "center",
                            minWidth: "60px",
                            height: "40px",
                        }}
                        bodyStyle={{ minWidth: "60px", textAlign: "center" }}
                        body={(viewData) => {

                            const tooltipId = `tooltip-button_${viewData._id}`
                            return (

                                <>
                                    {/* <ShareTooltip
                                        tooltipId={`${tooltipId}`}
                                        tables={this.makeTable(viewData)}
                                        columns={TooltipTree({})}></ShareTooltip> */}

                                    <span className={tooltipId}>{viewData.agent}</span>
                                </>
                            );

                        }}

                    />
                    <Column
                        header="벨런스"
                        headerStyle={{
                            textAlign: "center",
                            minWidth: "60px",
                            height: "40px",
                        }}
                        bodyStyle={{ minWidth: "60px", textAlign: "center" }}
                        body={(rowData) => {
                            return (
                                <div>{numTomoeny(rowData.balance)}</div>
                            );
                        }}

                    />
                    <Column

                        headerStyle={{
                            textAlign: "center",
                            minWidth: "60px",
                            height: "40px",
                        }}
                        bodyStyle={{ minWidth: "60px", textAlign: "center" }}
                        header="보유금"
                        body={(rowData) => {
                            return (
                                <div>{numTomoeny(rowData.afterbalance)}</div>
                            );
                        }}

                    />

                    <Column
                        header="이동금액"

                        headerStyle={{
                            textAlign: "center",
                            minWidth: "60px",
                            height: "40px",
                        }}
                        bodyStyle={{ minWidth: "60px", textAlign: "center" }}
                        body={(rowData) => {
                            return (
                                <div>{numTomoeny(rowData.inBalance)}</div>
                            );
                        }}

                    />

                    <Column
                        header="머니이력"
                        headerStyle={{
                            textAlign: "left",
                            minWidth: "350px",
                            height: "40px",
                            float: 'none'
                        }}
                        bodyStyle={{
                            minWidth: "350px", textAlign: "left", float: 'none', justifyContent: 'flex-start'
                        }}
                        body={(rowData: any, column: any) => {


                            if (rowData.logType === "balancetocasino") {
                                return <div style={{ color: "blue" }}>카지노 실행</div>;
                            }
                            else if (rowData.logType === "balancetoslot" || rowData.logType === "casinotobalance") {
                                return <div style={{ color: "red" }}>게임 실행</div>;
                            }
                            else if (rowData.logType === "balancetosloterror") {
                                return <div style={{ color: "red" }}>머니이동 예러 </div>;
                            }
                            else if (rowData.logType === "balancetorolling") {
                                return <div style={{ color: "red" }}>콤프 전환</div>;
                            } else if (rowData.logType === "pointtobalance") {
                                return <div style={{ color: "red" }}>금고 전환</div>;
                            }

                            return <span dangerouslySetInnerHTML={{ __html: ConvertBalanceType(rowData.agent, rowData.id, rowData.giveId, rowData.targetId, rowData.logType) }} ></span>

                        }}
                    />
                    <Column
                        field="company"
                        header="회사명"

                        headerStyle={{ textAlign: "center", minWidth: "60px", height: "40px", }}
                        bodyStyle={{ textAlign: "center", minWidth: "60px" }} />


                    <Column
                        header="처리시각"
                        headerStyle={{ textAlign: "center", minWidth: "60px", height: "40px", }}
                        bodyStyle={{ textAlign: "center", minWidth: "60px" }}

                        body={(rowData) => {
                            return (
                                <div>
                                    <span>{ConvertDate(rowData.regdate)}</span>
                                </div>
                            );
                        }}
                    />


                </DataTable>
            </div>
        )
    }
}








export class ShareAgentLogTable extends Component<Prop, State> {
    balanceService: BalanceService = new BalanceService();



    constructor(props: Prop) {
        super(props);

        this.state = {


        }
    }


    makeTable = (rowData) => {
        const trees: any = []
        const tree = ConvertTreeToAgents(rowData.agent_tree)
        const tree_ko = ConvertTreeToAgents(rowData.agent_tree_ko)


        for (let ii = 0; ii < tree.length; ii++) {
            trees.push({ level: ii + 1, agent: tree[ii], nick: tree_ko[ii] })
        }



        return trees
    }



    render() {

        const rowClass = (data) => {
            if (data.logType === "deposit" || data.logType === "agentdeposit" || data.logType === "admindeposit") return 'row-balance-deposit'
            if (data.logType === "withdraw" || data.logType === "agentwithdraw" || data.logType === "adminwithdraw") return 'row-balance-withdraw '


            return ' '
        }

        return (
            <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
                <DataTable

                    stripedRows responsiveLayout="scroll"
                    rowClassName={rowClass}

                    value={this.props.logs}
                    sortField="agentCode"
                    sortOrder={1}

                    rowGroupMode="rowspan"
                    style={{ marginTop: "30px", border: "1px solid #c8c8c8" }}
                >

                    <Column
                        field="id"
                        header="에이전트 아이디"

                    />
                    <Column
                        field="nick"
                        header="닉네임"

                    />
                    <Column
                        field="agent"
                        header="에이전트"

                        body={(rowData) => {

                            const tooltipId = `tooltip-button_${rowData._id}`
                            return (

                                <>

                                    <ShareTooltip
                                        tooltipId={`${tooltipId}`}
                                        tables={this.makeTable(rowData)}
                                        columns={TooltipTree({})}></ShareTooltip>

                                </>
                            );

                        }}

                    />
                    <Column
                        header="벨런스"
                        body={(rowData) => {
                            return (
                                <div>{numTomoeny(rowData.balance)}</div>
                            );
                        }}

                    />
                    <Column
                        header="보유금"
                        body={(rowData) => {
                            return (
                                <div>{numTomoeny(rowData.afterbalance)}</div>
                            );
                        }}

                    />

                    {/* <Column
                        header="이동금액"
                        body={(rowData) => {
                            return (
                                <div>{numTomoeny(rowData.inBalance)}</div>
                            );
                        }}

                    /> */}

                    <Column
                        header="상태"
                        body={(rowData: any, column: any) => {
                            return <span dangerouslySetInnerHTML={{ __html: ConvertBalanceType(rowData.agent, rowData.id, rowData.giveId, rowData.targetId, rowData.logType) }} ></span>
                        }}
                    />

                    <Column
                        header="처리시각"

                        body={(rowData) => {
                            return (
                                <span>{ConvertDate(rowData.regdate)}</span>
                            );
                        }}
                    />


                </DataTable>
            </div>
        )
    }
}




export class ShareSlotBetTable extends Component<Prop, State> {
    balanceService = new BalanceService();
    slotService = new SlotService();


    constructor(props: Prop) {
        super(props);

        this.state = {


        }


    }



    openDetail = async (code: any) => {
        var detail = await this.slotService.admin_slot_bet_detail(code);

        if (detail.status === 'success') {
            var win = window.open(
                detail.url,
                "_blank",
                "width=1600,height=1000"
            );

        }

    }


    render() {

        const rowClass = (data) => {
            if (data.logType === "deposit" || data.logType === "agentdeposit" || data.logType === "admindeposit") return 'row-balance-deposit'
            if (data.logType === "withdraw" || data.logType === "agentwithdraw" || data.logType === "adminwithdraw") return 'row-balance-withdraw '


            return ' '
        }

        return (
            // <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
            <DataTable
                rowClassName={rowClass}
                value={this.props.logs}
                sortOrder={1}

                style={{ marginTop: "30px", border: "1px solid #c8c8c8" }}

                rowGroupMode="rowspan"
                scrollable
                scrollHeight="800px"
            >


                <Column
                    field="userID"
                    headerStyle={{ textAlign: "center", minWidth: "60px" }}
                    bodyStyle={{ textAlign: "center", minWidth: "60px" }}
                    body={(rowData: any, column: any) => {
                        return <div onClick={() => OpenUserDetail(rowData.userID)} style={{ color: rowData.color }}>{rowData.userID}</div>
                    }}
                    header="아이디"

                />
                {/* <Column
                    field=""
                    header="닉네임"

                /> */}
                <Column
                    field="agent"
                    header="에이전트"

                    headerStyle={{ textAlign: "center", minWidth: "60px" }}
                    bodyStyle={{ textAlign: "center", minWidth: "60px" }}
                    body={(viewData: any) => {

                        const benefits = viewData.benefits == null ? [] : viewData.benefits


                        if (viewData == null) { return <></> }

                        const tooltipId = `tooltip-button_${viewData._id}`


                        if (benefits.length === 0) {
                            return (
                                <>
                                    <Tooltip target={`.${tooltipId}`} autoHide={false}>
                                        <DataTable
                                            value={[{ text: '내역없슴' }]}
                                            selectionMode="single"
                                            rows={5}

                                            style={{ width: '200px', padding: '5px' }}
                                        >
                                            <Column
                                                field="text"
                                                header="내용"
                                                headerStyle={{ textAlign: 'center' }}
                                                style={{ textAlign: 'center' }}

                                            />
                                        </DataTable>
                                    </Tooltip>
                                    <span className={tooltipId}>{viewData.agent}</span>

                                </>)
                        }


                        return (

                            <span className={tooltipId}>{viewData.agent}</span>

                        );

                    }}

                />
                {/* <Column
                        field=""
                        header="추천코드"

                    /> */}
                <Column
                    headerStyle={{ textAlign: "center", minWidth: "60px" }}
                    bodyStyle={{ textAlign: "center", minWidth: "60px" }}
                    field="thirdParty"
                    header="게임정보"

                />
                <Column

                    headerStyle={{ textAlign: "center", minWidth: "100px" }}
                    bodyStyle={{ textAlign: "center", minWidth: "100px" }} field="gamename"
                    header="게임"

                />

                <Column
                    headerStyle={{ textAlign: "center", minWidth: "40px" }}
                    bodyStyle={{ textAlign: "center", minWidth: "40px" }}
                    header="베팅금액"
                    body={(rowData) => {
                        return (
                            <>
                                <div>{numTomoeny(rowData.balanceBet)}</div>
                            </>
                        );
                    }}
                />
                <Column
                    style={{ textAlign: "center" }}

                    header="이긴금액"
                    body={(rowData) => {
                        return (
                            <>
                                <div>{numTomoeny(rowData.balanceWin)}</div>
                            </>
                        );
                    }}
                />

                 {
                    process.env.REACT_APP_API_HIDE_END_BALANCE !== 'Y' && (
                        <Column
                            headerStyle={{ textAlign: "center", minWidth: "60px" }}
                            bodyStyle={{ textAlign: "center", minWidth: "60px" }}
                            header="보유머니"
                            body={(rowData) => {
                                return (
                                    <>
                                        <div>{numTomoeny(rowData.balanceEnd)}</div>
                                    </>
                                );
                            }}
                        />
                    )
                 }       

                <Column
                    headerStyle={{ textAlign: "center", minWidth: "60px" }}
                    bodyStyle={{ textAlign: "center", minWidth: "60px" }}
                    header="보유롤링"
                    body={(rowData) => {
                        return (
                            <>
                                <div>{numTomoeny(rowData.giveBalance)}/ {numTomoeny(rowData.percent)} / {numTomoeny(rowData.afterBalance)}</div>
                            </>
                        );
                    }}
                />

                <Column
                    header="베팅시각"
                    headerStyle={{ textAlign: "center", minWidth: "60px" }}
                    bodyStyle={{ textAlign: "center", minWidth: "60px" }}
                    body={(rowData) => {
                        return (
                            <div>
                                <span>{ConvertDate(rowData.betTime)}</span>
                            </div>
                        );
                    }}
                />

            </DataTable>
            // </div >
        )
    }
}




export class ShareCasinoBetTable extends Component<Prop, State> {
    balanceService: BalanceService = new BalanceService();



    constructor(props: Prop) {
        super(props);

        this.state = {


        }


    }


    render() {

        const rowClass = (data) => {
            if (data.logType === "deposit" || data.logType === "agentdeposit" || data.logType === "admindeposit") return 'row-balance-deposit'
            if (data.logType === "withdraw" || data.logType === "agentwithdraw" || data.logType === "adminwithdraw") return 'row-balance-withdraw '


            return ' '
        }

        return (
            <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
                <DataTable

                    stripedRows responsiveLayout="scroll"
                    rowClassName={rowClass}

                    value={this.props.logs}
                    sortField="agentCode"
                    sortOrder={1}

                    rowGroupMode="rowspan"
                    style={{ marginTop: "30px", border: "1px solid #c8c8c8" }}

                    scrollable
                    scrollHeight="800px"
                >

                    <Column
                        field="transID"
                        header="트랜잭션아이디"

                    />
                    <Column
                        field="userID"
                        header="아이디"
                        body={(rowData: any) => {
                            return <div onClick={() => OpenUserDetail(rowData.userID)} style={{ color: rowData.color }}>{rowData.userID}</div>
                        }}
                    />
                    {/* 
                    <Column
                        field=""
                        header="닉네임"

                    /> */}
                    <Column
                        field="agent"
                        header="에이전트"

                        body={(viewData: any) => {

                            const benefits = viewData.benefits == null ? [] : viewData.benefits


                            if (viewData == null) { return <></> }

                            const tooltipId = `tooltip-button_${viewData._id}`


                            if (benefits.length === 0) {
                                return (
                                    <>
                                        <Tooltip target={`.${tooltipId}`} autoHide={false}>
                                            <DataTable
                                                value={[{ text: '내역없슴' }]}
                                                selectionMode="single"
                                                rows={5}

                                                style={{ width: '200px', padding: '5px' }}
                                            >
                                                <Column
                                                    field="text"
                                                    header="내용"
                                                    headerStyle={{ textAlign: 'center' }}
                                                    style={{ textAlign: 'center' }}

                                                />
                                            </DataTable>
                                        </Tooltip>

                                        <span className={tooltipId}>{viewData.agent}</span>
                                        {/* <Button className={tooltipId} type="button" label={viewData.agent}
                                            style={{ background: 'transparent', borderWidth: 0 }}
                                            tooltipOptions={{ position: 'bottom', mouseTrack: false, mouseTrackTop: 0 }} /> */}
                                    </>)
                            }


                            return (
                                <span className={tooltipId}>{viewData.agent}</span>
                            );

                        }}

                    />

                    <Column
                        field="thirdParty"
                        header="게임정보"

                    />

                    <Column
                        style={{ textAlign: "center" }}

                        header="베팅금액"
                        body={(rowData) => {
                            return (
                                <>
                                    <div>{numTomoeny(rowData.balanceBet)}</div>
                                </>
                            );
                        }}
                    />

                    <Column
                        style={{ textAlign: "center" }}

                        header="이긴금액"
                        body={(rowData) => {
                            return (
                                <>
                                    <div>{numTomoeny(rowData.balanceWin)}</div>
                                </>
                            );
                        }}
                    />


                    {
                        process.env.REACT_APP_API_HIDE_END_BALANCE !== 'Y' && (
                            <Column
                                style={{ textAlign: "center" }}

                                header="보유머니"
                                body={(rowData) => {
                                    return (
                                        <div>{numTomoeny(rowData.balanceEnd)}</div>
                                    );
                                }}
                            />
                        )
                    }

                    <Column
                        header="베팅시각"

                        body={(rowData) => {
                            return (
                                <div>
                                    <span>{ConvertDate(rowData.betTime)}</span>
                                </div>
                            );
                        }}
                    />
                    {/* <Column
                        field=""
                        header="결과"

                    /> */}

                </DataTable>
            </div>
        )
    }
}