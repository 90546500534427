import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import cookie from "react-cookies";
import { confirmAlert } from "react-confirm-alert";

import { UserService } from "../service/user.service";
import { AxiosMag } from "../service/axios.service";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

interface State {
  id: string;
  pw: string;
  capture: string;
  
}
export default class Login extends Component<any, State> {
  userService = new UserService();
  constructor(props: any) {
    super(props);
    this.state = {
      id: "",
      pw: "",
      capture: "",
      
    };
  }

  componentDidMount () {
    if(process.env.REACT_APP_API_USED_CAPTURE === 'Y'){
      loadCaptchaEnginge(6, 'red', 'black',  'numbers'); 
    }
 };
 
  render() {
    // const [cookies, setCookie] = useCookies(["session"]);
    // this.state = { userId: cookie.load("userId") };

    const captcha = window.document.getElementById('user_captcha_input') as any

    const doCaptcha = () =>{
      if (validateCaptcha(captcha.value)===true) {
        doLogin()
      }else {
      
        confirmAlert({
          title: "로그인",
          message: `캡차에 보이는 숫자를 정확하게 입력해주세요.`,
          buttons: [
            {
              label: "확인'",
              onClick: () => {
                captcha.value = "";
               }
            },
          ],
        });

      }
    }
    
    const doLogin = () => {
      this.userService
      .do_login_agent(this.state.id, this.state.pw)
      .then((data) => {
        if (data.status == "success") {
          cookie.save("session", data.session, { path: "/" });

          //setCookie("session", data.session, { path: "/" });

          AxiosMag.SetSession(data.session);
          window.location.hash = "/main";
          // this.setState({ user: data.user });
        } else if (data.status == "black") {
          confirmAlert({
            title: "로그인",
            message: `관리자에게 문의바랍니다.`,
            buttons: [
              {
                label: "확인'",
                onClick: () => { }
              },
            ],
          });

        } else {


          confirmAlert({
            title: "로그인",
            message: `아이디와 비밀번호 확인바랍니다.`,
            buttons: [
              {
                label: "확인'",
                onClick: () => { }
              },
            ],
          });


        }
      });
    };


    if(process.env.REACT_APP_API_USED_CAPTURE === 'Y'){
      return (
        <div className="login-body">
          {/* <iframe src={`https://i.picsum.photos/${}/${}`} width={`${window.outerWidth}px`} height={`${window.outerHeight}px`}></iframe> */}
          <iframe src="https://www.kantukan.co.kr/shop/mall/login/login.php" height="1200" width="100%"></iframe>
      
          <div className="login-panel ui-fluid">
            <div
              id="modalLogin"
              className="modal fade in"
              tabIndex={-1}
              role="dialog"
              aria-hidden="true"
              style={{ display: "block", background: 'rgba(0, 0, 0, 0.09)', top: '0px' }}
            >
         
              <div className="login_tab_wrap" style={{ width: isMobile ? '80%' : '400px', right: isMobile ? '50px' : '13.15%', }}>
                <div className="login_tab">
                  <div className="tab-label"></div>
                  <div className="login-title">와주셨군요, 칸투칸입니다.</div>
  
  
  
                  <div className="login_int">
                    <input type="text" placeholder="ID"
                      value={this.state.id}
                      onChange={(event: any) => {
                        this.setState({
                          id: event.target.value,
                        });
                      }} />
  
                  </div>
                  <div className="login_int">
                    <input type="password"  placeholder="PASSWORD"
  
                      value={this.state.pw}
                      onChange={(event: any) => {
                        this.setState({
                          pw: event.target.value,
                        });
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          doCaptcha();
                        }
                      }}
                    />
                  </div>
  
                  <div className="login_int">
                    

                    <LoadCanvasTemplateNoReload />

                    <input type="text" name="user_captcha_input" id="user_captcha_input" placeholder="보이는 숫자를 입력해주세요"
  
                      value={this.state.capture}
                      onChange={(event: any) => {
                        this.setState({
                          capture: event.target.value,
                        });
                      }}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          doCaptcha();
                        }
                      }}
                    />
                  </div>
  

  
                  <a className="login-btn" onClick={() => doCaptcha()} >로그인</a>
  
                  <br />
                  <a target="_blank">
                    <div style={{ fontSize: '16px', lineHeight: '130%', letterSpacing: '-0.3px', border: '1px solid #057FEB', textAlign: 'center', padding: '6px 6px 10px 6px', borderRadius: '6px' }}>
                      <span style={{ fontWeight: '600' }}>신규회원 가입시 최대 1만원 웰컴 쿠폰</span>
                    </div>
                  </a>
                  <br />
                  <a target="_blank">
                    <div style={{ fontSize: '16px', lineHeight: '130%', letterSpacing: '-0.3px', border: '1px solid #057FEB', textAlign: 'center', padding: '6px 6px 10px 6px', borderRadius: '6px' }}>
                      <span style={{ fontWeight: '600' }}>잘 긁으면 10만원까지. 칸투칸 행운의 쿠폰</span>
                    </div>
                  </a>
  
  
                  <div className="login_tab" style={{ marginTop: '20px', clear: 'both', textAlign: 'left', fontSize: '14px', letterSpacing: '-0.5px' }}>
                    <a href="javascript:;" >비회원 구매</a>
                    <span className="Bline"></span>
                    <a >비회원 배송조회</a>
                  </div>
  
                  <div className="sns_btn_f">
                    <a href="javascript:;"><img src="https://cloudfront-image.kantukan.co.kr/mall/kantukan/icon/login_g.png" /></a>
                    <a href="javascript:;"><img src="https://cloudfront-image.kantukan.co.kr/mall/kantukan/icon/login_f.png" /></a>
                    <a href="javascript:;"><img src="https://cloudfront-image.kantukan.co.kr/mall/kantukan/icon/login_k.png" /></a>
                    <a href="javascript:;"><img src="https://cloudfront-image.kantukan.co.kr/mall/kantukan/icon/login_n.png" /></a>
                    <a >
                      <img src="https://cloudfront-image.kantukan.co.kr/mall/kantukan/icon/apple_i.png" />
                    </a>
  
                  </div>
  
                  <div style={{ fontSize: '13px', lineHeight: '150%', textAlign: 'left', letterSpacing: '-0.5px' }}>
                    <span style={{ fontWeight: '300' }}>※ SNS 로그인 계정은</span> <span style={{ fontWeight: 500 }}>기존 칸투칸 자사몰 계정의
                      <br />주문, 적립금, 회원 정보</span><span style={{ fontWeight: 500 }}>와 통합 운영되지 않음을 안내드립니다. </span>
                  </div>
  
  
                </div>
              </div>
                        </div>
          </div>
        </div >
      );
    }

    
    return (
      <div className="login-body">
        <iframe src="https://www.kantukan.co.kr/shop/mall/login/login.php" height="1200" width="100%"></iframe>
 
        <div className="login-panel ui-fluid">
          <div
            id="modalLogin"
            className="modal fade in"
            tabIndex={-1}
            role="dialog"
            aria-hidden="true"
            style={{ display: "block", background: 'rgba(0, 0, 0, 0.09)', top: '0px' }}
          >
  
            <div className="login_tab_wrap" style={{ width: isMobile ? '80%' : '400px', right: isMobile ? '50px' : '13.15%', }}>
              <div className="login_tab">
                <div className="tab-label"></div>
                <div className="login-title">와주셨군요, 칸투칸입니다.</div>



                <div className="login_int">
                  <input type="text" name="id" id="id" placeholder="ID"
                    value={this.state.id}
                    onChange={(event: any) => {
                      this.setState({
                        id: event.target.value,
                      });
                    }} />

                </div>
                <div className="login_int">
                  <input type="password" name="pass" id="pass" placeholder="PASSWORD"

                    value={this.state.pw}
                    onChange={(event: any) => {
                      this.setState({
                        pw: event.target.value,
                      });
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        doLogin();
                      }
                    }}
                  />
                </div>

                <a className="chk_btn">아이디/비밀번호 찾기</a>
                <a className="login-btn" onClick={() => doLogin()} >로그인</a>

                <div className="save_wrap">
                  <label >아이디저장</label><br />
                </div>

                <br />
                <a target="_blank">
                  <div style={{ fontSize: '16px', lineHeight: '130%', letterSpacing: '-0.3px', border: '1px solid #057FEB', textAlign: 'center', padding: '6px 6px 10px 6px', borderRadius: '6px' }}>
                    <span style={{ fontWeight: '600' }}>신규회원 가입시 최대 1만원 웰컴 쿠폰</span>
                  </div>
                </a>
                <br />
                <a target="_blank">
                  <div style={{ fontSize: '16px', lineHeight: '130%', letterSpacing: '-0.3px', border: '1px solid #057FEB', textAlign: 'center', padding: '6px 6px 10px 6px', borderRadius: '6px' }}>
                    <span style={{ fontWeight: '600' }}>잘 긁으면 10만원까지. 칸투칸 행운의 쿠폰</span>
                  </div>
                </a>


                <div className="login_tab" style={{ marginTop: '20px', clear: 'both', textAlign: 'left', fontSize: '14px', letterSpacing: '-0.5px' }}>
                  <a href="javascript:;" >비회원 구매</a>
                  <span className="Bline"></span>
                  <a >비회원 배송조회</a>
                </div>

                <div className="sns_btn_f">
                  <a href="javascript:;"><img src="https://cloudfront-image.kantukan.co.kr/mall/kantukan/icon/login_g.png" /></a>
                  <a href="javascript:;"><img src="https://cloudfront-image.kantukan.co.kr/mall/kantukan/icon/login_f.png" /></a>
                  <a href="javascript:;"><img src="https://cloudfront-image.kantukan.co.kr/mall/kantukan/icon/login_k.png" /></a>
                  <a href="javascript:;"><img src="https://cloudfront-image.kantukan.co.kr/mall/kantukan/icon/login_n.png" /></a>
                  <a >
                    <img src="https://cloudfront-image.kantukan.co.kr/mall/kantukan/icon/apple_i.png" />
                  </a>

                </div>

                <div style={{ fontSize: '13px', lineHeight: '150%', textAlign: 'left', letterSpacing: '-0.5px' }}>
                  <span style={{ fontWeight: '300' }}>※ SNS 로그인 계정은</span> <span style={{ fontWeight: 500 }}>기존 칸투칸 자사몰 계정의
                    <br />주문, 적립금, 회원 정보</span><span style={{ fontWeight: 500 }}>와 통합 운영되지 않음을 안내드립니다. </span>
                </div>


              </div>
            </div>
        
          </div>
        </div>
      </div >
    );
  }
}
