import React, { Component, useState, useEffect } from "react";

import { Tooltip } from 'primereact/tooltip';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";


import {
    numTomoeny,
    converBetStatus,
    convertIP,
    ConvertDate,
    getDepositColor,
    getWithdrawColor,
    getDeposiOrWithdrawColor,
    ConvertNick
} from "../utility/help";



interface Props {
    tooltipId: string;
    tables?: any[]
    columns: any[]

}

interface State {
}



export class ShareTooltip extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

    }

    componentDidMount() {

    }


    render() {


        const dynamicColumns = this.props.columns.map((col, i) => {
            return <Column key={col.field} field={col.field} header={col.header} headerStyle={{ textAlign: "center", }} style={{ textAlign: "center", }} />;
        });

        return (

            <Tooltip target={`.${this.props.tooltipId}`} autoHide={false} style={{ padding: '5px' }}>
                <DataTable
                    value={this.props.tables}
                    selectionMode="single"
                    rows={5}
                    style={{ padding: '5px', fontSize: 16 }}
                >
                    {dynamicColumns}

                </DataTable>
            </Tooltip>
        );
    }
}
