
import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { confirmAlert } from "react-confirm-alert";

import {
  GetNow,
  ConvertCalendar,
  numTomoeny,
} from "../utility/help";
import { SubPaging } from "../share/sub-paging";
import { AgentService } from "../service/agent.service";
import { Message } from "primereact/message";
import { ShareBalanceRequestedMenu } from "./share.balance.requested.menu";
import { ShareAgentBalanceRequestedTable } from "./share.balance.requested.table";


import { SharePopupBalanceLog } from "./share.popup.balancelog";



interface State {
  page: number;
  maxCount: number;
  balances: any[];
  selectedCustomers: any[];

  findDay: any;
  begin: any;
  end: any;

  seach: string;
  seach2: string;

  selectAll: boolean;
  ProcessCancelPopup: boolean;
  SearchLogPopup: boolean;
}

export class RequestShopOrderList extends Component<any, State> {
  userService = new UserService();
  fileService = new FileService();
  agentService = new AgentService();

  menu: any;

  constructor(props: any) {
    super(props);

    let begin = GetNow();
    let end = GetNow();

    this.state = {
      page: 1,
      maxCount: 1,
      balances: [],

      findDay: "5",
      begin: begin,
      end: end,

      selectedCustomers: [],
      seach: "",
      seach2: "",

      selectAll: false,
      ProcessCancelPopup: false,
      SearchLogPopup: false,
    };

    this.onSelectAllChange = this.onSelectAllChange.bind(this);
    this.onSelectionChange = this.onSelectionChange.bind(this);
    this.GetBalanceList = this.GetBalanceList.bind(this);
    this.WaitAllUser = this.WaitAllUser.bind(this);
    this.SendMessageServer = this.SendMessageServer.bind(this);





    this.GetBalanceList(1);
  }


  componentDidMount() { }


  GetBalanceList = async (page: number) => {
    // const data = await this.agentService.admin_get_agent_wait_deposit({ skip: page })
    // if (data.status == "success") {
    //   this.setState({ balances: data.balance });
    // }
  };

  onSelectionChange = (event) => {
    const value = event.value;
    this.setState({ selectedCustomers: value, selectAll: value.length === this.state.balances.length })
  }

  onSelectAllChange = (info) => {

    const { checked } = info
    this.state.balances.map(s => (s.checked = checked))

    if (checked) {
      this.setState({ balances: this.state.balances, selectedCustomers: this.state.balances, selectAll: checked })
    } else {
      this.setState({ balances: this.state.balances, selectedCustomers: [], selectAll: checked })
    }
  }

  WaitAllUser = () => {

    this.setState({ selectedCustomers: this.state.balances })

  }


  SendMessageServer = (mode: any) => {
    let ex: any[] = [];


    for (let deposit of this.state.selectedCustomers) {
      ex.push(deposit);
    }

    if (ex.length === 0) {
      confirmAlert({
        title: "에이전트 관리",
        message: `아이템을 선택해주세요.`,
        buttons: [
          {
            label: "확인'",
            onClick: () => this.GetBalanceList(0),
          },
        ],
      });

      return
    }

    if (mode == "success" && ex.length > 0) {
      // this.agentService.set_agnet_deposit_success({ balances: ex }).then(s => {
      //   if (s.status == "success") {
      //     confirmAlert({
      //       title: "에이전트 관리",
      //       message: `정상 처리되었습니다.`,
      //       buttons: [
      //         {
      //           label: "확인'",
      //           onClick: () => this.GetBalanceList(0),
      //         },
      //       ],
      //     });
      //   } else if (s.status == "deposits") {
      //     confirmAlert({
      //       title: "에이전트 관리",
      //       message: `선택된 아이템이 존재하지 않습니다.`,
      //       buttons: [{
      //         label: "확인'",
      //         onClick: () => this.GetBalanceList(0),
      //       },
      //       ],
      //     });

      //   } else if (s.status == "already") {
      //     confirmAlert({
      //       title: "에이전트 관리",
      //       message: `충전완료 또는 충전취소 정보가 있습니다.`,
      //       buttons: [{
      //         label: "확인'",
      //         onClick: () => this.GetBalanceList(0),
      //       },
      //       ],
      //     });

      //   } else {

      //     confirmAlert({
      //       title: "에이전트 관리",
      //       message: `관리자에게 문의 부탁드림니다.`,
      //       buttons: [{
      //         label: "확인'",
      //         onClick: () => this.GetBalanceList(0),
      //       },],
      //     });

      //   }
      // });

    } else if (mode == "wait" && ex.length > 0) {
      // this.agentService.set_agnet_banace_change_wait({ balances: ex }).then(s => {
      //   if (s.status == "success") {
      //     alert("충전 대기상태로 변경되었습니다 .");
      //     window.location.reload();
      //   } else if (s.status == "deposits") {
      //     alert("선택된 아이템이 존재하지 않습니다");
      //     window.location.reload();
      //   } else if (s.status == "already") {
      //     alert("충전완료 또는 충전취소 정보가 있습니다");
      //     window.location.reload();
      //   } else {
      //     alert("관리자에게 문의 부탁드림니다");
      //     window.location.reload();
      //   }
      // });
    } else if (mode == "cancel" && ex.length > 0) {
      // this.agentService.set_agnet_deposit_cancel({ balances: ex }).then(s => {
      //   if (s.status == "success") {
      //     alert("충전을 취소 하였습니다.");
      //     window.location.reload();
      //   } else if (s.status == "deposits") {
      //     alert("선택된 아이템이 존재하지 않습니다");
      //     window.location.reload();
      //   } else if (s.status == "already") {
      //     alert("충전완료 또는 충전취소 정보가 있습니다");
      //     window.location.reload();
      //   } else {
      //     alert("관리자에게 문의 부탁드림니다");
      //     window.location.reload();
      //   }
      // });
    }
  };


  render() {
    return (
      <>
        <section className="content-header">
          <h1>에이전트 입금 요청</h1>
        </section>

        <section className="content">



          <div style={{ backgroundColor: "#F5A9A9" }}>
            마지막 입금 3일이상 충전시 빨간색
          </div>
          <div style={{ backgroundColor: "#F2F5A9" }}>
            마지막 입금 7일이상 충전시 노란색
          </div>
          <div style={{ backgroundColor: "#A9F5A9" }}>
            등록된 계좌이름과 입금자 이름이 다를때 녹색
          </div>
          <div style={{ backgroundColor: "#F5D0A9" }}>
            두개 이상조건 중복시 주황색
          </div>

          <ShareBalanceRequestedMenu

            onWaitAllUser={this.WaitAllUser}
            onSendMessage={this.SendMessageServer}

          ></ShareBalanceRequestedMenu>

          {/* 메인컨텐츠 */}
          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>

            <ShareAgentBalanceRequestedTable
              balances={this.state.balances}
              onSelectAllChange={this.onSelectAllChange}
              onSelectionChange={this.onSelectionChange}
              onSendMessage={this.SendMessageServer}


              selectAll={this.state.selectAll}
              selectedCustomers={this.state.selectedCustomers}

            ></ShareAgentBalanceRequestedTable>

          </div>
          <div className="text-center">
            <SubPaging
              activePage={this.state.page}
              maxCount={this.state.maxCount}
              limit={20}
              MoveSel={(page: number) => {
              }}
            ></SubPaging>
          </div>
        </section>

        {<SharePopupBalanceLog></SharePopupBalanceLog>}
      </>
    );
  }
}
