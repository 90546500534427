import React, { Component } from "react";
import classNames from "classnames";
import { AppMenu } from "./AppMenu";
import { AppBreadcrumb } from "./AppBreadcrumb";
import { Route } from "react-router-dom";
import cookie from "react-cookies";
import { EmptyPage } from "./components/EmptyPage";

import { confirmAlert } from "react-confirm-alert";

import { withRouter } from "react-router";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "./ripple.js";
import "./App.css";
import { AppConfig } from "./AppConfig";
import { AgentService } from "./service/agent.service";
import { AxiosMag } from "./service/axios.service";
import { SelectButton } from "primereact/selectbutton";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

// #### 신규화면 #####
// 에이전트관리
// import { AgentMngHeadOfficeList } from "./new_main/agent_1_headofficelist";
import { AgentMngManagerList } from "./new_main/agent_2_managerlist";
import { AgentMngAgentList } from "./new_main/agent_3_agentlist";
import { AgentMngStoreList } from "./new_main/agent_4_storetlist";
// import { AgentMngAdminLog } from "./new_main/agent_5_adminlog";
import { AgentMngReg } from "./new_main/agent_5_reg";
//회원관리 화면
import { UserMngUserList } from "./new_main/user_1_userlist";
import { UserMngCurrentUserList } from "./new_main/user_2_currentuserlist";
// import { UserMngUserLogList } from "./new_main/user_3_userloglist";
// import { UserMngUserIpDupList } from "./new_main/user_4_useripduplist";
// import { UserMngUserMoneyList } from "./new_main/user_5_usermoneylist";
// import { UserMngUserLevelLog } from "./new_main/user_6_userlevellog";
import { UserMngRegList } from "./new_main/user_7_userreg";
//입출금 관리
import { OrderManageUserCmpl } from "./new_main/ordermanage_1_usercmpl";
import { OrderManageShopCmpl } from "./new_main/ordermanage_2_shopcmpl";
//요청 화면
import { RequestUserOrderList } from "./new_main/request_1_userorderlist";
import { RequestUserTransList } from "./new_main/request_2_usertranslist";
import { RequestShopOrderList } from "./new_main/request_3_shoporderlist";
import { RequestShopTransList } from "./new_main/request_4_shoptranslist";
import { UserMngMoneyTransProc } from "./new_main/request_5_moneytransproc";
import { RequestUserHelpList } from "./new_main/request_6_userhelp";
import { RequestAgentHelpList } from "./new_main/request_7_agenthelp";

import { UserCalc } from "./new_main/realtime_3_usercalc";
import { ShareCalc } from "./new_main/realtime_4_sharecalc";
//내역화면
import { HistoryGame } from "./new_main/history_1_game";
import { HistoryMileage } from "./new_main/history_2_mileage";
import { HistoryMoney } from "./new_main/history_3_money";
import { HistoryUserBet } from "./new_main/history_4_userbet";
import { HistoryUserMoneyHistory } from "./new_main/history_5_usermoney";
import { HistoryUserBetShare } from "./new_main/history_6_userbetshare";
import { HistoryShopMoneyLog } from "./new_main/history_7_shopmoneylog";
import { ConvertAgentBenefitBalanceText, ConvertBalanceMove, numTomoeny } from "./utility/help";

//게시물관련화면
// import { BoardAdminNotice } from "./new_main/board_1_adminnotice";
// import { BoardUserNotice } from "./new_main/board_2_usernotice";
// import { BoardSlideNotice } from "./new_main/board_3_slidenotice";
// import { BoardPopupNotice } from "./new_main/board_4_popupnotice";
//게임관련화면

//설정
import { SettingAdmin } from "./new_main/setting_1_admin";
import { SettingIpBlock } from "./new_main/setting_2_ipblock";
import { SettingSaIp } from "./new_main/setting_3_saip";
import { SettingDomain } from "./new_main/setting_4_domain";
import { SettingSystem } from "./new_main/setting_5_system";
//메인
import { MainView } from "./new_main/main";

// import { DetailAgent } from "./new_main/detail.agent";
// import { DetailUser } from "./new_main/detail.user";


import { DetailAgent } from "./new_main/detail.agent";
import { DetailUser } from "./new_main/detail.user";


import { ShareInbalance } from "./new_main/share.inbalance";
import { ShareOutbalance } from "./new_main/share.outbalance";
import { SharePoint } from "./new_main/share.point";

import { RealtimeCalc } from "./new_main/realtime_1_realtimecalc";

import { SubCalc } from "./new_main/realtime_2_subcalc";

import { CalculateCasino } from "./new_main/realtime_3_casino";
import { CalculateUser } from "./new_main/realtime_3_user";


import { AgentNote } from "./new_main/note_agent";
import { AgentHelp } from "./new_main/note_help";
import { HistoryAgentBalcne } from "./new_main/history_3_agent_balance";
import { UserAgentRegUserList } from "./new_main/user_agent_userlist";

import { NoticeAgent } from "./new_main/note_noticeagent";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import SoundPlays from "./SoundPlay";


import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

enum POP_STATE {
  none,
  inBalance,
  outBalance,
  point,


}


async function Sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

interface Props { }
interface State {
  management: any;
  horizontal: boolean;
  topbarSize: string;
  topbarColor: string;
  menuColor: string;
  layoutColor: string;
  themeColor: string;
  menuActive: boolean;
  configDialogActive: boolean;
  topbarUserMenuActive: boolean;
  admin: any;
  currentmenu: string;
  popState: POP_STATE

  noteCount: any;
  helpCount: any;
  benefit: any;
  agentBalanceSum: any;

  oldPW: any;
  newPW: any;
  isOpen: boolean;

}

// name: cookies.get('session') || 'Ben'

class App extends Component<Props, State> {
  layoutContainer: any;
  agentService: any = new AgentService();

  menuClick = false;
  configMenuClick = false;
  userMenuClick = false;
  menu: any[] = [];
  menu_bak: any[] = [];


  constructor(props: any) {
    super(props);

    this.state = {
      admin: {},
      management: {},
      // top: {
      //   depositCount: 0,
      //   withdrawCount: 0,
      //   userCount: 0,
      //   agentCount: 0,
      //   agentBalanceCount: 0,
      //   helpCount: 0,
      // },

      horizontal: true,
      topbarSize: "large",
      topbarColor: "layout-topbar-blue",
      menuColor: "layout-menu-light",
      layoutColor: "blue",
      themeColor: "blue",
      menuActive: false,
      configDialogActive: false,
      topbarUserMenuActive: false,
      currentmenu: "",
      popState: POP_STATE.none,
      agentBalanceSum: 0,
      benefit: [],

      oldPW: '',
      newPW: '',

      helpCount: 0,
      noteCount: 0,
      isOpen: false


    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
    this.onTopbarUserMenuButtonClick = this.onTopbarUserMenuButtonClick.bind(
      this
    );
    this.onTopbarUserMenuClick = this.onTopbarUserMenuClick.bind(this);
    this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.changeTopbarTheme = this.changeTopbarTheme.bind(this);
    this.changeTopbarSize = this.changeTopbarSize.bind(this);
    this.changeMenuToHorizontal = this.changeMenuToHorizontal.bind(this);
    this.changeMenuTheme = this.changeMenuTheme.bind(this);
    this.changeComponentTheme = this.changeComponentTheme.bind(this);
    this.changePrimaryColor = this.changePrimaryColor.bind(this);
    this.onToggleBlockBodyScroll = this.onToggleBlockBodyScroll.bind(this);
    this.isHorizontalMenuActive = this.isHorizontalMenuActive.bind(this);
    this.update = this.update.bind(this);

    // this.createMenu();
    // this.handelInit();
    // this.handelTopinfo();

    this.update()

    setImmediate(() => {

      this.update()
    }, 10000);



  }

  onWrapperClick(event: any) {
    if (!this.menuClick) {
      this.setState({ menuActive: false });

      if (!this.configMenuClick) {
        this.unblockBodyScroll();
      }
    }

    if (!this.userMenuClick) {
      this.setState({ topbarUserMenuActive: false });
    }

    this.userMenuClick = false;
    this.menuClick = false;
    this.configMenuClick = false;
  }

  onMenuButtonClick(event: any, isMenuButtonActive: any) {
    this.menuClick = true;

    if (!this.isHorizontalMenuActive()) {
      this.setState({ menuActive: !isMenuButtonActive }, () => {
        if (this.state.menuActive) {
          this.blockBodyScroll();
        } else {
          this.unblockBodyScroll();
        }
      });
    }

    event.preventDefault();
  }

  onToggleBlockBodyScroll(add: boolean) {
    if (add) this.blockBodyScroll();
    else this.unblockBodyScroll();

    this.configMenuClick = true;
  }

  blockBodyScroll() {
    let className = `blocked-scroll${this.state.horizontal ? "-horizontal-menu" : ""
      }`;
    if (document.body.classList) {
      document.body.classList.add(className);
    } else {
      document.body.className += ` ${className}`;
    }
  }

  unblockBodyScroll() {
    let className = `blocked-scroll${this.state.horizontal ? "-horizontal-menu" : ""
      }`;
    if (document.body.classList) {
      document.body.classList.remove(className);
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + `${className}`.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  }

  onTopbarUserMenuButtonClick(event: any) {
    this.userMenuClick = true;
    this.setState({ topbarUserMenuActive: !this.state.topbarUserMenuActive });

    event.preventDefault();
  }

  onTopbarUserMenuClick(event: any) {
    this.userMenuClick = true;

    if (
      event.target.nodeName === "BUTTON" ||
      event.target.parentNode.nodeName === "BUTTON"
    ) {
      this.setState({ topbarUserMenuActive: false });
    }
    event.preventDefault();
  }

  onRootMenuItemClick(event: any) {
    this.menuClick = true;

    if (this.isHorizontalMenuActive()) {
      this.setState({
        menuActive: !this.state.menuActive,
      });
    }
  }

  onMenuItemClick(event: any) {
    if (!event.item.items) {
      this.setState({ menuActive: false });
    }

    this.setState({ currentmenu: event.item.label });
  }

  onSidebarClick = (event: any) => {
    this.menuClick = true;
  };

  isMobile() {
    return window.innerWidth <= 1024;
  }

  isHorizontalMenuActive() {
    return this.state.horizontal && !this.isMobile();
  }

  changeTopbarSize(event: any) {
    this.setState({ topbarSize: event.size });

    event.originalEvent.preventDefault();
  }

  changeTopbarTheme(event: any) {
    this.setState({ topbarColor: "layout-topbar-" + event.color });

    event.originalEvent.preventDefault();
  }

  changeMenuToHorizontal(event: any) {
    this.setState({ horizontal: event.mode });

    event.originalEvent.preventDefault();
  }

  changeMenuTheme(event: any) {
    this.setState({ menuColor: "layout-menu-" + event.color });

    event.originalEvent.preventDefault();
  }

  changeComponentTheme(event: any) {
    this.setState({ themeColor: event.theme });

    let element = document.getElementById("theme-css");
    if (element != null) {
      let urlTokens = element!.getAttribute("href")!.split("/");
      urlTokens[urlTokens.length - 1] = "theme-" + event.theme + ".css";
      let newURL = urlTokens.join("/");
      element.setAttribute("href", newURL);
    }

    event.originalEvent.preventDefault();
  }

  changePrimaryColor(event: any) {
    this.setState({ layoutColor: event.color });

    let element = document.getElementById("layout-css");
    if (element != null) {
      let urlTokens = element!.getAttribute("href")!.split("/");
      urlTokens[urlTokens.length - 1] = "layout-" + event.color + ".css";
      let newURL = urlTokens.join("/");
      element.setAttribute("href", newURL);
    }

    event.originalEvent.preventDefault();
  }

  componentDidMount = () => { };

  setCurrentMenu = (value: any) => {
    this.setState({ currentmenu: value });
  };

  createMenu() {

    this.menu = [
 
      {
        label: "에이전트 관리",
        icon: "list",
        items: [
        ],
      },
      {
        label: "회원관리 화면",
        icon: "list",
        items: [
          {
            label: "회원 리스트",
            icon: "desktop_mac",
            to: "/userMngUserList",
          },
          {
            label: "현재사용자 리스트",
            icon: "desktop_mac",
            to: "/userMngCurrentUserList",
          },
          {
            label: "회원 입출금 내역",
            icon: "desktop_mac",
            to: "/orderManageUserCmpl",
          },
          {
            label: "에이전트 입출금 내역",
            icon: "desktop_mac",
            to: "/orderManageShopCmpl",
          },

        ],
      },

      {
        label: "정산화면",
        icon: "list",
        items: [
          {
            label: "입출금 정산",
            icon: "desktop_mac",
            to: "/realtimeCalc",
          },
          {
            label: "통합 정산",
            icon: "desktop_mac",
            to: "/subCalc",
          },
          {
            label: "카지노정산",
            icon: "desktop_mac",
            to: "/casinoCalc",
          },
          {
            label: "회원 정산",
            icon: "desktop_mac",
            to: "/casinoUser",
          },
        ],
      },
      {
        label: "내역화면",
        icon: "list",
        items: [
          { label: "게임내역", icon: "desktop_mac", to: "/historyGame" },
          // {
          //   label: "롤링 내역",
          //   icon: "desktop_mac",
          //   to: "/historyMileage",
          // },
          { label: "머니 내역", icon: "desktop_mac", to: "/historyMoney" },
          { label: "에이전트 머니이력", icon: "desktop_mac", to: "/agent_balance" },
    
        ],

      },
    
    ];

    if (this.state.admin.level < 2) {
      this.menu[0].items.push({
        label: "부본사 리스트",
        icon: "desktop_mac",
        to: "/agentMngManagerList",
      })
    }

    if (this.state.admin.level < 3) {
      this.menu[0].items.push({
        label: "총판 리스트",
        icon: "desktop_mac",
        to: "/agentMngAgentList",
      })
    }

    if (this.state.admin.level < 4) {
      this.menu[0].items.push({
        label: "매장 리스트",
        icon: "desktop_mac",
        to: "/agentMngStoreList",
      })
    }


    this.menu_bak = [];

    let management = this.state.management;

    let newmenu: any = [];

    // newmenu.push(this.menu[0]);

    for (let items of this.menu) {
      if (items.items != null && items.items.length >= 1) {
        let submenu: any = [];

        for (let item of Object.values(items.items) as any) {
          if (management.sportpre === "y" && item.name === "sportpre") {
            submenu.push(item);
          } else if (
            management.sportlive === "y" &&
            item.name === "sportlive"
          ) {
            submenu.push(item);
          } else if (management.mini === "y" && item.name === "mini") {
            submenu.push(item);
          } else if (management.casino === "y" && item.name === "casino") {
            submenu.push(item);
          } else if (management.slot === "y" && item.name === "slot") {
            submenu.push(item);
          } else if (management.fx === "y" && item.name === "fx") {
            submenu.push(item);
          } else if (item.name == null) {
            submenu.push(item);
          }
        }

        if (submenu.length > 0) {
          newmenu.push({
            label: items.label,
            icon: items.icon,
            items: submenu,
            badge: items.badge,
            quickUrl: items.quickUrl,
          });
        }
      }
    }

    newmenu.push({
      label: "고객센터",
      icon: "list",
      to: "/helpagent",
    })

    newmenu.push({
      label: "쪽지함",
      icon: "list",
      to: "/noteagent",
    })

    newmenu.push({
      label: "가입 대기",
      icon: "list",
      to: "/reguserlist",
    })

    this.menu = newmenu;
  }




  handleWirteHelp = async () => {
    let data = await this.agentService.admin_wirte_agent_help({ title: '-자동 계좌문의', contents: '-문의' },).then(data => data)
    if (data.status == "success") {
      confirmAlert({
        title: "계좌문의",
        message: "계좌문의 성공하였습니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      });
    } else {
      confirmAlert({
        title: "계좌문의",
        message: "계좌문의 작성 실패.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      });
    }
  };




  handleExchangeBalance = (balance, ex) => {

    if ((Number(balance) % 1000) > 0) {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "변환할 금액을 1,000 단위 로 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => { },
          },
        ],
      });
      return;
    }



    if (ex.type == "rolling") {
      confirmAlert({
        title: "에이전트 벨런스",
        message: "에이젼트 포인트 캐쉬전환이 완료되었습니다.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.agentService.agent_exchange_balance(ex).then(s => {
                if (s.status == "success") {
                  window.location.reload();
                }
              });
            }
          }
        ]
      });
    } else if (ex.type == "balance") {


      if ((Number(balance) % 10000) > 0) {
        confirmAlert({
          title: "에이전트 벨런스",
          message: "환전 금액을 10,000 단위 로 입력해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => { },
            },
          ],
        });
        return;
      }

      confirmAlert({
        title: "에이전트 벨런스",
        message: "벨런스를 환전 신청합니다 잠시만 대기해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => {
              this.agentService.agent_exchange_balance(ex).then(s => {
                if (s.status == "success") {
                  window.location.reload();
                }
                else if (s.status == "balance") {
                  confirmAlert({
                    title: "에이전트 벨런스",
                    message: "에이젼트 보유금액이 환전신청금액보다 작습니다.",
                    buttons: [
                      {
                        label: "확인",
                        onClick: () => { }
                      }
                    ]
                  });
                }

              });
            }
          }
        ]
      });
    }
  };



  update = async () => {
    try {
      let time = new Date().getTime() / 1000;

      let hash = window.location.hash;

      if (hash.indexOf("DetailAgent") >= 0 ||
        hash.indexOf("calculatesubagent") >= 0 ||
        hash.indexOf("calculatesubagent") >= 0 ||
        hash.indexOf("casinosubCalc") >= 0 ||
        hash.indexOf("UserDetail") >= 0) {
        return
      }
      for (; ;) {
        try {
          const { admin, benefit, agentBalanceSum, helpCount, noteCount } = await this.agentService.agent_check_health();


          if (helpCount + noteCount > 0) {
            SoundPlays('userReg');
          }

          this.setState({ admin: admin, benefit: benefit, agentBalanceSum: agentBalanceSum, helpCount: helpCount, noteCount: noteCount });
        } catch (ex) {

        }




        await Sleep(10000);

      }

    }
    catch
    { }

  }



  handleSave = () => {

    if (
      this.state.oldPW.length < 4
    ) {
      confirmAlert({
        title: "에이전트",
        message: "에이전트 비밀번호는 최소 4자리 이상을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => { },
          },
        ],
      });

      return;
    }

    if (
      this.state.newPW.length < 4

    ) {
      confirmAlert({
        title: "에이전트",
        message: "에이전트 비밀번호는 최소 4자리 이상을 입력해주세요.",
        buttons: [
          {
            label: "확인",
            onClick: () => { },
          },
        ],
      });

      return;
    }



    this.agentService.admin_change_to_password(this.state.oldPW, this.state.newPW).then(data => {

      if (data.status == "success") {
        confirmAlert({
          title: "에이전트",
          message: "에이전트 비밀번호를 변경하였습니다",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },

          ],
        });
      }
      else {
        confirmAlert({
          title: "에이전트",
          message: "기존 비밀번호를 확인해주세요.",
          buttons: [
            {
              label: "확인",
              onClick: () => {
              },
            },

          ],
        });
      }
    })
  };


  render() {
    if (this.state.admin == null) {
      window.location.href = "/";
    }
    let hash = window.location.hash;

    if (hash.indexOf("DetailAgent") >= 0) {

      return (
        <>
          <Route
            exact={true}
            path="/DetailAgent/:agentID"
            component={(match: any) => {
              const { agentID } = match.match.params

              return (
                <DetailAgent agentId={agentID}></DetailAgent>
              );
            }}
          />

          <Route
            exact={true}
            path="/DetailAgent/:agentID/:eventType"
            component={(match: any) => {
              const { agentID, eventType } = match.match.params

              return (
                <DetailAgent agentId={agentID} eventType={eventType}></DetailAgent>
              );
            }}
          />
        </>

      )
    } else if (hash.indexOf("UserDetail") >= 0) {

      return (
        <>
          <Route
            exact={true}
            path="/UserDetail/:userID"
            component={(match: any) => {
              const { userID } = match.match.params

              return (
                <DetailUser userId={userID}></DetailUser>
              );
            }}
          />
          <Route
            exact={true}
            path="/UserDetail/:userID/:eventType"
            component={(match: any) => {
              const { userID, eventType } = match.match.params

              return (
                <DetailUser userId={userID} eventType={eventType}></DetailUser>
              );
            }}
          />
        </>

      )
    }



    const layoutContainerClassName = classNames(
      "layout-container",
      {
        "layout-menu-horizontal": this.state.horizontal,
        "layout-menu-active":
          this.state.menuActive && !this.isHorizontalMenuActive(),
        "layout-top-small": this.state.topbarSize === "small",
        "layout-top-medium": this.state.topbarSize === "medium",
        "layout-top-large": this.state.topbarSize === "large",
      },
      this.state.topbarColor,
      this.state.menuColor
    );

    const AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);


    // const PostPage = (match: any) => {
    //   return <div>blablabla</div>;
    // };

    this.createMenu();

    let options = ["on", "off"];
    let soundplay = cookie.load("soundplay");

    // const topInfo = this.state.topInfo

    return (
      <>
        {/* New Layout */}
        <body className={this.isMobile() ? (this.state.isOpen ? "skin-blue sidebar-mini sidebar-open" : "skin-blue sidebar-mini") : "hold-transition skin-blue sidebar-mini "} style={{ fontWeight: '400' }}>
          <div className="wrapper">
            <header className="main-header">
              <a
                className="logo"
                onClick={() => {
                  window.location.href = "/#/main";
                }}
              >
                <span className="logo-mini">
                  <b>{process.env.REACT_APP_API_NAME}</b> 에이전트
                </span>
                <span className="logo-lg">
                  <b>{process.env.REACT_APP_API_NAME}</b> 에이전트
                </span>
              </a>

              <nav className="navbar navbar-static-top">
                <a
                  className="sidebar-toggle"
                  data-toggle="offcanvas"
                  role="button"
                  onClick={() => this.setState({ isOpen: this.state.isOpen ? false : true })}
                >
                  <span className="sr-only">Toggle navigation</span>
                </a>

                <div className="navbar-custom-menu">
                  <ul className="nav navbar-nav">
                    <li className="dropdown notifications-menu">
                      <a
                        id="alarm_icon"
                        className="dropdown-toggle "
                        data-toggle="dropdown"
                        style={{
                          display: (
                            this.state.noteCount +
                            this.state.helpCount) > 0 ? "block " : 'none'
                        }}
                      >


                        <i className="fa fa-bell-o neon"></i>
                        <span id="alarm_number" className="label label-warning" style={{ fontSize: 14 }}>{this.state.noteCount +
                          this.state.helpCount}</span>
                      </a>
                      <ul className="dropdown-menu">
                        <li className="header">
                          <span id="alarm_number2">
                            {this.state.noteCount +
                              this.state.helpCount}</span>개의 알람이 등록되었습니다.
                        </li>
                        <li>
                          <ul id="alarm_list" className="menu">

                            {
                              this.state.helpCount > 0 && (
                                <li id="alarm_agent_deposit">
                                  <a href="#helpagent">
                                    <i className="fa fa-users text-aqua"></i>
                                    <span id="alarm_agent_deposit_cnt">{this.state.helpCount}</span>개의
                                    고객센터 답변.
                                  </a>
                                </li>
                              )
                            }

                            {
                              this.state.noteCount > 0 && (
                                <li id="alarm_agent_deposit">
                                  <a href="#noteagent">
                                    <i className="fa fa-users text-aqua"></i>
                                    <span id="alarm_agent_deposit_cnt">{this.state.noteCount}</span>개의
                                    쪽지 .
                                  </a>
                                </li>
                              )
                            }



                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      {/* <SelectButton
                        value={soundplay}
                        options={options}
                        onChange={(e) => {
                          cookie.save("soundplay", e.value, { path: "/" });
                        }}
                      /> */}
                    </li>

                    <li className="dropdown user user-menu">
                      <a
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        id="agent_info"
                      >
                        <img
                          src="/img/admin.png"
                          className="user-image"
                          height="30"
                          width="30"
                        />
                      </a>
                      <ul className="dropdown-menu">
                        <li className="list-group-item">
                          <b>브라우져 언어설정</b>{" "}
                          <label className="pull-right">ko_KR</label>
                        </li>
                        {
                          this.state.admin != null && (
                            <>
                              <li className="list-group-item">
                                <b>보유머니</b>{" "}
                                {this.state.admin && <label className="pull-right">{numTomoeny(this.state.admin.balance)}</label>}
                              </li>
                              <li className="list-group-item">
                                <b>보유롤링</b>{" "}
                                {this.state.admin && <label className="pull-right">{numTomoeny(this.state.admin.rolling)}</label>}
                              </li>


                              <li className="list-group-item" style={{ padding: '10px' }}>
                                <b>총보유머니(하부단계 포함)</b>
                                {this.state.admin && <label className="pull-right">{numTomoeny(this.state.agentBalanceSum)}</label>}
                              </li>
                            </>
                          )
                        }


                        {
                          this.state.benefit.map((ss: any, i: any) => {
                            if (ss.benefit_type === 'agent_casino_losing' || ss.benefit_type === 'agent_casino_losing' || (Number.isInteger(ss.percent)  && Number(ss.percent) <= 0)) {
                              return (<></>)
                            }


                            return (
                              <li className="list-group-item" key={`benefit_li_${i}`}>
                                <b>{ConvertAgentBenefitBalanceText(ss.benefit_type)}</b>
                                <label className="pull-right">{ss.percent}%</label>
                              </li>
                            )
                          })
                        }


                        <li className="user-body">
                          <div className="pull-left">

                            <a className="btn btn-default btn-flat" onClick={() => this.handleWirteHelp()}>
                              계좌 문의
                            </a>
                          </div>

                          <div className="pull-right">
                            <a className="btn btn-default btn-flat" onClick={() => this.setState({ popState: POP_STATE.point })}>
                              롤링 {"=>"} 벨런스 전환
                            </a>
                          </div>

                        </li>

                        <li className="user-body">
                          <div className="pull-left">
                            <a className="btn btn-default btn-flat"
                              onClick={() => this.setState({ popState: POP_STATE.inBalance })}>
                              입금 신청
                            </a>
                          </div>

                          <div className="pull-right">
                            <a
                              className="btn btn-default btn-flat"
                              onClick={() => this.setState({ popState: POP_STATE.outBalance })}
                            >
                              출금 신청
                            </a>

                          </div>
                        </li>



                        <li className="user-footer">
                          <div className="pull-left">
                            <a
                              className="btn btn-default btn-flat"
                              onClick={() => {

                                confirmAlert({
                                  customUI: ({ onClose }) => {

                                    const popClonse = () => {
                                      onClose()
                                    }

                                    return (
                                      <div
                                        className="modal fade in"
                                        tabIndex={-1}
                                        role="dialog"
                                        style={{
                                          display: "block",
                                          paddingRight: "17px",
                                        }}
                                      >
                                        <div
                                          className="modal-content"
                                          style={{
                                            minWidth: "300px",
                                            maxWidth: "600px",
                                            overflow: "auto",
                                            top: "100px",
                                            left: "30%",
                                          }}
                                        >
                                          <div className="modal-header">
                                            <button
                                              type="button"
                                              className="close"
                                              data-dismiss="modal"
                                              aria-hidden="true"
                                              onClick={popClonse}
                                            >
                                              ×
                                            </button>

                                            <h4 className="modal-title">비밀번호 변경</h4>
                                          </div>
                                          <div className="modal-body">
                                            <div className="form-horizontal">
                                              <div className="form-group">
                                                <label className="control-label col-sm-3">기존 비밀번호</label>
                                                <div className="col-sm-9">
                                                  <InputText
                                                    className="form-control input-sm"

                                                    value={this.state.oldPW}
                                                    onChange={(e: any) => {

                                                      this.setState({ oldPW: e.target.value })
                                                    }}
                                                  />
                                                </div>
                                              </div>

                                              <div className="form-group">
                                                <label className="control-label col-sm-3">신규 비밀번호</label>
                                                <div className="col-sm-9">
                                                  <InputText
                                                    className="form-control input-sm"

                                                    value={this.state.newPW}
                                                    onChange={(e: any) => {

                                                      this.setState({ newPW: e.target.value })
                                                    }}
                                                  />
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                          <div className="modal-footer">
                                            <div className="pull-right">
                                              <div className="form-group">
                                                <Button
                                                  label="확인"
                                                  onClick={() => this.handleSave()}
                                                  className="btn btn-default btn-sm"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div >
                                    )
                                  }
                                });



                              }}
                            >
                              비밀번호 변경
                            </a>
                          </div>
                          <div className="pull-right">
                            <a
                              onClick={() => {
                                cookie.remove("session", { path: "/" });
                                window.location.reload();
                              }}
                              className="btn btn-default btn-flat"
                            >
                              로그아웃
                            </a>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </header>

            <aside className="main-sidebar">
              <section className="sidebar" style={{ height: "auto" }}>
                <ul className="sidebar-menu">
                  <AppMenu
                    items={this.menu}
                    onMenuItemClick={this.onMenuItemClick}
                  />
                </ul>
              </section>
            </aside>

            <div className="content-wrapper" style={{ minHeight: "92vh" }} onClick={() => this.state.isOpen && this.setState({ isOpen: false })} >

              {/* ### 신규화면 ###  */}
              {/* 에이전트관리 */}
              {/* <Route path="/agentMngHeadOfficeList" component={AgentMngHeadOfficeList} /> */}
              <Route path="/agentMngManagerList" component={AgentMngManagerList} />
              <Route path="/agentMngManager/:searchText/:findText" component={AgentMngManagerList} />
              <Route path="/agentMngAgentList" component={AgentMngAgentList} />
              <Route path="/agentMngAgent/:searchText/:findText" component={AgentMngAgentList} />
              <Route path="/agentMngStoreList" component={AgentMngStoreList} />
              <Route path="/agentMngStore/:searchText/:findText" component={AgentMngStoreList} />

              {/* <Route path="/agentMngAdminLog" component={AgentMngAdminLog} /> */}
              <Route path="/agentMngReg" component={AgentMngReg} />

              {/* 회원관리 화면 */}
              <Route path="/userMngUserList" component={UserMngUserList} />
              <Route path="/userMngUserListFind/:searchText/:findText"
                component={(match: any) => {
                  const { searchText, findText } = match.match.params

                  return (
                    <UserMngUserList findText={findText} searchText={searchText}></UserMngUserList>
                  );
                }}
              />
              <Route
                path="/userMngCurrentUserList"
                component={UserMngCurrentUserList}
              />


              <Route
                path="/userMngRegList"
                component={UserMngRegList}
              />

              {/* 입출금 관리 */}
              <Route
                path="/orderManageUserCmpl"
                component={OrderManageUserCmpl}
              />
              <Route
                path="/orderManageShopCmpl"
                component={OrderManageShopCmpl}
              />
              {/* 요청 화면 */}
              <Route
                path="/requestUserOrderList"
                component={RequestUserOrderList}
              />
              <Route
                path="/requestUserTransList"
                component={RequestUserTransList}
              />
              <Route
                path="/requestShopOrderList"
                component={RequestShopOrderList}
              />
              <Route
                path="/requestShopTransList"
                component={RequestShopTransList}
              />
              <Route
                path="/userMngMoneyTransProc"
                component={UserMngMoneyTransProc}
              />

              <Route
                path="/requestUserHelpList"
                component={RequestUserHelpList}
              />

              <Route
                path="/requestAgentHelpList"
                component={RequestAgentHelpList}
              />




              {/* 정산화면 */}
              <Route path="/realtimeCalc" component={RealtimeCalc} />
              <Route path="/subCalc" component={SubCalc} />
              <Route path="/casinoCalc" component={CalculateCasino} />
              <Route path="/casinoUser" component={CalculateUser} />




              <Route path="/userCalc" component={UserCalc} />
              <Route path="/shareCalc" component={ShareCalc} />
              {/* 내역화면 */}
              <Route path="/historyGame" component={HistoryGame} />
              <Route path="/historyMileage" component={HistoryMileage} />
              <Route path="/historyMoney" component={HistoryMoney} />
              <Route path="/historyUserBet" component={HistoryUserBet} />

              <Route path="/reguserlist" component={UserAgentRegUserList} />
              
              {/* <Route
                path="/historyUserMoneyHistory"
                component={HistoryUserMoneyHistory}
              />
              <Route
                path="/historyUserBetShare"
                component={HistoryUserBetShare}
              />
              <Route
                path="/historyShopMoneyLog"
                component={HistoryShopMoneyLog}
              />

              {/* 설정 */}
              {/* <Route path="/settingAdmin" component={SettingAdmin} />
              <Route path="/settingIpBlock" component={SettingIpBlock} />
              <Route path="/settingSaIp" component={SettingSaIp} />
              <Route path="/settingDomain" component={SettingDomain} />
              <Route path="/settingSystem" component={SettingSystem} /> */}
              {/* 메인 */}
              <Route path="/main" component={MainView} />


              <Route path="/noteagent" component={AgentNote} />
              <Route path="/helpagent" component={AgentHelp} />
              <Route path="/agent_balance" component={HistoryAgentBalcne} />

            </div>

            {
              !this.isHorizontalMenuActive() && this.state.menuActive && (
                <div className="layout-mask" />
              )
            }

            <footer className="main-footer">
              <div className="pull-right hidden-xs">
                <b>Version</b> 2.3.8
              </div>
              <strong>
                Copyright © 2017 <a href="http://almsaeedstudio.com">company</a>
                .
              </strong>{" "}
              All rights reserved.
            </footer>

            {this.state.popState === POP_STATE.inBalance && <ShareInbalance onClose={() => this.setState({ popState: POP_STATE.none })}></ShareInbalance>}
            {this.state.popState === POP_STATE.outBalance && < ShareOutbalance onClose={() => this.setState({ popState: POP_STATE.none })}></ShareOutbalance>}
            {this.state.popState === POP_STATE.point && <SharePoint onClose={() => this.setState({ popState: POP_STATE.none })}></SharePoint>}


          </div >
          <div id="userReg" style={{ display: 'none' }}>0</div>

          <NoticeAgent></NoticeAgent>

        </body >

      </>
    );
  }
}

export default App;
