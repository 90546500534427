import React, { Component } from "react";

// import { Messages } from "primereact/messages";
// import { Message } from "primereact/message";
import { CountryService } from "../service/CountryService";
import { CarService } from "../service/CarService";
import { NodeService } from "../service/NodeService";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
// // import { Spinner } from "primereact/spinner";
import { Slider } from "primereact/slider";
import { ListBox } from "primereact/listbox";
import { RadioButton } from "primereact/radiobutton";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";

import { ColorPicker } from 'primereact/colorpicker';
import { CDropdown } from "./custom/cdropdown";
import { AgentService } from "../service/agent.service";

import { confirmAlert } from "react-confirm-alert";

import { AgentInfoTable, AgentBenefitTable, AgentBalanceTable } from "./detail.agent.table";



import {
  numTomoeny,
  converBetStatus,
  convertIP,
  ConvertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  ConvertNick
} from "../utility/help";

enum RenderTable {
  BASIC, // 0
  BENFIT,
  BALANCE,
}

interface Props {
  agentId: string;
  eventType?: string;
}

interface State {
  agent: any;
  userTableValue: any[];
  page: number;
  pages: any;
  render: RenderTable
}

export class DetailAgent extends Component<Props, State> {
  agentService: AgentService = new AgentService();
  constructor(props: Props) {
    super(props);

    let render = RenderTable.BASIC
    if (this.props.eventType === 'balance') {
      render = RenderTable.BALANCE
    }

    this.state = {
      agent: {},
      userTableValue: [],
      page: 0,
      pages: {},
      render: render
    };

    // this.handlePageChange = this.handlePageChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
  }

  componentDidMount() {
    this.agentService
      .admin_get_agnet_detail(this.props.agentId)
      .then((data) => {
        if (data.status == "success") {
          this.setState({ agent: data.agent });
        }
      })
  }

  handleUserChange = (e: any) => {
    if (this.state.agent[e.target.id] != undefined) {
      this.state.agent[e.target.id] = e.target.value;

      this.setState({ agent: this.state.agent });
    }
  };

  render() {
    let agent = this.state.agent;
    console.log(agent)

    if (agent == undefined || agent.agent == undefined) {
      return <div></div>;
    }


    return (

      <div
        id="detail_agent"
        className="fade in"
        tabIndex={-1}
        role="dialog"
        style={{
          // display: this.state.ManagerPopup === true ? "block" : "none",
          paddingRight: "17px",
        }}
      >
        <div
          className="modal-content"
          style={{
            // minWidth: "300px",
            // maxWidth: "800px",
            // minHeight: "600px",
            // maxHeight: "1500px",
            // overflow: "auto",
            // top: "100px",
            // left: "30%",
          }}
        >
          <div className="modal-header">
            <h4 className="modal-title">에이전트 디테일</h4>
          </div>
          <div className="modal-body">
            <div id="shopForm" className="form-horizontal">
              <div id="tabs" className="nav-tabs-custom">
                <ul className="nav nav-tabs">
                  <li className="active">
                    <a data-toggle="tab" onClick={() => this.setState({ render: RenderTable.BASIC })}>
                      기본 정보
                    </a>
                  </li>
                  <li>
                    <a data-toggle="tab" onClick={() => this.setState({ render: RenderTable.BENFIT })}>
                      요율설정
                    </a>
                  </li>
                  {/* <li>
                    <a data-toggle="tab" onClick={() => this.setState({ render: RenderTable.BALANCE })}>
                      충/환전
                    </a>
                  </li> */}

                </ul>
                <div className="tab-content">

                  <div className="panel-body">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">



                      <div className="form-group">
                        <div className="input-group">
                          <div className="input-group-addon">에이전트아이디</div>
                          <InputText
                            value={agent.agent}
                            className="form-control"
                            maxLength={20}
                            style={{ color: agent.color }}
                            readOnly
                          />
                        </div>
                      </div>


                      <div className="form-group" style={{}}>
                        <div className="input-group">
                          <div className="input-group-addon">보유머니</div>
                          <InputText
                            value={numTomoeny(agent.balance)}
                            className="form-control"
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="form-group" style={{}}>
                        <div className="input-group">
                          <div className="input-group-addon">롤링머니</div>
                          <InputText
                            value={numTomoeny(agent.rolling)}
                            className="form-control"
                            readOnly
                          />
                        </div>
                      </div>

                    </div>

                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">

                      {
                        this.state.render === RenderTable.BASIC && (
                          <div className="tab-pane active">
                            <AgentInfoTable agent={this.state.agent}></AgentInfoTable>
                          </div>
                        )
                      }



                      {
                        this.state.render === RenderTable.BENFIT && (
                          <div className="tab-pane active">
                            <AgentBenefitTable agent={this.state.agent}></AgentBenefitTable>
                          </div>
                        )
                      }
                      {/* 
                      {
                        this.state.render === RenderTable.BALANCE && (
                          <div className="tab-pane active">
                            <AgentBalanceTable agent={this.state.agent} agentId={this.props.agentId}></AgentBalanceTable>
                          </div>
                        )
                      }
 */}

                      {/*

                       */}

                    </div>
                  </div>
                </div>
              </div>
            </div >
          </div >
        </div >
      </div >
    );
  }
}
