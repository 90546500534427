//eBetting\admin\src\service\WORK\eBetting\admin\src\service\AxiosService.js;
import { AxiosMag } from "./axios.service";

export class BetService {
  // get_casino_bets = async (page, type, sort) => {
  //   return await AxiosMag.SendMessageByPost("/bet/get_casino_bets", {
  //     skip: page
  //   });
  // };


  get_casino_bets_search = async (
    page: any,
    begin: any,
    end: any,
    findText: any,
    searchText: any
  ) => {
    return await AxiosMag.SendMessageByPost("/casino/get_casino_bets_search", {
      skip: page,
      begin: begin,
      end: end,
      findText: findText,
      searchText: searchText,
    });
  };

  // get_casino_bets = async (
  //   page: any,
  //   begin: any,
  //   end: any,
  //   findText: any,
  //   searchText: any
  // ) => {
  //   return await AxiosMag.SendMessageByPost("/casino/get_casino_bets", {
  //     skip: page,
  //     begin: begin,
  //     end: end,
  //     findText: findText,
  //     searchText: searchText,
  //   });
  // };



  get_user_detail_casino_bets = async (
    page: any,
    begin: any,
    end: any,
    casino_date: any,
    findText: any,
    searchText: any
  ) => {
    return await AxiosMag.SendMessageByPost("/casino/get_user_detail_casino_bets", {
      skip: page,
      begin: begin,
      end: end,
      findText: findText,
      searchText: searchText,
      casino_date,
    });
  };




  get_user_detail_slot_bets = async (
    page: any,
    begin: any,
    end: any,
    casino_date: any,
    findText: any,
    searchText: any
  ) => {
    return await AxiosMag.SendMessageByPost("/slot/admin_get_user_detail_slot_bets", {
      skip: page,
      begin: begin,
      end: end,
      findText: findText,
      searchText: searchText,
      casino_date,
    });
  };



  get_mini_bet_list = async (
    page: any,
    begin: any,
    end: any,
    findText: any,
    searchText: any
  ) => {
    return await AxiosMag.SendMessageByPost("/mini/get_minis_bets", {
      skip: page,
      begin: begin,
      end: end,
      findText: findText,
      searchText: searchText,
    });
  };

  get_mini_bet_list_by_id = async (
    page: any,
    begin: any,
    end: any,
    id: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/mini/admin_get_minis_bets_By_id",
      {
        skip: page,
        begin: begin,
        end: end,
        id: id,
      }
    );
  };

  get_match_bet_list = async (
    page: any,
    begin: any,
    end: any,
    findText: any,
    searchText: any,
    veiwSelectType: any
  ) => {
    return await AxiosMag.SendMessageByPost("/match/admin_get_match_bets", {
      skip: page,
      begin: begin,
      end: end,
      findText: findText,
      searchText: searchText,
      veiwSelectType: veiwSelectType,
    });
  };

  get_admin_get_match_bets_by_userId = async (
    page: any,
    begin: any,
    end: any,
    findText: any,
    searchText: any,
    folder: string
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/match/admin_get_match_bets_by_userId",
      {
        skip: page,
        begin: begin,
        end: end,
        findText: findText,
        searchText: searchText,
        folder: folder,
      }
    );
  };

  admin_get_slot_bets_by_userId = async (
    page: any,
    begin: any,
    end: any,
    findText: any,
    searchText: any,
    veiwSelectType: any
  ) => {
    return await AxiosMag.SendMessageByPost("/slot/admin_get_slot_bets", {
      skip: page,
      begin: begin,
      end: end,
      findText: findText,
      searchText: searchText,
      veiwSelectType: veiwSelectType,
    });
  };

  get_match_detail_by_betId = async (idx: any) => {
    return await AxiosMag.SendMessageByPost("/match/get_match_detail", {
      idx: idx,
    });
  };

  // get_live_bet_list = async (
  //   page: any,
  //   begin: any,
  //   end: any,
  //   findText: any,
  //   searchText: any
  // ) => {
  //   return await AxiosMag.SendMessageByPost("/live/get_live_bets", {
  //     skip: page,
  //     begin: begin,
  //     end: end,
  //     findText: findText,
  //     searchText: searchText,
  //   });
  // };



  get_live_bet_list = async (
    page: any,
    begin: any,
    end: any,
    findText: any,
    searchText: any,
    veiwSelectType: any
  ) => {
    return await AxiosMag.SendMessageByPost("/live/admin_get_live_bets", {
      skip: page,
      begin: begin,
      end: end,
      findText: findText,
      searchText: searchText,
      veiwSelectType: veiwSelectType,
    });
  };


  get_end_live_game = async (
    page: any,
    begin: any,
    end: any,
    findText: any,
    searchText: any,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost("/live/admin_get_end_live_game", {
      skip: page,
      begin: begin,
      end: end,
      findText: findText,
      searchText: searchText,
      mode,
    });
  };

  get_progress_match_game = async (
    page: any,
    begin: any,
    end: any,
    findText: any,
    searchText: any,
    mode: any,
    sort: string,
    sport: string
  ) => {
    return await AxiosMag.SendMessageByPost("/match/get_progress_match_game", {
      skip: page,
      begin: begin,
      end: end,
      findText: findText,
      searchText: searchText,
      mode,
      sort,
      sport,
    });
  };

  admin_get_all_sport_name = async () => {
    return await AxiosMag.SendMessageByPost(
      "/match/admin_get_all_sport_name",
      {}
    );
  };

  get_end_match_game = async (
    page: any,
    begin: any,
    end: any,
    findText: any,
    searchText: any,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost("/match/admin_get_end_match_game", {
      skip: page,
      begin: begin,
      end: end,
      findText: findText,
      searchText: searchText,
      mode: mode,
    });
  };

  get_progress_live_game = async (
    page: any,
    begin: any,
    end: any,
    findText: any,
    searchText: any,
    mode: any,
    sort: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/live/admin_get_progress_live_game",
      {
        skip: page,
        begin: begin,
        end: end,
        findText: findText,
        searchText: searchText,
        mode,
        sort,
      }
    );
  };

  del_match_game = async () => {
    return await AxiosMag.SendMessageByPost("/match/del_match_game", {});
  };

  get_match_details = async (matchId: any) => {
    return await AxiosMag.SendMessageByPost("/match/get_match_details", {
      matchId: matchId,
    });
  };

  get_live_details = async (matchId: any) => {
    return await AxiosMag.SendMessageByPost("/live/get_live_details", {
      liveId: matchId,
    });
  };

  get_progress_mini_game_none_bet = async (
    page: any,
    findText: any,
    searchText: any,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost("/mini/progress_none_bet_del", {
      skip: page,
      findText: findText,
      searchText: searchText,
      mode: mode,
    });
  };

  get_progress_mini_game = async (
    page: any,
    findText: any,
    searchText: any,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost("/mini/get_progress_mini_games", {
      skip: page,
      findText: findText,
      searchText: searchText,
      mode: mode,
    });
  };


  admin_mini_cancel_by_user = async (
    id: any,
  ) => {
    return await AxiosMag.SendMessageByPost("/mini/admin_mini_cancel_by_user", {
      id: id,
    });
  };

  get_end_mini_game = async (
    page: any,
    findText: any,
    searchText: any,
    mode: any
  ) => {
    return await AxiosMag.SendMessageByPost("/mini/admin_get_end_mini_games", {
      skip: page,
      findText: findText,
      searchText: searchText,
      mode: mode,
    });
  };

  admin_mini_recovery = async (id) => {
    return await AxiosMag.SendMessageByPost("/mini/admin_mini_recovery", {
      id: id,
    });
  };

  admin_mini_cancel = async (id) => {
    return await AxiosMag.SendMessageByPost("/mini/admin_mini_cancel", {
      id: id,
    });
  };

  admin_make_benefit_level = async (level: any, benefits: any) => {
    return await AxiosMag.SendMessageByPost("/mini/admin_make_benefit_level", {
      maxlevel: level,
      benefits: benefits,
    });
  };

  // admin_make_benefit_level = async (level: any, benefits: any) => {
  //   return await AxiosMag.SendMessageByPost("/mini/admin_make_benefit_level", {
  //     maxlevel: level,
  //     benefits: benefits
  //   });
  // };

  get_mini_game_list = async () => {
    return await AxiosMag.SendMessageByPost(
      "/mini/admin_get_mini_game_list",
      {}
    );
  };

  progress_none_bet_del = async () => {
    return await AxiosMag.SendMessageByPost(
      "/mini/admin_progress_none_bet_del",
      {}
    );
  };

  get_match_details_by_matchId = async (page: any, matchId: any) => {
    return await AxiosMag.SendMessageByPost(
      "/match/admin_get_details_by_matchid",
      {
        skip: page,
        matchid: matchId,
      }
    );
  };

  get_mini_game_by_categoryid_and_episode = async (
    page: any,
    categoryId: any,
    episode: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/mini/admin_get_mini_game_by_categoryid_and_episode",
      {
        skip: page,
        categoryid: categoryId,
        episode: episode,
      }
    );
  };

  admin_do_finish_mini_game = async (miniFinish: any) => {
    return await AxiosMag.SendMessageByPost("/mini/admin_do_finish_mini_game", {
      finish: miniFinish,
    });
  };

  admin_match_update_odds = async (
    matchId: any,
    marketId: any,
    specifiers: any,
    oddsId: any,

    plusOdds: any
  ) => {
    return await AxiosMag.SendMessageByPost("/match/admin_match_update_odds", {
      matchId: matchId,
      marketId: marketId,
      specifiers: specifiers,
      oddsId: oddsId,

      plusOdds: plusOdds,
    });
  };

  admin_match_update_fix = async (
    matchId: any,
    marketId: any,
    specifiers: any,
    oddsId: any,

    fix: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/match/admin_match_update_odds_fix",
      {
        matchId: matchId,
        marketId: marketId,
        specifiers: specifiers,
        oddsId: oddsId,

        fix: fix,
      }
    );
  };

  admin_match_update_game_active = async (
    matchId: any,
    marketId: any,
    specifiers: any,
    active: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/match/admin_match_update_active",
      {
        matchId: matchId,
        marketId: marketId,
        specifiers: specifiers,
        active: active,
      }
    );
  };

  get_match_game_by_matchId_and_gameId = async (
    matchId: any,
    oddsId: any,
    specifiers: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/match/admin_get_match_game_by_matchId_and_gameId",
      {
        matchId,
        oddsId,
        specifiers,
      }
    );
  };

  admin_end_match_game_by_matchId_and_gameId = async (
    matchId: any,
    marketId: any,
    winId: any,
    specifiers: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/match/admin_end_match_game_by_matchId_and_gameId",
      {
        matchId,
        marketId,
        winId,
        specifiers,
      }
    );
  };

  admin_cancel_match_game_by_matchId_and_gameId = async (
    matchId: any,
    marketId: any,
    specifiers: any
  ) => {
    return await AxiosMag.SendMessageByPost(
      "/match/admin_cancel_match_game_by_matchId_and_gameId",
      {
        matchId,
        marketId,
        specifiers,
      }
    );
  };

  admin_set_open_live_game = async (id: any, isOpenLive: any) => {
    return await AxiosMag.SendMessageByPost("/live/admin_set_open_live_game", {
      id,
      isOpenLive,
    });
  };

  admin_change_match_status = async (matchIds: any, status: any) => {
    return await AxiosMag.SendMessageByPost("/match/change_match_status", {
      matchIds,
      status,
    });
  };


  admin_cancel_match_bet = async (betId: any) => {
    return await AxiosMag.SendMessageByPost("/match/admin_cancel_match_bet", {
      betId,
    });
  };


  get_slot_bets = async (params: any) => {
    return await AxiosMag.SendMessageByPost("/agent/agent_get_slot_none_rolling_list", params);
  };



  get_slot_bets_summary = async (params: any) => {
    return await AxiosMag.SendMessageByPost("/agent/agent_get_slot_none_rolling_sum", params);
  };

  get_casino_bets = async (params: any) => {
    return await AxiosMag.SendMessageByPost("/agent/agent_get_casion_list", params);
  };


  get_casin_bets_summary = async (params: any) => {
    return await AxiosMag.SendMessageByPost("/agent/agent_get_casion_list_sum", params);
  };



  get_user_total_betting = async (params: any) => {
    return await AxiosMag.SendMessageByPost("/agentv2/admin_get_user_total_betting", params);
  };
}
