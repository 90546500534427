import React, { Component } from "react";
import { Button } from "primereact/button";
import { AgentService } from "../service/agent.service";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { GetNow, GetToday, GetAgentTreeByLevel, ConvertLogType, OpenAgentDetail, ConverAgentStatus } from "../utility/help";
import { CCalendarTime } from "./custom/ccalendartime";
import { CDropdown } from "./custom/cdropdown";

import { SubPaging } from "../share/sub-paging";

interface State {
    page: number;
    maxCount: number;

    begin: any;
    end: any;

    agents: any[];
    searchText: string
    findText: string
}

export class AgentMngReg extends Component<any, State> {
    agentService = new AgentService();

    menu: any;

    constructor(props: any) {
        super(props);

        let today = GetToday();

        this.state = {
            page: 1,
            maxCount: 1,

            begin: today.begin,
            end: today.end,

            agents: [],

            searchText: '',
            findText: '',
        };

        this.handleAgentWaitList();
    }

    // selectData2 = [
    //   { label: "관리자아이디", value: "admin_id" },
    //   { label: "접속자아이디", value: "conn_ip" },
    //   { label: "세션아이디", value: "session_id" },
    //   { label: "내용", value: "log" },
    // ];


    componentDidMount() { }

    handleAgentWaitList = () => {
        // this.agentService.admin_get_agent_wait().then((s: any) => {
        //     this.setState({ agents: s.agent });
        // });
    };

    //조회

    handleAgentDel = (idx: any) => {
        // this.agentService.admin_agnet_stause_change_del(idx).then((s: any) => {

        //     window.location.reload();
        // });
    };
    handleAgentWait = (idx: any) => {
        // this.agentService.admin_agnet_stause_change_wait(idx).then((s: any) => {

        //     window.location.reload();
        // });
    };
    handleAgentAccept = (idx: any) => {
        // this.agentService.admin_agnet_stause_change_accept(idx).then((s: any) => {

        //     window.location.reload();
        // });
    };
    render() {
        return (
            <>
                <section className="content-header">
                    <h1>에이전트 승인</h1>
                </section>

                <section className="content">
                    {/* <div className="form-inline">
                        <div className="form-group">
                            <CCalendarTime
                                placeholder="시작일"
                                value={this.state.begin}
                                onChange={(e) => this.setState({ begin: e })}
                            />
                        </div>

                        <div className="form-group">
                            <CCalendarTime
                                placeholder="종료일"
                                value={this.state.end}
                                onChange={(e) => this.setState({ end: e })}
                            />
                        </div>

                        <div className="form-group">검색타입</div>
                        <div className="form-group">
                            <CDropdown
                                options={[
                                    { label: "전체", value: "" },
                                    { label: "로그인", value: "admin_do_login" },
                                    { label: "유저 정보변경", value: "'admin_update_user_detail" },
                                    { label: "에이전트 요율변경", value: "admin_agent_benefit_edit" },
                                    { label: "에이전트 정보변경", value: "admin_save_agnet_detail" },
                                ]}
                                value={this.state.searchText}
                                onChange={(event) => this.setState({ searchText: event.value })}
                            />
                        </div>

                        <div className="form-group">
                            <InputText
                                value=""
                                onChange={(e: any) => this.setState({ findText: e.target.value })}
                                onKeyPress={(e: any) => {
                                    if (e.key === "Enter") {
                                        this.GetAgentEditLog(1);
                                    } else {
                                    }
                                }}
                                className="form-control input-sm"
                                style={{ width: "200px" }}
                                placeholder="아이디를 입력해 주세요"
                            />
                        </div>

                        <div className="form-group">
                            <Button
                                label="조회"
                                onClick={() => {
                                    this.GetAgentEditLog(1);
                                }}
                                className="btn btn-primary btn-sm"
                            />
                        </div>
                        <div className="form-group">
                            <Button
                                label="재입력"
                                onClick={() => this.setState({ findText: '' })}
                                className="btn btn-default btn-sm"
                            />
                        </div>
                    </div> */}

                    {/* 설명 */}
                    <div>
                        <span>소속 : </span>
                        <span id="shop_hierarchy">
                            - 에이전트 코드를 선택하면 상위 에이전트 정보를 알 수 있습니다.
                        </span>
                    </div>

                    {/* 메인컨텐츠 */}
                    <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
                        <DataTable

                            value={this.state.agents}
                            sortField="agentCode"
                            sortOrder={1}
                            //
                            rowGroupMode="rowspan"
                            style={{ marginTop: "30px" }}
                        >
                            <Column
                                style={{ textAlign: "center", width: "120px" }}
                                header="아이디"
                                body={(agent: any) => {
                                    return (
                                        <div
                                            onClick={() => OpenAgentDetail(agent.agent)}
                                        >
                                            {agent.agent}
                                            <i className="pi pi-cog" style={{ fontSize: '12px', color: 'red' }}></i>
                                        </div>
                                    );
                                }}
                                field="agent"
                            />

                            <Column
                                style={{ textAlign: "center", width: "120px" }}
                                header="연락처"
                                body={(agent: any) => {
                                    return agent.phone;
                                }}
                                field="phone"

                            />

                            <Column
                                style={{ textAlign: "center", width: "120px" }}
                                header="본사"
                                body={(agent: any) => {
                                    return GetAgentTreeByLevel(agent.agent_tree, 1);
                                }}
                                field="level"
                            />

                            <Column
                                style={{ textAlign: "center", width: "120px" }}
                                header="부본사"
                                body={(agent: any) => {
                                    return GetAgentTreeByLevel(agent.agent_tree, 2);
                                }}
                                field="balance"
                            />

                            <Column
                                style={{ textAlign: "center", width: "120px" }}
                                header="총판"
                                body={(agent: any) => {
                                    return GetAgentTreeByLevel(agent.agent_tree, 3);
                                }}
                                field="rolling"
                            />

                            <Column
                                style={{ textAlign: "center", width: "120px" }}
                                header="매장"
                                body={(agent: any) => {
                                    return GetAgentTreeByLevel(agent.agent_tree, 4);
                                }}
                                field="rolling"
                            />
                            <Column
                                style={{ textAlign: "center", width: "120px" }}
                                header="은행명"
                                body={(agent: any) => {
                                    return agent.bankname;
                                }}
                                field="rolling"
                            />

                            <Column
                                style={{ textAlign: "center", width: "120px" }}
                                header="입금자명"
                                body={(agent: any) => {
                                    return agent.bankowner;
                                }}
                                field="rolling"
                            />

                            <Column
                                style={{ textAlign: "center", width: "120px" }}
                                header="계좌번호"
                                body={(agent: any) => {
                                    return agent.banknum;
                                }}
                                field="rolling"
                            />
                            <Column
                                style={{ textAlign: "center", width: "120px" }}
                                header="등록일"
                                body={(agent: any) => {
                                    return agent.regdate;
                                }}
                                field="rolling"
                            />

                            <Column
                                style={{ textAlign: "center", width: "120px" }}
                                header="상태"
                                body={(agent: any) => {
                                    return ConverAgentStatus(agent.status);
                                }}
                                field="rolling"
                            />
                            <Column
                                style={{ textAlign: "center", width: "80px" }}
                                header="수락"
                                body={(agent: any) => {
                                    return (
                                        <Button
                                            label="수락"
                                            className="p-button-success"
                                            onClick={() => {
                                                this.handleAgentAccept(agent.idx);
                                            }}
                                        />
                                    );
                                }}
                                field="rolling"
                            />
                            <Column
                                style={{ textAlign: "center", width: "80px" }}
                                header="대기"
                                body={(agent: any) => {
                                    return (
                                        <Button
                                            label="대기"
                                            className="p-button-warning"
                                            onClick={() => {
                                                this.handleAgentWait(agent.idx);
                                            }}
                                        />
                                    );
                                }}
                                field="rolling"
                            />
                            <Column
                                style={{ textAlign: "center", width: "80px" }}
                                header="삭제"
                                body={(agent: any) => {
                                    return (
                                        <Button
                                            label="삭제"
                                            className="p-button-danger"
                                            onClick={() => {
                                                this.handleAgentDel(agent.idx);
                                            }}
                                        />
                                    );
                                }}
                                field="rolling"

                            // handleAgentDel = (idx: any) => {};
                            // handleAgentWait = (idx: any) => {};
                            // handleAgentAccept = (idx: any) => {};
                            />
                        </DataTable>

                    </div>

                </section>
            </>
        );
    }
}
