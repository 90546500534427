


export const TooltipCalculate = (tree) => {
    const ex =
        [
            { field: 'level', header: 'level' },
            { field: 'agent', header: '에이전트' },
            { field: 'nick', header: '닉네임' },
            { field: 'benefitLosing', header: '루징' },
            { field: 'benefitCasinoLosing', header: '카지노 루징' },
            { field: 'benefitCasinoRolling', header: '카지노 롤링' },
            { field: 'benefitSlotRolling', header: '슬롯 롤링' },
        ]

    return ex
}


export const TooltipTree = (tree) => {
    const ex =
        [
            { field: 'level', header: 'level' },
            { field: 'agent', header: '에이전트' },
            { field: 'nick', header: '닉네임' },
        ]

    return ex
}