import React, { Component, useState, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";


import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { confirmAlert } from "react-confirm-alert";

import { CDropdown } from "./custom/cdropdown";
import { CInputText } from "./custom/cinputtext";
import { CButton } from "./custom/cbutton";



import { BalanceService } from "../service/balance.service";


import { ConvertDate, convertToCSV, GetNow, numTomoeny } from "../utility/help";
import { ShareAgentNewPopup } from "../new_main/share.agent.new.popup";


const selectData = [
  { label: "아이디", value: "agent" },
  // { label: "이름", value: "name" },
  { label: "에이전트아이디", value: "tree" },
  // { label: "은행계좌번호", value: "bank_account" },
  // { label: "예금주명", value: "bank_owner" },
  // { label: "전화번호", value: "phone" },
];
// const selectData2 = [
//   { label: "전체", value: "" },
//   { label: "1 LV", value: "1" },
//   { label: "2 LV", value: "2" },
//   { label: "3 LV", value: "3" },
//   { label: "4 LV", value: "4" },
//   { label: "5 LV", value: "5" },
//   { label: "6 LV", value: "6" },
//   { label: "7 LV", value: "7" },
//   { label: "8 LV", value: "8" },
//   { label: "9 LV", value: "9" },
//   { label: "10 LV", value: "10" },
// ];


enum RenderPOP {
  MakeNewAgent

}


interface Prop {
  level: number
  myLevel: number
  management: any
  newAgent: any

  saveNewAgent: (agent: any) => any;
  getAgentList: (params: any) => any;
}


interface State {
  balance: string
  searchText: string
  findText: string

}

export class ShareAgentMenu extends Component<Prop, State> {
  balanceService: BalanceService = new BalanceService();


  constructor(props: Prop) {
    super(props);
    this.state = {
      balance: '',
      searchText: "",
      findText: "",
    }
  }

  RenderNewAgent = () => {

    console.log(this.props.newAgent)

    confirmAlert({
      customUI: ({ onClose }) => {

        const popClonse = () => {
          onClose()
        }

        return (
          <div className="modal-content" style={{ width: '500px' }} >
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => popClonse()}>×</button>
            </div>
            <div className="modal-body">

              <ShareAgentNewPopup
                parentId={''}
                newAgent={this.props.newAgent}
                management={this.props.management}
                saveNewAgent={(newAgent) => this.props.saveNewAgent(newAgent)}></ShareAgentNewPopup>

            </div>
          </div >

        )
      }
    });
  }


  render() {

    return (
      <div className="form-inline">
        <div className="form-group">
          <CDropdown
            options={selectData}
            value={this.state.searchText}
            onChange={(event) =>
              this.setState({ searchText: event.value })
            }
          />
        </div>
        <div className="form-group">
          <CInputText
            value={this.state.findText}
            onChange={(e: any) => this.setState({ findText: e.target.value })}
            onKeyPress={(e: any) => {
              if (e.key === "Enter") {
                this.props.getAgentList({ searchText: this.state.searchText, findText: this.state.findText });
              }
            }}
          />
        </div>

        <div className="form-group">
          <CButton
            label="조회"
            onClick={() => {
              this.props.getAgentList({ searchText: this.state.searchText, findText: this.state.findText });
            }}
          />
        </div>
        <div className="form-group">
          <CButton
            label="재입력"
            onClick={() => this.setState({ findText: '' })}
          />
        </div>
        {
          (this.props.level) === (this.props.myLevel) && (
            <div className="form-group">


              <CButton
                class="button-success"
                icon="pi pi-plus"
                label="에이전트 생성"
                onClick={() => this.RenderNewAgent()}
              />
            </div>
          )
        }

      </div>
    )
  }
}