import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import {
    GetNow,
    ConvertCalendar,
    numTomoeny,
    ConvertDate,
    GetToday,
    ConvertBalanceType,
    ConvertTreeToAgents
} from "../utility/help";
import { SubPaging } from "../share/sub-paging";
import { AgentService } from "../service/agent.service";

import { ShareTooltip } from "./share.tooltip";


import { CCalendarTime } from "./custom/ccalendartime";
import { CDropdown } from "./custom/cdropdown";


import { ShareMoveLogTable } from "./share.historylog.table";
import { TooltipTree } from "../utility/optionpack";



interface State {
    activePage: number;
    maxCount: number;
    logs: any;

    findDay: any;
    begin: any;
    end: any;

    findText: string;
    searchText: string;
    viewType: string;

}

export class HistoryAgentBalcne extends Component<any, State> {
    agentService = new AgentService();

    menu: any;

    constructor(props: any) {
        super(props);

        let today = GetToday();

        this.state = {
            activePage: 0,
            maxCount: 1,
            logs: [],

            findDay: "5",
            begin: today.begin,
            end: today.end,

            findText: "",
            searchText: "",
            viewType: "all",
        };


        // this.GetUserList(0, "", "", "regdate", 1);
    }

    selectData = [
        { label: "아이디", value: "id" },
        // { label: "닉네임", value: "nick" },
        // { label: "에이전트(하부포함X)", value: "agent" },
        // { label: "에이전트(하부포함)", value: "tree" },
    ];


    makeTable = (rowData) => {
        const trees: any = []
        const tree = ConvertTreeToAgents(rowData.agent_tree)
        const tree_ko = ConvertTreeToAgents(rowData.agent_tree_ko)


        for (let ii = 0; ii < tree.length; ii++) {
            trees.push({ level: ii + 1, agent: tree[ii], nick: tree_ko[ii] })
        }
        return trees
    }

    componentDidMount() {
        this.GetUserList(1)
    }

    GetUserList = async (page: number) => {

        const balances = await this.agentService.get_agent_balance({
            skip: page,
            begin: this.state.begin.toLocaleString('sv-SE'),
            end: this.state.end.toLocaleString('sv-SE'),
            searchText: this.state.searchText,
            findText: this.state.findText,
            viewType: this.state.viewType

        })
        if (balances.status === 'success') {
            this.setState({ logs: balances.balances, maxCount: balances.maxCount, activePage: page })
        }
    };



    render() {


        return (
            <>
                <section className="content-header">
                    <h1> 에이전트 머니이력</h1>
                </section>

                <section className="content-header">
                    <ul className="nav nav-tabs" style={{ marginBottom: "20px" }}>
                        <li className={this.state.viewType === "all" ? "active" : ""}>
                            <a
                                onClick={() => {
                                    this.setState({ viewType: "all", });
                                    setTimeout(() => this.GetUserList(1))
                                }}
                            >
                                모두
                            </a>
                        </li>
                        <li className={this.state.viewType === "deposit_agent" ? "active" : ""}>
                            <a
                                onClick={() => {
                                    this.setState({ viewType: "deposit_agent", });
                                    setTimeout(() => this.GetUserList(1))
                                }}
                            >
                                에이젼트지급{"=>"}회원
                            </a>
                        </li>
                        <li className={(this.state.viewType === "withdraw_agent") ? "active" : ""}>
                            <a
                                onClick={() => {

                                    this.setState({ viewType: "withdraw_agent" });
                                    setTimeout(() => this.GetUserList(1))
                                }}
                            >
                                에이젼트회수{"=>"}회원
                            </a>
                        </li>


                        <li className={this.state.viewType === "exchange_rolling_to_balance" ? "active" : ""}>
                            <a
                                onClick={() => {

                                    this.setState({ viewType: "exchange_rolling_to_balance" });
                                    setTimeout(() => this.GetUserList(1))
                                }}
                            >
                                롤링전환
                            </a>
                        </li>
                    </ul>
                </section>

                <section className="content">
                    <div className="form-inline">
                        <div className="form-group">
                            <CCalendarTime
                                placeholder="종료일"
                                value={this.state.begin}
                                onChange={(e) => {
                                    this.setState({ begin: e })
                                }}

                            />
                        </div>

                        <div className="form-group">
                            <CCalendarTime
                                placeholder="종료일"
                                value={this.state.end}
                                onChange={(e) => {
                                    this.setState({ end: e })
                                }}

                            />
                        </div>

                        <div className="form-group">
                            <Button
                                label="기간재입력"
                                onClick={() => {
                                    let today = GetToday();

                                    this.setState({
                                        begin: today.begin,
                                        end: today.end,
                                    });
                                }}
                            />
                        </div>
                        <div className="form-group">
                            <CDropdown
                                options={this.selectData}
                                value={this.state.searchText}
                                onChange={(event) =>
                                    this.setState({ searchText: event.value })
                                }

                            />
                        </div>
                        <div className="form-group">
                            <InputText
                                value={this.state.findText}
                                onChange={(e: any) => this.setState({ findText: e.target.value })}
                                onKeyPress={(e: any) => {
                                    if (e.key === "Enter") {
                                        this.GetUserList(1)
                                    }
                                }}
                                className="form-control input-sm"
                                style={{ width: "150px", margin: 0 }}
                                placeholder="검색어를 입력해 주세요"
                            />
                        </div>

                        <div className="form-group">
                            <Button
                                label="조회"
                                onClick={() => {
                                    this.GetUserList(1)
                                }}
                                className="btn btn-primary btn-sm"
                            />
                        </div>
                        <div className="form-group">
                            <Button
                                label="재입력"
                                onClick={() => { }}
                                className="btn btn-default btn-sm"
                            />
                        </div>
                    </div>

                    {/* 설명 */}

                    {/* <h4>지급 마일리지 총액 : -7,61110P</h4> */}

                    {/* 메인컨텐츠 */}
                    <div className="table-responsive list_view" style={{ marginTop: "10px" }}>


                        <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
                            <DataTable
                                value={this.state.logs}
                                paginatorPosition="both"
                                rows={99999}
                                sortOrder={-1}
                                scrollable
                            >


                                <Column
                                    field="id"
                                    header="종류"

                                    headerStyle={{
                                        textAlign: "center",
                                        minWidth: "60px",
                                        height: "40px",
                                    }}
                                    bodyStyle={{ minWidth: "60px", textAlign: "center" }}
                                    body={(rowData: any, column: any) => {
                                        if (rowData.logType == "admin_do_deposit") {
                                            return <div style={{ color: "blue" }}>에이젼트 충전</div>;
                                        }
                                        if (rowData.logType == "agent_deposit_agent") {
                                            return <div style={{ color: "blue" }}>에이전트 지급</div>;
                                        }
                                        if (rowData.logType == "deposit_agent") {
                                            return <div style={{ color: "blue" }}>에이전트 유저지급</div>;
                                        }
                                        if (rowData.logType == "admin_do_user_deposit") {
                                            return <div style={{ color: "blue" }}>센터 유저지급</div>;
                                        }
                                        if (rowData.logType == "exchange_rolling_to_balance") {
                                            return <div style={{ color: "green" }}>콤프 전환</div>;
                                        }




                                        if (rowData.logType == "exchange_to_balance") {
                                            return <div style={{ color: "red" }}>에이젼트 환전</div>;
                                        }
                                        if (rowData.logType == "change_to_balance") {
                                            return <div style={{ color: "red" }}>에이전트 입금</div>;
                                        }

                                        if (rowData.logType == "withdraw_agent") {
                                            return <div style={{ color: "red" }}>에이전트 유저회수</div>;
                                        }



                                        if (rowData.logType == "agent_withdraw_agent") {
                                            return <div style={{ color: "red" }}>에이전트 {"=>"} 에이전트 회수</div>;
                                        }

                                        if (rowData.logType == "admin_give_agent_balance_deposit") {
                                            return <div style={{ color: "blue" }}>센터 지급</div>;
                                        }

                                        if (rowData.logType == "admin_give_agent_balance_withdraw") {
                                            return <div style={{ color: "red" }}>센터 회수</div>;
                                        }



                                        if (rowData.logType == "admin_do_withdraw") {
                                            return <div style={{ color: "red" }}>에이전트 환전</div>;
                                        }

                                        if (rowData.logType == "agentwithdraw" || rowData.logType == "withdraw_agent" || rowData.logType == "agent_withdraw_agent") {
                                            return <div style={{ color: "red" }}>에이전트 회수</div>;
                                        }


                                        if (rowData.logType == "adminwithdraw") {
                                            return <div style={{ color: "red" }}>환전 완료</div>;
                                        }

                                        if (rowData.logType == "admin_give_agent_balance_withdraw") {
                                            return <div style={{ color: "red" }}>센터 회수</div>;
                                        }

                                        if (rowData.logType == "withdrawcancel") {
                                            return <div style={{ color: "orange" }}>환전 취소</div>;
                                        }
                                        if (rowData.logType == "depositcancel") {
                                            return <div style={{ color: "orange" }}>충전 취소</div>;
                                        }
                                        if (rowData.logType == "agent_withdraw_agent") {
                                            return <div style={{ color: "red" }}>에이전트회수</div>;
                                        }
                                        if (rowData.logType == "agent_deposit_agent") {
                                            return <div style={{ color: "blue" }}>에이전트지급</div>;
                                        }
                                        if (rowData.logType == "admin_withdraw_cancel") {
                                            return <div style={{ color: "red" }}>환전취소</div>;
                                        }

                                        if (rowData.logType == "admin_deposit_cancel") {
                                            return <div style={{ color: "green" }}>관리자 충전취소</div>;
                                        }

                                        return `미정의`;
                                    }} />

                                <Column
                                    field="agent"
                                    header="에이전트 아이디"

                                    headerStyle={{
                                        textAlign: "center",
                                        minWidth: "60px",
                                        height: "40px",
                                    }}
                                    bodyStyle={{ minWidth: "60px", textAlign: "center" }} />
                                {/* <Column
                                    field="nick"
                                    header="닉네임"

                                    headerStyle={{
                                        textAlign: "center",
                                        minWidth: "60px",
                                        height: "40px",
                                    }}
                                    bodyStyle={{ minWidth: "60px", textAlign: "center" }} /> */}
                                {/* <Column
                                    field="agent"
                                    header="에이전트"

                                    headerStyle={{
                                        textAlign: "center",
                                        minWidth: "60px",
                                        height: "40px",
                                    }}
                                    bodyStyle={{ minWidth: "60px", textAlign: "center" }}
                                    body={(viewData) => {

                                        const tooltipId = `tooltip-button_${viewData._id}`
                                        return (

                                            <>
                                                <ShareTooltip
                                                    tooltipId={`${tooltipId}`}
                                                    tables={this.makeTable(viewData)}
                                                    columns={TooltipTree({})}></ShareTooltip>

                                                <span className={tooltipId}>{viewData.agent}</span>
                                            </>
                                        );

                                    }}

                                />
                                <Column
                                    header="벨런스"
                                    headerStyle={{
                                        textAlign: "center",
                                        minWidth: "60px",
                                        height: "40px",
                                    }}
                                    bodyStyle={{ minWidth: "60px", textAlign: "center" }}
                                    body={(rowData) => {
                                        return (
                                            <div>{numTomoeny(rowData.balance)}</div>
                                        );
                                    }}

                                /> */}

                                <Column
                                    header="변동금액"

                                    headerStyle={{
                                        textAlign: "center",
                                        minWidth: "60px",
                                        height: "40px",
                                    }}
                                    bodyStyle={{ minWidth: "60px", textAlign: "center" }}
                                    body={(rowData) => {
                                        return (
                                            <div>{numTomoeny(rowData.balance)}</div>
                                        );
                                    }}

                                />
                                <Column

                                    headerStyle={{
                                        textAlign: "center",
                                        minWidth: "60px",
                                        height: "40px",
                                    }}
                                    bodyStyle={{ minWidth: "60px", textAlign: "center" }}
                                    header="보유금"
                                    body={(rowData) => {
                                        return (
                                            <div>{numTomoeny(rowData.afterbalance)}</div>
                                        );
                                    }}

                                />



                                <Column
                                    header="머니이력"
                                    headerStyle={{
                                        textAlign: "left",
                                        minWidth: "350px",
                                        height: "40px",
                                        float: 'none'
                                    }}
                                    bodyStyle={{
                                        minWidth: "350px", textAlign: "left", float: 'none', justifyContent: 'flex-start'
                                    }}
                                    body={(rowData: any, column: any) => {


                                        if (rowData.logType === "balancetocasino") {
                                            return <div style={{ color: "blue" }}>카지노 실행</div>;
                                        }
                                        else if (rowData.logType === "balancetoslot" || rowData.logType === "casinotobalance") {
                                            return <div style={{ color: "red" }}>게임 실행</div>;
                                        }
                                        else if (rowData.logType === "balancetosloterror") {
                                            return <div style={{ color: "red" }}>머니이동 예러 </div>;
                                        }


                                        return <span dangerouslySetInnerHTML={{ __html: ConvertBalanceType(rowData.agent, rowData.id, rowData.giveId, rowData.targetId, rowData.logType) }} ></span>

                                    }}
                                />
                                {/* <Column
                                    field="company"
                                    header="회사명"

                                    headerStyle={{ textAlign: "center", minWidth: "60px", height: "40px", }}
                                    bodyStyle={{ textAlign: "center", minWidth: "60px" }} />
 */}

                                <Column
                                    header="처리시각"
                                    headerStyle={{ textAlign: "center", minWidth: "60px", height: "40px", }}
                                    bodyStyle={{ textAlign: "center", minWidth: "60px" }}

                                    body={(rowData) => {
                                        return (
                                            <div>
                                                <span>{ConvertDate(rowData.regdate)}</span>
                                            </div>
                                        );
                                    }}
                                />


                            </DataTable>
                        </div>
                    </div>
                    <div className="text-center">
                        <SubPaging
                            activePage={this.state.activePage}
                            maxCount={this.state.maxCount}
                            limit={20}
                            MoveSel={(page: number) => {
                                this.GetUserList(page)
                            }}
                        ></SubPaging>
                    </div>
                </section>
            </>
        );
    }
}


