import React, { Component, useState, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";


import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { confirmAlert } from "react-confirm-alert";

import { CInputText } from "./custom/cinputtext";
import { CButton } from "./custom/cbutton";



import { BalanceService } from "../service/balance.service";

import { CCalendarTime } from "./custom/ccalendartime";
import { CDropdown } from "./custom/cdropdown";


import { ConvertDate, convertToCSV, GetNow, numTomoeny, GetToday } from "../utility/help";


interface Prop {
    GetInfo: (params: any) => any;
}


interface State {
    search: string;
    find: string;


    begin: any;
    end: any;



    viewType: string,
    selectBalanceType: string

}

export class ShareBalanceMenu extends Component<Prop, State> {
    balanceService: BalanceService = new BalanceService();


    constructor(props: Prop) {
        super(props);

        const date = GetToday()

        this.state = {
            begin: date.begin,
            end: date.end,

            search: "",
            find: "",


            viewType: 'deposit',
            selectBalanceType: 'user'

        }
    }

    componentDidMount() {
        this.props.GetInfo({
            page: 1,
            begin: this.state.begin.toLocaleString('sv-SE'),
            end: this.state.end.toLocaleString('sv-SE'),


            search: this.state.search,
            find: this.state.find,

            viewType: this.state.viewType,
            selectBalanceType: this.state.selectBalanceType
        })
    }

    render() {
        return (
            <div
                id="searchForm"
                className="form-inline"
                role="form"
            >
                <div className="form-group">
                    <CCalendarTime
                        placeholder="시작일"
                        value={this.state.begin}
                        onChange={(e) => {
                            this.setState({ begin: e })
                        }}

                    />
                </div>

                <div className="form-group">
                    <CCalendarTime
                        placeholder="종료일"
                        value={this.state.end}
                        onChange={(e) => {
                            this.setState({ end: e })
                        }}

                    />
                </div>

                <div className="form-group">
                    <Button
                        label="기간재입력"
                        onClick={() => {
                            const date = GetToday()

                            this.setState({
                                begin: date.begin,
                                end: date.end,
                            });
                        }}
                        className="btn btn-default btn-sm"
                    />
                </div>
                <div className="form-group">
                    <CDropdown
                        options={[
                            { label: "아이디", value: "id" },
                            { label: "닉네임", value: "nick" },
                            { label: "에이전트(하부포함X)", value: "agent" },
                            { label: "에이전트(하부포함)", value: "tree" },
                            { label: "입금자명", value: "bankowner" },
                        ]}
                        value={this.state.search}
                        onChange={(event) =>
                            this.setState({ search: event.value })
                        }
                    />
                </div>
                <div className="form-group">
                    <InputText
                        value={this.state.find}
                        onChange={(e: any) => this.setState({ find: e.target.value })}
                        onKeyPress={(e: any) => {
                            if (e.key === "Enter") {
                                // this.SearchData();
                            }
                        }}
                        className="form-control input-sm"
                        style={{ width: "200px" }}
                        placeholder="검색어를 입력해 주세요"
                    />
                </div>
                {/* <div className="form-group">
                    일반/수기
                    <CDropdown
                        options={[
                            { label: "전체", value: "ALL" },
                            { label: "센터", value: "center" },
                            { label: "에이전트", value: "agent" },
                            { label: "유저", value: "user" },
                            { label: "취소", value: "cancel" },
                        ]}
                        value={this.state.selectBalanceType}
                        onChange={(event) =>
                            this.setState({ selectBalanceType: event.value })
                        }

                    />
                </div> */}
                <div className="form-group">
                    <Button
                        label="조회"
                        onClick={() => {
                            this.props.GetInfo({
                                page: 1,
                                begin: this.state.begin.toLocaleString('sv-SE'),
                                end: this.state.end.toLocaleString('sv-SE'),


                                searchText: this.state.find,
                                findText: this.state.search,

                                viewType: this.state.viewType,
                                selectBalanceType: this.state.selectBalanceType
                            })
                        }}
                        className="btn btn-primary btn-sm"
                    />
                </div>
                <div className="form-group">
                    <Button
                        label="재입력"
                        onClick={() => { }}
                        className="btn btn-default btn-sm"
                    />
                </div>
            </div>
        )
    }
}




export class AgentBalanceMenu extends Component<Prop, State> {
    balanceService: BalanceService = new BalanceService();


    constructor(props: Prop) {
        super(props);

        const date = GetToday()

        this.state = {
            begin: date.begin,
            end: date.end,

            search: "",
            find: "",


            viewType: 'all',
            selectBalanceType: 'all'

        }
    }

    componentDidMount() {
        this.props.GetInfo({
            page: 1,
            begin: this.state.begin.toLocaleString('sv-SE'),
            end: this.state.end.toLocaleString('sv-SE'),


            search: this.state.search,
            find: this.state.find,

            viewType: this.state.viewType,
            selectBalanceType: this.state.selectBalanceType
        })
    }

    render() {
        return (
            <div
                id="searchForm"
                className="form-inline"
                role="form"
            >
                <div className="form-group">
                    <CCalendarTime
                        placeholder="시작일"
                        value={this.state.begin}
                        onChange={(e) => {
                            this.setState({ begin: e })
                        }}

                    />
                </div>

                <div className="form-group">
                    <CCalendarTime
                        placeholder="종료일"
                        value={this.state.end}
                        onChange={(e) => {
                            this.setState({ end: e })
                        }}

                    />
                </div>

                <div className="form-group">
                    <Button
                        label="기간재입력"
                        onClick={() => {
                            const date = GetToday()

                            this.setState({
                                begin: date.begin,
                                end: date.end,
                            });
                        }}
                        className="btn btn-default btn-sm"
                    />
                </div>
                <div className="form-group">
                    <CDropdown
                        options={[
                            { label: "닉네임", value: "nick" },
                            { label: "에이전트", value: "agent" },
                            // { label: "에이전트(하부포함)", value: "tree" },
                            { label: "입금자명", value: "owner" },
                        ]}
                        value={this.state.search}
                        onChange={(event) =>
                            this.setState({ search: event.value })
                        }
                    />
                </div>
                <div className="form-group">
                    <InputText
                        value={this.state.find}
                        onChange={(e: any) => this.setState({ find: e.target.value })}
                        onKeyPress={(e: any) => {
                            if (e.key === "Enter") {
                                // this.SearchData();
                            }
                        }}
                        className="form-control input-sm"
                        style={{ width: "200px" }}
                        placeholder="검색어를 입력해 주세요"
                    />
                </div>
                {/* <div className="form-group">
                    일반/수기
                    <CDropdown
                        options={[
                            { label: "전체", value: "ALL" },
                            { label: "센터", value: "center" },
                            { label: "에이전트", value: "agent" },
                            { label: "유저", value: "user" },
                            { label: "취소", value: "cancel" },
                        ]}
                        value={this.state.selectBalanceType}
                        onChange={(event) =>
                            this.setState({ selectBalanceType: event.value })
                        }

                    />
                </div> */}
                <div className="form-group">
                    <Button
                        label="조회"
                        onClick={() => {
                            this.props.GetInfo({
                                page: 1,
                                begin: this.state.begin.toLocaleString('sv-SE'),
                                end: this.state.end.toLocaleString('sv-SE'),


                                searchText: this.state.find,
                                findText: this.state.search,

                                viewType: this.state.viewType,
                                selectBalanceType: this.state.selectBalanceType
                            })
                        }}
                        className="btn btn-primary btn-sm"
                    />
                </div>
                <div className="form-group">
                    <Button
                        label="재입력"
                        onClick={() => { }}
                        className="btn btn-default btn-sm"
                    />
                </div>
            </div>
        )
    }
}