import React, { Component, useState, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";


import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { confirmAlert } from "react-confirm-alert";
import { Message } from "primereact/message";
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';

// import 'primeicons/primeicons.css';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';

// import 'primereact/resources/primereact.css';
// import 'primeflex/primeflex.css';

import { CDropdown } from "./custom/cdropdown";



import { BalanceService } from "../service/balance.service";


import {
    ConvertDate,
    convertToCSV,
    GetNow,
    numTomoeny,
    ConvertNick,
    RenderAgent,
    ConvertTreeToAgents,
    ConverAgentBalanceStatus,
    OpenUserDetail,
    OpenAgentDetail,
    RenderAgentByTree
} from "../utility/help";


import { TooltipTree } from "../utility/optionpack";
import { ShareTooltip } from "./share.tooltip";


interface Prop {
    balances: any
    selectAll: boolean
    selectedCustomers: any[]

    onSelectAllChange: (balance: any) => any
    onSelectionChange: (balance: any) => any

    onSendMessage: (mode: string) => any
}


interface State {
    checked: boolean
    // balance: string

    // userInfo: any
    // dropdownItem: string
    // search: any
    // find: string

    // status: string
}

export class ShareBalanceRequestedTable extends Component<Prop, State> {
    balanceService: BalanceService = new BalanceService();



    constructor(props: Prop) {
        super(props);

        this.state = {
            checked: true
            // balance: '',
            // userInfo: null,
            // dropdownItem: "user_id",
            // search: "",
            // status: "",
            // find: "",

        }


    }



    render() {

        const rowClass = (data) => {

            if (data.status === 'already' || data.status === 'cansel') { return '' }

            console.log(data.code)
            if (data.code === "R") return 'row-balance-color-first'
            if (data.code === "Y") return 'row-balance-color-1000'


            return ' '
        }



        return (
            <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
                <DataTable

                    rowClassName={rowClass}
                    value={this.props.balances}
                    sortField=""
                    sortOrder={1}
                    rowGroupMode="rowspan"
                    style={{ border: "1px solid #c8c8c8" }}
                    selectAll={this.props.selectAll}
                    selection={this.props.selectedCustomers}

                    onSelectAllChange={this.props.onSelectAllChange}
                    onSelectionChange={this.props.onSelectionChange}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '30px' }} style={{ width: '30px' }} ></Column>



                    <Column
                        header="구분"
                        body={(rowData: any, column: any) => {
                            {
                                rowData.type === "deposit" && <Message severity="error" text="충전" />;
                                rowData.type === "withdraw" && <Message severity="info" text="환전" />;
                                rowData.type === "admin_deposit" && <Message severity="success" text="지급" />;
                                rowData.type === "admin_withdraw" && <Message severity="warn" text="회수" />;
                                rowData.type === "coupon" && <Message severity="success" text="쿠폰" />;
                            }

                            if (rowData.type === "deposit") return <Tag className="p-mr-2 " value="충전"></Tag>
                            if (rowData.type === "withdraw") return <Tag className="p-mr-2 p-tag-danger" value="환전"></Tag>
                            if (rowData.type === "admin_deposit") return <Tag className="p-mr-2" value="지급"></Tag>
                            if (rowData.type === "admin_withdraw") return <Tag className="p-mr-2 p-tag-danger" value="회수"></Tag>

                            return <Tag className="p-mr-2" value="미정의"></Tag>
                        }}
                    />
                    <Column
                        field="id"
                        header="아이디"
                        body={(rowData) => {
                            return (
                                <div style={{ color: rowData.color }} onClick={() => OpenUserDetail(rowData.id)}>{rowData.id}</div>
                            );
                        }}
                    />

                    <Column
                        field="nick"
                        header="닉네임"
                    />


                    <Column
                        field=""
                        header="에이전트등급"
                        body={(rowData) => {
                            return (
                                RenderAgentByTree(rowData.agent_tree)
                            );
                        }}
                    />

                    <Column
                        field="agent"
                        header="에이전트"

                    />
                    <Column

                        header="입금/출금"
                        body={(rowData) => {
                            return (
                                <div>{numTomoeny(rowData.balance)}</div>
                            );
                        }}
                    />
                    <Column
                        field="bankowner"
                        header="입금자명"

                    />
                    <Column
                        field="bankname"
                        header="회원정보은행"

                    />
                    <Column
                        field="phone"
                        header="연락처"

                    />
                    {/* <Column
                        field=""
                        header="회원레벨"

                    />
                    <Column
                        field=""
                        header="게임레벨"
                    />
                     */}
                    {/* <Column

                        header="처리확인"
                        body={(rowData) => {
                            if (rowData.status === 'already' || rowData.status === 'cansel') { return }

                            return (
                                <Button
                                    type="button"
                                    label={"확인"}
                                    onClick={(e: any) => {
                                        this.props.onSelectionChange({ value: [rowData] })
                                        setTimeout(() => this.props.onSendMessage('success'));

                                    }}
                                    aria-haspopup
                                    aria-controls="overlay_panel"
                                    className="btn btn-success btn-xs"
                                />
                            );
                        }}
                    /> */}
                    <Column
                        header="처리"
                        style={{ width: '150px' }}
                        body={(rowData) => {
                            if (rowData.status === 'already' || rowData.status === 'cansel') { return ConverAgentBalanceStatus(rowData.type, rowData.status) }

                            return (
                                < div className="p-formgroup-inline" style={{}}>
                                    <Button
                                        type="button"
                                        label={"취소"}
                                        onClick={(e: any) => {
                                            this.props.onSelectionChange({ value: [rowData] })
                                            setTimeout(() => this.props.onSendMessage('cancel'));

                                        }}
                                        aria-haspopup
                                        aria-controls="overlay_panel"
                                        className="btn btn-danger p-button-danger btn-xs"
                                    />

                                    <Button
                                        type="button"
                                        label={"확인"}
                                        onClick={(e: any) => {
                                            this.props.onSelectionChange({ value: [rowData] })
                                            setTimeout(() => this.props.onSendMessage('success'));

                                        }}
                                        aria-haspopup
                                        aria-controls="overlay_panel"
                                        className="btn btn-success btn-xs"
                                    />
                                </div>

                            );
                        }}
                    />

                    <Column

                        header="당일입금"
                        body={(rowData) => {
                            return (
                                <div className="text-bold text-info">{numTomoeny(rowData.today_change)}</div>
                            );
                        }}
                    />

                    <Column

                        header="당일출금"
                        body={(rowData) => {
                            return (
                                <div className="text-bold text-danger">{numTomoeny(rowData.today_exchange)}</div>
                            );
                        }}
                    />
                    <Column
                        field=""
                        header="신청시각"
                        body={(rowData) => {
                            return ConvertDate(rowData.regdate)
                        }}
                    />
                    <Column

                        header="기존이력 조회"
                        body={(rowData) => {
                            return (
                                <Button
                                    type="button"
                                    label={"조회"}
                                    onClick={() => OpenUserDetail(rowData.id, 'balance')}
                                    aria-haspopup
                                    aria-controls="overlay_panel"
                                    className="btn btn-warning btn-xs"
                                />
                            );
                        }}
                    />
                    <Column
                        field="simplememo"
                        header="메모"

                    />
                </DataTable>
            </div>
        )
    }
}






export class ShareAgentBalanceRequestedTable extends Component<Prop, State> {
    balanceService: BalanceService = new BalanceService();



    constructor(props: Prop) {
        super(props);

        this.state = {
            checked: true
            // balance: '',
            // userInfo: null,
            // dropdownItem: "user_id",
            // search: "",
            // status: "",
            // find: "",

        }
    }


    makeTable = (rowData) => {
        const trees: any = []
        const tree = ConvertTreeToAgents(rowData.agent_tree)
        const tree_ko = ConvertTreeToAgents(rowData.agent_tree_ko)


        for (let ii = 0; ii < tree.length; ii++) {
            trees.push({ level: ii + 1, agent: tree[ii], nick: tree_ko[ii] })
        }



        return trees
    }

    render() {

        const rowClass = (data) => {
            if (data.logType === "deposit" || data.logType === "agentdeposit" || data.logType === "admindeposit") return 'row-balance-deposit'
            if (data.logType === "withdraw" || data.logType === "agentwithdraw" || data.logType === "adminwithdraw") return 'row-balance-withdraw '


            return ' '
        }



        return (
            <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
                <DataTable

                    rowClassName={rowClass}
                    value={this.props.balances}
                    sortField=""
                    sortOrder={1}
                    rowGroupMode="rowspan"
                    style={{ border: "1px solid #c8c8c8" }}
                    selectAll={this.props.selectAll}
                    selection={this.props.selectedCustomers}

                    onSelectAllChange={this.props.onSelectAllChange}
                    onSelectionChange={this.props.onSelectionChange}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: '30px' }} style={{ width: '30px' }} ></Column>

                    {/*
                    <Column
                        style={{ width: '50px' }}
                        header={() => {
                            return <div className="p-field-checkbox">
                                <div className="p-field-checkbox">
                                </div>
                            </div>
                        }}
                        body={(rowData: any, column: any) => {
                            return <div className="p-field-checkbox">
                                <div className="p-field-checkbox">
                                    <Checkbox checked={rowData.checked} onChange={e => { this.props.UpdateChecked(rowData) }} />
                                </div>
                            </div>
                        }}
                    /> */}


                    <Column
                        header="구분"
                        body={(rowData: any, column: any) => {

                            if (rowData.type === "change_to_balance") return <Tag className="p-mr-2 " value="에이전트 충전"></Tag>
                            if (rowData.type === "exchange_to_balance") return <Tag className="p-mr-2 p-tag-danger" value="에이전트 환전"></Tag>

                            return <Tag className="p-mr-2" value="미정의"></Tag>
                        }}
                    />
                    <Column
                        field="agent"
                        header="아이디"
                        body={(rowData) => {
                            const tooltipId = `tooltip-button_${rowData._id}`

                            return (
                                <>
                                    <ShareTooltip
                                        tooltipId={`${tooltipId}`}
                                        tables={this.makeTable(rowData)}
                                        columns={TooltipTree({})}></ShareTooltip>

                                    <span className={tooltipId} onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}</span>
                                </>
                            );

                        }}
                    />

                    <Column
                        field="nick"
                        header="닉네임"
                    />

                    <Column

                        header="입금/출금"
                        body={(rowData) => {
                            return (
                                <div>{numTomoeny(rowData.balance)}</div>
                            );
                        }}
                    />
                    <Column
                        field="bankowner"
                        header="입금자명"

                    />
                    <Column
                        field="bankname"
                        header="회원정보은행"

                    />
                    <Column
                        field="phone"
                        header="연락처"

                    />

                    <Column
                        header="처리"
                        style={{ width: '150px' }}
                        body={(rowData) => {
                            if (rowData.status === 'already' || rowData.status === 'cansel') { return ConverAgentBalanceStatus(rowData.type, rowData.status) }

                            return (
                                < div className="p-formgroup-inline" style={{}}>
                                    <Button
                                        type="button"
                                        label={"취소"}
                                        onClick={(e: any) => {
                                            this.props.onSelectionChange({ value: [rowData] })
                                            setTimeout(() => this.props.onSendMessage('cancel'));

                                        }}
                                        aria-haspopup
                                        aria-controls="overlay_panel"
                                        className="btn btn-danger p-button-danger btn-xs"
                                    />

                                    <Button
                                        type="button"
                                        label={"확인"}
                                        onClick={(e: any) => {
                                            this.props.onSelectionChange({ value: [rowData] })
                                            setTimeout(() => this.props.onSendMessage('success'));

                                        }}
                                        aria-haspopup
                                        aria-controls="overlay_panel"
                                        className="btn btn-success btn-xs"
                                    />
                                </div>

                            );
                        }}
                    />

                    <Column

                        header="당일입금"
                        body={(rowData) => {
                            return (
                                <div className="text-bold text-info">{numTomoeny(rowData.today_change)}</div>
                            );
                        }}
                    />

                    <Column

                        header="당일출금"
                        body={(rowData) => {
                            return (
                                <div className="text-bold text-danger">{numTomoeny(rowData.today_exchange)}</div>
                            );
                        }}
                    />
                    <Column
                        field=""
                        header="신청시각"
                        body={(rowData) => {
                            return ConvertDate(rowData.regdate)
                        }}
                    />
                    <Column

                        header="기존이력 조회"
                        body={(rowData) => {
                            return (
                                <Button
                                    type="button"
                                    label={"조회"}
                                    onClick={(e: any) => {
                                        // this.OpenSearchLog();
                                    }}
                                    aria-haspopup
                                    aria-controls="overlay_panel"
                                    className="btn btn-warning btn-xs"
                                />
                            );
                        }}
                    />


                    <Column
                        field="ip"
                        header="신청IP"

                    />

                    <Column
                        field="simplememo"
                        header="메모"

                    />

                </DataTable>
            </div >
        )
    }
}





