import React, { Component } from "react";
import { Button } from "primereact/button";

import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { BalanceService } from "../service/balance.service";

import {
  ConvertDate,
  convertToCSV,
  GetNow,
  numTomoeny,
  IsConnection,
  OpenUserDetail,
  uuidv4
} from "../utility/help";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SubPaging } from "../share/sub-paging";
import { ShareUserMenu } from "./share.user.menu";

import { confirmAlert } from "react-confirm-alert";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { ifError } from "assert";
import CurrencyInput from 'react-currency-input-field';

enum popupView {
  none = "none",
  charge = "charge",
  deposit = "deposit",
  withdraw = "withdraw",

  comp_deposit = "comp_deposit",
  comp_withdraw = "comp_withdraw",


  edit_user = "edit_user",

  user_pass = "user_pass",
  user_phone = "user_phone",

  note = "note",
}


interface Props {
  searchText?: string
  findText?: string

}

interface State {
  sortType: string;
  sort: number;
  page: number;
  maxCount: number;

  userTableValue : [],
  agentTableValue : [],

  seach: string;
  uuid: string;

  userMaxBenefits: any
  serverTime: Date

  cloneParams: any

  popupState: popupView
  user: any
  giveBalance: number

  rollingSlot: string
  rollingCasino: string

}

export class UserAgentRegUserList extends Component<any, State> {
  userService = new UserService();
  fileService = new FileService();
  balanceService = new BalanceService();


  menu: any;

  constructor(props: any) {
    super(props);
    this.state = {
      uuid: uuidv4(),
      sortType: "regdate",
      sort: 1,
      page: 1,
      maxCount: 1,
      user: null,
      userMaxBenefits: {},
      seach: "",
      serverTime: new Date(),
      popupState: popupView.none,
      cloneParams: {
        page: 1,
        search: this.props.searchText,
        find: this.props.findText,
        sortType: 'regdate',
        sort: 1
      },
      giveBalance: 0,
      rollingSlot: '0',
      rollingCasino: '0',

      userTableValue : [],
      agentTableValue : [],

    };

    this.GetUserList(this.state.cloneParams);
  }

  componentDidMount() { }

  GetUserList = async (params: any) => {
    const response = await this.userService.get_user_and_agent_wait(params).then(s => s);
    if (response.status === 'success') {
      this.setState({ 

        userTableValue: response.users,
        agentTableValue: response.agents,

       });

    }
  };



  render() {


    // const rowClass = (data) => {
    //   if (data.total_deposit > 10000000) return 'row-balance-color-1000'
    //   if (data.total_deposit < 1000000 && data.total_deposit > 0) return 'row-balance-color-first'
    //   if (data.total_deposit < 3000000 && data.total_deposit > 0) return 'row-balance-color-300'
    //   if (data.total_deposit > 9999999 && data.total_deposit > 0) return 'row-balance-color-999'
    //   return ''
    // }

    const rowUserState = (data, options) => {

      if (data === options.rowData.status && options.rowData.status !== 'used') return 'blackUser'
      if (data === options.rowData.logindate && IsConnection(this.state.serverTime, options.rowData.logindate) && options.rowData.status === 'used') return 'connectionUser'

      // if (data === options.rowData.status && options.rowData.status === 'used') return 'connectionUser'
      // IsConnection

      return ''
    }



    return (
      <>
        <section className="content-header">
          <h1>대기중 회원 리스트</h1>
        </section>

        <section className="content">

      
          {/* 메인컨텐츠 */}
          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>

            <DataTable
              cellClassName={rowUserState}
              value={this.state.userTableValue}
              sortField=""
              sortOrder={1}
              rowGroupMode="rowspan"
              scrollable
              scrollHeight="800px"
            >
              <Column
                field="status"
                headerStyle={{ textAlign: "center", width: "40px" }}
                bodyStyle={{ textAlign: "center", width: "40px" }}
                header="대기"
                body={(rowData) => {
                  if (rowData.status === 'used') return
                  return (
                    <div>
                      <i className="pi pi-circle" style={{ fontSize: '24px', fontWeight: 'bold', color: 'white' }}></i>
                    </div>
                  );
                }}
              />
       
              <Column
                header="아이디"
                headerStyle={{ textAlign: "center", }}
                bodyStyle={{ textAlign: "center", }}
                body={(rowData) => {
                  return (
                    <div >{rowData.id}</div>
                  );
                }}

              />

              <Column
                field="nick"
                headerStyle={{ textAlign: "center", }}
                bodyStyle={{ textAlign: "center", }}

                header="닉네임"


              />
      

              <Column
                field="agent"
                header="에이전트"
                headerStyle={{ textAlign: "center", justifyContent: 'center' }}
                bodyStyle={{ textAlign: "center", justifyContent: 'center' }}

              />
              <Column
                headerStyle={{ textAlign: "center", justifyContent: 'center', width: "100px" }}
                bodyStyle={{ textAlign: "center", justifyContent: 'center', width: "100px", border: '1px solid rgb(200, 200, 200)' }}
                header="보유머니"
                body={(rowData) => {
                  return (
                    <div style={{ textAlign: 'right', padding: 5 }}>{numTomoeny(rowData.balance + rowData.in_balance)}</div>
                  );
                }}
              />


              <Column
                headerStyle={{ textAlign: "center", width: "60px" }}
                bodyStyle={{ textAlign: "center", width: "60px" }}

                header="금고"
                body={(rowData) => {
                  return (
                    <div style={{ textAlign: 'right', padding: 5 }}>{numTomoeny(rowData.point)}</div>
                  );
                }}
              />
              <Column
                headerStyle={{ textAlign: "center", width: "60px" }}
                bodyStyle={{ textAlign: "center", width: "60px" }}

                header="롤링"
                body={(rowData) => {
                  return (
                    <div style={{ textAlign: 'right', padding: 5 }}>{numTomoeny(rowData.rolling)}</div>
                  );
                }}
              />
           
              <Column
                header="가입일"
                headerStyle={{ textAlign: "center", width: "100px" }}
                bodyStyle={{ textAlign: "center", width: "100px" }}

                body={(rowData) => {
                  return (
                    <div>
                      <span>{ConvertDate(rowData.regdate)}</span>
                    </div>
                  );
                }}
              />
              <Column
                header="마지막 로그인"
                headerStyle={{ textAlign: "center", width: "100px" }}
                bodyStyle={{ textAlign: "center", width: "100px" }}
                body={(rowData) => {
                  return (
                    <div>
                      <span>{ConvertDate(rowData.logindate)}</span>
                    </div>
                  );
                }}
              />
              <Column
                field="loginip"
                headerStyle={{ textAlign: "center", width: "100px" }}
                bodyStyle={{ textAlign: "center", width: "100px" }}
                header="최종 로그인 IP"
              />


            </DataTable>
          </div>
       


        </section >

        <section className="content-header">
          <h1>대기중 에이전트 리스트</h1>
        </section>

        <section className="content">

      
          {/* 메인컨텐츠 */}
          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>

            <DataTable
              cellClassName={rowUserState}
              value={this.state.agentTableValue}
              sortField=""
              sortOrder={1}
              rowGroupMode="rowspan"
              scrollable
              scrollHeight="800px"
            >
              <Column
                field="status"
                headerStyle={{ textAlign: "center", width: "40px" }}
                bodyStyle={{ textAlign: "center", width: "40px" }}
                header="대기"
                body={(rowData) => {
                  if (rowData.status === 'used') return
                  return (
                    <div>
                      <i className="pi pi-circle" style={{ fontSize: '24px', fontWeight: 'bold', color: 'white' }}></i>
                    </div>
                  );
                }}
              />
      
              <Column
                header="아이디"
                headerStyle={{ textAlign: "center", }}
                bodyStyle={{ textAlign: "center", }}
                body={(rowData) => {
                  return (
                    <div >{rowData.agent}</div>
                  );
                }}

              />

              <Column
                field="nick"
                headerStyle={{ textAlign: "center", }}
                bodyStyle={{ textAlign: "center", }}

                header="닉네임"


              />

              <Column
                field="agent"
                header="에이전트"
                headerStyle={{ textAlign: "center", justifyContent: 'center' }}
                bodyStyle={{ textAlign: "center", justifyContent: 'center' }}

              />
              <Column
                headerStyle={{ textAlign: "center", justifyContent: 'center', width: "100px" }}
                bodyStyle={{ textAlign: "center", justifyContent: 'center', width: "100px", border: '1px solid rgb(200, 200, 200)' }}
                header="보유머니"
                body={(rowData) => {
                  return (
                    <div style={{ textAlign: 'right', padding: 5 }}>{numTomoeny(rowData.balance + rowData.in_balance)}</div>
                  );
                }}
              />


              <Column
                headerStyle={{ textAlign: "center", width: "60px" }}
                bodyStyle={{ textAlign: "center", width: "60px" }}

                header="금고"
                body={(rowData) => {
                  return (
                    <div style={{ textAlign: 'right', padding: 5 }}>{numTomoeny(rowData.point)}</div>
                  );
                }}
              />
              <Column
                headerStyle={{ textAlign: "center", width: "60px" }}
                bodyStyle={{ textAlign: "center", width: "60px" }}

                header="롤링"
                body={(rowData) => {
                  return (
                    <div style={{ textAlign: 'right', padding: 5 }}>{numTomoeny(rowData.rolling)}</div>
                  );
                }}
              />
           
              <Column
                header="가입일"
                headerStyle={{ textAlign: "center", width: "100px" }}
                bodyStyle={{ textAlign: "center", width: "100px" }}

                body={(rowData) => {
                  return (
                    <div>
                      <span>{ConvertDate(rowData.regdate)}</span>
                    </div>
                  );
                }}
              />
              <Column
                header="마지막 로그인"
                headerStyle={{ textAlign: "center", width: "100px" }}
                bodyStyle={{ textAlign: "center", width: "100px" }}
                body={(rowData) => {
                  return (
                    <div>
                      <span>{ConvertDate(rowData.logindate)}</span>
                    </div>
                  );
                }}
              />
              <Column
                field="loginip"
                headerStyle={{ textAlign: "center", width: "100px" }}
                bodyStyle={{ textAlign: "center", width: "100px" }}
                header="최종 로그인 IP"
              />


            </DataTable>
          </div>
       


        </section >

        


      </>
    );
  }
}
