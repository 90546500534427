import axios from 'axios';

import { AxiosMag } from "./axios.service";

export class FileService {
    get_wait_deposit = async (
        begin: any,
        end: any,
        searchText: any,
        findText: any,
        viewType: any) => {
        return await AxiosMag.SendMessageByPost("/file/admin_get_deposit_and_withdraw", {
            begin,
            end,
            searchText,
            findText,
            viewType
        });
    };

    export_slot_bet = async (
        begin: any,
        end: any,
        userId: any) => {
        return await AxiosMag.SendMessageByPost("/file/admin_do_slot_export", {
            begin,
            end,
            userId,
        });
    };



    export_org_slot_bet = async (
        begin: any,
        end: any,
        userId: any) => {
        return await AxiosMag.SendMessageByPost("/file/admin_do_slot_org_export", {
            begin,
            end,
            userId,
        });
    };

    exportBalancePoint = async (
        begin: any,
        end: any,
        searchText: any,
        findText: any,
        viewType: any,
        type: any) => {
        return await AxiosMag.SendMessageByPost("/file/admin_get_balance_bouns", {
            begin,
            end,
            searchText,
            findText,
            viewType,
            type
        });
    };


    exportUser = async (status: any) => {
        return await AxiosMag.SendMessageByPost("/file/admin_get_user", {
            status
        });
    };
}