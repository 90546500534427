import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { AgentService } from "../service/agent.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { TreeTable } from 'primereact/treetable';

import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { SubPaging } from "../share/sub-paging";
import { CCalendarTime } from "./custom/ccalendartime";
import { ThreeDRotationSharp } from "@material-ui/icons";

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';


import { numTomoeny, balanceColor, OpenAgentCal, OpenAgentDetail, GetToday, ConvertCalendar, GetYesterDay, ConvertCalculate, CalculateTotalBalance } from "../utility/help";



interface State {

  begin: any;
  end: any;
  agentInfo: any[];
  totalBalance: any;
  viewType: string;
  level: number
}

export class SubCalc extends Component<any, State> {
  agentService = new AgentService();

  menu: any;

  constructor(props: any) {
    super(props);

    const today = GetYesterDay()
    const begin = today.begin
    const end = today.end

    this.state = {
      begin: begin,
      end: end,

      viewType: 'all',
      agentInfo: [],
      totalBalance: {},
      level: 4
    };

    this.handlegetSubAgentCalculate(begin, end);
  }




  handlegetSubAgentCalculate(begin: any, end: any) {
    this.agentService.admin_get_sub_agent(this.props.agentId, begin.toLocaleString('sv-SE'), end.toLocaleString('sv-SE')).then((data) => {
      if (data.status == "success") {

        let totalBalance = {}
        for (let balances of Object.values(data.agentCombine) as any) {
          for (let key of Object.keys(balances as [])) {

            if (typeof (balances[key]) !== 'number') {
              continue;
            }

            if (totalBalance[key] == null) {
              totalBalance[key] = 0
            }
            totalBalance[key] += Number(balances[key] as Number)



          }

          if (totalBalance['total'] == null) {
            totalBalance['total'] = 0
          }

          totalBalance['total'] += (((balances['totalSlotBet'] - balances['totalSlotWin']) / 100) * balances['benefitLosing']) - ((balances['benefitSlotRolling'] / 100) * balances['totalSlotBet'])


          if (totalBalance['winlose'] == null) {
            totalBalance['winlose'] = 0
          }

          if (totalBalance['totalSlotRolling'] == null) {
            totalBalance['totalSlotRolling'] = 0
          }
          totalBalance['totalSlotRolling'] += ((totalBalance['totalSlotBet'] / 100) * totalBalance['benefitSlotRolling'])




        }

        if (totalBalance['winlose'] == null) {
          totalBalance['winlose'] = 0
        }

        totalBalance['winlose'] = totalBalance['totalSlotBet'] - totalBalance['totalSlotWin']



        totalBalance['totalBalance'] = 0
        totalBalance['exchangeBalance'] = 0
        for (const agent of Object.values(data.agentCombine) as any) {
          totalBalance['totalBalance'] += CalculateTotalBalance(agent)
          totalBalance['exchangeBalance'] += (Number.isNaN(agent['exchangeBalance']) ? 0 : Number(agent['exchangeBalance']))
        }



        const combin = Object.values(data.agentCombine)
        combin.sort(function (a: any, b: any) {
          if (a.agent_tree < b.agent_tree) return -1;
          if (b.agent_tree < a.agent_tree) return 1;
          return 0;
        })

        console.log(combin[0])
        this.setState({ agentInfo: combin, totalBalance: combin[0], level: data.level });
        // this.setState({ agentInfo: data.agentCombine });
      }
    });
  }


  componentDidMount() {

  }

  // rowClassName(node) {



  //   return { 'p-highlight': (node.children && node.children.length === 3) };
  // }

  rowClassName(node) {

    if (node.level === 2) return { 'p-tag-level2': true }
    if (node.level === 3) return { 'p-tag-level3': true }
    if (node.level === 4) return { 'p-tag-level4': true }

    return { '': true };
  }

  sizeTemplate(node) {

    // console.log(node)
    // let size = node.data.size;
    // let fontWeight = parseInt(size, 10) > 75 ? 'bold' : 'normal';

    // return <span style={{ fontWeight: fontWeight }}>{size}</span>;
    return <span style={{}}>{ }</span>;
  }




  render() {


    let headerGroup = (
      <ColumnGroup>
        <Row>
          {
            this.state.level <= 1 && (
              <Column style={{ textAlign: "center", }} header={(rowData: any, column: any) => { return (<div>본사</div>) }} />
            )
          }
          {
            this.state.level <= 2 && (
              <Column header={(rowData: any, column: any) => { return (<div>부본사</div>) }} />
            )
          }
          {
            this.state.level <= 3 && (
              <Column header={(rowData: any, column: any) => { return (<div>총판</div>) }} />
            )
          }
          {
            this.state.level <= 4 && (
              <Column header={(rowData: any, column: any) => { return (<div>매장</div>) }} />
            )
          }


          <Column header={(rowData: any, column: any) => { return <div><div>유저 입금</div><div>총판 지급</div><div>관리자 지급</div></div> }} />
          <Column header={(rowData: any, column: any) => { return <div><div>유저 출금</div><div>총판 회수</div><div>관리자 회수</div></div> }} />
          <Column header={(rowData: any, column: any) => { return <div><div>유저 수익</div><div>총판 수익</div></div> }} />
          <Column header={(rowData: any, column: any) => { return <div><div>Casino Bet</div></div> }} />
          <Column header={(rowData: any, column: any) => { return <div><div>Slot Bet</div></div> }} />

          <Column header={(rowData: any, column: any) => { return <div><div>유저보유금</div><div>전일 보유</div></div> }} />

          <Column header={(rowData: any, column: any) => { return (<div><div>총회원 루징</div></div>) }} />


          <Column header={(rowData: any, column: any) => { return <div><div>롤링 케쉬전환</div></div> }} />
          <Column header={(rowData: any, column: any) => { return (<div><div>정산금</div><div>루징프로테이지%</div></div>) }} />

          <Column header={(rowData: any, column: any) => { return <div><div>슬롯 롤링</div><div>프로테이지</div></div>; }} />
          <Column header={(rowData: any, column: any) => { return <div><div>카지노 롤링</div><div>프로테이지</div></div> }} />

          <Column header={(rowData: any, column: any) => { return <div><div>파트너 충전</div><div>파트너 환전</div></div> }} />
          <Column header={(rowData: any, column: any) => { return <div>유저 슬롯 롤링</div> }} />
          <Column header={(rowData: any, column: any) => { return <div>유저 카지노 롤링</div> }} />



        </Row>
        {
          this.state.totalBalance && this.state.totalBalance['idx'] !== null && (

            <Row >

              {
                this.state.level <= 1 && (
                  <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header="-" />
                )
              }
              {
                this.state.level <= 2 && (
                  <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header="-" />
                )
              }
              {
                this.state.level <= 3 && (
                  <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header="-" />
                )
              }
              {
                this.state.level <= 4 && (
                  <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header="-" />
                )
              }


              <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                return <div>
                  <div>{numTomoeny(this.state.totalBalance['totalDeposit'])}</div>
                  <div>{numTomoeny(this.state.totalBalance['totalGiveDeposit'])}</div>
                  <div>{numTomoeny(this.state.totalBalance['totalAdmindeposit'])}</div>
                </div>
              }} />
              <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                return <div>
                  <div>{numTomoeny(this.state.totalBalance['totalWithdraw'])}</div>
                  <div>{numTomoeny(this.state.totalBalance['totalGiveWithdraw'])}</div>
                  <div>{numTomoeny(this.state.totalBalance['totalAdminwithdraw'])}</div>
                </div>
              }} />

              <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                return <div>
                  <div> {numTomoeny(this.state.totalBalance['totalDeposit'] - this.state.totalBalance['totalWithdraw'])}</div>
                  <div> {numTomoeny(this.state.totalBalance['totalGiveDeposit'] - this.state.totalBalance['totalGiveWithdraw'])}</div>
                  <div> {numTomoeny(this.state.totalBalance['totalAdmindeposit'] - this.state.totalBalance['totalAdminwithdraw'])}</div>
                </div>
              }} />

              <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                return <div>{numTomoeny(this.state.totalBalance['totalCasinoBet'])}</div>
              }} />


              <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                return <div>{numTomoeny(this.state.totalBalance['totalSlotBet'])}</div>
              }} />

              <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                return (<div></div>)
              }} />




              <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                return <div>{numTomoeny(CalculateTotalBalance(this.state.totalBalance))}</div>
              }} />



              <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                return (<div>{numTomoeny(this.state.totalBalance['exchangeBalance'])}</div>)
              }} />



              <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                return <div>{numTomoeny(ConvertCalculate(this.state.totalBalance))}</div>;
              }} />




              <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {

                if (Number.isNaN(((this.state.totalBalance['totalSlotBet'])) * this.state.totalBalance['benefitSlotRolling'] / 100)) {
                  return <div>0</div>;
                }

                return <div>
                  <div >{numTomoeny(((this.state.totalBalance['totalSlotBet'])) * this.state.totalBalance['benefitSlotRolling'] / 100)}</div>
                  <div style={{ color: 'green' }}>{this.state.totalBalance['benefitSlotRolling']}%</div>
                </div>;
              }} />

              <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                if (Number.isNaN(((this.state.totalBalance['totalCasinoBet'])) * this.state.totalBalance['benefitCasinoRolling'] / 100)) {
                  return <div>0</div>;
                }

                return <div>
                  <div style={{ color: '#ffc107' }}>{numTomoeny(((this.state.totalBalance['totalCasinoBet'])) * this.state.totalBalance['benefitCasinoRolling'] / 100)}</div>
                  <div style={{ color: 'green' }}>{this.state.totalBalance['benefitCasinoRolling']}%</div>
                </div>;
              }} />


              <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {

                const realAgentChangeBalance = Object.values(this.state.agentInfo).reduce(function add(sum, currValue) {
                  return sum + currValue.realAgentChangeBalance;
                }, 0);

                const realAgentExchangeBalance = Object.values(this.state.agentInfo).reduce(function add(sum, currValue) {
                  return sum + currValue.realAgentExchangeBalance;
                }, 0);

                return <div>
                  <div style={{ color: 'red' }}>{numTomoeny(realAgentChangeBalance)}</div>
                  <div style={{ color: 'blue' }}>{numTomoeny(realAgentExchangeBalance)}</div>
                </div>;
              }} />
              <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                return <div>
                  <div>{numTomoeny(this.state.totalBalance['totalUserRollingSlot'])}</div>
                </div>;
              }} />


            <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                return <div>
                  <div>{numTomoeny(this.state.totalBalance['totalUserRollingCasino'])}</div>
                </div>;
              }} />


            </Row>
          )

        }


      </ColumnGroup >
    )

    return (
      <>
        <section className="content-header">
          <h1>통합 정산</h1>
          <h5>(12시에 업데이트됩니다.)</h5>
        </section>

        <section className="content">

          <ul className="nav nav-tabs" style={{ marginBottom: "20px" }}>

            <li className={this.state.viewType === "all" ? "active" : ""}>
              <a
                onClick={() => {
                  this.setState({ viewType: "all", });
                }}
              >
                모두
              </a>
            </li>

          </ul>
          <div className="form-inline">

            <div className="form-group">
              <CCalendarTime
                placeholder="시작일"
                value={this.state.begin}
                onChange={(e) => this.setState({ begin: e })}
              />
            </div>

            <div className="form-group">
              <CCalendarTime
                placeholder="종료일"
                value={this.state.end}
                onChange={(e) => this.setState({ end: e })}
              />
            </div>
            <div className="form-group">
              <Button
                label="조회"
                onClick={() => {
                  this.handlegetSubAgentCalculate(this.state.begin, this.state.end);
                }}
                className="btn btn-primary btn-sm"
              />
            </div>
          </div>

          <div
            className="table-responsive list_view"
            style={{ marginTop: "10px", }}
          >
            <DataTable
              id="fixTable"
              sortOrder={1}
              rowGroupMode="rowspan"
              value={this.state.agentInfo}
              scrollable
              scrollHeight="95vh"
              virtualScrollerOptions={{ itemSize: 46 }}
              headerColumnGroup={headerGroup}
            >
              {
                this.state.level <= 1 && (
                  <Column
                    headerStyle={{ textAlign: "center", }}
                    body={(rowData: any, column: any) => {
                      const tipname = `tooltip_${rowData.agent}`
                      if (rowData.level === 1) return (
                        <div className={tipname}>
                          <div >본사</div>
                        </div>
                      )
                      return <></>;


                    }}
                  />

                )
              }

              {
                this.state.level <= 2 && (
                  <Column
                    header="부본사"
                    headerStyle={{ textAlign: "center", }}
                    body={(rowData: any, column: any) => {
                      const tipname = `tooltip_${rowData.agent}`
                      if (rowData.level === 2) return (
                        <div className={tipname}>
                          <div >{rowData.agent}</div>
                          <div >{rowData.nick}</div>
                        </div>
                      )
                      return <></>;


                    }}
                  />
                )
              }

              {
                this.state.level <= 3 && (
                  <Column
                    header="총판"
                    headerStyle={{ textAlign: "center", }}
                    body={(rowData: any, column: any) => {
                      const tipname = `tooltip_${rowData.agent}`
                      if (rowData.level === 3) return (
                        <div className={tipname}>
                          <div >{rowData.agent}</div>
                          <div >{rowData.nick}</div>

                        </div>
                      )
                      return <></>;

                    }}
                  />
                )
              }
              {
                this.state.level <= 4 && (
                  <Column
                    header="매장"
                    headerStyle={{ textAlign: "center", }}
                    body={(rowData: any, column: any) => {

                      const tipname = `tooltip_${rowData.agent}`
                      if (rowData.level === 4) return (
                        <div className={tipname}>
                          <div >{rowData.agent}</div>
                          <div >{rowData.nick}</div>

                        </div>
                      )
                      return <></>;

                    }}
                  />
                )
              }

              <Column
                header={<div><div>유저 입금</div><div>총판 지급</div><div>관리자 지급</div></div>}
                body={(rowData: any, column: any) => {
                  return <div>
                    <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit)}</div>
                    <div style={{ color: 'red' }}>{numTomoeny(rowData.totalGiveDeposit)}</div>
                    <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAdmindeposit)}</div>
                  </div>;
                }}
              />


              <Column
                header={<div><div>유저 출금</div><div>총판 회수</div><div>관리자 회수</div></div>}
                body={(rowData: any, column: any) => {

                  return <div>
                    <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw)}</div>
                    <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalGiveWithdraw)}</div>
                    <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAdminwithdraw)}</div>

                  </div>;
                }}
              />



              <Column
                header={<div><div>유저 수익</div><div>총판 수익</div></div>}
                body={(rowData: any, column: any) => {
                  return <div>
                    <div style={{ color: rowData.totalDeposit - rowData.totalWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalDeposit - rowData.totalWithdraw)))}</div>
                    <div style={{ color: rowData.totalGiveDeposit - rowData.totalGiveWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalGiveDeposit - rowData.totalGiveWithdraw)))}</div>
                    <div style={{ color: rowData.totalAdmindeposit - rowData.totalAdminwithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalAdmindeposit - rowData.totalAdminwithdraw)))}</div>
                  </div>;
                }}
              />


              {
                (this.state.viewType === 'all' || this.state.viewType === 'casino') && (

                  <Column
                    header={<div><div>카지노 Bet</div>
                    </div>}
                    body={(rowData: any, column: any) => {
                      return <div>
                        <div style={{ color: 'red' }}>{numTomoeny(rowData.totalCasinoBet)}</div>
                      </div>;

                    }}
                  />

                )
              }



              {
                (this.state.viewType === 'all' || this.state.viewType === 'slot') && (

                  <Column
                    header={<div><div>슬롯 Bet</div>
                      {/* <div>슬롯 Win</div> */}
                    </div>}
                    body={(rowData: any, column: any) => {
                      return <div>
                        <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotBet)}</div>
                      </div>;

                    }}
                  />

                )
              }




              <Column
                header={<div><div>유저보유금</div><div>전일 보유</div></div>}
                body={(rowData: any, column: any) => {
                  return <div> <div style={{ color: '#2196f3' }}>{numTomoeny(rowData.totalInBalance)}</div>
                    <div>{numTomoeny(rowData.totalYDInBalance)}</div></div>
                }}
              />

              <Column
                header={<div><div>총회원 루징</div></div>}
                body={(rowData: any, column: any) => {

                  return (
                    <div>{numTomoeny(CalculateTotalBalance(rowData))}</div>
                  )
                }}
              />

              <Column
                header={<div><div>롤링캐시전환</div></div>}
                body={(rowData: any, column: any) => {
                  return <div>
                    <div style={{ color: rowData.exchangeBalance > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny(rowData.exchangeBalance)}</div>
                  </div>;
                }}
              />

              <Column
                header={<div>
                  <div>정산금</div>
                  <div>루징프로테이지%</div>
                </div>}
                body={(rowData: any, column: any) => {

                  return (
                    <div>
                      {numTomoeny(ConvertCalculate(rowData))}
                      <div>{rowData.benefitLosing}%</div>
                    </div>
                  )

                }}
              />

              {
                (this.state.viewType === 'all' || this.state.viewType === 'slot') && (

                  <Column
                    header={<div><div>슬롯 롤링</div><div>프로테이지</div></div>}
                    body={(rowData: any, column: any) => {

                      if (Number.isNaN(((rowData.totalSlotBet)) * rowData.benefitSlotRolling / 100)) {
                        return <div>0</div>;
                      }

                      return <div>
                        <div style={{ color: rowData.totalSlotBet - rowData.totalSlotWin > 0 ? '#ffc107' : '#ffc107' }}>{numTomoeny(((rowData.totalSlotBet)) * rowData.benefitSlotRolling / 100)}</div>
                        <div style={{ color: 'green' }}>{rowData.benefitSlotRolling}%</div>
                      </div>;
                    }}
                  />

                )
              }


              {
                (this.state.viewType === 'all' || this.state.viewType === 'casino') && (

                  <Column
                    header={<div><div>카지노 롤링</div><div>프로테이지</div></div>}
                    body={(rowData: any, column: any) => {

                      if (Number.isNaN(((rowData.totalCasinoBet)) * rowData.benefitCasinoRolling / 100)) {
                        return <div>0</div>;
                      }

                      return <div>
                        <div style={{ color: '#ffc107' }}>{numTomoeny(((rowData.totalCasinoBet)) * rowData.benefitCasinoRolling / 100)}</div>
                        <div style={{ color: 'green' }}>{rowData.benefitCasinoRolling}%</div>
                      </div>;
                    }}
                  />
                )
              }


              <Column
                header={<div><div>파트너 충전</div><div>파트너 환전</div></div>}
                body={(rowData: any, column: any) => {

                  return <div>
                    <div style={{ color: 'red' }}>{numTomoeny(rowData.realAgentChangeBalance)}</div>
                    <div style={{ color: 'blue' }}>{numTomoeny(rowData.realAgentExchangeBalance)}</div>
                  </div>;
                }}
              />

              <Column
                header={<div>유저 슬롯 롤링</div>}
                body={(rowData: any, column: any) => {
                  return <div>
                    <div>{numTomoeny(rowData.totalUserRollingSlot)}</div>
                  </div>;
                }}
              />


              <Column
                header={<div>유저 카지노 롤링</div>}
                body={(rowData: any, column: any) => {
                  return <div>
                    <div>{numTomoeny(rowData.totalUserRollingCasino)}</div>
                  </div>;
                }}
              />


            </DataTable>

          </div>


        </section >
      </>
    );
  }
}
