import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { GetToday, ConvertCalendar, numTomoeny, ConvertTreeToAgents } from "../utility/help";
import { SubPaging } from "../share/sub-paging";
import { BetService } from "../service/bet.service";
import { CCalendarTime } from "./custom/ccalendartime";
import { ShareTooltip } from "./share.tooltip";
import { TooltipTree } from "../utility/optionpack";


interface Prop {
    betSummary: any
    Sort: (name, isReverse) => any
}


interface State {

}

export class TotalUserSlot extends Component<Prop, State> {

    menu: any;

    constructor(props: any) {
        super(props);

        this.state = {

        };

        // this.GetUserList(0, "", "", "regdate", 1);
    }


    componentDidMount() { }



    makeTable = (rowData) => {
        const trees: any = []
        const tree = ConvertTreeToAgents(rowData.agent_tree)
        const tree_ko = ConvertTreeToAgents(rowData.agent_tree_ko)


        for (let ii = 0; ii < tree.length; ii++) {
            trees.push({ level: ii + 1, agent: tree[ii], nick: tree_ko[ii] })
        }



        return trees
    }



    render() {

        const betSummary = this.props.betSummary
        return (
            <>
                <DataTable
                    value={betSummary}
                    sortField="agentCode"
                    sortOrder={1}


                    rowGroupMode="rowspan"
                    style={{ marginTop: "30px" }}
                >
                    <Column
                        field="id"
                        header={() => (<div>
                            <i className="pi pi-chevron-up" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'blue' }} onClick={() => this.props.Sort('userId', true)}></i>
                            아이디
                            <i className="pi pi-chevron-down" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'red' }} onClick={() => this.props.Sort('userId', false)}></i>
                        </div>)}


                        body={(rowData) => {
                            return (
                                <div style={{ color: rowData.color }}>{rowData.userId}</div>
                            );
                        }}

                    />
                    <Column
                        field="nick"
                        header="닉네임"
                    />
                    <Column
                        field="agent"
                        header={() => (<div>
                            <i className="pi pi-chevron-up" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'blue' }} onClick={() => this.props.Sort('agent', true)}></i>
                            에이전트
                            <i className="pi pi-chevron-down" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'red' }} onClick={() => this.props.Sort('agent', false)}></i>
                        </div>)}


                        body={(rowData) => {

                            const tooltipId = `tooltip-button_${rowData._id}`
                            return (
                                <>

                                    <ShareTooltip
                                        tooltipId={`${tooltipId}`}
                                        tables={this.makeTable(rowData)}
                                        columns={TooltipTree({})}></ShareTooltip>

                                    <span className={tooltipId}>{rowData.agent}</span>

                                </>
                            );
                        }}
                    />

                    <Column
                        header={() => (<div>
                            <i className="pi pi-chevron-up" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'blue' }} onClick={() => this.props.Sort('totalSlotBetCount', true)}></i>
                            총 배팅건수
                            <i className="pi pi-chevron-down" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'red' }} onClick={() => this.props.Sort('totalSlotBetCount', false)}></i>
                        </div>)}

                        body={(rowData) => {
                            return (
                                <>
                                    <div>{numTomoeny(rowData.totalSlotBetCount)}</div>
                                </>
                            );
                        }}
                    />
                    <Column

                        header={() => (<div>
                            <i className="pi pi-chevron-up" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'blue' }} onClick={() => this.props.Sort('totalSlotBet', true)}></i>
                            총 Bet 금액
                            <i className="pi pi-chevron-down" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'red' }} onClick={() => this.props.Sort('totalSlotBet', false)}></i>
                        </div>)}


                        body={(rowData: any, column: any) => {
                            return <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalSlotBet)}</div>;
                        }}
                    />

                    <Column

                        header={() => (<div>
                            <i className="pi pi-chevron-up" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'blue' }} onClick={() => this.props.Sort('totalSlotWin', true)}></i>
                            WIN 금액
                            <i className="pi pi-chevron-down" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'red' }} onClick={() => this.props.Sort('totalSlotWin', false)}></i>
                        </div>)}
                        body={(rowData) => {
                            return (
                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalSlotWin)}</div>
                            );
                        }}
                    />

                    <Column

                        header={() => (<div>
                            <i className="pi pi-chevron-up" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'blue' }} onClick={() => this.props.Sort('totalSlotBetWin', true)}></i>
                            BET - WIN 금액
                            <i className="pi pi-chevron-down" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'red' }} onClick={() => this.props.Sort('totalSlotBetWin', false)}></i>
                        </div>)}
                        body={(rowData) => {
                            return (
                                <div style={{ color: (rowData.totalSlotBetWin) > 0 ? 'blue' : 'red' }}>{numTomoeny(rowData.totalSlotBetWin)}</div>
                            );
                        }}
                    />

                </DataTable>
            </>
        );
    }
}






export class TotalUserCasino extends Component<Prop, State> {

    menu: any;

    constructor(props: any) {
        super(props);

        this.state = {

        };

        // this.GetUserList(0, "", "", "regdate", 1);
    }


    componentDidMount() { }



    makeTable = (rowData) => {
        const trees: any = []
        const tree = ConvertTreeToAgents(rowData.agent_tree)
        const tree_ko = ConvertTreeToAgents(rowData.agent_tree_ko)


        for (let ii = 0; ii < tree.length; ii++) {
            trees.push({ level: ii + 1, agent: tree[ii], nick: tree_ko[ii] })
        }



        return trees
    }



    render() {

        const betSummary = this.props.betSummary
        return (
            <>
                <DataTable
                    value={betSummary}
                    sortField="agentCode"
                    sortOrder={1}


                    rowGroupMode="rowspan"
                    style={{ marginTop: "30px" }}
                >
                    <Column
                        field="id"
                        header={() => (<div>
                            <i className="pi pi-chevron-up" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'blue' }} onClick={() => this.props.Sort('userId', true)}></i>
                            아이디
                            <i className="pi pi-chevron-down" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'red' }} onClick={() => this.props.Sort('userId', false)}></i>
                        </div>)}


                        body={(rowData) => {
                            return (
                                <div style={{ color: rowData.color }}>{rowData.userId}</div>
                            );
                        }}

                    />
                    <Column
                        field="nick"
                        header="닉네임"
                    />
                    <Column
                        field="agent"
                        header={() => (<div>
                            <i className="pi pi-chevron-up" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'blue' }} onClick={() => this.props.Sort('agent', true)}></i>
                            에이전트
                            <i className="pi pi-chevron-down" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'red' }} onClick={() => this.props.Sort('agent', false)}></i>
                        </div>)}


                        body={(rowData) => {

                            const tooltipId = `tooltip-button_${rowData._id}`
                            return (
                                <>

                                    <ShareTooltip
                                        tooltipId={`${tooltipId}`}
                                        tables={this.makeTable(rowData)}
                                        columns={TooltipTree({})}></ShareTooltip>

                                    <span className={tooltipId}>{rowData.agent}</span>

                                </>
                            );
                        }}
                    />

                    <Column
                        header={() => (<div>
                            <i className="pi pi-chevron-up" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'blue' }} onClick={() => this.props.Sort('totalCasinoBetCount', true)}></i>
                            총 배팅건수
                            <i className="pi pi-chevron-down" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'red' }} onClick={() => this.props.Sort('totalCasinoBetCount', false)}></i>
                        </div>)}

                        body={(rowData) => {
                            return (
                                <div>{numTomoeny(rowData.totalCasinoBetCount)}</div>
                            );
                        }}
                    />
                    <Column

                        header={() => (<div>
                            <i className="pi pi-chevron-up" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'blue' }} onClick={() => this.props.Sort('totalCasinoBet', true)}></i>
                            총 Bet 금액
                            <i className="pi pi-chevron-down" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'red' }} onClick={() => this.props.Sort('totalCasinoBet', false)}></i>
                        </div>)}


                        body={(rowData: any, column: any) => {
                            return <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalCasinoBet)}</div>;
                        }}
                    />

                    <Column

                        header={() => (<div>
                            <i className="pi pi-chevron-up" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'blue' }} onClick={() => this.props.Sort('totalCasinoWin', true)}></i>
                            WIN 금액
                            <i className="pi pi-chevron-down" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'red' }} onClick={() => this.props.Sort('totalCasinoWin', false)}></i>
                        </div>)}
                        body={(rowData) => {
                            return (
                                <div style={{ color: 'red' }}>{numTomoeny(rowData.totalCasinoWin)}</div>
                            );
                        }}
                    />

                    <Column

                        header={() => (<div>
                            <i className="pi pi-chevron-up" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'blue' }} onClick={() => this.props.Sort('totalCasinoBetWin', true)}></i>
                            BET - WIN 금액
                            <i className="pi pi-chevron-down" style={{ 'fontSize': '16px', fontWeight: 'bold', color: 'red' }} onClick={() => this.props.Sort('totalCasinoBetWin', false)}></i>
                        </div>)}
                        body={(rowData) => {
                            return (
                                <div style={{ color: (rowData.totalCasinoBetWin) > 0 ? 'blue' : 'red' }}>{numTomoeny(rowData.totalCasinoBetWin)}</div>
                            );
                        }}
                    />

                </DataTable>
            </>
        );
    }
}
