import React, { Component, useState, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";


import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { confirmAlert } from "react-confirm-alert";

import { CDropdown } from "./custom/cdropdown";



import { BalanceService } from "../service/balance.service";


import { ConvertDate, convertToCSV, GetNow, numTomoeny } from "../utility/help";


interface Prop {
}


interface State {
}

export class SharePopupBalanceLog extends Component<Prop, State> {



    constructor(props: Prop) {
        super(props);

        this.state = {
        }


    }


    render() {


        return (
            <div
                className="modal fade in"
                tabIndex={-1}
                role="dialog"
                style={{
                    // display: this.state.SearchLogPopup === true ? "block" : "none",
                    paddingRight: "17px",
                }}
            >
                <div
                    className="modal-content"
                    style={{
                        minWidth: "300px",
                        maxWidth: "600px",
                        minHeight: "300px",
                        maxHeight: "300px",
                        overflow: "auto",
                        top: "100px",
                        left: "30%",
                    }}
                >
                    <div className="modal-header">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-hidden="true"
                        // onClick={this.CloseSearchLog}
                        >
                            ×
                        </button>

                        <h4 className="modal-title">거래이력</h4>
                    </div>
                    <div className="modal-body">
                        <DataTable
                            // value={this.state.userTableValue2}
                            sortField=""
                            sortOrder={1}
                            rowGroupMode="rowspan"
                            style={{ marginTop: "30px", border: "1px solid #c8c8c8" }}
                        >
                            <Column
                                field=""
                                header="종류"

                            />
                            <Column
                                field="connectYn"
                                header="아이디"

                            />
                            <Column
                                field=""
                                header="닉네임"

                            />
                            <Column
                                field=""
                                header="은행명"

                            />
                            <Column
                                field=""
                                header="계좌번호"

                            />
                            <Column
                                field=""
                                header="입금자명"

                            />
                            <Column
                                header="금액"
                                body={(rowData) => {
                                    return (
                                        <>
                                            <div>{numTomoeny(rowData.balance)}</div>
                                        </>
                                    );
                                }}
                            />
                            <Column
                                field=""
                                header="상태"

                            />
                            <Column
                                field=""
                                header="처리시각"

                            />
                            <Column
                                field=""
                                header="담당자"

                            />
                            <Column
                                field=""
                                header="메모"

                            />
                            <Column
                                field=""
                                header="일반/수기"

                            />
                        </DataTable>
                    </div>
                    <div className="modal-footer">
                        <div className="pull-right">
                            <div className="form-inline">
                                <div className="form-group">
                                    <Button
                                        label="닫기"
                                        onClick={() => {
                                        }}
                                        className="btn btn-default btn-sm"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        )
    }
}
