import React, { Component, useState, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";


import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { confirmAlert } from "react-confirm-alert";

import { CInputText } from "./custom/cinputtext";
import { CButton } from "./custom/cbutton";



import { BalanceService } from "../service/balance.service";

import { CCalendarTime } from "./custom/ccalendartime";
import { CDropdown } from "./custom/cdropdown";


import { ConvertDate, convertToCSV, GetNow, numTomoeny, GetToday } from "../utility/help";


interface Prop {
    onWaitAllUser: () => any
    onSendMessage: (mode: any) => any
}


interface State {
    dropdownItem: string;
    search: string;
    find: string;
}

export class ShareBalanceRequestedMenu extends Component<Prop, State> {
    balanceService: BalanceService = new BalanceService();


    constructor(props: Prop) {
        super(props);

        const date = GetToday()

        this.state = {
            dropdownItem: '',
            search: "",
            find: "",



        }
    }

    componentDidMount() {

    }

    render() {
        return (

            < div className="p-formgroup-inline" style={{ margin: "10px", }}>
                <div className="p-field">
                    {/* <Button
                        label="확인"
                        onClick={() => this.props.onSendMessage('success')}
                        className="btn btn-success btn-sm p-mr-2 p-mb-2"

                    />

                    <Button
                        label="대기"
                        onClick={() => this.props.onSendMessage('wait')}
                        className="btn btn-info btn-sm p-mr-2 "
                    />
                    <Button
                        label="취소"
                        onClick={() => this.props.onSendMessage('cancel')}
                        className="btn btn-danger btn-sm"
                    /> */}

                    <Button
                        label="알림 정지"
                        onClick={() => {
                            this.props.onWaitAllUser()
                            setTimeout(() => {
                                this.props.onSendMessage('wait')
                            })
                        }}
                        className="btn btn-warning btn-sm "
                    />
                </div>
            </div >
        )
    }
}


