

import React, { Component } from "react";

// import { Messages } from "primereact/messages";
// import { Message } from "primereact/message";
import { CountryService } from "../service/CountryService";
import { CarService } from "../service/CarService";
import { NodeService } from "../service/NodeService";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
// // import { Spinner } from "primereact/spinner";
import { Slider } from "primereact/slider";
import { ListBox } from "primereact/listbox";
import { RadioButton } from "primereact/radiobutton";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";


import { ColorPicker } from 'primereact/colorpicker';
import { CDropdown } from "./custom/cdropdown";
import { BalanceService } from "../service/balance.service";
import { AgentService } from "../service/agent.service";

import { confirmAlert } from "react-confirm-alert";
import { Column } from "primereact/column";

import {
    numTomoeny,
    converBetStatus,
    convertIP,
    ConvertDate,
    getDepositColor,
    getWithdrawColor,
    getDeposiOrWithdrawColor,
    ConvertNick,
    ConvertBenefitName,
    GetCalcuateOptionType,
    GetToday,
    ConvertBalanceType
} from "../utility/help";


interface Props {
    agent: any
}

interface State {
    agent: any
}

export class AgentInfoTable extends Component<Props, State> {
    agentService: AgentService = new AgentService();

    constructor(props: Props) {
        super(props);
        this.state = {
            agent: null,
        };

        this.handleSave = this.handleSave.bind(this);
    }
    componentDidMount() {
    }




    handleSave = () => {

        const agent = this.state.agent
        console.log(agent)
        if (agent == null) {
            confirmAlert({
                title: "에이전트",
                message: "변경된 정보가 없습니다",
                buttons: [
                    {
                        label: "확인",
                        onClick: () => {
                            window.location.reload();
                        },
                    },
                    {
                        label: "취소",
                        onClick: () => {
                            window.location.reload();
                        },
                    },
                ],
            });
            return
        }

        if (agent.expass != null && agent.pass !== agent.expass) {
            confirmAlert({
                title: "에이전트",
                message: "비밀번호를 확인해주세요.",
                buttons: [
                    {
                        label: "확인",
                        onClick: () => {
                            window.location.reload();
                        },
                    },
                    {
                        label: "취소",
                        onClick: () => {
                            window.location.reload();
                        },
                    },
                ],
            });
            return
        }


        confirmAlert({
            title: "확인",
            message: "에이전트 정보가 변경 하시겠습니까 ?",
            buttons: [
                {
                    label: "확인",
                    onClick: async () => {

                        // const detail = await this.agentService
                        //     .save_agent_detail({
                        //         nick: agent.nick,
                        //         agent: agent.agent,
                        //         pass: agent.pass,
                        //         phone: agent.phone,
                        //         bankname: agent.bankname,
                        //         banknum: agent.banknum,
                        //         bankowner: agent.bankowner,
                        //         simplememo: agent.simplememo,
                        //         status: agent.status,
                        //         ignored: agent.ignored,
                        //         ignoredCasino: agent.ignoredCasino,
                        //         color: agent.color,
                        //         memo: agent.memo,
                        //     })


                        // if (detail.status == "success") {
                        //     confirmAlert({
                        //         title: "확인",
                        //         message: "에이전트 정보가 변경되었습니다.",
                        //         buttons: [
                        //             {
                        //                 label: "확인",
                        //                 onClick: () => {
                        //                     window.location.reload();
                        //                 },
                        //             },
                        //         ],
                        //     });
                        // } else if (detail.status == "params") {
                        //     confirmAlert({
                        //         title: "확인",
                        //         message: "잘못된 데이터가 포함되어 있습니다",
                        //         buttons: [
                        //             {
                        //                 label: "확인",
                        //                 onClick: () => {
                        //                     window.location.reload();
                        //                 },
                        //             },
                        //         ],
                        //     });
                        // } else {
                        //     confirmAlert({
                        //         title: "확인",
                        //         message: "관리자에게 문의 부탁드림니다",
                        //         buttons: [
                        //             {
                        //                 label: "확인",
                        //                 onClick: () => {
                        //                     window.location.reload();
                        //                 },
                        //             },
                        //         ],
                        //     });
                        // }

                    },
                },
                {
                    label: "취소",
                    onClick: () => {

                    },
                },
            ],
        });
    }

    render() {
        let agent = this.props.agent;
        if (agent == undefined || agent.agent == undefined) {
            return <div></div>;
        }

        return (
            <>


                {/* <div className="form-group">
                    <div className="input-group">
                        <div className="input-group-addon">
                            상위 에이전트
                        </div>

                        <div className="form-control" dangerouslySetInnerHTML={{ __html: ConvertNick(agent.agent_tree, agent.agent_tree_ko) }}></div>

                    </div>
                </div> */}



                <div className="form-group ">
                    <div className="input-group">
                        <div className="input-group-addon">전화번호</div>
                        <InputText
                            value={agent.phone}
                            className="form-control"
                            maxLength={20}
                            onChange={(e: any) => {
                                agent.phone = e.target.value
                                this.setState({ agent: agent })
                            }}
                            readOnly
                        />
                    </div>
                </div>

                <div className="form-group ">
                    <div className="input-group">
                        <div className="input-group-addon">은행</div>
                        <InputText
                            // options={this.selectBankData}
                            value={agent.bankname}
                            onChange={(e: any) => {
                                agent.bankname = e.target.value
                                this.setState({ agent: agent })
                            }}
                            // autoWidth={false}
                            className="form-control input-sm"
                            readOnly
                        />
                    </div>
                </div>

                <div className="form-group ">
                    <div className="input-group">
                        <div className="input-group-addon">예금주</div>
                        <InputText
                            value={agent.bankowner}
                            onChange={(e: any) => {
                                agent.bankowner = e.target.value
                                this.setState({ agent: agent })
                            }}

                            className="form-control"
                            maxLength={20}
                            readOnly
                        />
                    </div>
                </div>

                <div className="form-group ">
                    <div className="input-group">
                        <div className="input-group-addon">계좌번호</div>
                        <InputText
                            value={agent.banknum}
                            onChange={(e: any) => {
                                agent.banknum = e.target.value
                                this.setState({ agent: agent })
                            }}
                            className="form-control"
                            maxLength={100}
                            readOnly
                        />
                    </div>
                </div>


                <div className="form-group ">
                    <div className="input-group">

                    </div>
                </div>


                <div className="form-group ">
                    <div className="input-group">
                        {/* <div className="input-group-addon">카지노 롤링개수 빼기</div>
                        <Button
                            className="form-control p-button-success"

                            label={'저장하기'}
                            onClick={() => {
                                agent.color = `#9c27b0`
                                this.setState({ agent: agent })
                            }}
                        /> */}
                    </div>
                </div>

                <div className="form-group ">
                    <div className="input-group">

                        <Button
                            className="form-control p-button-success"

                            label={'저장하기'}
                            onClick={() => {
                                this.setState({ agent: agent })
                                setTimeout(() => this.handleSave())
                            }}
                        />
                    </div>
                </div>
            </>
        )
    }
}

interface StateBenefit {
    benefits: any
    calcuateType: any
    agent: any
}


export class AgentBenefitTable extends Component<Props, StateBenefit> {
    agentService: AgentService = new AgentService();

    constructor(props: Props) {
        super(props);
        this.state = {
            calcuateType: 'N',
            benefits: [],
            agent: null
        };

        this.handleSave = this.handleSave.bind(this);

    }

    componentDidMount() {

        if (this.props.agent != null) {
            this.handleGetBenefit()
        }
    }

    handleGetBenefit = async () => {
        const data = await this.agentService.admin_get_agent_benefit(this.props.agent.agent).then(s => s)
        if (data.status == "success") {
            this.setState({ benefits: data.benefits })
        }
    }



    handleSave = async (benefit: any) => {

        const data = await this.agentService.admin_update_agent_benefit(benefit).then(s => s)
        if (data.status == "success") {
            confirmAlert({
                title: "베네핏 ",
                message: "수정에 성공하였습니다",
                buttons: [
                    {
                        label: "확인",
                        onClick: () => {
                        }
                    }

                ]
            })
        }
        else if (data.status == "find_agent") {
            confirmAlert({
                title: "베네핏 ",
                message: "해당 에이전트에서는 요율변경이 불가능합니다",
                buttons: [{ label: "확인", onClick: () => { } }]
            })
        }
        else {
            confirmAlert({
                title: "베네핏 ",
                message: "",
                buttons: [
                    {
                        label: "확인",
                        onClick: () => {
                        }
                    }

                ]
            })
        }

    }

    render() {
        let agent = this.props.agent;
        if (agent == undefined || agent.agent == undefined) {
            return <div></div>;
        }

        return (
            <>



                <div className="tab-pane" id="tab_2">
                    {/* <div className="col-md-6">
                        <div className="box box-danger">
                            <div className="box-header"></div>
                            <div className="box-body">
                                <div className="form-group" id="add_share_percent">
                                    <div className="input-group">
                                        <div className="input-group-addon">
                                            정산타입 타입
                                        </div>
                                        <div className="input-group-addon">
                                            <CDropdown
                                                width='100%'
                                                options={GetCalcuateOptionType()}
                                                value={this.state.calcuateType}
                                                onChange={(event) => {
                                                    this.setState({ calcuateType: event.value })
                                                }}
                                            />

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> */}


                    <table className="table table-bordered table-condensed table-striped">
                        <thead>
                            <tr>
                                <th style={{ width: "100px" }}>정산그룹명</th>
                                <th style={{ width: "100px" }}>딜비율</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
        process.env.REACT_APP_API_AGENT_BENFIT !== null &&  process.env.REACT_APP_API_AGENT_BENFIT !== 'N'        &&    Object.values(this.state.benefits).map((s: any) => {
                                    return (
                                        <tr>
                                            <td className="text-center">{ConvertBenefitName(s.benefit_type)}</td>
                                            <td className="text-center">
                                                <InputText
                                                    value={s.percent}
                                                    onChange={(e: any) => {

                                                        const benefit = this.state.benefits.find(kk => kk.benefit_type === s.benefit_type)
                                                        if (benefit.percent != null) {
                                                            benefit.percent = e.target.value
                                                            this.setState({ benefits: this.state.benefits })
                                                        }
                                                    }}
                                                    className="form-control input-sm"
                                                    placeholder="딜비율(%)"
                                                />
                                            </td>
                                            <td className="text-center">
                                                <Button
                                                    label="저장"
                                                    onClick={() => {

                                                        const benefit = this.state.benefits.find(kk => kk.benefit_type === s.benefit_type)
                                                        if (benefit != null) {
                                                            this.handleSave(benefit)
                                                        }
                                                    }}
                                                />
                                            </td>

                                        </tr>
                                    )
                                })
                            }


                        </tbody>
                    </table>


                </div>

            </>
        )
    }
}





interface PropsBalance {
    agent: any
    agentId?: string
}


interface StateBalance {
    balances: any
    begin: any;
    end: any;
    maxCount: number
    activePage: number
    totalBalance: any

}


export class AgentBalanceTable extends Component<PropsBalance, StateBalance> {
    agentService: AgentService = new AgentService();
    balanceService = new BalanceService();

    constructor(props: PropsBalance) {
        super(props);

        let today = GetToday();

        super(props);
        this.state = {
            balances: [],
            begin: today.begin,
            end: today.end,
            maxCount: 0,
            activePage: 0,
            totalBalance: {}
        };


        setTimeout(() => this.GetAgentBalance(1))
    }

    componentDidMount() {

    }


    GetAgentBalance = (page: any) => {
        // this.balanceService.admin_get_agent_balances({
        //     agentId: this.props.agentId,
        //     skip: page,
        //     begin: this.state.begin,
        //     end: this.state.end,
        // }).then(
        //     (data) => {
        //         if (data.status == "success") {
        //             this.setState({
        //                 balances: data.logs,
        //                 maxCount: data.maxCount,
        //                 totalBalance: data.totalBalance,

        //             });
        //         }
        //     }
        // );
    };


    render() {
        let agent = this.props.agent;
        if (agent == undefined || agent.agent == undefined) {
            return <div></div>;
        }

        return (
            <>



                <div className="tab-pane" id="tab_2">


                    <div className="form-group">
                        <div className="input-group">
                            <div className="input-group-addon">
                                에이전트 벨런스
                            </div>


                        </div>
                    </div>



                    <DataTable


                        value={this.state.balances}
                        sortField="agentCode"
                        sortOrder={1}

                        rowGroupMode="rowspan"
                        style={{ marginTop: "30px", border: "1px solid #c8c8c8" }}
                    >
                        <Column
                            header="종류"

                            body={(rowData: any, column: any) => {

                                return <span dangerouslySetInnerHTML={{ __html: ConvertBalanceType(rowData.agent, rowData.id, rowData.giveId, rowData.targetId, rowData.logType) }} ></span>
                            }}
                        />

                        <Column
                            field="agent"
                            header="에이전트"

                        />

                        <Column
                            field="nick"
                            header="닉네임"

                        />

                        <Column
                            field="bankname"
                            header="은행명"

                        />
                        <Column
                            field="banknum"
                            header="계좌번호"

                        />
                        <Column
                            field="owner"
                            header="입금자명"

                        />
                        <Column
                            style={{ textAlign: "center" }}
                            header="금액"
                            body={(rowData) => {
                                return (
                                    <div>{numTomoeny(rowData.balance)}</div>
                                );
                            }}
                        />
                        <Column
                            field=""
                            header="상태"
                            body={(rowData) => {
                                return (
                                    <div>{ }</div>
                                );
                            }}
                        />
                        <Column
                            header="처리시각"

                            body={(rowData) => {
                                return (
                                    <span>{ConvertDate(rowData.regdate)}</span>
                                );
                            }}
                        />

                        <Column
                            field="approval"
                            header="승인자"

                        />
                        <Column
                            header="처리IP"

                            body={(rowData) => {
                                if (rowData.ip == null || rowData.ip === '') return '-'

                                return (
                                    <span>{rowData.ip.replace('"::ffff:', '')}</span>
                                );
                            }}
                        />
                        <Column
                            field=""
                            header="일반/수기"

                        />
                        <Column
                            field="memo"
                            header="메모"

                        />
                    </DataTable>


                    <div className="form-group ">
                        <div className="input-group">

                        </div>
                    </div>


                    {/* <div className="form-group ">
                        <div className="input-group">
                            <div className="input-group-addon">심플</div>

                            <InputTextarea
                                value={agent.simplememo}
                                onChange={(e: any) => {
                                    agent.simplememo = e.target.value
                                    this.setState({ agent: agent })
                                }}
                                rows={5}
                                cols={30}
                                className="form-control"
                                style={{ height: '120px' }}
                            />

                        </div>
                    </div> */}


                </div>

            </>
        )
    }
}



