import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { GetNow, ConvertCalendar, numTomoeny } from "../utility/help";
import { SubPaging } from "../share/sub-paging";

interface State {
  page: number;
  maxCount: number;

  findDay: any;
  begin: any;
  end: any;
  beginHour: any;
  endHour: any;

  userTableValue: any[];
  dropdownItem: string;
  seach: string;
  block_yn: string;
}

export class ShareCalc extends Component<any, State> {
  userService = new UserService();
  fileService = new FileService();

  menu: any;

  constructor(props: any) {
    super(props);

    let begin = GetNow();
    let end = GetNow();

    this.state = {
      page: 1,
      maxCount: 1,

      findDay: "5",
      begin: begin,
      end: end,
      beginHour: "0000",
      endHour: "0000",

      userTableValue: [],
      dropdownItem: "",
      seach: "",
      block_yn: "",
    };

    this.ShareCalcList(this.state.page, this.state.begin, this.state.end);

    this.GetUserList(0, "", "", "regdate", 1);
  }

  selectHourData = [
    { label: "00:00", value: "0000" },
    { label: "01:00", value: "0100" },
    { label: "02:00", value: "0200" },
    { label: "03:00", value: "0300" },
    { label: "04:00", value: "0400" },
    { label: "05:00", value: "0500" },
    { label: "06:00", value: "0600" },
    { label: "07:00", value: "0700" },
    { label: "08:00", value: "0800" },
    { label: "09:00", value: "0900" },
    { label: "10:00", value: "1000" },
    { label: "11:00", value: "1100" },
    { label: "12:00", value: "1200" },
    { label: "13:00", value: "1300" },
    { label: "14:00", value: "1400" },
    { label: "15:00", value: "1500" },
    { label: "16:00", value: "1600" },
    { label: "17:00", value: "1700" },
    { label: "18:00", value: "1800" },
    { label: "19:00", value: "1900" },
    { label: "20:00", value: "2000" },
    { label: "21:00", value: "2100" },
    { label: "22:00", value: "2200" },
    { label: "23:00", value: "2300" },
  ];

  componentDidMount() { }

  GetUserList = (
    page: number,
    find: string,
    search: string,
    sortType: string,
    sort: number
  ) => {
    // this.userService
    //   .get_user_list(page, find, search, sortType, sort, "used")
    //   .then((data) => {
    //     this.setState({
    //       userTableValue: data.users,
    //     });
    //   });
  };

  //조회
  SearchData = () => {
    this.setState({ page: 1 });
    this.ShareCalcList(this.state.page, this.state.begin, this.state.end);
  };

  MoveSel = (page: any) => {
    console.log(page);
    this.setState({ page: page });
    this.ShareCalcList(page, this.state.begin, this.state.end);
  };

  //쉐어 정산
  ShareCalcList = (page: number, begin: any, end: any) => { };

  // 재입력
  ReloadPage = () => {
    console.log("ReloadPage : 재입력");
  };

  // 메니저조회
  SeacrchManager = () => {
    console.log("SeacrchManager : 메니저조회");
  };

  render() {
    return (
      <>
        <section className="content-header">
          <h1>쉐어 정산</h1>
        </section>

        <section className="content">
          <div
            id="searchForm"
            className="form-inline"
            role="form"
          >
            <div className="form-group">
              <Calendar
                placeholder="시작일"
                showWeek={false}
                dateFormat="yy-mm-dd"
                value={this.state.begin}
                onChange={(e) => this.setState({ begin: e.value })}

                style={{ width: "100px" }}
              />
            </div>
            <div className="form-group">
              <Dropdown
                options={this.selectHourData}
                value={this.state.beginHour}
                onChange={(event) => this.setState({ beginHour: event.value })}

                className="form-control input-sm"
                style={{ width: "80px" }}
              />
            </div>
            <div className="form-group">
              &nbsp;&nbsp;
              <Calendar
                placeholder="종료일"
                showWeek={false}
                dateFormat="yy-mm-dd"
                value={this.state.end}
                onChange={(e) => this.setState({ end: e.value })}

                style={{ width: "100px" }}
              />
            </div>
            <div className="form-group">
              <Dropdown
                options={this.selectHourData}
                value={this.state.endHour}
                onChange={(event) => this.setState({ endHour: event.value })}

                className="form-control input-sm"
                style={{ width: "80px" }}
              />
            </div>
            <div className="form-group">
              <Button
                label="이번 한주"
                onClick={() => { }}
                className="btn btn-default btn-sm"
              />
            </div>
            <div className="form-group">
              <Button
                label="저번 한주"
                onClick={() => { }}
                className="btn btn-default btn-sm"
              />
            </div>
            <div className="form-group">
              <Button
                label="이번 한달"
                onClick={() => { }}
                className="btn btn-default btn-sm"
              />
            </div>
            <div className="form-group">
              <Button
                label="저번 한달"
                onClick={() => { }}
                className="btn btn-default btn-sm"
              />
            </div>
            <div className="form-group">
              <Button
                label="조회"
                onClick={() => {
                  this.props.SearchData(
                    this.state.dropdownItem,
                    this.state.seach,
                    this.state.block_yn
                  );
                }}
                className="btn btn-primary btn-sm"
              />
            </div>
            <div className="form-group">
              <Button
                label="재입력"
                onClick={() => { }}
                className="btn btn-default btn-sm"
              />
            </div>
            <div className="form-group">
              <Button
                label="수동정산"
                onClick={() => { }}
                className="btn btn-danger btn-sm"
              />
            </div>
          </div>

          {/* 설명 */}
          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
            <table className="table table-bordered table-condensed">
              <tbody>
                <tr style={{ backgroundColor: "#DDDDDD" }}>
                  <th className="text-center" colSpan={13}>
                    실시간 정산 정보 요약
                  </th>
                </tr>
                <tr style={{ backgroundColor: "#DDDDDD" }}>
                  <th className="text-center" colSpan={2}>
                    WIN금액
                  </th>
                  <th className="text-center" colSpan={2}>
                    LOSE금액
                  </th>

                  <th className="text-center" colSpan={2}>
                    이익금액 합계
                  </th>
                  <th className="text-center" colSpan={2}>
                    총 딜비
                  </th>

                  <th className="text-center" colSpan={2}>
                    윈로스쉐어
                  </th>

                  <th className="text-center" colSpan={2}>
                    딜비쉐어
                  </th>
                  <th className="text-center" colSpan={2}>
                    쉐어이익
                  </th>
                </tr>
                <tr>
                  <td
                    style={{ backgroundColor: "#FFFFFF" }}
                    className="text-right"
                    colSpan={2}
                  >
                    20,835,050
                  </td>
                  <td
                    style={{ backgroundColor: "#EEEEEE" }}
                    className="text-right"
                    colSpan={2}
                  >
                    24,565,050
                  </td>
                  <td
                    style={{ backgroundColor: "#FFFFFF" }}
                    className="text-right"
                    colSpan={2}
                  >
                    3,730,000
                  </td>
                  <td
                    style={{ backgroundColor: "#EEEEEE" }}
                    className="text-right"
                    colSpan={2}
                  >
                    0
                  </td>
                  <td
                    style={{ backgroundColor: "#FFFFFF" }}
                    className="text-right"
                    colSpan={2}
                  >
                    1,678,499
                  </td>
                  <td
                    style={{ backgroundColor: "#EEEEEE" }}
                    className="text-right"
                    colSpan={2}
                  >
                    0
                  </td>
                  <td
                    style={{ backgroundColor: "#FFFFFF" }}
                    className="text-right"
                    colSpan={2}
                  >
                    1,678,499
                  </td>
                </tr>
                <tr style={{ backgroundColor: "#DDDDDD" }}>
                  <th className="text-center" colSpan={13}>
                    미정산 정보 요약
                  </th>
                </tr>
                <tr style={{ backgroundColor: "#DDDDDD" }}>
                  <th className="text-center" colSpan={2}>
                    WIN금액
                  </th>
                  <th className="text-center" colSpan={2}>
                    LOSE금액
                  </th>

                  <th className="text-center" colSpan={2}>
                    이익금액 합계
                  </th>
                  <th className="text-center" colSpan={2}>
                    총 딜비
                  </th>

                  <th className="text-center" colSpan={2}>
                    윈로스쉐어
                  </th>

                  <th className="text-center" colSpan={2}>
                    딜비쉐어
                  </th>
                  <th className="text-center" colSpan={2}>
                    쉐어이익
                  </th>
                </tr>
                <tr>
                  <td
                    style={{ backgroundColor: "#FFFFFF" }}
                    className="text-right"
                    colSpan={2}
                  >
                    20,835,050
                  </td>
                  <td
                    style={{ backgroundColor: "#EEEEEE" }}
                    className="text-right"
                    colSpan={2}
                  >
                    24,565,050
                  </td>
                  <td
                    style={{ backgroundColor: "#FFFFFF" }}
                    className="text-right"
                    colSpan={2}
                  >
                    3,730,000
                  </td>
                  <td
                    style={{ backgroundColor: "#EEEEEE" }}
                    className="text-right"
                    colSpan={2}
                  >
                    0
                  </td>
                  <td
                    style={{ backgroundColor: "#FFFFFF" }}
                    className="text-right"
                    colSpan={2}
                  >
                    1,678,499
                  </td>
                  <td
                    style={{ backgroundColor: "#EEEEEE" }}
                    className="text-right"
                    colSpan={2}
                  >
                    0
                  </td>
                  <td
                    style={{ backgroundColor: "#FFFFFF" }}
                    className="text-right"
                    colSpan={2}
                  >
                    1,678,499
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* 메인컨텐츠 */}
          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
            <DataTable
              value={this.state.userTableValue}
              sortField="agentCode"
              sortOrder={1}

              rowGroupMode="rowspan"
              style={{ marginTop: "30px", border: "1px solid #c8c8c8" }}
            >
              <Column
                field=""
                header="상태"
                style={{
                  border: "1px solid #c8c8c8",

                  height: "25px",
                }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                  height: "40px",
                }}
              />
              <Column
                field=""
                header="생성시각"
                style={{ textAlign: "center" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                  height: "40px",
                }}
              />
              <Column
                field=""
                header="정산 시각"
                style={{ textAlign: "center" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                  height: "40px",
                }}
              />
              <Column
                style={{ textAlign: "center" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                }}
                header="WIN금액"
                body={(rowData) => {
                  return (
                    <>
                      <div>{numTomoeny(rowData.balance)}</div>
                    </>
                  );
                }}
              />
              <Column
                style={{ textAlign: "center" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                }}
                header="LOSE금액"
                body={(rowData) => {
                  return (
                    <>
                      <div>{numTomoeny(rowData.balance)}</div>
                    </>
                  );
                }}
              />
              <Column
                style={{ textAlign: "center" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                }}
                header="이익금액 합계"
                body={(rowData) => {
                  return (
                    <>
                      <div>{numTomoeny(rowData.balance)}</div>
                    </>
                  );
                }}
              />
              <Column
                style={{ textAlign: "center" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                }}
                header="총 딜비"
                body={(rowData) => {
                  return (
                    <>
                      <div>{numTomoeny(rowData.balance)}</div>
                    </>
                  );
                }}
              />
              <Column
                style={{ textAlign: "center" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                }}
                header="윈로스 쉐어"
                body={(rowData) => {
                  return (
                    <>
                      <div>{numTomoeny(rowData.balance)}</div>
                    </>
                  );
                }}
              />
              <Column
                style={{ textAlign: "center" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                }}
                header="딜비쉐어"
                body={(rowData) => {
                  return (
                    <>
                      <div>{numTomoeny(rowData.balance)}</div>
                    </>
                  );
                }}
              />
              <Column
                style={{ textAlign: "center" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                }}
                header="쉐어이익"
                body={(rowData) => {
                  return (
                    <>
                      <div>{numTomoeny(rowData.balance)}</div>
                    </>
                  );
                }}
              />
            </DataTable>
          </div>
          <div className="text-center">
            <SubPaging
              activePage={this.state.page}
              maxCount={this.state.maxCount}
              limit={20}
              MoveSel={(page: number) => {
                this.MoveSel(page);
              }}
            ></SubPaging>
          </div>
        </section>
      </>
    );
  }
}
