import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { AgentService } from "../service/agent.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { TreeTable } from 'primereact/treetable';

import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { SubPaging } from "../share/sub-paging";
import { CCalendarTime } from "./custom/ccalendartime";
import { ThreeDRotationSharp } from "@material-ui/icons";

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';


import { numTomoeny, balanceColor, OpenAgentCal, OpenAgentDetail, GetToday, ConvertCalendar, GetYesterDay, ConvertCalculate, CalculateTotalBalance, ConvertTreeToAgents } from "../utility/help";
import { TooltipCalculate } from "../utility/optionpack";

import { ShareTooltip } from "./share.tooltip";


interface State {
    begin: any;
    end: any;

    agentInfo: any[];
    topInfo: any;
    totalBalance: any;
    level: number
}

export class CalculateCasino extends Component<any, State> {
    agentService = new AgentService();

    menu: any;

    constructor(props: any) {
        super(props);

        let today = GetYesterDay();

        this.state = {

            //begin: new Date('2021-01-01'),
            begin: today.begin,
            end: today.end,

            agentInfo: [],
            totalBalance: {},
            topInfo: {},

            level: 4

        };

        this.sizeTemplate = this.sizeTemplate.bind(this);
        this.rowClassName = this.rowClassName.bind(this);


    }




    handlegetTopAgentCalculate(begin: any, end: any) {
        this.agentService.admin_get_sub_agent(this.props.agentId, begin.toLocaleString('sv-SE'), end.toLocaleString('sv-SE')).then((data) => {
            if (data.status == "success") {

                let totalBalance = {}
                for (let balances of Object.values(data.agentCombine) as any) {
                    for (let key of Object.keys(balances as [])) {

                        if (typeof (balances[key]) !== 'number') {
                            continue;
                        }

                        if (totalBalance[key] == null) {
                            totalBalance[key] = 0
                        }
                        totalBalance[key] += Number(balances[key] as Number)



                    }

                    if (totalBalance['total'] == null) {
                        totalBalance['total'] = 0
                    }


                    totalBalance['total'] += (balances['totalDeposit'] + balances['totalGiveDeposit'] + balances['totalAdmindeposit'] - balances['totalWithdraw'] - balances['totalGiveWithdraw'] - balances['totalAdminwithdraw']
                        + balances['totalYDInBalance'] - balances['totalInBalance'])



                    if (totalBalance['winlose'] == null) {
                        totalBalance['winlose'] = 0
                    }

                    if (totalBalance['totalCasinoRolling'] == null) {
                        totalBalance['totalCasinoRolling'] = 0
                    }

                    if (totalBalance['totalCasinoRolling'] < ((totalBalance['totalCasinoBet'] / 100) * balances['benefitSlotRolling'])) {
                        totalBalance['totalCasinoRolling'] = ((totalBalance['totalCasinoBet'] / 100) * balances['benefitCasinoRolling'])
                    }
                }

                if (totalBalance['winlose'] == null) {
                    totalBalance['winlose'] = 0
                }

                // console.log(totalBalance['totalSlotRolling'])

                totalBalance['winlose'] = totalBalance['totalSlotBet'] - totalBalance['totalSlotWin']

                totalBalance['totalSlotRolling'] = Object.values(data.agentCombine).reduce((acc: any, cur: any, i) => {
                    const totalRoll = ((cur['totalSlotBet'] / 100) * cur['benefitSlotRolling'])

                    return acc + (Number.isNaN(totalRoll) ? 0 : totalRoll)
                }, 0);

                totalBalance['totalBalance'] = Object.values(data.agentCombine).reduce((acc: any, cur: any, i) => {

                    const total = ((cur['totalDeposit'] + cur['totalGiveDeposit'] + cur['totalAdmindeposit'] - cur['totalWithdraw'] - cur['totalGiveWithdraw'] - cur['totalAdminwithdraw']
                        + (cur['totalYDInBalance'] - cur['totalInBalance'])) / 100 * cur['benefitLosing']) - ((cur['totalSlotBet'] / 100) * cur['benefitSlotRolling'])
                    return acc + (Number.isNaN(total) ? 0 : total)
                }, 0);




                if (process.env.REACT_APP_API_CALCULATE_CASINO === 'Y') {

                    totalBalance['totalBalance'] = Object.values(data.agentCombine).reduce((acc: any, cur: any, i) => {

                        const total = ((((cur['totalDeposit'] + cur['totalGiveDeposit'] + cur['totalAdmindeposit'] - cur['totalWithdraw'] - cur['totalGiveWithdraw'] - cur['totalAdminwithdraw']
                            + (cur['totalYDInBalance'] - cur['totalInBalance'])))
                            - ((cur['totalSlotBet'] / 100) * cur['benefitSlotRolling'])
                            - ((cur['totalCasinoBet'] / 100) * cur['benefitCasinoRolling']))

                            / 100) * cur['benefitLosing']
                        return acc + (Number.isNaN(total) ? 0 : total)
                    }, 0);



                }

                totalBalance['totalCasinoLosing'] = Object.values(data.agentCombine).reduce((acc: any, cur: any, i) => {
                    const totalRoll = ((((cur['totalCasinoBet'] - cur['totalCasinoWin']) - ((cur['totalCasinoBet'] / 100) * cur['benefitCasinoRolling'])) * cur['benefitCasinoLosing']) / 100)
                    return acc + (Number.isNaN(totalRoll) ? 0 : totalRoll)
                }, 0);

                totalBalance['totalCasinoWinLose'] = Object.values(data.agentCombine).reduce((acc: any, cur: any, i) => {
                    const totalRoll = (cur['totalCasinoBet'] - cur['totalCasinoWin'])
                    return acc + (Number.isNaN(totalRoll) ? 0 : totalRoll)
                }, 0);


                const combin = Object.values(data.agentCombine)
                combin.sort(function (a: any, b: any) {
                    if (a.agent_tree < b.agent_tree) return -1;
                    if (b.agent_tree < a.agent_tree) return 1;
                    return 0;
                })




                this.setState({ agentInfo: combin, topInfo: combin[0] ? combin[0] : {}, totalBalance: totalBalance, level: data.level });
            }
        });
    }

    // handlegetTopAgentCalculate(begin: any, end: any) {
    //     this.adminService.admin_get_main_agent(begin, end).then((data) => {
    //         if (data.status == "success") {
    //             this.setState({ agentInfo: data.agentCombine });
    //         }
    //     });
    // }

    componentDidMount() {
        const today = GetYesterDay()
        const begin = today.begin
        const end = today.end

        this.handlegetTopAgentCalculate(begin, end);
    }

    // 재입력
    ReloadPage = () => {
        console.log("ReloadPage : 재입력");
    };

    // 정산요청
    RequestCalculate = () => {
        console.log("RequestCalculate : 정산요청");
    };


    // rowClassName(node) {



    //   return { 'p-highlight': (node.children && node.children.length === 3) };
    // }

    rowClassName(node) {

        if (node.level === 2) return { 'p-tag-level2': true }
        if (node.level === 3) return { 'p-tag-level3': true }
        if (node.level === 4) return { 'p-tag-level4': true }

        return { '': true };
    }

    sizeTemplate(node) {

        // console.log(node)
        // let size = node.data.size;
        // let fontWeight = parseInt(size, 10) > 75 ? 'bold' : 'normal';

        // return <span style={{ fontWeight: fontWeight }}>{size}</span>;
        return <span style={{}}>{ }</span>;
    }




    render() {



        let headerGroup = (
            <ColumnGroup>
                <Row>
                    {
                        this.state.level <= 1 && (
                            <Column style={{ textAlign: "center", }} header={(rowData: any, column: any) => { return (<div>본사</div>) }} />
                        )
                    }
                    {
                        this.state.level <= 2 && (
                            <Column header={(rowData: any, column: any) => { return (<div>부본사</div>) }} />
                        )
                    }
                    {
                        this.state.level <= 3 && (
                            <Column header={(rowData: any, column: any) => { return (<div>총판</div>) }} />
                        )
                    }
                    {
                        this.state.level <= 4 && (
                            <Column header={(rowData: any, column: any) => { return (<div>매장</div>) }} />
                        )
                    }


                    <Column header={(rowData: any, column: any) => { return <div><div>유저 입금</div><div>총판 지급</div><div>관리자 지급</div></div> }} />
                    <Column header={(rowData: any, column: any) => { return <div><div>유저 출금</div><div>총판 회수</div><div>관리자 회수</div></div> }} />
                    <Column header={(rowData: any, column: any) => { return <div><div>유저 수익</div><div>총판 수익</div></div> }} />
                    <Column header={(rowData: any, column: any) => { return <div><div>Casino Bet</div><div>Casino Win</div></div> }} />
                    {/* <Column header={(rowData: any, column: any) => { return <div><div>유저보유금</div><div>전일 보유</div></div> }} /> */}


                    {/* <Column header={(rowData: any, column: any) => { return <div><div>롤링 케쉬전환</div></div> }} /> */}
                    <Column header={(rowData: any, column: any) => { return (<div>카지노루징</div>) }} />
                    <Column header={(rowData: any, column: any) => { return (<div><div>정산금</div><div>루징프로테이지%</div></div>) }} />
                    <Column header={(rowData: any, column: any) => { return <div><div>카지노 롤링</div><div>프로테이지</div></div> }} />

                    <Column header={(rowData: any, column: any) => { return <div><div>파트너 충전</div><div>파트너 환전</div></div> }} />
                    <Column header={(rowData: any, column: any) => { return <div>유저 카지노 롤링</div> }} />



                </Row>
                {
                    this.state.totalBalance && this.state.totalBalance['idx'] !== null && (

                        <Row >

                            {
                                this.state.level <= 1 && (
                                    <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header="-" />
                                )
                            }
                            {
                                this.state.level <= 2 && (
                                    <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header="-" />
                                )
                            }
                            {
                                this.state.level <= 3 && (
                                    <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header="-" />
                                )
                            }
                            {
                                this.state.level <= 4 && (
                                    <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header="-" />
                                )
                            }


                            <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                                console.log(this.state.topInfo)
                                return <div>
                                    <div>{numTomoeny(this.state.topInfo['totalDeposit'])}</div>
                                    <div>{numTomoeny(this.state.topInfo['totalGiveDeposit'])}</div>
                                    <div>{numTomoeny(this.state.topInfo['totalAdmindeposit'])}</div>
                                </div>
                            }} />
                            <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                                return <div>
                                    <div>{numTomoeny(this.state.topInfo['totalWithdraw'])}</div>
                                    <div>{numTomoeny(this.state.topInfo['totalGiveWithdraw'])}</div>
                                    <div>{numTomoeny(this.state.topInfo['totalAdminwithdraw'])}</div>
                                </div>
                            }} />

                            <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                                return <div>
                                    <div> {numTomoeny(this.state.topInfo['totalDeposit'] - this.state.topInfo['totalWithdraw'])}</div>
                                    <div> {numTomoeny(this.state.topInfo['totalGiveDeposit'] - this.state.topInfo['totalGiveWithdraw'])}</div>
                                    <div> {numTomoeny(this.state.topInfo['totalAdmindeposit'] - this.state.topInfo['totalAdminwithdraw'])}</div>
                                </div>
                            }} />


                            <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                                return <div>{numTomoeny(this.state.topInfo['totalCasinoBet'])}</div>
                            }} />



                            <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                                return <div></div>
                            }} />



                            <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                                if (Number.isNaN(((this.state.topInfo['totalSlotBet'])) * this.state.topInfo['benefitSlotRolling'] / 100)) {
                                    return <div>0</div>;
                                }

                                return <div>
                                    <div >{numTomoeny(((this.state.topInfo['totalSlotBet'])) * this.state.topInfo['benefitSlotRolling'] / 100)}</div>
                                </div>;
                            }} />

                            <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                                if (Number.isNaN(((this.state.topInfo['totalCasinoBet'])) * this.state.topInfo['benefitCasinoRolling'] / 100)) {
                                    return <div>0</div>;
                                }

                                return <div>
                                    <div style={{ color: '#ffc107' }}>{numTomoeny(((this.state.topInfo['totalCasinoBet'])) * this.state.topInfo['benefitCasinoRolling'] / 100)}</div>
                                </div>;
                            }} />


                            <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                                return <div>
                                    <div style={{ color: 'red' }}>{numTomoeny(this.state.topInfo['realAgentChangeBalance'])}</div>
                                    <div style={{ color: 'blue' }}>{numTomoeny(this.state.topInfo['realAgentExchangeBalance'])}</div>
                                </div>;
                            }} />
                            <Column style={{ textAlign: "center", border: '1px solid #fff', backgroundColor: '#3c8dbcc7' }} header={(rowData: any, column: any) => {
                                return <div>
                                    <div>{numTomoeny(this.state.topInfo['totalUserRollingSlot'])}</div>
                                </div>;
                            }} />



                        </Row>
                    )

                }


            </ColumnGroup >
        )
        return (
            <>
                <section className="content-header">
                    <h1>카지노 정산</h1>
                </section>

                <section className="content">
                    <div
                        id="searchForm"
                        className="form-inline"
                        role="form"
                    >
                        <div className="form-group">
                            <CCalendarTime
                                placeholder="시작일"
                                value={this.state.begin}
                                onChange={(e) => this.setState({ begin: e })}
                            />
                        </div>

                        <div className="form-group">
                            <CCalendarTime
                                placeholder="종료일"
                                value={this.state.end}
                                onChange={(e) => this.setState({ end: e })}
                            />
                        </div>
                        <div className="form-group">
                            <Button
                                label="조회"
                                onClick={() => {
                                    this.handlegetTopAgentCalculate(this.state.begin, this.state.end);

                                }}
                                className="btn btn-primary btn-sm"
                            />
                        </div>
                        <div className="form-group">
                            <Button
                                label="재입력"
                                onClick={() => { }}
                                className="btn btn-default btn-sm"
                            />
                        </div>
                    </div>

                    <div
                        className="table-responsive list_view"
                        style={{ marginTop: "10px", }}
                    >
                        <DataTable

                            id="fixTable"
                            sortOrder={1}
                            rowGroupMode="rowspan"
                            value={this.state.agentInfo}
                            scrollable
                            scrollHeight="95vh"
                            virtualScrollerOptions={{ itemSize: 46 }}


                            headerColumnGroup={headerGroup}
                        >
                            {
                                this.state.level <= 1 && (
                                    <Column
                                        header="본사"
                                        headerStyle={{ textAlign: "center", }}
                                        body={(rowData: any, column: any) => {
                                            if (rowData.level === 1) return (
                                                <div>
                                                    <div onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}</div>
                                                    <div onClick={() => {
                                                        var win = window.open("/#/casinosubCalc/" + rowData.agent, "_blank", "width=1600,height=1000");
                                                        win!.focus();
                                                    }}>{rowData.nick}</div>
                                                </div>
                                            )
                                            return <div></div>;


                                        }}
                                    />
                                )
                            }

                            {
                                this.state.level <= 2 && (
                                    <Column
                                        header="부본사"
                                        headerStyle={{ textAlign: "center", }}
                                        body={(rowData: any, column: any) => {
                                            const tipname = `tooltip_${rowData.agent}`
                                            if (rowData.level === 2) return (
                                                <div className={tipname}>
                                                    <div >{rowData.agent}</div>
                                                    <div >{rowData.nick}</div>
                                                </div>
                                            )
                                            return <></>;


                                        }}
                                    />
                                )
                            }
                            {
                                this.state.level <= 3 && (
                                    <Column
                                        header="총판"
                                        headerStyle={{ textAlign: "center", }}
                                        body={(rowData: any, column: any) => {
                                            const tipname = `tooltip_${rowData.agent}`
                                            if (rowData.level === 3) return (
                                                <div className={tipname}>
                                                    <div >{rowData.agent}</div>
                                                    <div >{rowData.nick}</div>

                                                </div>
                                            )
                                            return <></>;

                                        }}
                                    />
                                )
                            }

                            {
                                this.state.level <= 4 && (
                                    <Column
                                        header="매장"
                                        headerStyle={{ textAlign: "center", }}
                                        body={(rowData: any, column: any) => {

                                            const tipname = `tooltip_${rowData.agent}`
                                            if (rowData.level === 4) return (
                                                <div className={tipname}>
                                                    <div >{rowData.agent}</div>
                                                    <div >{rowData.nick}</div>

                                                </div>
                                            )
                                            return <></>;

                                        }}
                                    />

                                )
                            }
                            <Column
                                header={<div><div>유저 입금</div><div>총판 지급</div><div>관리자 지급</div></div>}
                                body={(rowData: any, column: any) => {
                                    return <div>
                                        <div style={{ color: 'red' }}>{numTomoeny(rowData.totalDeposit)}</div>
                                        <div style={{ color: 'red' }}>{numTomoeny(rowData.totalGiveDeposit)}</div>
                                        <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAdmindeposit)}</div>
                                    </div>;
                                }}
                            />


                            <Column
                                header={<div><div>유저 출금</div><div>총판 회수</div><div>관리자 회수</div></div>}
                                body={(rowData: any, column: any) => {

                                    return <div>
                                        <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalWithdraw)}</div>
                                        <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalGiveWithdraw)}</div>
                                        <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAdminwithdraw)}</div>

                                    </div>;
                                }}
                            />



                            <Column
                                header={<div><div>유저 수익</div><div>총판 수익</div></div>}
                                body={(rowData: any, column: any) => {
                                    return <div>
                                        <div style={{ color: rowData.totalDeposit - rowData.totalWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalDeposit - rowData.totalWithdraw)))}</div>
                                        <div style={{ color: rowData.totalGiveDeposit - rowData.totalGiveWithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalGiveDeposit - rowData.totalGiveWithdraw)))}</div>
                                        <div style={{ color: rowData.totalAdmindeposit - rowData.totalAdminwithdraw > 0 ? 'red' : 'blue' }}>{numTomoeny(((rowData.totalAdmindeposit - rowData.totalAdminwithdraw)))}</div>
                                    </div>;
                                }}
                            />


                            <Column
                                header={<div><div>Casino Bet</div><div>Casino Win</div></div>}
                                body={(rowData: any, column: any) => {
                                    return <div>
                                        <div style={{ color: 'red' }}>{numTomoeny(rowData.totalCasinoBet)}</div>
                                        <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalCasinoWin)}</div>
                                    </div>;

                                }}
                            />


                            <Column
                                header={<div>
                                    <div>카지노 루징</div>
                                </div>}
                                body={(rowData: any, column: any) => {
                                    const total = (rowData.totalCasinoBet - rowData.totalCasinoWin)
                                    return (
                                        <div>
                                            {numTomoeny(total)}
                                        </div>
                                    )

                                }}
                            />


                            <Column
                                header={<div>
                                    <div>정산금</div>
                                    <div>루징프로테이지%</div>
                                </div>}
                                body={(rowData: any, column: any) => {
                                    const total = (((rowData.totalCasinoBet - rowData.totalCasinoWin)
                                        - ((rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling)
                                    ) * rowData.benefitCasinoLosing) / 100

                                    if (Number.isNaN(total)) {
                                        return <div>0</div>;
                                    }

                                    return (
                                        <div>
                                            {numTomoeny(total)}
                                            <div>{rowData.benefitCasinoLosing}%</div>
                                        </div>
                                    )

                                }}
                            />



                            <Column
                                header={<div><div>카지노 롤링</div><div>프로테이지</div></div>}
                                body={(rowData: any, column: any) => {
                                    return <div>
                                        <div style={{ color: '#ffc107' }}>{numTomoeny(((rowData.totalCasinoBet)) * rowData.benefitCasinoRolling / 100)}</div>
                                        <div style={{ color: 'green' }}>{rowData.benefitCasinoRolling}%</div>
                                    </div>;
                                }}
                            />


                            <Column
                                header={<div><div>파트너 충전</div><div>파트너 환전</div></div>}
                                body={(rowData: any, column: any) => {
                                    return <div>
                                        <div style={{ color: 'red' }}>{numTomoeny(rowData.realAgentChangeBalance)}</div>
                                        <div style={{ color: 'blue' }}>{numTomoeny(rowData.realAgentExchangeBalance)}</div>
                                    </div>;
                                }}
                            />



                            <Column
                                header={<div>유저 카지노 롤링</div>}
                                body={(rowData: any, column: any) => {
                                    return <div>
                                        <div>{numTomoeny(rowData.totalUserRollingCasino)}</div>
                                    </div>;
                                }}
                            />

                        </DataTable>

                    </div>


                </section >
            </>
        );
    }
}
