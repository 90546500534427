import React, { Component } from "react";

interface SubMenuState {
  activeIndex: any;
}

interface SubMenuProps {
  items: any;
}

class AppTopSubmenu extends Component<SubMenuProps, SubMenuState> {
  render() {
    var items =
      this.props.items &&
      this.props.items.map((item: any, i: any) => {
        return (
          <li key={`apptopsubmenu_${item.to}`}>
            <a href={"/#" + item.to}>
              <i className="fa fa-circle-o"></i>
              {item.label}
            </a>
          </li>
        );
      });

    return <>{items}</>;
  }
}

interface MenuProps {
  items: any;
  onMenuItemClick: (par: any) => any;
}

interface State {
  soundFiles: any;
  top: any;
}

export class AppMenu extends Component<MenuProps, State> {
  audio: any = new Audio();

  constructor(props: any) {
    super(props);
    this.state = {
      soundFiles: [
        { name: "userReg", file: "/assets/sound/reg.mp3" },
        { name: "depositReg", file: "/assets/sound/ch.mp3" },
        { name: "withdrawReg", file: "/assets/sound/ex.mp3" },
        { name: "helpReg", file: "/assets/sound/qa.mp3" },
        { name: "agentReg", file: "/assets/sound/agent.wav" },
        {
          name: "agentBalanceCountReg",
          file: "/assets/sound/agentbalance.mp3",
        },
      ],
      top: {},
    };
  }

  componentDidMount() { }

  playAudio() {
    const playPromise = this.audio.play();
    if (playPromise !== undefined) {
      playPromise
        .then(function () {
          console.log("ok");
        })
        .catch(function (error) {
          console.error("Failed to start your sound, retrying.");
          console.log(error);
        });
    }
  }

  render() {


    var items =
      this.props.items &&
      this.props.items.map((item: any, i: any) => {

        return (
          <>
            {!item.items && (
              <li className="treeview active" key={`appmenu_li_${i}`}>
                <a href={"/#" + item.to}>
                  <i className="fa fa-files-o"></i>
                  <span>{item.label}</span>
                </a>
              </li>
            )}

            {item.items && (
              <li className="treeview active">
                <a>
                  <i className="fa fa-files-o"></i>
                  <span>{item.label}</span>
                </a>
                <ul className="treeview-menu" style={{ display: "none" }} >
                  <AppTopSubmenu items={item.items} />
                </ul>
              </li>
            )}
          </>
        );
      });

    return <>{items}</>;
  }
}
