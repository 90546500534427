import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { confirmAlert } from "react-confirm-alert";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Editor } from "primereact/editor";
import { Panel } from "primereact/panel";
import { SelectButton } from "primereact/selectbutton";
import { Card } from 'primereact/card';
import { Fieldset } from 'primereact/fieldset';

import {
    GetNow,
    ConvertCalendar,
    numTomoeny,
    OpenUserDetail,
    HelpStatus,
    ConvertDate,
    OpenAgentCal,
    OpenAgentDetail
} from "../utility/help";
import { SubPaging } from "../share/sub-paging";
import { BalanceService } from "../service/balance.service";
import { Message } from "primereact/message";
import { ShareBalanceRequestedMenu } from "./share.balance.requested.menu";
import { ShareBalanceRequestedTable } from "./share.balance.requested.table";
import { CommunityService } from "../service/community.service";
import { AgentService } from "../service/agent.service";


import { SharePopupBalanceLog } from "./share.popup.balancelog";



interface State {
    activePage: number;
    maxCount: number;
    deposits: any[];
    note: any[];
    help: any;

    begin: any;
    end: any;

    findText: string;
    searchText: string;

    selectAll: boolean;
    ProcessCancelPopup: boolean;
    SearchLogPopup: boolean;
    templatesTitles: any

    template: any[];

}


export class RequestAgentHelpList extends Component<any, State> {
    userService = new UserService();
    fileService = new FileService();
    agentService = new AgentService();
    balanceService = new BalanceService();
    communityService = new CommunityService();

    menu: any;

    constructor(props: any) {
        super(props);

        let begin = GetNow();
        let end = GetNow();

        this.state = {
            activePage: 1,
            maxCount: 1,
            deposits: [],
            help: {},
            template: [],
            begin: begin,
            end: end,
            templatesTitles: [],
            note: [],
            findText: "",
            searchText: "",

            selectAll: false,
            ProcessCancelPopup: false,
            SearchLogPopup: false,

        };


        this.handleUserNote('', ' ', 1);
        this.handleTemplate();
    }


    componentDidMount() { }




    handleDelAllNote = () => {
        confirmAlert({
            title: "에이전트 고객센터",
            message: "에이전트 고객센터를 삭제합니다 3일전 내역은 모두 삭제됩니다.",
            buttons: [
                {
                    label: "확인",
                    onClick: () => {

                        this.communityService.admin_del_all_agent_help().then((data: any) => {
                            if (data.status == "success") {
                                confirmAlert({
                                    title: "에이전트 고객센터",
                                    message: "에이전트페이지에서 삭제되었습니다.",
                                    buttons: [
                                        {
                                            label: "확인",
                                            onClick: () => {
                                                window.location.reload();
                                            },
                                        },
                                    ],
                                });
                            }
                            else {
                                confirmAlert({
                                    title: "에이전트 고객센터",
                                    message: "알수 없는 예러 발생.",
                                    buttons: [
                                        {
                                            label: "확인",
                                            onClick: () => {
                                                window.location.reload();
                                            },
                                        },
                                    ],
                                });

                            }
                        });
                    },
                },
                {
                    label: "취소",
                    onClick: () => {

                    },
                },
            ],
        });
        return;

    };

    handleTemplate = async () => {
        let data = await this.communityService.admin_get_help_template_list(1)
        if (data.status == "success") {
            let templates: any = []
            for (let note of data.note) {
                templates.push({ value: note._id, label: note.title })
            }

            this.setState({ template: data.note, templatesTitles: templates });
        }
        // let data = await this.agentService.admin_get_help_template_list(1, '', '')
        // if (data.status == "success") {
        //   this.setState({ template: data.note });
        // }
    };

    handleUserNote = (dropdownItem, seach, skip: any) => {
        this.setState({ activePage: skip });
        // this.agentService.admin_get_agent_help_list(seach, dropdownItem, skip).then((data: any) => {
        //     if (data.status == "success") {
        //         this.setState({ note: data.note, maxCount: data.maxCount });
        //     }
        // });
    };


    handleAlreadRead = async (help) => {
        // await this.agentService.admin_agent_help_already_read(help).then(s => s)
    };


    handleWirteHelp = () => {
        // this.agentService.admin_wirte_ref_agent_help(this.state.help).then((data) => {
        //     if (data.status == "success") {
        //         confirmAlert({
        //             title: "고객센터",
        //             message: "고객센터 작성/수정 성공.",
        //             buttons: [
        //                 {
        //                     label: "확인",
        //                     onClick: () => {
        //                         window.location.reload();
        //                     },
        //                 },
        //             ],
        //         });
        //     } else {
        //         confirmAlert({
        //             title: "고객센터",
        //             message: "고객센터 작성 실패.",
        //             buttons: [
        //                 {
        //                     label: "확인",
        //                     onClick: () => {
        //                         window.location.reload();
        //                     },
        //                 },
        //             ],
        //         });
        //     }
        // });
    };


    handleDelHelp = (_idx) => {
        // this.agentService.admin_del_agent_help(_idx).then((data) => {
        //     if (data.status == "success") {
        //         confirmAlert({
        //             title: "고객센터",
        //             message: "고객센터 삭제되었습니다.",
        //             buttons: [
        //                 {
        //                     label: "확인",
        //                     onClick: () => {
        //                         window.location.reload();
        //                     },
        //                 },
        //             ],
        //         });
        //     } else {
        //         confirmAlert({
        //             title: "고객센터",
        //             message: "고객센터 작성 실패.",
        //             buttons: [
        //                 {
        //                     label: "확인",
        //                     onClick: () => {
        //                         window.location.reload();
        //                     },
        //                 },
        //             ],
        //         });
        //     }
        // });
    };



    render() {

        let items = this.state.note;


        return (
            <>
                <section className="content-header">
                    <h1>에이전트 고객센터</h1>
                </section>

                <section className="content">


                    {/* 메인컨텐츠 */}
                    <div className="table-responsive list_view" style={{ marginTop: "10px" }}>

                        <div className="tab-content">

                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <Card title="" style={{ marginBottom: '2em' }}>

                                    <div style={{ margin: '10px' }}>

                                        <Button
                                            label="삼일 이전내역삭제"
                                            onClick={() => {
                                                this.handleDelAllNote();
                                            }}
                                        />
                                    </div>
                                    <DataTable

                                        value={items}
                                        paginatorPosition="both"
                                        rows={20}
                                        sortOrder={-1}
                                    >
                                        <Column
                                            style={{ textAlign: "left" }}
                                            header="제목"
                                            body={(rowData: any, column: any) => {
                                                return <div
                                                    style={{ color: rowData.color }}
                                                    onClick={() => {

                                                        this.setState({ help: rowData });
                                                        this.handleAlreadRead(rowData)
                                                    }}>  {rowData.title}!!!!!!!!!!</div>;
                                            }}
                                        />

                                        <Column
                                            style={{ textAlign: "center" }}
                                            header="아이디"
                                            body={(rowData: any, column: any) => {
                                                return (
                                                    <div
                                                        onClick={() => OpenAgentDetail(rowData.agentId)}
                                                        style={{ color: rowData.color }}
                                                    >
                                                        {rowData.agentId}
                                                        <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                                                    </div>
                                                );
                                            }}
                                        />
                                        <Column
                                            style={{ textAlign: "center" }}
                                            header="상태"
                                            body={(rowData: any, column: any) => {

                                                if (rowData.del == 'y') {
                                                    return (
                                                        <>
                                                            {/* <DeleteForeverIcon></DeleteForeverIcon> */}
                                                            {HelpStatus(rowData.status)}
                                                        </>
                                                    )
                                                }
                                                return <>{HelpStatus(rowData.status)}</>;
                                            }}
                                        />

                                    </DataTable>


                                </Card>

                                <SubPaging
                                    activePage={this.state.activePage}
                                    maxCount={this.state.maxCount}
                                    limit={20}
                                    MoveSel={(page: number) => {
                                        this.handleUserNote('', ' ', page);
                                    }}
                                ></SubPaging>

                            </div>

                            {
                                this.state.help.agentId && (

                                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                        <Card style={{ marginBottom: '2em' }}>



                                            <Fieldset legend="제목" style={{}}>
                                                {this.state.help.title}!!!!!!!!!!
                                            </Fieldset>
                                            <Fieldset legend="문의 시간">
                                                {ConvertDate(this.state.help.regDate)}
                                            </Fieldset>
                                            <Fieldset legend="내용">
                                                <p>{this.state.help.contents}</p>
                                            </Fieldset>

                                            <Fieldset legend="답변" style={{}}>
                                                {this.state.help && this.state.help.ref && (
                                                    <p>{ConvertDate(this.state.help.ref.date)}</p>
                                                )}
                                                <Editor
                                                    style={{ height: "320px" }}
                                                    value={this.state.help.ref.contents}
                                                    onTextChange={(e) => {

                                                        if (this.state.help && this.state.help.ref) {
                                                            this.state.help.ref.contents = e.htmlValue;
                                                            this.setState({ help: this.state.help });
                                                        }
                                                    }}
                                                />
                                            </Fieldset>


                                            <Fieldset legend="템플릿 답변" style={{}}>
                                                {
                                                    this.state.templatesTitles.map(ss => (
                                                        <Button
                                                            label={ss.label}
                                                            onClick={() => {

                                                                if (ss.value == null) {
                                                                    return
                                                                }

                                                                const value = Object.values(this.state.template).find((s: any) => s._id == ss.value) as any
                                                                if (value != null) {
                                                                    console.log(value)
                                                                    this.state.help.ref.contents = value.contents;
                                                                    this.setState({ help: this.state.help });
                                                                }
                                                            }}
                                                        />

                                                    ))
                                                }

                                            </Fieldset>
                                            <>


                                                <Button
                                                    label="저장 하기"
                                                    onClick={() => {
                                                        this.handleWirteHelp();
                                                        //this.handleEdithelp(this.state.help);
                                                        //this.handleUpdateBenefit();
                                                    }}
                                                />
                                                <Button
                                                    label="삭제"
                                                    color="secondary"
                                                    onClick={() => {
                                                        this.handleDelHelp(this.state.help._id);
                                                    }}
                                                />
                                            </>


                                        </Card>



                                    </div>
                                )

                            }

                        </div>
                    </div>

                </section>


                {<SharePopupBalanceLog></SharePopupBalanceLog>}
            </>
        );
    }
}
