

import React, { Component } from "react";
import { Button } from "primereact/button";

interface Prop {

    class?: any
    icon?: any
    label: any
    onClick: (event) => any

}

interface State {
}

export class CButton extends Component<Prop, State> {

    constructor(props: any) {
        super(props);
        this.state = {
        };


        // this.GetUserList(0, "", "", "regdate", 1);
    }

    componentDidMount() { }


    render() {
        return (
            <Button

                className={this.props.class}
                icon={this.props.icon}
                label={this.props.label}
                onClick={this.props.onClick}
            />
        );
    }
}
