import axios from "axios";
import { AxiosMag } from "./axios.service";

export class CommunityService {
  admin_get_help_list = async (paramter) => {
    return await AxiosMag.SendMessageByPost("/community/admin_get_help_list", paramter);
  };

  admin_get_help_template_list = async (skip) => {
    return await AxiosMag.SendMessageByPost(
      "/community/admin_get_help_template_list",
      {
        skip: skip,
      }
    );
  };

  admin_wirte_help_template = async (template) => {
    return await AxiosMag.SendMessageByPost(
      "/community/admin_wirte_help_template",
      template
    );
  };

  admin_del_help_template = async (template) => {
    return await AxiosMag.SendMessageByPost(
      "/community/admin_del_help_template",
      template
    );
  };




  admin_already_read = async (ref) => {
    return await AxiosMag.SendMessageByPost(
      "/community/admin_already_read",
      ref
    );
  };


  admin_wirte_ref_help = async (ref) => {
    return await AxiosMag.SendMessageByPost(
      "/community/admin_wirte_ref_help",
      ref
    );
  };

  admin_del_user_help = async (idx) => {
    return await AxiosMag.SendMessageByPost(
      "/community/admin_del_user_help",
      { idx: idx }
    );
  };

  // admin_wirte_help_template = async template => {
  //   return await AxiosMag.SendMessageByPost(
  //     "/community/admin_wirte_help_template",
  //     template
  //   );
  // };

  admin_del_user_note = async (dels) => {
    return await AxiosMag.SendMessageByPost("/community/admin_del_user_note", {
      dels
    });
  };

  admin_get_user_note = async (level, userId, agent, skip) => {
    return await AxiosMag.SendMessageByPost("/community/admin_get_user_note", {
      skip: skip,
      userId: userId,
      level: level,
      agent: agent
    });
  };


  admin_get_agent_note = async (level, userId, agent, skip) => {
    return await AxiosMag.SendMessageByPost("/agent/admin_get_agent_note", {
      skip: skip,
      userId: userId,
      level: level,
      agent: agent
    });
  };



  admin_get_user_note_by_id = async (skip, id) => {
    return await AxiosMag.SendMessageByPost(
      "/community/admin_get_user_note_by_id",
      {
        skip: skip,
        id: id,
      }
    );
  };


  admin_wirte_agent_note = async (notice: any) => {
    return await AxiosMag.SendMessageByPost(
      "/agent/admin_wirte_agent_note",
      notice
    );
    // return axios
    //   .get("/community/admin_wirte_user_note")
    //   .then(res => res.data.data)
    //   .then(data => {
    //     _this.setState({ countriesData: data });
    //     return data;
    //   });
  };

  admin_wirte_user_note = async (notice: any) => {
    return await AxiosMag.SendMessageByPost(
      "/community/admin_wirte_user_note",
      notice
    );
    // return axios
    //   .get("/community/admin_wirte_user_note")
    //   .then(res => res.data.data)
    //   .then(data => {
    //     _this.setState({ countriesData: data });
    //     return data;
    //   });
  };

  admin_get_notice_even = async (skip) => {
    return await AxiosMag.SendMessageByPost(
      "/community/get_event_list",
      {
        skip: skip,
      }
    );
  };

  admin_even_wirte = async (wirte) => {
    return await AxiosMag.SendMessageByPost(
      "/community/admin_even_wirte",
      wirte
    );
  };

  admin_even_del = async (wirte) => {
    return await AxiosMag.SendMessageByPost(
      "/community/admin_even_del",
      wirte
    );
  };


  admin_get_notice_popup = async (skip) => {
    return await AxiosMag.SendMessageByPost(
      "/community/admin_get_notice_popup",
      {
        skip: skip,
      }
    );
  };

  admin_wirte_notice_popup = async (notice: any) => {
    return await AxiosMag.SendMessageByPost(
      "/community/admin_wirte_notice_popup",
      notice
    );
    // return axios
    //   .get("/community/admin_wirte_user_note")
    //   .then(res => res.data.data)
    //   .then(data => {
    //     _this.setState({ countriesData: data });
    //     return data;
    //   });
  };


  admin_del_notice_popup = async (notice: any) => {
    return await AxiosMag.SendMessageByPost(
      "/community/admin_del_notice_popup",
      notice
    );
    // return axios
    //   .get("/community/admin_wirte_user_note")
    //   .then(res => res.data.data)
    //   .then(data => {
    //     _this.setState({ countriesData: data });
    //     return data;
    //   });
  };
  admin_get_advertisement = async (skip) => {
    return await AxiosMag.SendMessageByPost(
      "/community/admin_get_advertisement",
      {
        skip: skip,
      }
    );
  };

  admin_wirte_advertisement = async (notice: any) => {
    return await AxiosMag.SendMessageByPost(
      "/community/admin_wirte_advertisement",
      notice
    );
  };


  admin_del_all_user_help = async () => {
    return await AxiosMag.SendMessageByPost("/community/admin_del_all_user_help", {
    });
  };


  admin_del_all_agent_help = async () => {
    return await AxiosMag.SendMessageByPost("/community/admin_del_all_agent_help", {
    });
  };


}
