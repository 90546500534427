import React, { Component, useState, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";


import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { confirmAlert } from "react-confirm-alert";

import { CDropdown } from "./custom/cdropdown";



import { BalanceService } from "../service/balance.service";


import { ConvertDate, convertToCSV, GetNow, numTomoeny } from "../utility/help";


interface Prop {
    getUserList: (params: any) => any;
}


interface State {
    balance: string

    selectValue: any
    userInfo: any
    dropdownItem: string
    search: any
    find: string

    status: string
}

export class ShareUserMenu extends Component<Prop, State> {
    balanceService: BalanceService = new BalanceService();



    selectData = [
        { label: "아이디", value: "id" },
        { label: "닉네임", value: "nick" },
        { label: "에이전트ID", value: "agent" },
        { label: "은행계좌번호", value: "banknumber" },
        { label: "예금주명", value: "bankowner" },
        { label: "전화번호", value: "phone" }
    ];


    selectData2 = [
        { label: "전체", value: "" },
        { label: "Y", value: "y" },
        { label: "N", value: "n" },
    ];
    selectValue2 = "";

    selectBankData = [
        { label: "은행선택", value: "" },
        { label: "BNP파리바", value: "BNP파리바" },
        { label: "BOA은행", value: "BOA은행" },
        { label: "HSBC은행", value: "HSBC은행" },
        { label: "JP모간체이스은행", value: "JP모간체이스은행" },
        { label: "kb증권", value: "kb증권" },
        { label: "K뱅크", value: "K뱅크" },
        { label: "SC은행", value: "SC은행" },
        { label: "경남은행", value: "경남은행" },
        { label: "광주은행", value: "광주은행" },
        { label: "국민은행", value: "국민은행" },
        { label: "기업은행", value: "기업은행" },
        { label: "농협(축협)", value: "농협(축협)" },
        { label: "농협투자증권", value: "농협투자증권" },
        { label: "대구은행", value: "대구은행" },
        { label: "대신 증권", value: "대신 증권" },
        { label: "도이치은행", value: "도이치은행" },
        { label: "미쓰비시도쿄UFJ은행", value: "미쓰비시도쿄UFJ은행" },
        { label: "부산은행", value: "부산은행" },
        { label: "산림조합중앙회", value: "산림조합중앙회" },
        { label: "산업은행", value: "산업은행" },
        { label: "삼성증권", value: "삼성증권" },
        { label: "상호저축은행", value: "상호저축은행" },
        { label: "새마을금고", value: "새마을금고" },
        { label: "수협중앙회", value: "수협중앙회" },
        { label: "신한금융투자", value: "신한금융투자" },
        { label: "신한은행", value: "신한은행" },
        { label: "신협중앙회", value: "신협중앙회" },
        { label: "외환은행", value: "외환은행" },
        { label: "우리은행", value: "우리은행" },
        { label: "우체국", value: "우체국" },
        { label: "유안타증권", value: "유안타증권" },
        { label: "유진투자증권", value: "유진투자증권" },
        { label: "전북은행", value: "전북은행" },
        { label: "제주은행", value: "제주은행" },
        { label: "중국공상은행", value: "중국공상은행" },
        { label: "카카오뱅크", value: "카카오뱅크" },
        { label: "토스뱅크", value: "토스뱅크" },
        { label: "하나금융투자증권", value: "하나금융투자증권" },
        { label: "하나은행", value: "하나은행" },
        { label: "한국씨티은행", value: "한국씨티은행" },
        { label: "한국투자증권", value: "한국투자증권" },
    ];

    selectLevelData = [
        { label: "1 LV", value: "1" },
        { label: "2 LV", value: "2" },
        { label: "3 LV", value: "3" },
        { label: "4 LV", value: "4" },
        { label: "5 LV", value: "5" },
        { label: "6 LV", value: "6" },
        { label: "7 LV", value: "7" },
        { label: "8 LV", value: "8" },
        { label: "9 LV", value: "9" },
        { label: "10 LV", value: "10" },
    ];


    constructor(props: Prop) {
        super(props);

        this.state = {
            balance: '',
            userInfo: null,
            dropdownItem: "user_id",
            status: "",
            find: "",

            search: this.selectData[0].value,
            selectValue: this.selectData[0]
        }


    }


    render() {
        return (
            <div className="form-inline">
                <div className="form-group">
                    <CDropdown
                        options={this.selectData}
                        value={this.state.search}

                        onChange={(event) => {
                            console.log(event.value)
                            this.setState({ search: event.value })
                        }}
                    />
                </div>
                <div className="form-group">
                    <InputText
                        value={this.state.find}
                        onChange={(e: any) => this.setState({ find: e.target.value })}
                        onKeyPress={(e: any) => {
                            if (e.key === "Enter") {
                                this.props.getUserList({ search: this.state.search, find: this.state.find, status: this.state.status })
                            }
                        }}
                        className="form-control input-sm"
                        style={{ width: "200px" }}
                        placeholder="아이디를 입력해 주세요"
                    />
                </div>
                <div className="form-group">
                    블록여부
                    <CDropdown
                        options={this.selectData2}
                        value={this.state.status}
                        onChange={(event) => this.setState({ status: event.value })}
                    />
                </div>
                <div className="form-group">
                    <Button
                        label="조회"
                        onClick={() => setTimeout(() => this.props.getUserList({ search: this.state.search, find: this.state.find, status: this.state.status }))}
                        className="btn btn-primary btn-sm"
                    />
                </div>


            </div>
        )
    }
}