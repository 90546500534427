import React, { Component, useState, useEffect } from "react";

import { InputText } from "primereact/inputtext";


import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { confirmAlert } from "react-confirm-alert";


import { BalanceService } from "../service/balance.service";
import { AgentService } from "../service/agent.service";


import { ConvertDate, convertToCSV, GetNow, numTomoeny, IsConnection, OpenAgentDetail, ConvertTreeToAgents, UUID } from "../utility/help";
import { TooltipTree } from "../utility/optionpack";
import { ShareTooltip } from "./share.tooltip";
import { ShareAgentNewPopup } from "../new_main/share.agent.new.popup";

import CurrencyInput from 'react-currency-input-field';

enum popupView {
    none = "none",
    charge = "charge",
    deposit = "deposit",
    withdraw = "withdraw",
    point = "point",
    notice = "notice",
    note = "note",
    help = "help",
    user = "user",
    reg = "reg",
    login = "login",
}

interface Prop {

    agents: any
    serverTime: any
    newAgent: any
    management: any
    saveNewAgent: (agent: any) => any;
}


interface State {

    code: string
    balance: number
    popupState: popupView
    userInfo: any
}

export class TableAgentShare extends Component<Prop, State> {
    balanceService: BalanceService = new BalanceService();
    agentService: AgentService = new AgentService();



    constructor(props: Prop) {
        super(props);
        this.state = {
            code: UUID(),
            balance: 0,
            userInfo: null,
            popupState: popupView.none,

        }

        // this.RenderChargePopup = this.RenderChargePopup.bind(this);
        // this.RenderWithdrawPopup = this.RenderWithdrawPopup.bind(this);

    }

    RenderChargePopup = () => {
        if (this.state.popupState !== popupView.charge) return


        const info = this.state.userInfo

        confirmAlert({
            customUI: ({ onClose }) => {

                const popClonse = () => {
                    this.setState({ popupState: popupView.none })
                    onClose()
                }

                return (
                    <div className="modal-content" >
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => popClonse()}>×</button>
                            <h4 className="modal-title" id="sendMoneyModalLabel">충전</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label className="control-label col-sm-3">에이전트</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control input-sm" readOnly value={`${info.agent} (${info.nick}) `} />
                                </div>
                            </div>

                            <br/>

                            <div className="form-group">
                                <label className="control-label col-sm-3">보유머니</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control input-sm number" readOnly value={`${numTomoeny(info.balance)}`} />
                                </div>
                            </div>

                            <br/>

                            <div className="form-group">
                                <label className="control-label col-sm-3">금액(머니)</label>
                                <div className="col-sm-9">

                      
                                    <CurrencyInput
                                        id="username"
                                        name="username"
                                        placeholder="Please enter a number"
                                        // defaultValue={1000}
                                        value={this.state.balance} 
                                        onFocus={() => this.setState({ balance: 0 })}
                                        // onChange={(e: any) => this.setState({ balance: e.target.value })} 
                                        decimalsLimit={0}
                                        onValueChange={(value, name) =>  this.setState({ balance: Number(value)})}
                                    />

                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-3"></label>
                                <div className="col-sm-9">
                                    <button type="button" className="btn btn-default btn-sm" onClick={() => this.setState({ balance:Number(this.state.balance) + Number(10000)} )}>10,000</button>
                                    <button type="button" className="btn btn-default btn-sm" onClick={() => this.setState({ balance:Number(this.state.balance) + Number(100000)} )}>100,000</button>
                                    <button type="button" className="btn btn-default btn-sm" onClick={() => this.setState({ balance:Number(this.state.balance) + Number(500000)} )}>500,000</button>
                                    <button type="button" className="btn btn-default btn-sm" onClick={() => this.setState({ balance:Number(this.state.balance) + Number(1000000)} )}>1,000,000</button>
                                    <button type="button" className="btn btn-default btn-sm" onClick={() => this.setState({ balance:Number(this.state.balance) + Number(5000000)} )}>5,000,000</button>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default btn-sm" onClick={() => popClonse()}>취소</button>
                            <button type="button" className="btn btn-primary btn-sm" id="sendMoneyBtn" onClick={() => {

                                this.balanceService
                                    .agent_deposit_to_agent(
                                        { balance: this.state.balance, takeId: info.agent, code: this.state.code })
                                    .then((s: any) => {
                                        console.log(s);
                                        if (s.status === "success") {
                                            confirmAlert({
                                                title: "지급",
                                                message: "정상적으로 처리되었습니다.",
                                                buttons: [
                                                    {
                                                        label: "예",
                                                        onClick: () => {
                                                            window.location.reload()
                                                        },
                                                    },
                                                ],
                                            });
                                        } else if (s.status === "balance") {
                                            confirmAlert({
                                                title: "지급",
                                                message: "보유중인 벨런스를 확인해주세요.",
                                                buttons: [
                                                    {
                                                        label: "예",
                                                        onClick: () => {
                                                            this.setState({ code: UUID() })

                                                        },
                                                    },
                                                ],
                                            });
                                        } else {
                                            confirmAlert({
                                                title: "지급",
                                                message:
                                                    "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                                                buttons: [
                                                    {
                                                        label: "예",
                                                        onClick: () => {
                                                            this.setState({ code: UUID() })

                                                        },
                                                    },
                                                ],
                                            });
                                        }
                                    });
                            }
                            }>충전</button>
                        </div>
                    </div >

                )
            }
        });
    };



    //회수 팝업 호출
    RenderWithdrawPopup = () => {
        if (this.state.popupState !== popupView.withdraw) return


        const info = this.state.userInfo

        confirmAlert({
            customUI: ({ onClose }) => {

                const popClonse = () => {
                    this.setState({ popupState: popupView.none })
                    onClose()
                }

                return (
                    <div className="modal-content" >
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => popClonse()}>×</button>
                            <h4 className="modal-title" id="sendMoneyModalLabel">회수</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label className="control-label col-sm-3">에이전트</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control input-sm" readOnly value={`${info.agent} (${info.nick}) `} />
                                </div>
                            </div>

                            <br/>

                            <div className="form-group">
                                <label className="control-label col-sm-3">보유머니</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control input-sm number" readOnly value={`${numTomoeny(info.balance)}`} />
                                </div>
                            </div>
                            <br/>

                            <div className="form-group">
                                <label className="control-label col-sm-3">금액(머니)</label>
                                <div className="col-sm-9">

                                    <input type="text" className="form-control input-sm number"

                                        onFocus={() => this.setState({ balance: 0 })}

                                        onChange={(e: any) => {
                                            this.setState({
                                                balance: e.target.value,
                                            })
                                        }}
                                        value={this.state.balance}

                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label col-sm-3"></label>
                                <div className="col-sm-9">
                                    <button type="button" className="btn btn-default btn-sm" onClick={() => this.setState({ balance:  this.state.balance + Number(10000) })}>10,000</button>
                                    <button type="button" className="btn btn-default btn-sm" onClick={() => this.setState({ balance:  this.state.balance + Number(100000) })}>100,000</button>
                                    <button type="button" className="btn btn-default btn-sm" onClick={() => this.setState({ balance:  this.state.balance + Number(500000) })}>500,000</button>
                                    <button type="button" className="btn btn-default btn-sm" onClick={() => this.setState({ balance:  this.state.balance + Number(1000000) })}>1,000,000</button>
                                    <button type="button" className="btn btn-default btn-sm" onClick={() => this.setState({ balance:  this.state.balance + Number(5000000) })}>5,000,000</button>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default btn-sm" onClick={() => popClonse()}>취소</button>
                            <button type="button" className="btn btn-primary btn-sm" id="sendMoneyBtn" onClick={() => {
                                if (Number(this.state.balance) < 0) {
                                    confirmAlert({
                                        title: "회수",
                                        message: "마이너스는 입력할수 없습니다.",
                                        buttons: [
                                            {
                                                label: "예",
                                                onClick: () => {
                                                    window.location.reload()

                                                },
                                            },
                                        ],
                                    });
                                    return
                                }

                                confirmAlert({
                                    title: "회수",
                                    message: "해당 벨런스를 에이전트에게 회수하시겠습니까?.",
                                    buttons: [
                                        {
                                            label: "예",
                                            onClick: () => {
                                                this.balanceService
                                                    .agent_withdrawt_to_agent({ balance: this.state.balance, takeId: info.agent, code: this.state.code })
                                                    .then((s: any) => {
                                                        if (s.status === "success") {
                                                            confirmAlert({
                                                                title: "회수",
                                                                message: "정상적으로 처리되었습니다.",
                                                                buttons: [
                                                                    {
                                                                        label: "예",
                                                                        onClick: () => {
                                                                            this.setState({ code: UUID() })

                                                                            window.location.reload()
                                                                        },
                                                                    },
                                                                ],
                                                            });
                                                        } else if (s.status === "balance") {
                                                            confirmAlert({
                                                                title: "회수",
                                                                message: "에이전트 벨런스를 확인해주세요.",
                                                                buttons: [
                                                                    {
                                                                        label: "예",
                                                                        onClick: () => {
                                                                            this.setState({ code: UUID() })

                                                                        },
                                                                    },
                                                                ],
                                                            });
                                                        } else {
                                                            confirmAlert({
                                                                title: "회수",
                                                                message:
                                                                    "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                                                                buttons: [
                                                                    {
                                                                        label: "예",
                                                                        onClick: () => {
                                                                            this.setState({ code: UUID() })

                                                                        },
                                                                    },
                                                                ],
                                                            });
                                                        }
                                                    });
                                            },
                                        },
                                        {
                                            label: "아니오",
                                            onClick: () => { },
                                        },
                                    ],
                                });

                            }
                            }>회수</button>
                        </div>
                    </div >
                )
            }
        });
    };



    //메니저 조회
    GetPassword = async (agentId) => {
        const response = await this.agentService.admin_get_agent_password({ agentId: agentId })
        if (response.status === 'success') {
            confirmAlert({
                title: "에이전트",
                message: `${agentId} 의 비밀번호는 ${response.pass} 입니다`,
                buttons: [
                    {
                        label: "확인",
                        onClick: () => { },
                    },
                ],
            });

            return
        } else {
            confirmAlert({
                title: "에이전트",
                message: `찾을수 없는 에이전트 입니다.`,
                buttons: [
                    {
                        label: "확인",
                        onClick: () => { },
                    },
                ],
            });
        }
    };



    //메니저 팝업 호출

    RenderNewAgent = (parent) => {


        confirmAlert({
            customUI: ({ onClose }) => {

                const popClonse = () => {
                    onClose()
                }
                const { parentAgent, parentNick } = parent


                return (
                    <div className="modal-content" style={{ width: '500px' }} >
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => popClonse()}>×</button>
                        </div>
                        <div style={{ padding: '20px' }}>
                            <h4 className="modal-title">상위 관리자 {parentAgent} ({parentNick})</h4>
                        </div>
                        <div className="modal-body">

                            <ShareAgentNewPopup
                                parentId={parentAgent}
                                newAgent={Object.assign(this.props.newAgent, { parentId: parentAgent })}
                                management={this.props.management}
                                saveNewAgent={this.props.saveNewAgent}></ShareAgentNewPopup>

                        </div>
                    </div >

                )
            }
        });
    }





    makeTable = (rowData) => {
        const trees: any = []
        const tree = ConvertTreeToAgents(rowData.agent_tree)
        const tree_ko = ConvertTreeToAgents(rowData.agent_tree_ko)


        for (let ii = 0; ii < tree.length; ii++) {
            trees.push({ level: ii + 1, agent: tree[ii], nick: tree_ko[ii] })
        }


        return trees
    }

    render() {
        const rowUserState = (data, options) => {

            if (data === options.rowData.lastdate && IsConnection(this.props.serverTime, options.rowData.lastdate)) return 'connectionUser'

            return ''
        }

        return (
            <>
                <DataTable
                    //
                    value={this.props.agents}
                    paginatorPosition="both"
                    rows={99999}
                    sortOrder={-1}
                    scrollable
                    scrollHeight="800px"
                >

                    <Column
                        field="agent"
                        header="에이전트"
                        headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                        bodyStyle={{ textAlign: "center", }}
                        body={(rowData) => {
                            const tooltipId = `tooltip-button_${rowData.agent}`

                            return (
                                <span className={tooltipId} onClick={() => OpenAgentDetail(rowData.agent)}>{rowData.agent}</span>
                            );
                        }}
                    />
                    <Column
                        field="nick"
                        header="닉네임"
                        headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                        bodyStyle={{ textAlign: "center", }}

                    />
                    <Column
                        field="phone"
                        header="전화번호"

                        headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                        bodyStyle={{ textAlign: "center", }}
                    />

                    <Column
                        field="balance"
                        header="게임머니"
                        headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                        bodyStyle={{ textAlign: "center", }}

                        body={(rowData) => {
                            return (
                                <div style={{ textAlign: "center", width: '100%' }}>
                                    <span>{numTomoeny(rowData.balance)}</span>
                                </div>
                            );
                        }}
                    />

                    <Column
                        field="rolling"
                        header="롤링"
                        headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                        bodyStyle={{ textAlign: "center", }}

                        body={(rowData) => {
                            return (
                                <div style={{ textAlign: "center", width: '100%' }}>
                                    <span>{numTomoeny(rowData.rolling)}</span>
                                </div>
                            );
                        }}
                    />


                    <Column
                        field="rolling"
                        header="하부 합산"
                        headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                        body={(rowData) => {
                            return (
                                <div style={{ textAlign: "center", width: '100%' }}>
                                    <span>{numTomoeny(rowData.balanceSum)}</span>
                                </div>
                            );
                        }}
                    />


                    <Column
                        header="머니 충전"
                        headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                        body={(rowData) => {
                            return (
                                <Button
                                    type="button"
                                    label={"충전"}
                                    onClick={(e: any) => this.setState({ popupState: popupView.charge, userInfo: rowData })}
                                    className="btn btn-primary btn-xs"
                                />
                            );
                        }}
                    />
                    <Column

                        headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                        header="머니 회수"
                        body={(rowData) => {
                            return (
                                <Button
                                    type="button"
                                    label={"회수"}
                                    className="btn btn-primary btn-xs"
                                    onClick={(e: any) => this.setState({ popupState: popupView.withdraw, userInfo: rowData })}
                                />
                            );
                        }}
                    />
                    <Column
                        header="에이전트 수"
                        headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                        body={(rowData) => {
                            return (
                                <div style={{ textAlign: "center", width: '100%' }}>
                                    <span>{numTomoeny(rowData.childCount)}</span>

                                </div>
                            );
                        }}
                    />
                    <Column
                        header="회원수"
                        headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}

                        body={(rowData) => {
                            return (
                                <div style={{ textAlign: "center", width: '100%' }}>
                                    <span>{numTomoeny(rowData.userCount)}</span>
                                </div>
                            );
                        }}
                    />
                    <Column
                        header="생성일"

                        headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                        body={(rowData) => {
                            return (
                                <div>
                                    <span>{ConvertDate(rowData.regdate)}</span>
                                </div>
                            );
                        }}
                    />
                    <Column

                        header="매니저 조회"
                        headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                        body={(rowData) => {

                            return (
                                <Button
                                    type="button"
                                    label={"조회"}
                                    onClick={(e: any) => {
                                        if (rowData.level === 1) {
                                            window.location.hash = `/agentMngManager/tree/${rowData.agent}`
                                        }
                                        else if (rowData.level === 2) {
                                            window.location.hash = `/agentMngAgent/tree/${rowData.agent}`
                                        }
                                        else if (rowData.level === 3) {
                                            window.location.hash = `/agentMngStore/tree/:${rowData.agent}`
                                        }
                                    }}
                                    aria-haspopup
                                    aria-controls="overlay_panel"
                                    className="btn btn-primary btn-xs"
                                />
                            );
                        }}
                    />
                    <Column

                        header="매니저 추가"
                        headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                        body={(rowData) => {
                            if (rowData.level >= 4) {
                                return '-'
                            }

                            return (
                                <Button
                                    type="button"
                                    label={"추가"}
                                    onClick={(e: any) => this.RenderNewAgent({ parentAgent: rowData.agent, parentNick: rowData.nick })}
                                    aria-haspopup
                                    aria-controls="overlay_panel"
                                    className="btn btn-primary btn-xs"
                                />
                            );
                        }}
                    />
                    <Column

                        header="회원 조회"
                        headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                        body={(rowData) => {
                            return (
                                <Button
                                    type="button"
                                    label={"회원 조회"}
                                    onClick={(e: any) => window.location.hash = `userMngUserListFind/agent/${rowData.agent}`}
                                    aria-haspopup
                                    //
                                    aria-controls="overlay_panel"
                                    className="btn btn-primary btn-xs"
                                />
                            );
                        }}
                    />
                    <Column

                        header="정보변경"
                        headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                        body={(rowData) => {
                            return (
                                <Button
                                    type="button"
                                    label={"정보변경"}
                                    onClick={() => OpenAgentDetail(rowData.agent)}
                                    aria-haspopup
                                    //
                                    aria-controls="overlay_panel"
                                    className="btn btn-success btn-xs"
                                />
                            );
                        }}
                    />


                </DataTable>
                {this.RenderChargePopup()}
                {this.RenderWithdrawPopup()}
            </>
        )
    }
}