import axios from "axios";

import { AxiosMag } from "./axios.service";

export class SlotService {
  admin_get_slot_bets = async (
    skip: any,
    begin: any,
    end: any,
    findText: any,
    searchText: any,
    sort: any,
    count: any
  ) => {
    return await AxiosMag.SendMessageByPost("/slot/admin_get_slot_bets_fix_count", {
      begin,
      skip,
      end,
      findText,
      searchText,
      sort,
      count
    });
  };

  admin_get_slot_search = async (
    page: any,
    begin: any,
    end: any,
    findText: any,
    searchText: any
  ) => {
    return await AxiosMag.SendMessageByPost("/slot/admin_get_slot_search", {
      skip: page,
      begin: begin,
      end: end,
      findText: findText,
      searchText: searchText,
    });
  };


  admin_slot_bet_detail = async (
    code: any) => {
    return await AxiosMag.SendMessageByPost("/slot/admin_slot_bet_detail", {
      code
    });
  };


}
