import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { AgentService } from "../service/agent.service";

import { CDropdown } from "./custom/cdropdown";
import { CInputText } from "./custom/cinputtext";


import {
  GetNow,
  ConvertCalendar,
  numTomoeny,
  ConvertDate,
} from "../utility/help";
import { SubPaging } from "../share/sub-paging";

interface State {
  sortType: string;
  sort: number;
  search: string;
  find: string;

  findDay: any;
  begin: any;
  end: any;

  users: any[];
  dropdownItem: string;
  text: string;

  sumBalance: number;
  sumRolling: number;
  sumPoint: number;
}

export class UserMngCurrentUserList extends Component<any, State> {
  userService = new UserService();
  fileService = new FileService();

  menu: any;

  constructor(props: any) {
    super(props);

    let begin = GetNow();
    let end = GetNow();

    this.state = {
      sortType: "regdate",
      sort: 1,
      search: "",
      find: "",

      findDay: "5",
      begin: begin,
      end: end,

      users: [],
      dropdownItem: "id",
      text: "",

      sumBalance: 0,
      sumRolling: 0,
      sumPoint: 0
    };

    this.getCurrentUserList({ find: this.state.find, search: this.state.search });
  }

  selectData = [
    { label: "아이디", value: "id" },
    { label: "닉네임", value: "nick" },
    { label: "에이전트", value: "agentid" },
  ];

  componentDidMount() { }


  //현재사용자리스트 조회
  getCurrentUserList = (params) => {
    this.userService
      .get_user_current(params)
      .then((data) => {


        const sumBalance = data.users.reduce(function add(sum, user) {
          if (user.inBalance == null) {
            return sum + user.balance
          }

          return sum + user.balance + user.inBalance;
        }, 0);


        const sumRolling = data.users.reduce(function add(sum, user) {
          return sum + user.rolling;
        }, 0);

        const sumPoint = data.users.reduce(function add(sum, user) {
          return sum + user.point;
        }, 0);

        this.setState({
          users: data.users,
          sumBalance,
          sumRolling,
          sumPoint
        });


      });
  };


  // 재입력
  ReloadPage = () => {
    console.log("ReloadPage : 재입력");
  };

  // 회원리스트 화면으로 이동
  MoveUserList = () => {
    console.log("MoveUserList : 회원리스트 화면으로 이동");
  };


  handleBalanceUpdate = async (id: string) => {
    let user = this.state.users.find((data) => data.userId === id);
    if (user != null) {
      user.refresh = true;
    }



    const respone = await this.userService.get_in_game_balance(id)
    // .then((s: any) => {
    if (respone.status === "success") {
      let user = this.state.users.find((data: any) => data.userId === respone.id);
      if (user != null) {
        user.balance = Number(respone.balance);
        user.in_balance = 0
        user.refresh = false;
        this.setState({ users: this.state.users });
      }
    } else {
      let user = this.state.users.find((data: any) => data.userId === respone.id);
      if (user != null) {
        user.update = null;
        this.setState({ users: this.state.users })

      }
    }
    // });

  };


  render() {
    return (
      <>
        <section className="content-header">
          <h1>현재사용자리스트</h1>
        </section>

        <section className="content">
          <div className="form-inline">
            <div className="form-group">
              <CDropdown
                options={this.selectData}
                value={this.state.search}
                onChange={(event) => {
                  this.setState({ search: event.value })
                }}
              />

            </div>
            <div className="form-group">
              <CInputText
                value={this.state.find}
                onChange={(e: any) => this.setState({ find: e.target.value })}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    this.getCurrentUserList({ find: this.state.find, search: this.state.search });
                  }
                }}
              />

            </div>
            <div className="form-group">
              <Button
                label="조회"
                onClick={() => {
                  this.getCurrentUserList({ find: this.state.find, search: this.state.search });
                }}
                className="btn btn-primary btn-sm"
              />
            </div>
            <div className="form-group">
              <Button
                label="재입력"
                onClick={() => this.setState({ find: '', search: '' })}
                className="btn btn-default btn-sm"
              />
            </div>
          </div>
          <div className="form-group">
            <span id="shop_hierarchy">
              * 접속유저 : {numTomoeny(this.state.users.length)} / 보유머니 합계 : {numTomoeny(this.state.sumBalance)}
              / 롤링 합계 : {numTomoeny(this.state.sumRolling)} / 금고 합계 : {numTomoeny(this.state.sumPoint)}
            </span>
          </div>

          {/* 메인컨텐츠 */}
          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
            <DataTable

              value={this.state.users}
              sortField="agentCode"
              sortOrder={1}
              rowGroupMode="rowspan"
              style={{ marginTop: "30px", border: "1px solid #c8c8c8" }}
              scrollable
              scrollHeight="800px"
            >
              <Column
                header="아이디"
                headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                bodyStyle={{ textAlign: "center", }}
                body={(rowData) => {
                  return (
                    <div style={{ color: `${rowData.color}` }}>{rowData.userId}</div>
                  );
                }}
              />
              <Column
                field="nick"
                headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                bodyStyle={{ textAlign: "center", }}
                header="닉네임"
              />
              <Column
                field="agentId"
                headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                bodyStyle={{ textAlign: "center", }}
                header="에이전트"
              />
              <Column
                header="보유머니"
                headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                bodyStyle={{ textAlign: "center", }}
                body={(rowData) => {
                  // if (rowData.inBalance == null) {
                  //   return numTomoeny(rowData.balance)
                  // }

                  return (
                    <div>
                      {numTomoeny(rowData.balance)}
                      {
                        rowData.refresh == true ? (
                          <i className="pi pi-spin pi-spinner" style={{ 'fontSize': '2em' }} ></i>

                        ) : (
                          <i
                            className="pi pi-refresh" style={{ 'fontSize': '18px' }}
                            onClick={() => {
                              let user = this.state.users.find((data) => data.id == rowData.id);
                              if (user != null) {
                                user.refresh = true;
                              }
                              this.handleBalanceUpdate(rowData.userId)
                              this.setState({ users: this.state.users })
                            }}
                          ></i>
                        )
                      }
                    </div>
                  );
                }}
              />
              <Column
                header="금고"
                headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                bodyStyle={{ textAlign: "center", }}
                body={(rowData) => {
                  return (
                    <div>{numTomoeny(rowData.point)}</div>
                  );
                }}
              />

              <Column
                header="롤링"
                headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                bodyStyle={{ textAlign: "center", }}
                body={(rowData) => {
                  return (
                    <div>{numTomoeny(rowData.rolling)}</div>
                  );
                }}
              />

              {/* <Column
                field="game"
                header="접속게임"
              /> */}
              <Column
                field="ip"
                headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                bodyStyle={{ textAlign: "center", }}
                header="접속IP"
              />
              <Column
                header="접속시각"
                headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                bodyStyle={{ textAlign: "center", }}
                body={(rowData) => {
                  return (
                    <span>{ConvertDate(rowData.connectionDate)}</span>
                  );
                }}
              />
              <Column
                header="마지막요청시각"
                headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                bodyStyle={{ textAlign: "center", }}
                body={(rowData) => {
                  return (
                    <span>{ConvertDate(rowData.pingDate)}</span>
                  );
                }}
              />


              <Column
                headerStyle={{ textAlign: "center", minWidth: "200px", height: "40px", }}
                bodyStyle={{ textAlign: "center", minWidth: "200px", }}
                field="domain"
                header="접속도메인"
              />
              <Column
                field=""
                bodyStyle={{ textAlign: "center", }}
                headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                header="OS"
                body={(rowData) => {
                  // console.log(rowData.os)
                  if (rowData.os == null) return <span></span>

                  return (
                    <span>{rowData.os.name}</span>
                  );
                }}
              />
              <Column
                header="브라우저"
                bodyStyle={{ textAlign: "center", }}
                headerStyle={{ textAlign: "center", minWidth: "100px", height: "40px", }}
                body={(rowData) => {
                  if (rowData.browser == null) return <span></span>

                  return (
                    <span>{rowData.browser.name}</span>
                  );
                }}
              />
            </DataTable>
          </div>

        </section >
      </>
    );
  }
}
