import React, { Component, useState, useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";


import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { Checkbox } from "primereact/checkbox";
import { confirmAlert } from "react-confirm-alert";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { CDropdown } from "./custom/cdropdown";
import { CInputText } from "./custom/cinputtext";
import { CButton } from "./custom/cbutton";



import { BalanceService } from "../service/balance.service";
import { AgentService } from "../service/agent.service";

import { InputTextarea } from 'primereact/inputtextarea';

import { ConvertDate, convertToCSV, GetNow, numTomoeny, GetCalcuateOptionType } from "../utility/help";



interface Props {
    parentId: any
    newAgent: any

    management: any

    saveNewAgent: (agent: any) => any;

}


interface State {
}

export class ShareAgentNewPopup extends Component<Props, State> {
    agentService: AgentService = new AgentService()

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
    }


    render() {

        const agent = this.props.newAgent

        return (

            <div
                id="detail_agent"
                className="fade in"
                tabIndex={-1}
                role="dialog"
                style={{
                }}
            >
                <div className="modal-content">
                    {/* <div className="modal-header">
                        <h4 className="modal-title">매니저 디테일</h4>
                    </div> */}

                    <div className="modal-body" style={{ padding: isMobile ? '10%' : '0px' }}>
                        <div id="shopForm" className="form-horizontal">
                            <div id="tabs" className="nav-tabs-custom">
                                <ul className="nav nav-tabs">
                                    <li className="active">
                                        <a data-toggle="tab" >
                                            기본 정보
                                        </a>
                                    </li>

                                </ul>
                                <div className="tab-content">

                                    <div className="panel-body">


                                        <div className="tab-pane active">


                                            <div className="form-group ">
                                                <div className="input-group">
                                                    <div className="input-group-addon">에이전트 ID</div>
                                                    <InputText
                                                        value={agent.agent}

                                                        className="form-control"
                                                        maxLength={20}
                                                        onChange={(e: any) => {
                                                            agent.agent = e.target.value.trim()
                                                            this.setState({ agent: agent })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group ">
                                                <div className="input-group">
                                                    <div className="input-group-addon">닉네임</div>
                                                    <InputText
                                                        value={agent.nick}

                                                        className="form-control"
                                                        maxLength={20}
                                                        onChange={(e: any) => {
                                                            agent.nick = e.target.value.trim()
                                                            this.setState({ agent: agent })
                                                        }}
                                                    />
                                                </div>
                                            </div>



                                            <div className="form-group ">
                                                <div className="input-group">
                                                    <div className="input-group-addon">비밀번호</div>
                                                    <InputText
                                                        value={agent.pass}

                                                        className="form-control"
                                                        maxLength={20}
                                                        onChange={(e: any) => {
                                                            agent.pass = e.target.value
                                                            this.setState({ agent: agent })
                                                        }}
                                                    />
                                                </div>
                                            </div>



                                            <div className="form-group ">
                                                <div className="input-group">
                                                    <div className="input-group-addon">전화번호</div>
                                                    <InputText
                                                        value={agent.phone}
                                                        className="form-control"
                                                        maxLength={20}
                                                        onChange={(e: any) => {
                                                            agent.phone = e.target.value
                                                            this.setState({ agent: agent })
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group ">
                                                <div className="input-group">
                                                    <div className="input-group-addon">은행</div>
                                                    <InputText
                                                        // options={this.selectBankData}
                                                        value={agent.bankname}
                                                        onChange={(e: any) => {
                                                            agent.bankname = e.target.value
                                                            this.setState({ agent: agent })
                                                        }}
                                                        // autoWidth={false}
                                                        className="form-control input-sm"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group ">
                                                <div className="input-group">
                                                    <div className="input-group-addon">예금주</div>
                                                    <InputText
                                                        value={agent.bankowner}
                                                        onChange={(e: any) => {
                                                            agent.bankowner = e.target.value
                                                            this.setState({ agent: agent })
                                                        }}

                                                        className="form-control"
                                                        maxLength={20}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group ">
                                                <div className="input-group">
                                                    <div className="input-group-addon">계좌번호</div>
                                                    <InputText
                                                        value={agent.banknum}
                                                        onChange={(e: any) => {
                                                            agent.banknum = e.target.value
                                                            this.setState({ agent: agent })
                                                        }}
                                                        className="form-control"
                                                        maxLength={100}
                                                    />
                                                </div>
                                            </div>



                                            {
                                                this.props.management != null && this.props.management.slot === 'y' && <>
                                                    <div className="form-group ">
                                                        <div className="input-group">
                                                            <div className="input-group-addon">슬롯 롤링</div>
                                                            <InputText
                                                                className="form-control"
                                                                placeholder="슬롯"
                                                                value={agent.slot_percent || ""}
                                                                onChange={(even: any) => {
                                                                    agent.slot_percent = even.target.value;
                                                                    this.setState({ agent: agent });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {/* 
                                            {
                                                this.props.management != null && this.props.management.slotLosing === 'y' && <>
                                                    <div className="form-group ">
                                                        <div className="input-group">
                                                            <div className="input-group-addon">슬롯 루징</div>
                                                            <InputText
                                                                className="form-control"
                                                                placeholder="슬롯 루징"
                                                                value={agent.agent_slot_losing || ""}
                                                                onChange={(even: any) => {
                                                                    agent.agent_slot_losing = even.target.value;
                                                                    this.setState({ agent: agent });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            } */}

                                            {
                                                this.props.management != null && this.props.management.casino === 'y' && <>
                                                    <div className="form-group ">
                                                        <div className="input-group">
                                                            <div className="input-group-addon">카지노 롤링</div>

                                                            <InputText
                                                                className="form-control"
                                                                placeholder="카지노 롤링"
                                                                value={agent.casino_percent || ""}
                                                                onChange={(even: any) => {
                                                                    agent.casino_percent = even.target.value;
                                                                    this.setState({ agent: agent });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {/* {
                                                this.props.management != null && this.props.management.casinoLosing === 'y' && <>
                                                    <div className="form-group ">
                                                        <div className="input-group">
                                                            <div className="input-group-addon">카지노 루징</div>
                                                        </div>
                                                        <InputText
                                                            className="form-control"
                                                            placeholder="카지노 루징"
                                                            value={agent.casino_losing_percent || ""}
                                                            onChange={(even: any) => {
                                                                agent.casino_losing_percent = even.target.value;
                                                                this.setState({ agent: agent });
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            } */}

                                            {
                                                this.props.management != null && this.props.management.mini === 'y' && <>
                                                    <div className="form-group ">
                                                        <div className="input-group">
                                                            <div className="input-group-addon">파워볼 단폴</div>
                                                            <InputText
                                                                className="form-control"
                                                                placeholder="파워볼 롤링"
                                                                value={agent.mini_nomal || ""}
                                                                onChange={(even: any) => {
                                                                    agent.mini_nomal = even.target.value;
                                                                    this.setState({ agent: agent });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                this.props.management != null && this.props.management.mini === 'y' && <>
                                                    <div className="form-group ">
                                                        <div className="input-group">
                                                            <div className="input-group-addon">파워볼 멀티</div>

                                                            <InputText
                                                                className="form-control"
                                                                placeholder="파워볼 롤링"
                                                                value={agent.mini_double || ""}
                                                                onChange={(even: any) => {
                                                                    agent.mini_double = even.target.value;
                                                                    this.setState({ agent: agent });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            <div className="form-group ">
                                                <div className="input-group">

                                                </div>
                                            </div>


                                            <div className="form-group ">
                                                <div className="input-group">

                                                </div>
                                            </div>

                                            <div className="form-group ">
                                                <div className="input-group">

                                                    <Button
                                                        className="form-control p-button-success"


                                                        label={'저장하기'}
                                                        onClick={() => { this.props.saveNewAgent(Object.assign(agent, { parentId: this.props.parentId })) }}
                                                    />
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        );
    }
}
