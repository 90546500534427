import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { AgentService } from "../service/agent.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { TreeTable } from 'primereact/treetable';

import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { SubPaging } from "../share/sub-paging";
import { CCalendarTime } from "./custom/ccalendartime";
import { ThreeDRotationSharp } from "@material-ui/icons";

import { numTomoeny, balanceColor, OpenAgentCal, OpenAgentDetail, GetToday, ConvertCalendar, GetYesterDay, ConvertCalculate, CalculateTotalBalance, ConvertTreeToAgents } from "../utility/help";
import { TooltipCalculate } from "../utility/optionpack";

import { ShareTooltip } from "./share.tooltip";


interface State {
    begin: any;
    end: any;

    cals: any[];

    benefitInfo: any;

    viewType: string;
}

export class CalculateUser extends Component<any, State> {
    agentService = new AgentService();

    menu: any;

    constructor(props: any) {
        super(props);

        let today = GetToday();

        this.state = {
            viewType: 'all',
            begin: today.begin,
            end: today.end,

            benefitInfo: {
                benefitSlotRolling: 0,
                benefitCasinoRolling: 0
            },
            cals: [],
        };

        this.sizeTemplate = this.sizeTemplate.bind(this);
        this.rowClassName = this.rowClassName.bind(this);


        this.handlegetTopAgentCalculate(today.begin, today.end);
    }




    handlegetTopAgentCalculate(begin: any, end: any) {
        this.agentService.get_get_user_cal(begin.toLocaleString('sv-SE'), end.toLocaleString('sv-SE')).then((data) => {
            if (data.status == "success") {
                this.setState({ cals: data.cal, benefitInfo: data.benefitInfo })

            }
        });
    }

    // handlegetTopAgentCalculate(begin: any, end: any) {
    //     this.adminService.admin_get_main_agent(begin, end).then((data) => {
    //         if (data.status == "success") {
    //             this.setState({ agentInfo: data.agentCombine });
    //         }
    //     });
    // }

    componentDidMount() {


    }

    // 재입력
    ReloadPage = () => {
        console.log("ReloadPage : 재입력");
    };

    // 정산요청
    RequestCalculate = () => {
        console.log("RequestCalculate : 정산요청");
    };


    // rowClassName(node) {



    //   return { 'p-highlight': (node.children && node.children.length === 3) };
    // }

    rowClassName(node) {

        if (node.level === 2) return { 'p-tag-level2': true }
        if (node.level === 3) return { 'p-tag-level3': true }
        if (node.level === 4) return { 'p-tag-level4': true }

        return { '': true };
    }

    sizeTemplate(node) {

        // console.log(node)
        // let size = node.data.size;
        // let fontWeight = parseInt(size, 10) > 75 ? 'bold' : 'normal';

        // return <span style={{ fontWeight: fontWeight }}>{size}</span>;
        return <span style={{}}>{ }</span>;
    }




    render() {
        return (
            <>
                <section className="content-header">
                    <h1>회원 정산</h1>
                </section>

                <section className="content">
                    <ul className="nav nav-tabs" style={{ marginBottom: "20px" }}>
                        <li className={this.state.viewType === "all" ? "active" : ""}>
                            <a
                                onClick={() => this.setState({ viewType: "all" })}
                            >
                                모두
                            </a>
                        </li>
                        <li className={this.state.viewType === "casino" ? "active" : ""}>
                            <a
                                onClick={() => this.setState({ viewType: "casino" })}
                            >
                                카지노
                            </a>
                        </li>
                        <li className={this.state.viewType === "slot" ? "active" : ""}>
                            <a
                                onClick={() => this.setState({ viewType: "slot" })}
                            >
                                슬롯
                            </a>
                        </li>

                    </ul>
                    <div
                        id="searchForm"
                        className="form-inline"
                        role="form"
                    >
                        <div className="form-group">
                            <CCalendarTime
                                placeholder="시작일"
                                value={this.state.begin}
                                onChange={(e) => this.setState({ begin: e })}
                            />
                        </div>

                        <div className="form-group">
                            <CCalendarTime
                                placeholder="종료일"
                                value={this.state.end}
                                onChange={(e) => this.setState({ end: e })}
                            />
                        </div>
                        <div className="form-group">
                            <Button
                                label="조회"
                                onClick={() => {
                                    this.handlegetTopAgentCalculate(this.state.begin, this.state.end);

                                }}
                                className="btn btn-primary btn-sm"
                            />
                        </div>
                        <div className="form-group">
                            <Button
                                label="재입력"
                                onClick={() => { }}
                                className="btn btn-default btn-sm"
                            />
                        </div>
                    </div>

                    <div
                        className="table-responsive list_view"
                        style={{ marginTop: "10px", }}
                    >
                        <DataTable

                            sortOrder={1}
                            rowGroupMode="rowspan"
                            value={this.state.cals}
                            scrollable
                            scrollHeight="80vh"
                            virtualScrollerOptions={{ itemSize: 46 }}
                        >

                            <Column
                                header={<div><div>아이디</div></div>}
                                body={(rowData: any, column: any) => {
                                    return <div>{rowData.userId}</div>
                                }}
                            />


                            <Column
                                header={<div>닉네임</div>}
                                body={(rowData: any, column: any) => {

                                    return <div>
                                        <div style={{ color: 'blue' }}>{rowData.nick}</div>
                                    </div>;
                                }}
                            />


                            <Column
                                header={<div><div>에이전트</div></div>}
                                body={(rowData: any, column: any) => {
                                    return <div>{rowData.agentId}</div>
                                }}
                            />

                            <Column
                                header={<div>배팅 합계</div>}
                                body={(rowData: any, column: any) => {
                                    if (this.state.viewType === "casino") { return numTomoeny(rowData.totalCasinoBet) }
                                    if (this.state.viewType === "slot") { return numTomoeny(rowData.totalSlotBet) }
                                    return <div >{numTomoeny(rowData.totalSlotBet + rowData.totalCasinoBet)}</div>
                                }}
                            />






                            <Column
                                header={<div>정산 딜비</div>}
                                body={(rowData: any, column: any) => {
                                    if (this.state.viewType === "casino") { return numTomoeny((rowData.totalCasinoBet * this.state.benefitInfo.benefitCasinoRolling) / 100) }
                                    if (this.state.viewType === "slot") { return numTomoeny((rowData.totalSlotBet * this.state.benefitInfo.benefitSlotRolling) / 100) }
                                    return <div >{numTomoeny(((rowData.totalCasinoBet * this.state.benefitInfo.benefitCasinoRolling) / 100) + ((rowData.totalSlotBet * this.state.benefitInfo.benefitSlotRolling) / 100))}</div>
                                }}
                            />

                            <Column
                                header={<div>나의정산금</div>}
                                body={(rowData: any, column: any) => {

                                    const cBalance = (((rowData.totalCasinoBet * this.state.benefitInfo.benefitCasinoRolling) / 100) - rowData.totalCasinoRollingBalance)
                                    const sBalance = (((rowData.totalSlotBet * this.state.benefitInfo.benefitSlotRolling) / 100) - rowData.totalSlotRollingBalance)
                                    if (this.state.viewType === "casino") { return numTomoeny(cBalance) }
                                    if (this.state.viewType === "slot") { return numTomoeny(sBalance) }
                                    return <div >{numTomoeny(cBalance + sBalance)}</div>

                                }}
                            />




                            <Column
                                header={<div>하부정산금</div>}
                                body={(rowData: any, column: any) => {
                                    if (this.state.viewType === "casino") { return numTomoeny(rowData.totalCasinoRollingBalance) }
                                    if (this.state.viewType === "slot") { return numTomoeny(rowData.totalSlotRollingBalance) }
                                    return <div >{numTomoeny(rowData.totalCasinoRollingBalance + rowData.totalSlotRollingBalance)}</div>
                                }}
                            />



                        </DataTable>

                    </div>


                </section >
            </>
        );
    }
}
