import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { BalanceService } from "../service/balance.service";

import {
    ConvertDate, convertToCSV, GetNow, numTomoeny, IsConnection,
    OpenUserDetail,
    GetAgentTreeByLevel,
    uuidv4
} from "../utility/help";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SubPaging } from "../share/sub-paging";
import { ShareUserMenu } from "./share.user.menu";

import { confirmAlert } from "react-confirm-alert";
import { ifError } from "assert";

enum popupView {
    none = "none",
    charge = "charge",
    deposit = "deposit",
    withdraw = "withdraw",

    comp_deposit = "comp_deposit",
    comp_withdraw = "comp_withdraw",


    edit_user = "edit_user",

    user_pass = "user_pass",
    user_phone = "user_phone",

    note = "note",
}


interface State {
    sortType: string;
    sort: number;
    page: number;
    maxCount: number;

    users: any[];
    seach: string;
    uuid: string;

    userMaxBenefits: any
    serverTime: Date

    cloneParams: any

    popupState: popupView
    user: any
    giveBalance: string
}

export class UserMngRegList extends Component<any, State> {
    userService = new UserService();
    fileService = new FileService();
    balanceService = new BalanceService();


    menu: any;

    constructor(props: any) {
        super(props);
        this.state = {
            sortType: "regdate",
            sort: 1,
            page: 1,
            maxCount: 1,
            user: null,
            users: [],
            userMaxBenefits: {},
            seach: "",
            serverTime: new Date(),
            popupState: popupView.none,
            cloneParams: {
                page: 1,
                search: '',
                find: '',
                sortType: 'regdate',
                sort: 1
            },
            giveBalance: '0',
            uuid: uuidv4(),


        };

        this.GetUserList(this.state.cloneParams);
    }

    componentDidMount() { }

    GetUserList = async (params: any) => {

        // this.userService
        //     .get_user_list(1, '', '', 'regdate', 1, "reg")
        //     .then(data => {
        //         this.setState({ users: data.users, maxCount: data.maxCount });
        //     });
    };


    handleUseStauseChange = (userId: string, status: string) => {
        // if (status == "wait") {
        //     this.userService
        //         .admin_user_stause_change(userId, status)
        //         .then(data => { });
        // } else if (status == "used") {
        //     confirmAlert({
        //         title: "사용자 상태변경",
        //         message: "사용자를 승인하시겠습니까 ?",
        //         buttons: [
        //             {
        //                 label: "예",
        //                 onClick: () => {
        //                     this.userService
        //                         .admin_user_stause_change(userId, status)
        //                         .then(data => {
        //                             location.reload();
        //                         });
        //                 }
        //             },
        //             {
        //                 label: "아니요",
        //                 onClick: () => { }
        //             }
        //         ]
        //     });
        // } else if (status == "remove") {
        //     confirmAlert({
        //         title: "사용자 상태변경",
        //         message: "사용자를 삭제하시겠습니까 ?",
        //         buttons: [
        //             {
        //                 label: "예",
        //                 onClick: () => {
        //                     this.userService
        //                         .admin_user_stause_change(userId, status)
        //                         .then(data => {
        //                             location.reload();
        //                         });
        //                 }
        //             },
        //             {
        //                 label: "아니요",
        //                 onClick: () => { }
        //             }
        //         ]
        //     });
        // }
    };

    render() {


        const rowClass = (data) => {
            console.log(data)

            return ''
        }

        const rowUserState = (data, options) => {

            // if (data === options.rowData.status && options.rowData.status === 'used') return 'connectionUser'
            // IsConnection

            return ''
        }


        return (
            <>
                <section className="content-header">
                    <h1>회원 가입리스트</h1>
                </section>

                <section className="content">

                    {/* 설명 */}
                    {/* <div>
                        <span>소속 : </span>
                        <span id="shop_hierarchy">
                            - 에이전트를 선택하면 유저의 에이전트 트리 정보를 알 수 있습니다.
                        </span>
                    </div>
                    <div style={{ backgroundColor: "#F5A9A9" }}>첫 입금 빨간색</div>
                    <div style={{ backgroundColor: "#F5F6CE" }}>
                        두번째 입금부터 300만까지 노란색
                    </div>
                    <div style={{ backgroundColor: "#81DAF5" }}>
                        300만 이상입금 파란색
                    </div>
                    <div style={{ backgroundColor: "#D7DF01" }}>1000만입금이상 금색</div>
 */}
                    {/* 메인컨텐츠 */}
                    <div className="table-responsive list_view" style={{ marginTop: "10px" }}>

                        <DataTable

                            rowClassName={rowClass}
                            cellClassName={rowUserState}
                            value={this.state.users}
                            sortField=""
                            sortOrder={1}
                            rowGroupMode="rowspan"

                        >


                            <Column
                                style={{ textAlign: "center", width: "120px" }}
                                header={<div><div>아이디</div><div>이름</div></div>}
                                body={(rowData) => {
                                    return (
                                        <div
                                            onClick={() => OpenUserDetail(rowData.id)}
                                            style={{ color: rowData.color }}
                                        >
                                            {rowData.id}
                                            <i className="pi pi-star-o" style={{ fontSize: '12px', color: '#ff5722' }}></i>
                                            <br />({rowData.bankowner})
                                        </div>
                                    );
                                }}
                            />

                            <Column
                                style={{ textAlign: "center", width: "120px" }}
                                header={<div><div>닉네임</div><div>휴대폰</div></div>}
                                body={(rowData) => {
                                    return (
                                        <div>
                                            {rowData.nick}
                                            <br />
                                            {rowData.phone}
                                        </div>
                                    );
                                }}
                            />



                            <Column
                                header="본사"
                                body={(agent: any) => {
                                    return GetAgentTreeByLevel(agent.agent_tree, 1);
                                }}
                                field="level"
                            />

                            <Column
                                header="부본사"
                                body={(agent: any) => {
                                    return GetAgentTreeByLevel(agent.agent_tree, 2);
                                }}
                                field="balance"
                            />

                            <Column
                                header="총판"
                                body={(agent: any) => {
                                    return GetAgentTreeByLevel(agent.agent_tree, 3);
                                }}
                                field="rolling"
                            />

                            <Column
                                header="매장"
                                body={(agent: any) => {
                                    return GetAgentTreeByLevel(agent.agent_tree, 4);
                                }}
                                field="rolling"
                            />


                            <Column
                                header="은행"
                                field="bankname"
                            />


                            <Column
                                header="통장번호"
                                field="banknum"
                            />


                            <Column
                                header="예금주"
                                field="bankowner"
                            />


                            <Column
                                header={"가입일"}
                                field="balance"
                                body={(rowData) => {
                                    return (
                                        <span>{ConvertDate(rowData.regdate)}</span>
                                    );
                                }}
                            />

                            <Column
                                style={{ textAlign: "center", width: "140px" }}
                                header="상태 변경"
                                body={(rowData) => {
                                    return (
                                        <div className="p-grid form-group">
                                            <Button
                                                label="승인"
                                                className="p-button-success"
                                                onClick={() => {
                                                    this.handleUseStauseChange(rowData.id, "used");
                                                }}
                                            />
                                            <Button
                                                label="대기"
                                                className="p-button-info"
                                                onClick={() => {
                                                    this.handleUseStauseChange(rowData.id, "wait");
                                                }}
                                            />
                                            {/* <Button
                                                label="삭제"
                                                className="p-button-danger"
                                                onClick={() => {
                                                    this.handleUseStauseChange(rowData.id, "remove");
                                                }}
                                            /> */}
                                        </div>
                                    );
                                }}
                            />

                        </DataTable>
                    </div>
                    <div className="text-center">

                    </div>

                </section >


            </>
        );
    }
}
