import React, { Component } from "react";
import { Button } from "primereact/button";

import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { BalanceService } from "../service/balance.service";

import {
  ConvertDate,
  convertToCSV,
  GetNow,
  numTomoeny,
  IsConnection,
  OpenUserDetail,
  uuidv4
} from "../utility/help";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SubPaging } from "../share/sub-paging";
import { ShareUserMenu } from "./share.user.menu";

import { confirmAlert } from "react-confirm-alert";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { ifError } from "assert";
import CurrencyInput from 'react-currency-input-field';

enum popupView {
  none = "none",
  charge = "charge",
  deposit = "deposit",
  withdraw = "withdraw",

  comp_deposit = "comp_deposit",
  comp_withdraw = "comp_withdraw",


  edit_user = "edit_user",

  user_pass = "user_pass",
  user_phone = "user_phone",

  note = "note",
}


interface Props {
  searchText?: string
  findText?: string

}

interface State {
  sortType: string;
  sort: number;
  page: number;
  maxCount: number;

  users: any[];
  seach: string;
  uuid: string;

  userMaxBenefits: any
  serverTime: Date

  cloneParams: any

  popupState: popupView
  user: any
  giveBalance: number

  rollingSlot: string
  rollingCasino: string

}

export class UserMngUserList extends Component<any, State> {
  userService = new UserService();
  fileService = new FileService();
  balanceService = new BalanceService();


  menu: any;

  constructor(props: any) {
    super(props);
    this.state = {
      uuid: uuidv4(),
      sortType: "regdate",
      sort: 1,
      page: 1,
      maxCount: 1,
      user: null,
      users: [],
      userMaxBenefits: {},
      seach: "",
      serverTime: new Date(),
      popupState: popupView.none,
      cloneParams: {
        page: 1,
        search: this.props.searchText,
        find: this.props.findText,
        sortType: 'regdate',
        sort: 1
      },
      giveBalance: 0,
      rollingSlot: '0',
      rollingCasino: '0',

    };

    this.GetUserList(this.state.cloneParams);
  }

  componentDidMount() { }

  GetUserList = async (params: any) => {
    const response = await this.userService.get_user_list(params).then(s => s);
    if (response.status === 'success') {
      this.setState({ users: response.users, maxCount: response.maxCount, serverTime: response.serverTime });

    }
  };



  GetUserBenefit = async (userId) => {
    const detail = await this.userService.get_user_benefit(userId)
    if (detail.status === 'success') {
      this.setState({ rollingSlot: detail.rollingSlot, rollingCasino: detail.rollingCasino })
    }

  }


  handleBalanceUpdate = async (id: string) => {

    let user = this.state.users.find((data) => data.id === id);
    if (user != null) {
      user.refresh = true;
    }


    const respone = await this.userService.get_in_game_balance(id)
    // .then((s: any) => {
    if (respone.status === "success") {
      let user = this.state.users.find((data: any) => data.id === respone.id);
      if (user != null) {
        user.balance = Number(respone.balance);
        user.in_balance = 0
        user.refresh = false;
        this.setState({ users: this.state.users });
      }
    } else {
      let user = this.state.users.find((data: any) => data.id === respone.id);
      if (user != null) {
        user.update = null;
        this.setState({ users: this.state.users })

      }
    }

  };

  //조회
  SearchData = (params: any) => {
    console.log(params)
    params.page = 1
    this.setState({ cloneParams: params, page: 1 });
    this.GetUserList(this.state.cloneParams);
  };

  MoveSel = (page: any) => {
    this.setState({ page: page });
    this.GetUserList(Object.assign(this.state.cloneParams, { skip: page }))
  };


  UpdateUserBenefit(gameType: string, userId: string, rolling: any) {
    confirmAlert({
      title: "롤링 변경",
      message: "유저 롤링 프로테이지를 변경하시겠습니까??.",
      buttons: [
        {
          label: "예",
          onClick: async () => {
            let respone = { status: '' }
            if (gameType === 'slot') {
              respone = await this.userService
                .admin_update_user_benefit_slot(userId, rolling)
            } else if (gameType === 'casino') {
              respone = await this.userService
                .admin_update_user_benefit_casino(userId, rolling)
            }

            if (respone.status === "success") {
              confirmAlert({
                title: "변경",
                message: "정상적으로 처리되었습니다.",
                buttons: [
                  {
                    label: "예",
                    onClick: () => {

                    },
                  },
                ],
              });
            } else if (respone.status == "find_agent") {
              confirmAlert({
                title: "베네핏 ",
                message: "해당 에이전트에서는 요율변경이 불가능합니다",
                buttons: [{ label: "확인", onClick: () => { } }]
              })
            } else if (respone.status === "roll") {
              confirmAlert({
                title: "변경",
                message: "롤링 프로테이지를 확인해주세요.",
                buttons: [
                  {
                    label: "예",
                    onClick: () => { },
                  },
                ],
              });
            } else {
              confirmAlert({
                title: "변경",
                message:
                  "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                buttons: [
                  {
                    label: "예",
                    onClick: () => { },
                  },
                ],
              });
            }
          },
        },
        {
          label: "아니오",
          onClick: () => { },
        },
      ],
    });
    return;
  }



  //충전 팝업 닫기
  OpenPassPopup = () => {

    if (this.state.popupState !== popupView.user_pass) {
      return
    }

    confirmAlert({
      customUI: ({ onClose }) => {

        const user = this.state.user
        const popClonse = () => {
          this.setState({ popupState: popupView.none })
          onClose()
        }

        return (
          <div
            className="modal fade in"
            tabIndex={-1}
            role="dialog"
            style={{
              display: "block",
              paddingRight: "17px",
            }}
          >
            <div
              className="modal-content"
              style={{
                width: isMobile ? "" : "300px",
                maxWidth: isMobile ? "" : "600px",
                minHeight: isMobile ? "" : "300px",
                overflow: "auto",
                top: isMobile ? "" : "100px",
                left: isMobile ? "" : "30%",
              }}
            >
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={popClonse}
                >
                  ×
                </button>

                <h4 className="modal-title">{user.id}님 비밀번호</h4>
              </div>
              <div className="modal-body">
                <div className="form-horizontal">
                  <div className="form-group">
                    <label className="control-label col-sm-3">비밀번호</label>
                    <div className="col-sm-9">
                      <InputText
                        value={user.pw}
                        className="form-control input-sm"
                        readOnly
                      />
                    </div>
                  </div>

                </div>
              </div>
              <div className="modal-footer">
                <div className="pull-right">
                  <div className="form-group">
                    <Button
                      label="Cancel"
                      onClick={() => {
                        popClonse();
                      }}
                      className="btn btn-default btn-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div >
        )
      }
    });

  };



  OpenPhonePopup = () => {

    if (this.state.popupState !== popupView.user_phone) {
      return
    }

    confirmAlert({
      customUI: ({ onClose }) => {

        const user = this.state.user
        const popClonse = () => {
          this.setState({ popupState: popupView.none })
          onClose()
        }

        return (
          <div
            className="modal fade in"
            tabIndex={-1}
            role="dialog"
            style={{
              display: "block",
              paddingRight: "17px",
            }}
          >
            <div
              className="modal-content"
              style={{
                width: isMobile ? "" : "300px",
                maxWidth: isMobile ? "" : "600px",
                minHeight: isMobile ? "" : "300px",
                overflow: "auto",
                top: isMobile ? "" : "100px",
                left: isMobile ? "" : "30%",
              }}
            >
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={popClonse}
                >
                  ×
                </button>

                <h4 className="modal-title">{user.id}님 휴대폰번호</h4>
              </div>
              <div className="modal-body">
                <div className="form-horizontal">
                  <div className="form-group">
                    <label className="control-label col-sm-3">휴대폰번호</label>
                    <div className="col-sm-9">
                      <InputText
                        value={user.phone}
                        className="form-control input-sm"
                        readOnly
                      />
                    </div>
                  </div>

                </div>
              </div>
              <div className="modal-footer">
                <div className="pull-right">
                  <div className="form-group">
                    <Button
                      label="Cancel"
                      onClick={() => {
                        popClonse();
                      }}
                      className="btn btn-default btn-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div >
        )
      }
    });

  };


  //충전 팝업 닫기
  OpenMoneyChargePopup = () => {

    if (this.state.popupState !== popupView.charge &&
      this.state.popupState !== popupView.withdraw
    ) {
      return
    }

    confirmAlert({
      customUI: ({ onClose }) => {

        const user = this.state.user
        const title = this.state.popupState === popupView.charge ? '지급' : '회수'
        const popClonse = () => {
          this.setState({ popupState: popupView.none })
          onClose()
        }

        return (
          <div
            className="modal fade in"
            tabIndex={-1}
            role="dialog"
            style={{
              display: "block",
              paddingRight: "17px",
            }}
          >
            <div
              className="modal-content"
              style={{
                minHeight: "300px",
                overflow: "auto",
                top: "100px",

                width: isMobile ? "100%" : "300px",
                maxWidth: isMobile ? "100%" : "600px",
                left: isMobile ? "0%" : "30%",
              }}
            >
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={popClonse}
                >
                  ×
                </button>

                <h4 className="modal-title">{title}</h4>
              </div>
              <div className="modal-body">
                <div className="form-horizontal">
                  <div className="form-group">
                    <label className="control-label col-sm-3">아이디</label>
                    <div className="col-sm-9">
                      <InputText
                        className="form-control input-sm"
                        value={user.id}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="form-horizontal">
                  <div className="form-group">
                    <label className="control-label col-sm-3">닉네임</label>
                    <div className="col-sm-9">
                      <InputText
                        value={user.nick}
                        onChange={(e: any) => { }}
                        className="form-control input-sm"
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-body">
                <div className="form-horizontal">
                  <div className="form-group">
                    <label className="control-label col-sm-3">보유 금액</label>
                    <div className="col-sm-9">
                      <InputText
                        value={numTomoeny(user.balance + user.in_balance)}
                        onChange={(e: any) => { }}
                        className="form-control input-sm"
                        readOnly
                      />

                      <i
                        className="pi pi-refresh" style={{ 'fontSize': '18px' }}
                        onClick={() => {
                          let user1 = this.state.users.find((data) => data.id == user.id);
                          if (user1 != null) {
                            user1.refresh = true;
                          }
                          this.handleBalanceUpdate(user.id)
                          this.setState({ users: this.state.users })
                        }}
                      ></i>

                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-body">
                <div className="form-horizontal">
                  <div className="form-group">
                    <label className="control-label col-sm-3">금액</label>
                    <div className="col-sm-9">

                      <CurrencyInput
                        id="username"
                        name="username"
                        placeholder="Please enter a number"
                        // defaultValue={1000}
                        value={this.state.giveBalance}
                        // onChange={(e: any) => this.setState({ balance: e.target.value })} 
                        decimalsLimit={0}
                        onValueChange={(value, name) => this.setState({ giveBalance: Number(value) })}
                      />

                    </div>
                  </div>
                </div>
              </div>


              <div className="modal-body">
                <div className="form-horizontal">
                  <div className="form-group">
                    <div className="form-inline">
                      <div className="pull-right">
                        <Button
                          label="Cancel"
                          onClick={() => {
                            popClonse();
                          }}
                          className="btn btn-default btn-sm"
                        />
                        <Button
                          label={title}
                          onClick={async () => {

                            let respone = { status: '' }
                            console.log(this.state.popupState)
                            if (this.state.popupState === popupView.charge) {
                              respone = await this.balanceService.agent_deposit_to_user({ balance: this.state.giveBalance, id: user.id, code: this.state.uuid })

                            } else if (this.state.popupState === popupView.withdraw) {
                              respone = await this.balanceService.agent_withdraw_to_user({ balance: this.state.giveBalance, id: user.id, code: this.state.uuid })
                            }

                            if (respone.status === "success") {
                              confirmAlert({
                                title: title,
                                message: "정상적으로 처리되었습니다.",
                                buttons: [
                                  {
                                    label: "예",
                                    onClick: () => {

                                      this.handleBalanceUpdate(user.id);
                                      this.setState({ giveBalance: 0, uuid: uuidv4(), popupState: popupView.none })
                                    },
                                  },
                                ],
                              });
                            } else if (respone.status === "user") {
                              confirmAlert({
                                title: title,
                                message: "해당 에이전트에서는 회수할수 없습니다.",
                                buttons: [
                                  {
                                    label: "예",
                                    onClick: () => this.setState({ uuid: uuidv4(), popupState: popupView.none })
                                  },
                                ],
                              });

                            } else if (respone.status === "time") {
                              confirmAlert({
                                title: title,
                                message: "20초 이후에 다시 시도해주세요.",
                                buttons: [
                                  {
                                    label: "예",
                                    onClick: () => this.setState({ uuid: uuidv4(), popupState: popupView.none })
                                  },
                                ],
                              });
                            } else if (respone.status === "balance") {
                              confirmAlert({
                                title: title,
                                message: "보유중인 벨런스를 확인해주세요.",
                                buttons: [
                                  {
                                    label: "예",
                                    onClick: () => this.setState({ uuid: uuidv4(), popupState: popupView.none })
                                  },
                                ],
                              });
                            } else {
                              confirmAlert({
                                title: title,
                                message:
                                  "예상치 못한 예러가 발생되었습니다 계속발생되면 관리자에게 문의 부탁드림니다.",
                                buttons: [
                                  {
                                    label: "예",
                                    onClick: () => this.setState({ uuid: uuidv4(), popupState: popupView.none })
                                  },
                                ],
                              });
                            }

                          }}
                          className="btn btn-primary btn-sm"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div >
            </div>

          </div >
        )
      }
    });
  };


  DoKickUser = () => {
    const user = this.state.user

    confirmAlert({
      title: "유저 관리",
      message: `${user.id} (${user.nick}) 의 접속을 끊으시겠습니까?`,
      buttons: [
        {
          label: "확인'",

          onClick: () => {
          },
        },
        {
          label: "취소'",

          onClick: () => {
          },
        },
      ],
    });
  }


  DoRemoveUser = () => {
    const user = this.state.user

    confirmAlert({
      title: "유저 관리",
      message: `${user.id} (${user.nick})의 삭제하시겠습니까?`,
      buttons: [
        {
          label: "확인'",

          onClick: () => {
          },
        },
        {
          label: "취소'",

          onClick: () => {
          },
        },
      ],
    });
  }

  //회수 팝업 닫기
  OpenEdituser = () => {

    if (this.state.popupState !== popupView.edit_user) {
      return
    }

    confirmAlert({
      customUI: ({ onClose }) => {

        const user = this.state.user
        const userMaxBenefits = this.state.userMaxBenefits
        const popClonse = () => {
          this.setState({ popupState: popupView.none })
          onClose()
        }

        return (
          <div
            className="modal fade in"
            tabIndex={-1}
            role="dialog"
            style={{
              display: "block",
              zIndex: '999',
              paddingRight: "17px",
              overflow: 'auto'
            }}
          >
            <div
              className="modal-content"
              style={{
                width: isMobile ? "" : "300px",
                maxWidth: isMobile ? "" : "800px",
                minHeight: isMobile ? "" : "600px",
                maxHeight: isMobile ? "" : "1500px",
                // overflow: "auto",
                top: isMobile ? "" : "100px",
                left: isMobile ? "" : "30%",
              }}
            >
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                  onClick={popClonse}
                >
                  ×
                </button>

                <h4 className="modal-title">회원정보</h4>
              </div>
              <div className="modal-body row">
                <div className="form-horizontal">
                  <div className="col-md-6">
                    <div className="box box-danger">
                      <div className="box-header">
                        <h3 className="box-title">회원정보</h3>
                      </div>
                      <div className="box-body">
                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-addon">아이디</div>
                            <InputText
                              value={user.id}
                              className="form-control"
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-addon">닉네임</div>
                            <InputText
                              readOnly
                              className="form-control"
                              value={user.nick}
                              onChange={(event) => {

                                user.nick = event.target.value
                                this.setState({ user: user })

                              }} />
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-addon">레벨</div>
                            <InputText
                              readOnly
                              value={user.level}
                              onChange={(event) => {
                                user.level = event.target.value
                                this.setState({ user: user })
                              }}

                              className="form-control"
                            />
                          </div>
                        </div>


                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-addon">전화번호</div>
                            <InputText className="form-control"
                              readOnly
                              value={user.phone}
                              onChange={(event) => {
                                user.phone = event.target.value
                                this.setState({ user: user })
                              }} />
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-addon" >은행</div>
                            <Dropdown
                              value={user.bankname}
                              onChange={(event) => { }}
                              className="form-control input-sm"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-addon">예금주</div>
                            <InputText
                              readOnly
                              value={user.bankowner}
                              className="form-control"
                              maxLength={20}
                              onChange={(event) => {
                                user.bankowner = event.target.value
                                this.setState({ user: user })
                              }}
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="input-group">
                            <div className="input-group-addon">계좌번호</div>
                            <InputText
                              readOnly
                              value={user.banknum}
                              className="form-control"
                              maxLength={100}
                              onChange={(event) => {
                                user.banknum = event.target.value
                                this.setState({ user: user })
                              }}

                            />
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="box box-danger">
                      <div className="box-header">
                        <h3 className="box-title">딜비 설정</h3>
                      </div>
                      <div className="box-body">
                        {

process.env.REACT_APP_API_USER_BENFIT !== null &&  process.env.REACT_APP_API_USER_BENFIT !== 'N' && this.state.rollingCasino != null && (
                            <div className="form-group">
                              <label id="calc_group_a">
                                바카라
                                <br />
                              </label>
                              <div className="input-group">
                                <div className="input-group-addon">롤링</div>
                                <InputText
                                  className="form-control"
                                  maxLength={100}

                                  value={this.state.rollingCasino}
                                  onChange={(event) => {
                                    this.setState({ rollingCasino: event.target.value })
                                  }}

                                />
                                {/* UpdateUserBenefit */}

                                <Button
                                  className="form-control p-button-success"
                                  onClick={() => this.UpdateUserBenefit('casino', user.id, this.state.rollingCasino)}
                                  label={'저장하기'}
                                />
                              </div>
                            </div>
                          )
                        }


                        <br />
                        <br />

                        {
process.env.REACT_APP_API_USER_BENFIT !== null &&  process.env.REACT_APP_API_USER_BENFIT !== 'N'     &&                 this.state.rollingSlot != null && (
                            <div className="form-group">
                              <label id="calc_group_c">
                                <br />
                                슬롯
                                <br />
                              </label>
                              <div className="input-group">
                                <div className="input-group-addon">롤링</div>
                                <InputText
                                  className="form-control"
                                  value={this.state.rollingSlot}
                                  onChange={(event) => {
                                    this.setState({ rollingSlot: event.target.value })
                                  }}
                                />


                                <Button
                                  className="form-control p-button-success"
                                  onClick={() => this.UpdateUserBenefit('slot', user.id, this.state.rollingSlot)}
                                  label={'저장하기'}
                                />
                              </div>
                            </div>

                          )
                        }


                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div >
        )
      }
    })

  };



  render() {


    // const rowClass = (data) => {
    //   if (data.total_deposit > 10000000) return 'row-balance-color-1000'
    //   if (data.total_deposit < 1000000 && data.total_deposit > 0) return 'row-balance-color-first'
    //   if (data.total_deposit < 3000000 && data.total_deposit > 0) return 'row-balance-color-300'
    //   if (data.total_deposit > 9999999 && data.total_deposit > 0) return 'row-balance-color-999'
    //   return ''
    // }

    const rowUserState = (data, options) => {

      if (data === options.rowData.status && options.rowData.status !== 'used') return 'blackUser'
      if (data === options.rowData.logindate && IsConnection(this.state.serverTime, options.rowData.logindate) && options.rowData.status === 'used') return 'connectionUser'

      // if (data === options.rowData.status && options.rowData.status === 'used') return 'connectionUser'
      // IsConnection

      return ''
    }



    return (
      <>
        <section className="content-header">
          <h1>회원 리스트</h1>
        </section>

        <section className="content">
          <ShareUserMenu getUserList={this.SearchData}></ShareUserMenu>


          {/* 메인컨텐츠 */}
          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>

            <DataTable
              cellClassName={rowUserState}
              value={this.state.users}
              sortField=""
              sortOrder={1}
              rowGroupMode="rowspan"
              scrollable
              scrollHeight="800px"
            >
              <Column
                field="status"
                headerStyle={{ textAlign: "center", width: "40px" }}
                bodyStyle={{ textAlign: "center", width: "40px" }}
                header="블럭"
                body={(rowData) => {
                  if (rowData.status === 'used') return
                  return (
                    <div>
                      <i className="pi pi-circle" style={{ fontSize: '24px', fontWeight: 'bold', color: 'white' }}></i>
                    </div>
                  );
                }}
              />
              <Column
                field="logindate"
                columnKey="connection"
                headerStyle={{ textAlign: "center", width: "40px" }}
                bodyStyle={{ textAlign: "center", width: "40px" }}
                header="접속"
                body={(rowData) => {

                  if (IsConnection(this.state.serverTime, rowData.logindate) === false) return
                  <i className="pi pi-times" style={{ fontSize: '24px', fontWeight: 'bold', color: 'red' }}></i>

                  return (
                    <div>
                      <i className="pi pi-circle" style={{ fontSize: '24px', fontWeight: 'bold', color: 'white' }}></i>
                    </div>
                  );
                }}

              />
              <Column
                header="아이디"
                headerStyle={{ textAlign: "center", }}
                bodyStyle={{ textAlign: "center", }}
                body={(rowData) => {
                  return (
                    <div onClick={() => OpenUserDetail(rowData.id)} style={{ color: rowData.color }}>{rowData.id}</div>
                  );
                }}

              />

              <Column
                field="nick"
                headerStyle={{ textAlign: "center", }}
                bodyStyle={{ textAlign: "center", }}

                header="닉네임"


              />
              <Column

                header="전화번호"
                headerStyle={{ textAlign: "center", width: "100px" }}
                bodyStyle={{ textAlign: "center", width: "100px" }}

                body={(rowData) => {
                  return (
                    <>
                      <Button
                        type="button"
                        label={"조회"}
                        onClick={(e: any) => {
                          this.setState({ popupState: popupView.user_phone, user: rowData })
                          // this.GetUserPhone({ userId: rowData.id })
                        }}
                        aria-haspopup
                        aria-controls="overlay_panel"
                        className="btn bg-maroon btn-flat btn-xs"
                      />
                    </>
                  );
                }}
              />
              {/* <Column

                field="level"
                headerStyle={{ textAlign: "center", width: "100px" }}

                header="회원레벨"


              />
              <Column
                field="level"
                headerStyle={{ textAlign: "center", width: "100px" }}

                header="게임레벨"

              /> */}
              <Column
                field="agent"
                header="에이전트"
                headerStyle={{ textAlign: "center", justifyContent: 'center' }}
                bodyStyle={{ textAlign: "center", justifyContent: 'center' }}

              />
              <Column
                headerStyle={{ textAlign: "center", justifyContent: 'center', width: "100px" }}
                bodyStyle={{ textAlign: "center", justifyContent: 'center', width: "100px", border: '1px solid rgb(200, 200, 200)' }}
                header="보유머니"
                body={(rowData) => {
                  return (
                    <div style={{ textAlign: 'right', padding: 5 }}>{numTomoeny(rowData.balance + rowData.in_balance)}

                      {
                        rowData.refresh == true ? (
                          <i className="pi pi-spin pi-spinner" style={{ 'fontSize': '2em' }} ></i>

                        ) : (
                          <i
                            className="pi pi-refresh" style={{ 'fontSize': '18px' }}
                            onClick={() => {
                              let user = this.state.users.find((data) => data.id == rowData.id);
                              if (user != null) {
                                user.refresh = true;
                              }
                              this.handleBalanceUpdate(user.id)
                              this.setState({ users: this.state.users })
                            }}
                          ></i>

                        )
                      }
                    </div>
                  );
                }}
              />


              <Column
                headerStyle={{ textAlign: "center", width: "60px" }}
                bodyStyle={{ textAlign: "center", width: "60px" }}

                header="금고"
                body={(rowData) => {
                  return (
                    <div style={{ textAlign: 'right', padding: 5 }}>{numTomoeny(rowData.point)}</div>
                  );
                }}
              />
              <Column
                headerStyle={{ textAlign: "center", width: "60px" }}
                bodyStyle={{ textAlign: "center", width: "60px" }}

                header="롤링"
                body={(rowData) => {
                  return (
                    <div style={{ textAlign: 'right', padding: 5 }}>{numTomoeny(rowData.rolling)}</div>
                  );
                }}
              />
              <Column
                headerStyle={{ textAlign: "center", width: "100px" }}
                bodyStyle={{ textAlign: "center", width: "100px" }}
                header="충전"
                body={(rowData) => {
                  return (
                    <Button
                      type="button"
                      label={"충전"}
                      onClick={(e: any) => {
                        this.setState({ user: rowData })
                        setTimeout(() => this.setState({ popupState: popupView.charge }))
                      }}
                      className="btn bg-maroon btn-flat btn-xs"
                    />

                  );
                }}
              />

              <Column
                headerStyle={{ textAlign: "center", width: "100px" }}
                bodyStyle={{ textAlign: "center", width: "100px" }}
                header="회수"
                body={(rowData) => {
                  return (

                    <Button
                      type="button"
                      label={"회수"}
                      onClick={(e: any) => {
                        this.setState({ user: rowData })
                        setTimeout(() => this.setState({ popupState: popupView.withdraw }))
                      }}
                      className="btn bg-purple btn-flat btn-xs"
                    />
                  );
                }}
              />


              <Column
                headerStyle={{ textAlign: "center", width: "100px" }}
                bodyStyle={{ textAlign: "center", width: "100px" }}
                header="회원정보"
                body={(rowData) => {
                  return (
                    <Button
                      type="button"
                      className="btn bg-purple btn-flat btn-xs"
                      label={"수정"}
                      onClick={(e: any) => {

                        this.setState({ user: rowData })
                        this.GetUserBenefit(rowData.id)
                        setTimeout(() => this.setState({ popupState: popupView.edit_user }))
                      }}
                    />
                  );
                }}
              />

              <Column
                header="가입일"
                headerStyle={{ textAlign: "center", width: "100px" }}
                bodyStyle={{ textAlign: "center", width: "100px" }}

                body={(rowData) => {
                  return (
                    <div>
                      <span>{ConvertDate(rowData.regdate)}</span>
                    </div>
                  );
                }}
              />
              <Column
                header="마지막 로그인"
                headerStyle={{ textAlign: "center", width: "100px" }}
                bodyStyle={{ textAlign: "center", width: "100px" }}
                body={(rowData) => {
                  return (
                    <div>
                      <span>{ConvertDate(rowData.logindate)}</span>
                    </div>
                  );
                }}
              />
              <Column
                field="loginip"
                headerStyle={{ textAlign: "center", width: "100px" }}
                bodyStyle={{ textAlign: "center", width: "100px" }}
                header="최종 로그인 IP"
              />


            </DataTable>
          </div>
          <div className="text-center">
            <SubPaging
              activePage={this.state.page}
              maxCount={this.state.maxCount}
              limit={20}
              MoveSel={(page: number) => {
                this.MoveSel(page);
              }}
            ></SubPaging>
          </div>

          {this.OpenMoneyChargePopup()}

          {this.OpenEdituser()}
          {/* {this.OpenPassPopup()} */}
          {this.OpenPhonePopup()}


        </section >


      </>
    );
  }
}
