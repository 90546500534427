import React, { Component, useState, useEffect } from "react";

import { Tooltip } from 'primereact/tooltip';
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";

import { confirmAlert } from "react-confirm-alert";

import {
    numTomoeny,
    converBetStatus,
    convertIP,
    ConvertDate,
    getDepositColor,
    getWithdrawColor,
    getDeposiOrWithdrawColor,
    ConvertNick
} from "../utility/help";

import { AgentService } from "../service/agent.service";


interface Props {
    onClose: () => any

}

interface State {
    balance: string
    agent: any
}




export class SharePoint extends Component<Props, State> {

    agentService: any = new AgentService();


    constructor(props: Props) {
        super(props);


        this.state = {
            balance: '0',
            agent: {}
        }

        this.handleExchangeBalance = this.handleExchangeBalance.bind(this)

    }

    componentDidMount() {
        this.handelTopinfo()
    }


    handelTopinfo = async () => {

        const health = await this.agentService.agent_check_health();

        if (health.status == "success") {
            this.setState({ agent: health.admin });
        }
    };



    handleExchangeBalance = () => {

        const balance = Number(this.state.balance)


        if ((Number(balance) % 1000) > 0) {
            confirmAlert({
                title: "에이전트 롤링",
                message: "변환할 금액을 1,000 단위 로 입력해주세요.",
                buttons: [
                    {
                        label: "확인",
                        onClick: () => { },
                    },
                ],
            });
            return;
        }




        // if (ex.type == "rolling") {
        //     confirmAlert({
        //         title: "에이전트 롤링",
        //         message: "에이젼트 포인트 캐쉬전환이 완료되었습니다.",
        //         buttons: [
        //             {
        //                 label: "확인",
        //                 onClick: () => {
        //                     this.agentService.agent_exchange_balance(ex).then(s => {
        //                         if (s.status == "success") {
        //                             window.location.reload();
        //                         }
        //                     });
        //                 }
        //             }
        //         ]
        //     });
        // } else 


        if ((Number(balance) % 1000) > 0) {
            confirmAlert({
                title: "에이전트 롤링",
                message: "환전 금액을 1,000 단위 로 입력해주세요.",
                buttons: [
                    {
                        label: "확인",
                        onClick: () => { },
                    },
                ],
            });
            return;
        }

        confirmAlert({
            title: "에이전트 롤링",
            message: "롤링금액을 벨런스로 변경합니다.",
            buttons: [
                {
                    label: "확인",
                    onClick: () => {
                        this.agentService.agent_exchange_balance({ type: 'rolling', rolling: balance }).then(s => {
                            if (s.status == "success") {
                                window.location.reload();
                            }
                            else if (s.status == "balance") {
                                confirmAlert({
                                    title: "에이전트 롤링",
                                    message: "에이젼트 보유금액이 환전신청금액보다 작습니다.",
                                    buttons: [
                                        {
                                            label: "확인",
                                            onClick: () => { }
                                        }
                                    ]
                                });
                            }

                        });
                    }
                }
            ]
        });
    };



    render() {



        return (


            <div id="tradeOrderModal" className="modal fade in" role="dialog" aria-labelledby="tradeOrderModalLabel" aria-hidden="true" style={{ display: 'block', paddingRight: '17px' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => this.props.onClose()}>×</button>
                            <h4 className="modal-title" id="tradeOrderModalLabel">롤링 {"=>"} 벨런스</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-horizontal">
                                <div className="form-group">
                                    <label className="control-label col-sm-3" >보유 롤링</label>
                                    <div className="col-sm-9">
                                        {
                                            this.state.agent && <input type="text" className="form-control" name="curMoney" readOnly value={Number(this.state.agent.rolling).toFixed(0)} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">

                            <div className="form-horizontal">

                                <div className="form-group">
                                    <label className="control-label col-sm-3" >출금 머니</label>
                                    <div className="col-sm-9">
                                        <InputText type="text" className="form-control number" value={Number(this.state.balance).toFixed(0)}
                                            onChange={(e: any) => this.setState({ balance: e.target.value })}
                                            onFocus={() => this.setState({ balance: '' })}
                                        />                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">

                            <div className="form-horizontal">
                                <div className="form-group">
                                    <div className="col-sm-12">
                                        * 최소 1,000원 이상 변환 가능합니다.
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default btn-sm" data-dismiss="modal" aria-hidden="true">cancel</button>
                        <button type="button" className="btn btn-primary btn-sm" id="doOnlineOrder" onClick={() => this.handleExchangeBalance()}>변환하기</button>
                    </div>
                </div>
            </div>
        );
    }
}
