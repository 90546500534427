import axios from "axios";
import cookie from "react-cookies";


console.log(process.env.REACT_APP_API_URL)

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

export class AxiosService {
  static _instance = new AxiosService();
  static session: any;

  SetSession = (_session: any) => {
    //session = _session;
    axios.defaults.headers.post["session"] = _session;
    AxiosService.session = _session;
    // axios.headers["session"] = _session;
  };

  SendMessageByGet = async (url: any) => {
    console.log("SendMessageByPost ");

    var response = await axios.get(url);

    return response.data;
  };

  SendMessageByPost = async (url: any, params: any) => {
    let session = cookie.load("session");

    axios.defaults.headers.post["mode"] = "admin";
    axios.defaults.headers.post["session"] = session;

    var response = await axios.post(url, JSON.stringify(params), {
      headers: { "Content-Type": "application/json" },
    });


    return response.data.data;
  };


  SendMessageByFetch = async (url: any, params: any) => {
    let session = cookie.load("session");

    axios.defaults.headers.patch["mode"] = "admin";
    axios.defaults.headers.patch["session"] = session;

    var response = await axios.post(url, JSON.stringify(params), {
      headers: {
        'Content-Type': 'application/cvs',

      },
      responseType: 'blob', //important
    });

    return response.data.data;
  };


  static getInstance = () => {
    return AxiosService._instance;
  };


}

export const AxiosMag = AxiosService.getInstance(); // do something with the instance...
