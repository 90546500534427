import React, { Component } from "react";

// import { Messages } from "primereact/messages";
// import { Message } from "primereact/message";
import { CountryService } from "../service/CountryService";
import { CarService } from "../service/CarService";
import { NodeService } from "../service/NodeService";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Tree } from "primereact/tree";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { PanelMenu } from "primereact/panelmenu";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
// // import { Spinner } from "primereact/spinner";
import { Slider } from "primereact/slider";
import { ListBox } from "primereact/listbox";
import { RadioButton } from "primereact/radiobutton";
import { PickList } from "primereact/picklist";
import { OrderList } from "primereact/orderlist";
import { ToggleButton } from "primereact/togglebutton";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/panel";
import { TabView, TabPanel } from "primereact/tabview";
import { ProgressBar } from "primereact/progressbar";
import { Dialog } from "primereact/dialog";


import { ColorPicker } from 'primereact/colorpicker';
import { CDropdown } from "./custom/cdropdown";
import { AgentService } from "../service/agent.service";

import { confirmAlert } from "react-confirm-alert";

import { UserInfoTable, UserBetTable, UserBalanceTable } from "./detail.user.table";
import { UserService } from "../service/user.service";



import {
  numTomoeny,
  converBetStatus,
  convertIP,
  ConvertDate,
  getDepositColor,
  getWithdrawColor,
  getDeposiOrWithdrawColor,
  ConvertNick
} from "../utility/help";

enum RenderTable {
  BASIC, // 0
  BET,
  BALANCE
}

interface Props {
  userId: string;
  eventType?: string;
}

interface State {
  user: any;
  userTableValue: any[];
  page: number;
  pages: any;
  render: RenderTable
}

export class DetailUser extends Component<Props, State> {
  // agentService: AgentService = new AgentService();
  userService: UserService = new UserService();


  constructor(props: Props) {
    super(props);
    let render = RenderTable.BASIC
    if (this.props.eventType === 'balance') {
      render = RenderTable.BALANCE
    }

    this.state = {
      user: {},
      userTableValue: [],
      page: 0,
      pages: {},
      render: render
    };

    // this.handlePageChange = this.handlePageChange.bind(this);
    this.handelGetUser()
  }

  componentDidMount() {
  }




  handelGetUser = async () => {
    const data = await this.userService.get_admin_user_detail(this.props.userId)
    if (data.status == "success") {
      this.setState({ user: data.user });
    }
  }

  handleUserChange = (e: any) => {
    // if (this.state.user[e.target.id] != undefined) {
    this.state.user[e.target.id] = e.target.value;
    this.setState({ user: this.state.user });
    // }
  };

  render() {
    let user = this.state.user;
    if (user == null) {
      return <div></div>;
    }

    const RenderText = (label, value, style?) => {
      return (
        <div className="form-group" style={{ marginRight: 0, marginLeft: 0, height: 'inherit' }}>
          <div className="input-group">
            <div className="input-group-addon">{label}</div>
            <InputText
              value={value}
              className="form-control"
              readOnly
              style={style}
            />
          </div>
        </div>
      )
    }

    return (

      <div
        id="detail_agent"
        className="fade in"
        tabIndex={-1}
        role="dialog"
        style={{
          // display: this.state.ManagerPopup === true ? "block" : "none",
          paddingRight: "17px",
        }}
      >
        <div
          className="modal-content"
        >
          <div className="modal-header">
            <h4 className="modal-title">매니저 디테일</h4>
          </div>
          <div className="modal-body">
            <div id="shopForm" className="form-horizontal">
              <div id="tabs" className="nav-tabs-custom">
                <ul className="nav nav-tabs">
                  <li className="active"><a data-toggle="tab" onClick={() => this.setState({ render: RenderTable.BASIC })}>기본 정보</a></li>
                  <li><a data-toggle="tab" onClick={() => this.setState({ render: RenderTable.BET })}>배팅 정보</a></li>
                  <li><a data-toggle="tab" onClick={() => this.setState({ render: RenderTable.BALANCE })}>충환전 정보</a></li>



                </ul>
                <div className="tab-content">

                  <div className="panel-body">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">


                      <div className="form-group" style={{ marginRight: 0, marginLeft: 0, height: 'inherit' }}>
                        <div className="input-group">
                          <div className="input-group-addon">상위 에이전트</div>
                          <div className="form-control" dangerouslySetInnerHTML={{ __html: ConvertNick(user.agent_tree, user.agent_tree_ko) }}></div>
                        </div>
                      </div>

                      {RenderText("아이디", user.id, { color: user.color })}
                      {RenderText("보유머니", numTomoeny(user.balance))}
                      {RenderText("금고머니", numTomoeny(user.point))}
                      {RenderText("롤링머니", numTomoeny(user.rolling))}
                      <br />
                      {RenderText("충전(당일)", numTomoeny(user.today_deposit), { color: 'blue' })}
                      {RenderText("환전(당일)", numTomoeny(user.today_withdraw), { color: 'red' })}
                      {RenderText("충전-환전(당일)", numTomoeny(user.today_deposit - user.today_withdraw), { color: user.today_deposit - user.today_withdraw < 0 ? 'red' : 'blue' })}
                      <br />
                      {RenderText("충전(주)", numTomoeny(user.week_deposit), { color: 'blue' })}
                      {RenderText("환전(주)", numTomoeny(user.week_withdraw), { color: 'red' })}
                      {RenderText("충전-환전(주)", numTomoeny(user.week_deposit - user.week_withdraw), { color: user.week_deposit - user.week_withdraw < 0 ? 'red' : 'blue' })}
                      <br />
                      {RenderText("충전(월)", numTomoeny(user.month_deposit), { color: 'blue' })}
                      {RenderText("환전(월)", numTomoeny(user.month_withdraw), { color: 'red' })}
                      {RenderText("충전-환전(월)", numTomoeny(user.month_deposit - user.month_withdraw), { color: user.month_deposit - user.month_withdraw < 0 ? 'red' : 'blue' })}
                      <br />
                      {RenderText("충전(합)", numTomoeny(user.total_deposit), { color: 'blue' })}
                      {RenderText("환전(합)", numTomoeny(user.total_withdraw), { color: 'red' })}
                      {RenderText("충전-환전(합)", numTomoeny(user.total_deposit - user.total_withdraw), { color: user.total_deposit - user.total_withdraw < 0 ? 'red' : 'blue' })}

                      <br />

                      {RenderText("가입일", ConvertDate(user.regdate), { color: 'red' })}
                      {RenderText("접속일", ConvertDate(user.logindate), { color: 'red' })}
                      {RenderText("가입아이피", user.regip, { color: 'red' })}
                      {RenderText("마지막아이피", user.loginip, { color: 'red' })}

                    </div>

                    <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">

                      {
                        this.state.render === RenderTable.BASIC && (
                          <div className="tab-pane active">
                            <UserInfoTable user={this.state.user}></UserInfoTable>
                          </div>
                        )
                      }

                      {
                        this.state.render === RenderTable.BET && (
                          <div className="tab-pane active">
                            <UserBetTable user={this.state.user}></UserBetTable>
                          </div>
                        )
                      }


                      {
                        this.state.render === RenderTable.BALANCE && (
                          <div className="tab-pane active">
                            <UserBalanceTable user={this.state.user} userId={this.props.userId}></UserBalanceTable>
                          </div>
                        )
                      }


                    </div>
                  </div>
                </div>
              </div>
            </div >
          </div >
        </div >
      </div >
    );
  }
}
