import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import {
  GetNow,
  ConvertCalendar,
  numTomoeny,
  ConvertDate,
  GetToday,
} from "../utility/help";
import { SubPaging } from "../share/sub-paging";
import { AgentService } from "../service/agent.service";
import { BalanceService } from "../service/balance.service";



import { CCalendarTime } from "./custom/ccalendartime";
import { CDropdown } from "./custom/cdropdown";


import { ShareHistoryLogTable } from "./share.historylog.table";



interface State {
  page: number;
  maxCount: number;
  logs: any;

  findDay: any;
  begin: any;
  end: any;

  userTableValue: any[];
  dropdownItem: string;
  dropdownItem2: string;
  seach: string;
}

export class HistoryMileage extends Component<any, State> {
  balanceService = new BalanceService();

  menu: any;

  constructor(props: any) {
    super(props);

    let today = GetToday();

    this.state = {
      page: 0,
      maxCount: 1,
      logs: [],

      findDay: "5",
      begin: today.begin,
      end: today.end,

      userTableValue: [],
      dropdownItem: "id",
      dropdownItem2: "",
      seach: "",
    };


    // this.GetUserList(0, "", "", "regdate", 1);
  }

  selectData = [
    { label: "아이디", value: "id" },
    { label: "닉네임", value: "name" },
    { label: "에이전트(하부포함X)", value: "only_grp_admin_id" },
    { label: "에이전트(하부포함)", value: "all_grp_admin_id" },
  ];
  selectData2 = [
    { label: "전체", value: "" },
    { label: "일반 첫충전 보너스", value: "PNT0010001" },
    { label: "일반 매충전 보너스", value: "PNT0010002" },
    { label: "베팅 보너스", value: "PNT0010003" },
    { label: "추천인 베팅 보너스", value: "PNT0010004" },
    { label: "어드민", value: "PNT0010005" },
    { label: "회원 환전", value: "PNT0010006" },
    { label: "주말 첫충전 보너스", value: "PNT0010007" },
    { label: "주말 매충전 보너스", value: "PNT0010008" },
  ];


  componentDidMount() {
    this.GetUserList(1)
  }

  GetUserList = async (page: number) => {
    // const balances = await this.balanceService.get_user_balance_log({
    //   skip: page,
    //   begin: this.state.begin.toLocaleString("sv-SE"),
    //   end: this.state.end.toLocaleString("sv-SE"),
    // })
    // if (balances.status === 'success') {
    //   this.setState({ logs: balances.balances, maxCount: balances.maxCount, page: page })
    // }
  };



  render() {


    return (
      <>
        <section className="content-header">
          <h1>마일리지 내역</h1>
        </section>

        <section className="content">
          <div className="form-inline">
            <div className="form-group">
              <CCalendarTime
                placeholder="종료일"
                value={this.state.begin}
                onChange={(e) => {
                  this.setState({ begin: e })
                }}

              />
            </div>

            <div className="form-group">
              <CCalendarTime
                placeholder="종료일"
                value={this.state.end}
                onChange={(e) => {
                  this.setState({ end: e })
                }}

              />
            </div>

            <div className="form-group">
              <Button
                label="기간재입력"
                onClick={() => {
                  let today = GetToday();

                  this.setState({
                    begin: today.begin,
                    end: today.end,
                  });
                }}
              />
            </div>
            <div className="form-group">
              <CDropdown
                options={this.selectData}
                value={this.state.dropdownItem}
                onChange={(event) =>
                  this.setState({ dropdownItem: event.value })
                }

              />
            </div>
            <div className="form-group">
              <InputText
                value=""
                onChange={(e: any) => this.setState({ seach: e.target.value })}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                  }
                }}
                className="form-control input-sm"
                style={{ width: "150px" }}
                placeholder="검색어를 입력해 주세요"
              />
            </div>
            <div className="form-group">
              * 마일리지 종류

              <CDropdown
                options={this.selectData2}
                value={this.state.dropdownItem2}
                onChange={(event) =>
                  this.setState({ dropdownItem2: event.value })
                }

              />

            </div>
            <div className="form-group">
              <Button
                label="조회"
                onClick={() => {
                  this.GetUserList(1)
                }}
                className="btn btn-primary btn-sm"
              />
            </div>
            <div className="form-group">
              <Button
                label="재입력"
                onClick={() => { }}
                className="btn btn-default btn-sm"
              />
            </div>
          </div>

          {/* 설명 */}

          <h4>지급 마일리지 총액 : -7,61110P</h4>

          {/* 메인컨텐츠 */}
          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>

            <ShareHistoryLogTable logs={this.state.logs}></ShareHistoryLogTable>

          </div>
          <div className="text-center">
            <SubPaging
              activePage={this.state.page}
              maxCount={this.state.maxCount}
              limit={20}
              MoveSel={(page: number) => {
                this.GetUserList(page)
              }}
            ></SubPaging>
          </div>
        </section>
      </>
    );
  }
}
