

import React, { Component } from "react";
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import DateTimePicker from 'react-xdsoft-datetimepicker'

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from 'date-fns/locale/ko';

interface Prop {
    value: any
    placeholder: any
    onChange: (event) => any
    onKeyPress?: (event) => any
}

interface State {
    date: any
}

export class CCalendarTime extends Component<Prop, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            date: new Date()
        };


        // this.GetUserList(0, "", "", "regdate", 1);
    }

    componentDidMount() {


    }


    render() {
        registerLocale('ko', ko)

        return (
            <div >
                <DatePicker
                    locale="ko"

                    onChange={(date) => this.props.onChange(date)}
                    selected={this.props.value}
                    showTimeSelect
                    timeIntervals={60}

                    timeFormat="HH:mm"
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                />
            </div>

        );
    }
}
