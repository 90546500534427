import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
// import { FileService } from "../service/file.service";
import { AgentService } from "../service/agent.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { TreeTable } from 'primereact/treetable';

import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { GetToday, ConvertCalendar, numTomoeny, OpenAgentDetail, GetYesterDay } from "../utility/help";
import { SubPaging } from "../share/sub-paging";
import { CCalendarTime } from "./custom/ccalendartime";
import { ThreeDRotationSharp } from "@material-ui/icons";


import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';



interface State {
  page: number;
  maxCount: number;

  findDay: any;
  begin: any;
  end: any;
  agentInfo: any;

  userTableValue: any[];
  dropdownItem: string;
  seach: string;
  expandedKeys: any;
  oriItem: any;
  totalBalance: any
  level: number
}

export class RealtimeCalc extends Component<any, State> {
  agentService = new AgentService();

  menu: any;

  constructor(props: any) {
    super(props);

    const today = GetYesterDay()
    const begin = today.begin
    const end = today.end
    this.state = {
      page: 1,
      maxCount: 1,

      findDay: "5",
      // begin: today.begin,
      begin: today.begin,
      end: today.end,

      userTableValue: [],
      expandedKeys: {},
      dropdownItem: "",
      seach: "",
      oriItem: {},
      totalBalance: {},

      level: 0,
      agentInfo: [],


    };

    this.rowClassName = this.rowClassName.bind(this);


    this.handlegetSubAgentCalculate(begin, end);
  }

  componentDidMount() {

  }

  handlegetSubAgentCalculate(begin: any, end: any) {
    this.agentService.admin_get_sub_agent(this.props.agentId, begin.toLocaleString('sv-SE'), end.toLocaleString('sv-SE')).then((data) => {
      if (data.status == "success") {

        let totalBalance = {}
        for (let balances of Object.values(data.agentCombine) as any) {
          for (let key of Object.keys(balances as [])) {

            if (typeof (balances[key]) !== 'number') {
              continue;
            }

            if (totalBalance[key] == null) {
              totalBalance[key] = 0
            }
            totalBalance[key] += Number(balances[key] as Number)



          }

          if (totalBalance['total'] == null) {
            totalBalance['total'] = 0
          }

          totalBalance['total'] += (((balances['totalSlotBet'] - balances['totalSlotWin']) / 100) * balances['benefitLosing']) - ((balances['benefitSlotRolling'] / 100) * balances['totalSlotBet'])


          if (totalBalance['winlose'] == null) {
            totalBalance['winlose'] = 0
          }

          if (totalBalance['totalSlotRolling'] == null) {
            totalBalance['totalSlotRolling'] = 0
          }
          totalBalance['totalSlotRolling'] += ((totalBalance['totalSlotBet'] / 100) * totalBalance['benefitSlotRolling'])




        }

        if (totalBalance['winlose'] == null) {
          totalBalance['winlose'] = 0
        }

        totalBalance['winlose'] = totalBalance['totalSlotBet'] - totalBalance['totalSlotWin']



        totalBalance['totalBalance'] = 0
        totalBalance['exchangeBalance'] = 0


        const combin = Object.values(data.agentCombine)
        combin.sort(function (a: any, b: any) {
          if (a.agent_tree < b.agent_tree) return -1;
          if (b.agent_tree < a.agent_tree) return 1;
          return 0;
        })

        console.log(combin[0])
        this.setState({ agentInfo: combin, totalBalance: combin[0], level: data.level });
        // this.setState({ agentInfo: data.agentCombine });
      }
    });
  }
  //조회
  SearchData = () => {
    this.setState({ page: 1 });
    this.RealtimeCalc(this.state.page, this.state.begin);
  };

  MoveSel = (page: any) => {
    console.log(page);
    this.setState({ page: page });
    this.RealtimeCalc(page, this.state.begin);
  };

  //시간별 정산
  RealtimeCalc = (page: number, begin: any) => { };

  // 재입력
  ReloadPage = () => {
    console.log("ReloadPage : 재입력");
  };

  // 정산요청
  RequestCalculate = () => {
    console.log("RequestCalculate : 정산요청");
  };


  // rowClassName(node) {



  //   return { 'p-highlight': (node.children && node.children.length === 3) };
  // }

  rowClassName(node) {

    if (node.level === 2) return { 'p-tag-level2': true }
    if (node.level === 3) return { 'p-tag-level3': true }
    if (node.level === 4) return { 'p-tag-level4': true }

    return { '': true };
  }



  render() {

    const totalBalance = this.state.totalBalance



    return (
      <>
        <section className="content-header">
          <h1>입출금 정산</h1>
          <h5>(12시에 업데이트됩니다.)</h5>
        </section>

        <section className="content">
          <div
            id="searchForm"
            className="form-inline"
            role="form"
          >
            <div className="form-group">
              <CCalendarTime
                placeholder="시작일"
                value={this.state.begin}
                onChange={(e) => this.setState({ begin: e })}
              />
            </div>

            <div className="form-group">
              <CCalendarTime
                placeholder="종료일"
                value={this.state.end}
                onChange={(e) => this.setState({ end: e })}
              />
            </div>
            <div className="form-group">
              <Button
                label="조회"
                onClick={() => {
                  this.handlegetSubAgentCalculate(this.state.begin, this.state.end);
                }}
                className="btn btn-primary btn-sm"
              />
            </div>
            <div className="form-group">
              <Button
                label="재입력"
                onClick={() => { }}
                className="btn btn-default btn-sm"
              />
            </div>
          </div>

          <div className="table-responsive" style={{ marginTop: "10px" }}>
            <div style={{}}>본사</div>
            <div style={{ backgroundColor: "#F5F6CE" }}>부본사</div>
            <div style={{ backgroundColor: "#81DAF5" }}>총판</div>
            <div style={{ backgroundColor: "#D7DF01" }}>매장</div>
          </div>

          {/* 메인컨텐츠 */}
          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
            <DataTable
              id="fixTable"
              sortOrder={1}
              rowGroupMode="rowspan"
              value={this.state.agentInfo}
              scrollable
              scrollHeight="95vh"
              virtualScrollerOptions={{ itemSize: 46 }}
            >
              {
                this.state.level <= 1 && (
                  <Column
                    headerStyle={{ textAlign: "center", height: "80px" }}
                    body={(rowData: any, column: any) => {
                      const tipname = `tooltip_${rowData.agent}`
                      if (rowData.level === 1) return (
                        <div className={tipname}>
                          <div >본사</div>
                        </div>
                      )
                      return <></>;


                    }}
                  />

                )
              }

              {
                this.state.level <= 2 && (
                  <Column
                    header="부본사"
                    headerStyle={{ textAlign: "center", height: "80px" }}
                    body={(rowData: any, column: any) => {
                      const tipname = `tooltip_${rowData.agent}`
                      if (rowData.level === 2) return (
                        <div className={tipname}>
                          <div >{rowData.agent}</div>
                          <div >{rowData.nick}</div>
                        </div>
                      )
                      return <></>;


                    }}
                  />
                )
              }

              {
                this.state.level <= 3 && (
                  <Column
                    header="총판"
                    headerStyle={{ textAlign: "center", height: "80px" }}
                    body={(rowData: any, column: any) => {
                      const tipname = `tooltip_${rowData.agent}`
                      if (rowData.level === 3) return (
                        <div className={tipname}>
                          <div >{rowData.agent}</div>
                          <div >{rowData.nick}</div>

                        </div>
                      )
                      return <></>;

                    }}
                  />
                )
              }
              {
                this.state.level <= 4 && (
                  <Column
                    header="매장"
                    headerStyle={{ textAlign: "center", height: "80px" }}
                    body={(rowData: any, column: any) => {

                      const tipname = `tooltip_${rowData.agent}`
                      if (rowData.level === 4) return (
                        <div className={tipname}>
                          <div >{rowData.agent}</div>
                          <div >{rowData.nick}</div>

                        </div>
                      )
                      return <></>;

                    }}
                  />
                )
              }

              <Column
                header={<div><div>유저 입금</div><div>유저 출금</div><div>입금 - 출금</div></div>}
                headerStyle={{ textAlign: "center", height: "80px" }}
                body={(rowData: any, column: any) => {
                  return <div>
                    <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalDeposit)}</div>
                    <div style={{ color: 'red' }}>{numTomoeny(rowData.totalWithdraw)}</div>
                    <div style={{ color: rowData.totalDeposit - rowData.totalWithdraw > 0 ? 'blue' : 'red' }}>{numTomoeny(rowData.totalDeposit - rowData.totalWithdraw)}</div>
                  </div>;
                }}
              />





              <Column
                header={<div><div>에이전트 충전</div><div>에이전트 환전</div><div>충전 - 환전</div></div>}
                headerStyle={{ textAlign: "center", height: "80px" }}
                body={(rowData: any, column: any) => {
                  return <div>
                    <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAgentChangeBalance)}</div>
                    <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAgentExchangeBalance)}</div>
                    <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAgentChangeBalance - rowData.totalAgentExchangeBalance)}</div>
                  </div>;
                }}
              />

              <Column
                header={<div><div>관리자 지급</div><div>관리자 회수</div><div>지급 - 회수</div></div>}
                headerStyle={{ textAlign: "center", height: "80px" }}
                body={(rowData: any, column: any) => {
                  return <div>
                    <div style={{ color: 'blue' }}>{numTomoeny(rowData.totalAdmindeposit)}</div>
                    <div style={{ color: 'red' }}>{numTomoeny(rowData.totalAdminwithdraw)}</div>
                    <div style={{ color: rowData.totalAdmindeposit - rowData.totalAdminwithdraw > 0 ? 'blue' : 'red' }}>{numTomoeny(rowData.totalAdmindeposit - rowData.totalAdminwithdraw)}</div>
                  </div>;
                }}
              />




              <Column
                headerStyle={{ textAlign: "center", height: "80px" }}
                header={<div><div>총 입금 합계</div><div>총 출금 합계</div><div>총 입금-출금</div></div>}
                body={(rowData: any, column: any) => {
                  return <div>
                    <div style={{ color: rowData.totalDeposit + rowData.totalAgentChangeBalance + rowData.totalAdmindeposit > 0 ? 'blue' : 'blue' }}>{numTomoeny(rowData.totalDeposit + rowData.totalAgentChangeBalance + rowData.totalAdmindeposit)}</div>
                    <div style={{ color: rowData.totalWithdraw + rowData.totalAgentExchangeBalance + rowData.totalAdmindeposit > 0 ? 'red' : 'blue' }}>{numTomoeny(rowData.totalWithdraw + rowData.totalAgentExchangeBalance + rowData.totalAdminwithdraw)}</div>
                    <div style={{ color: (rowData.totalDeposit + rowData.totalAgentChangeBalance + rowData.totalAdmindeposit) - (rowData.totalWithdraw + rowData.totalAgentExchangeBalance + rowData.totalAdmindeposit) > 0 ? 'blue' : 'red' }}>{
                      numTomoeny((rowData.totalDeposit + rowData.totalAgentChangeBalance + rowData.totalAdmindeposit) - (rowData.totalWithdraw + rowData.totalAgentExchangeBalance + rowData.totalAdminwithdraw))}</div>
                  </div>;
                }}
              />


              <Column
                headerStyle={{ textAlign: "center", height: "80px" }}
                header={<div><div>슬롯 롤링</div><div>카지노 롤링</div></div>}
                body={(rowData: any, column: any) => {
                  return <div>
                    <div style={{}}>{numTomoeny(((rowData.totalSlotBet)) * rowData.benefitSlotRolling / 100)}</div>
                    <div style={{}}>{numTomoeny(((rowData.totalCasinoBet)) * rowData.benefitCasinoRolling / 100)}</div>

                  </div>;
                }}
              />




              {/*
              <Column
                header={<div>유저 카지노 롤링</div>}
                body={(rowData: any, column: any) => {
                  return <div>
                    <div>{numTomoeny(rowData.totalUserRollingCasino)}</div>
                  </div>;
                }}
              /> */}


            </DataTable>

          </div >
          <div className="text-center">
            <SubPaging
              activePage={this.state.page}
              maxCount={this.state.maxCount}
              limit={20}
              MoveSel={(page: number) => {
                this.MoveSel(page);
              }}
            ></SubPaging>
          </div>
        </section >
      </>
    );
  }
}
