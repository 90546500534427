import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import {
  GetNow,
  ConvertCalendar,
  numTomoeny,
  ConvertDate,
  GetToday,
  ConvertBalanceType
} from "../utility/help";
import { SubPaging } from "../share/sub-paging";
import { AgentService } from "../service/agent.service";



import { CCalendarTime } from "./custom/ccalendartime";
import { CDropdown } from "./custom/cdropdown";


import { ShareMoveLogTable } from "./share.historylog.table";



interface State {
  activePage: number;
  maxCount: number;
  logs: any;

  findDay: any;
  begin: any;
  end: any;

  findText: string;
  searchText: string;
}

export class HistoryMoney extends Component<any, State> {
  agentService = new AgentService();

  menu: any;

  constructor(props: any) {
    super(props);

    let today = GetToday();

    this.state = {
      activePage: 0,
      maxCount: 1,
      logs: [],

      findDay: "5",
      begin: today.begin,
      end: today.end,

      findText: "",
      searchText: "",
    };


    // this.GetUserList(0, "", "", "regdate", 1);
  }

  selectData = [
    { label: "아이디", value: "id" },
    { label: "닉네임", value: "nick" },
    { label: "에이전트(하부포함X)", value: "agent" },
    { label: "에이전트(하부포함)", value: "tree" },
  ];


  componentDidMount() {
    this.GetUserList(1)
  }

  GetUserList = async (page: number) => {

    const balances = await this.agentService.get_user_move_balance({
      skip: page,
      begin: this.state.begin.toLocaleString('sv-SE'),
      end: this.state.end.toLocaleString('sv-SE'),
      searchText: this.state.searchText,
      findText: this.state.findText

    })
    if (balances.status === 'success') {
      this.setState({ logs: balances.balances, maxCount: balances.maxCount, activePage: page })
    }
  };



  render() {


    return (
      <>
        <section className="content-header">
          <h1>머니 이동 내역</h1>
        </section>

        <section className="content">
          <div className="form-inline">
            <div className="form-group">
              <CCalendarTime
                placeholder="종료일"
                value={this.state.begin}
                onChange={(e) => {
                  this.setState({ begin: e })
                }}

              />
            </div>

            <div className="form-group">
              <CCalendarTime
                placeholder="종료일"
                value={this.state.end}
                onChange={(e) => {
                  this.setState({ end: e })
                }}

              />
            </div>

            <div className="form-group">
              <Button
                label="기간재입력"
                onClick={() => {
                  let today = GetToday();

                  this.setState({
                    begin: today.begin,
                    end: today.end,
                  });
                }}
              />
            </div>
            <div className="form-group">
              <CDropdown
                options={this.selectData}
                value={this.state.searchText}
                onChange={(event) =>
                  this.setState({ searchText: event.value })
                }

              />
            </div>
            <div className="form-group">
              <InputText
                value={this.state.findText}
                onChange={(e: any) => this.setState({ findText: e.target.value })}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    this.GetUserList(1)
                  }
                }}
                className="form-control input-sm"
                style={{ width: "150px", margin: 0 }}
                placeholder="검색어를 입력해 주세요"
              />
            </div>

            <div className="form-group">
              <Button
                label="조회"
                onClick={() => {
                  this.GetUserList(1)
                }}
                className="btn btn-primary btn-sm"
              />
            </div>
            <div className="form-group">
              <Button
                label="재입력"
                onClick={() => { }}
                className="btn btn-default btn-sm"
              />
            </div>
          </div>

          {/* 설명 */}

          {/* <h4>지급 마일리지 총액 : -7,61110P</h4> */}

          {/* 메인컨텐츠 */}
          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>

            <ShareMoveLogTable logs={this.state.logs}></ShareMoveLogTable>

          </div>
          <div className="text-center">
            <SubPaging
              activePage={this.state.activePage}
              maxCount={this.state.maxCount}
              limit={20}
              MoveSel={(page: number) => {
                this.GetUserList(page)
              }}
            ></SubPaging>
          </div>
        </section>
      </>
    );
  }
}


