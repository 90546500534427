import React, { Component } from "react";
import { Button } from "primereact/button";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { GetNow, ConvertCalendar, numTomoeny } from "../utility/help";
import { SubPaging } from "../share/sub-paging";

interface State {
  page: number;
  maxCount: number;

  findDay: any;
  begin: any;
  end: any;

  userTableValue: any[];
  dropdownItem: string;
  seach: string;
  seach2: string;
}

export class UserMngMoneyTransProc extends Component<any, State> {
  userService = new UserService();
  fileService = new FileService();

  menu: any;

  constructor(props: any) {
    super(props);

    let begin = GetNow();
    let end = GetNow();

    this.state = {
      page: 1,
      maxCount: 1,

      findDay: "5",
      begin: begin,
      end: end,

      userTableValue: [],
      dropdownItem: "",
      seach: "",
      seach2: "",
    };

    this.MoneyTransProc(
      this.state.page,
      this.state.dropdownItem,
      this.state.seach
    );

    this.GetUserList(0, "", "", "regdate", 1);
  }

  selectData = [
    { label: "전체", value: "" },
    { label: "대기", value: "COM0110001" },
    { label: "완료", value: "COM0110002" },
    { label: "취소", value: "COM0110003" },
  ];

  componentDidMount() { }

  GetUserList = (
    page: number,
    find: string,
    search: string,
    sortType: string,
    sort: number
  ) => {
    //   this.userService
    //     .get_user_list(page, find, search, sortType, sort, "used")
    //     .then((data) => {
    //       this.setState({
    //         userTableValue: data.users,
    //       });
    //     });
  };

  //조회
  SearchData = () => {
    this.setState({ page: 1 });
    this.MoneyTransProc(
      this.state.page,
      this.state.dropdownItem,
      this.state.seach
    );
  };

  MoveSel = (page: any) => {
    console.log(page);
    this.setState({ page: page });
    this.MoneyTransProc(page, this.state.dropdownItem, this.state.seach);
  };

  //게임칩 요청 조회
  MoneyTransProc = (page: number, dropdownItem: string, seach: string) => { };

  // 재입력
  ReloadPage = () => {
    console.log("ReloadPage : 재입력");
  };

  // 회원리스트 화면으로 이동
  MoveUserList = () => {
    console.log("MoveUserList : 회원리스트 화면으로 이동");
  };

  render() {
    return (
      <>
        <section className="content-header">
          <h1>게임칩 요청</h1>
        </section>

        <section className="content">
          <div className="form-inline">
            <div className="form-group">
              거래상태
              <Dropdown
                options={this.selectData}
                value={this.state.dropdownItem}
                onChange={(event) =>
                  this.setState({ dropdownItem: event.value })
                }

                className="form-control input-sm"
                style={{ width: "120px" }}
              />
            </div>
            <div className="form-group">
              <Button
                label="조회"
                onClick={() => {
                  this.SearchData();
                }}
                className="btn btn-primary btn-sm"
              />
            </div>
          </div>

          {/* 메인컨텐츠 */}
          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
            <DataTable
              value={this.state.userTableValue}
              sortField=""
              sortOrder={1}

              rowGroupMode="rowspan"
              style={{ marginTop: "30px", border: "1px solid #c8c8c8" }}
            >
              <Column
                style={{
                  textAlign: "center",

                  height: "25px",
                }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                }}
                header="아이디"
                body={(rowData) => {
                  return (
                    <>
                      <div>{rowData.id}</div>
                    </>
                  );
                }}
              />
              <Column
                field=""
                header="닉네임"
                style={{
                  border: "1px solid #c8c8c8",

                  height: "25px",
                }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                  height: "40px",
                }}
              />
              <Column
                field=""
                header="에이전트"
                style={{ border: "1px solid #c8c8c8" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                  height: "40px",
                }}
              />
              <Column
                field=""
                header="보냄"
                style={{ border: "1px solid #c8c8c8" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                  height: "40px",
                }}
              />
              <Column
                field=""
                header="받음"
                style={{ border: "1px solid #c8c8c8" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                  height: "40px",
                }}
              />
              <Column
                style={{ textAlign: "center" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                }}
                header="이동머니"
                body={(rowData) => {
                  return (
                    <>
                      <div>{numTomoeny(rowData.balance)}</div>
                    </>
                  );
                }}
              />
              <Column
                field=""
                header="처리일"
                style={{ border: "1px solid #c8c8c8" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                  height: "40px",
                }}
              />
              <Column
                field=""
                header="트랜젝션 아이디"
                style={{ border: "1px solid #c8c8c8" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                  height: "40px",
                }}
              />
              <Column
                field=""
                header="처리"
                style={{ border: "1px solid #c8c8c8" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                  height: "40px",
                }}
              />
              <Column
                field=""
                header="거래상태"
                style={{ border: "1px solid #c8c8c8" }}
                headerStyle={{
                  textAlign: "center",
                  background: "#dee2e6",
                  color: "#212529",
                  borderRight: "1px solid #d8d8d8",
                  height: "40px",
                }}
              />
            </DataTable>
          </div>
          <div className="text-center">
            <SubPaging
              activePage={this.state.page}
              maxCount={this.state.maxCount}
              limit={20}
              MoveSel={(page: number) => {
                this.MoveSel(page);
              }}
            ></SubPaging>
          </div>
        </section>
      </>
    );
  }
}
