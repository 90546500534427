import React, { Component } from "react";
import { Message } from "primereact/message";
import { IconFont } from "react-contexify";
import { FirstPageOutlined } from "@material-ui/icons";

const { format } = require("number-currency-format");

let LogText = {
  minibet: "미니게임배팅",
  miniwin: "미니게임당첨",
  livebet: "라이브배팅",
  livewin: "라이브당첨",
  sportbet: "스포츠배팅",
  sportwin: "스포츠당첨",
  bonus: "충전보너스",
  sportlose: "스포츠낙점",
  minilose: "미니게임낙점",
  miniBetcancel: "미니게임취소",
  pointtobalance: "포인트전환",
  admindeposit: "센터지급",
  adminwithdraw: "에이전트환전",

  deposit: "충전",
  withdraw: "환전",
  friend: "친구추천",
  withdrawcancel: "환전취소",

  agentdeposit: "총판지급",
  agentwithdraw: "총판회수",

};

let AgentLogText = {
  admin_do_deposit: "에이전트충전",
  admin_do_withdraw: "에이전트환전",
  admin_give_agent_balance_deposit: "센터 에이전트 지급",
  admin_give_agent_balance_withdraw: "센터 에이전트 회수",

  pointtobalance: "포인트전환",
  admindeposit: "센터지급",
  agentdeposit: "총판지급",
  agentwithdraw: "총판회수",

  miniBetcancel: "미니게임취소",
  minibet: "미니게임배팅",
  miniwin: "미니게임당첨",
  livebet: "라이브배팅",
  livewin: "라이브당첨",
  sportbet: "스포츠배팅",
  sportwin: "스포츠당첨",
  bonus: "충전보너스",
  sportlose: "스포츠낙점",
  minilose: "미니게임낙점",
  deposit: "충전",
  withdraw: "환전",
  friend: "친구추천",
  withdrawcancel: "환전취소",
  exchange_losing_to_balance: "루징=>벨런스",
  exchange_rolling_to_balance: "롤링=>벨런스",


  balance_losing: "충환전루징",
  agent_minilose_rolling: "미니롤링",
  agent_sportuserlose_rolling: "스포츠롤링",

  deposit_agent: "에이전트 충전",

  admin_do_user_deposit: "센터=>유저지급",

  admin_do_user_withdraw: "센터=>유저회수",

  withdraw_agent: "에이전트 지급",
  agent_deposit_agent: "에이전트 => 에이전트",

  depositcancel: '충전 취소',


};

let BalanceText = {
  balance: "벨런스",
  point: "포인트",
  rolling: "롤링",
  losing: "루징",
};

let AgentBenefitLogText = {
  balance_losing: "루징",

  agent_sportwserwin_rolling: "스포츠 당첨 롤링",
  agent_sportuserlose_rolling: "스포츠 낙점 롤링",

  agent_miniwin_rolling: "미니게임 당첨 롤링",
  agent_minilose_rolling: "미니게임 낙점 롤링",

  agent_livewin_rolling: "라이브 당첨 롤링",
  agent_livelose_rolling: "라이브 낙점 롤링",

  agent_casino_rolling: "카지노 롤링",
  agent_slot_rolling: "슬롯 롤링",


  agent_mini_nomal: "파워볼 단폴",
  agent_mini_double: "파워볼 조합",

  agent_casino_losing: "카지노 루징",
  agent_slot_losing: "슬롯 루징",

  agent_fx_win: "FX 당첨 롤링",
  agent_fx_bet: "FX 낙점 롤링",

  agent_fx_btc_win: "FX BTC 당첨 롤링",
  agent_fx_btc_bet: "FX BTC 낙점 롤링",
};

let GameType = {
  sport: "스포츠",

  sport_special: "스페셜",
  sport_live: "스포츠 라이브",

  pwball: "파워볼",
  pwladder: "파워볼 사다리",

  kenoladder: "키노 사다리",
  speedkeno: "스피드 키노",
  vmsoccer: "가상축구",
  vmdog: "가상 개경주",
  vmhorse: "가상 경마",
  fx_minute1: "FX 1분배팅",
  fx_minute2: "FX 2분배팅",
  fx_minute3: "FX 3분배팅",
  fx_minute5: "FX 5분배팅",
};

export const ConvertGameText = (text) => {
  if (GameType[text] != undefined) {
    return GameType[text];
  }
  return "미정의";
};

export const convertLogText = (text) => {
  if (LogText[text] != undefined) {
    return LogText[text];
  }
  return text;
};

export const ConvertAgentLogText = (text) => {
  if (AgentLogText[text] != undefined) {
    return AgentLogText[text];
  }
  return text;
};

export const converBalanceColor = (status) => {
  if (status == "exchange_rolling_to_balance")
    return "green"
  else if (status == "deposit_agent")
    return "red"
  else if (status === "withdraw_agent" || status === "admin_give_agent_balance_withdraw")
    return "blue"
  else if (status == "already")
    return "#3eb15b"
  return "#ff0000";
};



export const convertAgentBalanceText = (text) => {
  if (BalanceText[text] != undefined) {
    return BalanceText[text];
  }
  return text;
};

export const ConvertAgentBenefitBalanceText = (text) => {
  if (AgentBenefitLogText[text] != undefined) {
    return AgentBenefitLogText[text];
  }
  return text;
};

export const numTomoeny = (moeny) => {
  if (moeny == null) return 0

  return format(moeny, { decimalsDigits: 0, decimalSeparator: "" });
};

export const ConvertSpecifiersGame = (specifiers): string => {
  if (specifiers == null) {
    return "@";
  }

  let newkey = JSON.stringify(specifiers)
    .replace(".", "_")
    .replace(".", "_");

  if (newkey === "{}") {
    newkey = "@";
  }

  return newkey;
};


export const ConverBalanceStatus = (type, status) => {
  if (type == 'deposit' && status == "wait")
    return <div style={{ color: "#3a86c8", font: "bold" }}>충전 대기</div>;
  if (type == 'deposit' && status == "reg")
    return <div style={{ color: "#3a86c8", font: "bold" }}>충전 신청</div>;
  if (type == 'deposit' && status == "cansel")
    return <div style={{ color: "#ff0000", font: "bold" }}>충전 취소</div>;
  if (type == 'deposit' && status == "already")
    return <div style={{ color: "#3eb15b", font: "bold" }}>충전 완료</div>;

  if (type == 'withdraw' && status == "wait")
    return <div style={{ color: "#3a86c8", font: "bold" }}>환전 대기</div>;
  if (type == 'withdraw' && status == "reg")
    return <div style={{ color: "#3a86c8", font: "bold" }}>환전 신청</div>;
  if (type == 'withdraw' && status == "cansel")
    return <div style={{ color: "#ff0000", font: "bold" }}>환전 취소</div>;
  if (type == 'withdraw' && status == "already")
    return <div style={{ color: "#3eb15b", font: "bold" }}>환전 완료</div>;
  return `미정의 ${type}-${status}`;
};



export const ConverAgentBalanceStatus = (type, status) => {
  if (type == 'change_to_balance' && status == "wait")
    return <div style={{ color: "#3a86c8", font: "bold" }}>충전 대기</div>;
  if (type == 'change_to_balance' && status == "reg")
    return <div style={{ color: "#3a86c8", font: "bold" }}>충전 신청</div>;
  if (type == 'change_to_balance' && status == "cansel")
    return <div style={{ color: "#ff0000", font: "bold" }}>충전 취소</div>;
  if (type == 'change_to_balance' && status == "already")
    return <div style={{ color: "#3eb15b", font: "bold" }}>충전 완료</div>;

  if (type == 'exchange_to_balance' && status == "wait")
    return <div style={{ color: "#3a86c8", font: "bold" }}>환전 대기</div>;
  if (type == 'exchange_to_balance' && status == "reg")
    return <div style={{ color: "#3a86c8", font: "bold" }}>환전 신청</div>;
  if (type == 'exchange_to_balance' && status == "cansel")
    return <div style={{ color: "#ff0000", font: "bold" }}>환전 취소</div>;
  if (type == 'exchange_to_balance' && status == "already")
    return <div style={{ color: "#3eb15b", font: "bold" }}>환전 완료</div>;
  return `미정의 ${type}-${status}`;
};

export const ConverAgentStatus = (status) => {
  if (status == "wait")
    return <div className="p-message.p-message-success">대기</div>;
  if (status == "reg") return <div className="p-message-info">신청</div>;
  if (status == "cansel") return <div className="p-message-error">취소</div>;
  if (status == "used") return <div className="p-message-success">사용중</div>;
  if (status == "already") return <div className="p-message-success">완료</div>;

  return status;
};

export const HelpStatus = (status) => {
  if (status == "wait")
    return <div className="p-message-warn">대기</div>;
  if (status == "reg") return <div className="p-message-info">신청</div>;
  if (status == "cansel") return <div className="p-message-error">삭제</div>;
  if (status == "already") return <div className="p-message-success">완료</div>;
  return status;
};

export const converBalanceType = (status) => {
  if (status == "deposit")
    return <div style={{ color: "#3a86c8", font: "bold" }}>충전</div>;
  if (status == "withdraw")
    return <div style={{ color: "red", font: "bold" }}>환전</div>;
  if (status == "admin_deposit")
    return <div style={{ color: "#ff00ff", font: "bold" }}>지급</div>;
  if (status == "admin_withdraw")
    return <div style={{ color: "#00ff00", font: "bold" }}>회수</div>;
  if (status == "coupon")
    return <div style={{ color: "#0000ff", font: "bold" }}>쿠폰</div>;
  if (status == "admin")
    return <div style={{ color: "#ff0000", font: "bold" }}>센터</div>;
  if (status == "already")
    return <div style={{ color: "#3eb15b", font: "bold" }}>완료</div>;
  return "";
};

export const converBetStatus = (status) => {
  if (status == "cancel") return <Message severity="warn" text="취소" />;
  if (status == "reg") return <Message severity="info" text="대기" />;
  if (status == "lose") return <Message severity="error" text="낙점" />;
  if (status == "win") return <Message severity="success" text="당첨" />;
  return <Message severity="warn" text="미정의 " />;
};

export const getDepositColor = () => {
  return "red";
};

export const getWithdrawColor = () => {
  return "blue";
};

export const balanceColor = (balance: number) => {
  if (balance > 0) {
    return "red";
  }

  return "blue";
};

export const getDeposiOrWithdrawColor = (balance) => {
  if (balance >= 0) {
    return getDepositColor();
  }
  return getWithdrawColor();
};

export const pad2 = (n) => {
  return (n < 10 ? "0" : "") + n;
};

export const ConvertDate = (date1) => {
  if (date1 === null || date1 === "" || date1 === undefined) {
    return "-";
  }

  let date = new Date(date1);

  return (
    pad2(date.getMonth() + 1) +
    "-" +
    pad2(date.getDate()) +
    " " +
    pad2(date.getHours()) +
    ":" +
    pad2(date.getMinutes()) +
    ":" +
    pad2(date.getSeconds())
  );

  //return date;
};

export const convertSimpleDate = (date1) => {
  if (date1 === null || date1 === "" || date1 === undefined) {
    return "-";
  }

  let date = new Date(date1);

  return (
    date.getFullYear() +
    "-" +
    pad2(date.getMonth() + 1) +
    "-" +
    pad2(date.getDate()) +
    " " +
    "00:00:00"
  );

  //return date;
};

export const GetNow = () => {
  return new Date();
};

export const GetToday = () => {
  var today = new Date();
  var tomorrow = new Date();

  today.setDate(today.getDate());

  return {

    begin: new Date(today.setHours(0, 0, 0, 0)),
    end: new Date(today.setDate(today.getDate() + 1)),
    // end: new Date(
    //   today.getFullYear() +
    //   "-" +
    //   (today.getMonth() + 1) +
    //   "-" +
    //   pad2(today.getDate()) +
    //   " " +
    //   "23:59:59"
    // ),
  };

  // return {
  //   begin: new Date(
  //     today.getFullYear() +
  //     "-" +
  //     pad2(today.getMonth() + 1) +
  //     "-" +
  //     pad2(today.getDate()) +
  //     " " +
  //     "00:00:00"
  //   ),

  //   end: new Date(
  //     today.getFullYear() +
  //     "-" +
  //     (today.getMonth() + 1) +
  //     "-" +
  //     pad2(today.getDate()) +
  //     " " +
  //     "23:59:59"
  //   ),
  // };
};



export const GetYesterDay = () => {
  var today = new Date();
  var tomorrow = new Date();

  today.setDate(today.getDate() - 1);
  // tomorrow.setDate(tomorrow.getDate());

  return {

    begin: new Date(today.setHours(0, 0, 0, 0)),
    end: new Date(today.setDate(today.getDate() + 1)),


    // begin: new Date(
    //   today.getFullYear() +
    //   "-" +
    //   pad2(today.getMonth() + 1) +
    //   "-" +
    //   pad2(today.getDate()) +
    //   " " +
    //   "00:00:00"
    // ),

    // end: new Date(
    //   tomorrow.getFullYear() +
    //   "-" +
    //   (tomorrow.getMonth() + 1) +
    //   "-" +
    //   pad2(tomorrow.getDate()) +
    //   " " +
    //   "23:59:59"
    // ),
  };
}


export const GetTodayToString = () => {
  var today = new Date();
  var tomorrow = new Date();

  tomorrow.setDate(tomorrow.getDate() + 1);

  return {
    begin:
      today.getFullYear() +
      "-" +
      pad2(today.getMonth() + 1) +
      "-" +
      pad2(today.getDate()) +
      " " +
      "00:00:00"
    ,

    end:
      tomorrow.getFullYear() +
      "-" +
      pad2(tomorrow.getMonth() + 1) +
      "-" +
      pad2(tomorrow.getDate()) +
      " " +
      "23:59:59"
    ,
  };
};


export const GetMonth = () => {
  var today = new Date();
  var tomorrow = new Date();

  tomorrow.setDate(tomorrow.getDate() + 1);

  return {
    begin: new Date(
      today.getFullYear() +
      "-" +
      pad2(today.getMonth() + 1) +
      "-1-" +

      "00:00:00"
    ),

    end: new Date(
      tomorrow.getFullYear() +
      "-" +
      pad2(tomorrow.getMonth() + 1) +
      "-" +
      pad2(tomorrow.getDate()) +
      " " +
      "23:59:59"
    ),
  };
};

export const convertIP = (ip) => {
  if (ip === null || ip === "") {
    return "-";
  }
  return ip;
};

export const convertSpecifiers = (specifiers) => {
  if (specifiers == undefined) {
    return "-";
  }

  if (Object.values(specifiers).length == 0) {
    return "-";
  }
  let result = "";
  for (let val of Object.values(specifiers)) {
    result = result + "" + val;
  }
  return "-";
};

export const OpenUserDetail = (id, type?: string) => {
  // if (type == null) {
  //   var win = window.open(
  //     "/#/UserDetail/" + id,
  //     "_blank",
  //     "width=1600,height=1000"
  //   );
  //   win!.focus();
  // }
  // else {
  //   var win = window.open(
  //     `/#/UserDetail/${id}/${type}`,
  //     "_blank",
  //     "width=1600,height=1000"
  //   );
  //   win!.focus();
  // }
};

export const OpenAgentDetail = (id, type?: string) => {
  if (type == null) {
    var win = window.open(
      "/#/DetailAgent/" + id,
      "_blank",
      "width=900,height=800"
    );
    win!.focus();
  }
  else {
    var win = window.open(
      `/#/DetailAgent/${id}/${type}`,
      "_blank",
      "width=1600,height=800"
    );
    win!.focus();
  }
}

export const OpenMatchDetail = (matchId, oddsId, specifiers) => {
  var win = window.open(
    `/#/DetailMatchView/${matchId}/${oddsId}/${specifiers}`,
    "_blank",
    "width=1600,height=800"
  );
  win!.focus();
};

// export const OpenAgentDetail = id => {
//   var win = window.open("/userDetail/" + id, "_blank");
//   win!.focus();
// };

// export const OpenMatchDetail = (matchId) => {
//   var win = window.open("/detailMatch/" + matchId, "_blank");
//   win!.focus();
// };

export const GetTimeStemp = () => {
  return new Date().getTime();
};



export const ConverAgentExBalanceColor = (type) => {
  if (type === 'exchange_to_rolling') return 'blue'
  else if (type === 'exchange_to_losing') return 'blue'
  else if (type === 'exchange_to_balance') return 'blue'
  return 'red'
};

export const ConverAgentExBalanceType = (type) => {
  if (type === 'exchange_to_rolling') return '롤링 환전'
  else if (type === 'exchange_to_losing') return '루징 환전'
  else if (type === 'exchange_to_balance') return '벨런스 환전'
  else if (type === 'change_to_balance') return '벨런스 충전'
};


export const OpenDetailMini = (categoryId, episode) => {
  var win = window.open("/detailMini/" + categoryId + "/" + episode, "_blank");
  win!.focus();
};


export const OpenAgentCal = (categoryId) => {
  var win = window.open("/#/calculatesubagent/" + categoryId, "_blank", "width=1600,height=1000");
  win!.focus();
};


export const GetAgentTreeByLevel = (agent_tree, level) => {
  let trees: any[] = agent_tree.split("|");

  if (trees.length >= level) return trees[level - 1];
  return "";
};

// export const ConvertDateSmall = (dt: any) => {
//   console.log(dt);
//   return Moment(dt).format("MM-DD HH:mm");
// };

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}


export const convertToCSV = (objArray) => {
  var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  var str = "";

  for (var i = 0; i < array.length; i++) {
    var line = "";
    for (var index in array[i]) {
      if (line != '') line += ","

      line += array[i][index];
    }

    str += line + "\r\n";
  }

  return str;
}



let levellables = {
  0: 'p-tag text-bold text-danger',
  1: 'p-tag p-tag-success text-bold text-danger',
  2: 'p-tag p-tag-warning text-bold text-danger',
  3: 'p-tag p-tag-danger text-bold text-danger',
  4: 'p-tag text-bold text-danger'
}


let colors = {
  0: '#ff5722',
  1: '#2196f3',
  2: '#ffc107',
  3: '#4caf50',
  4: '#',
}


let agents = {
  0: '본',
  1: '부',
  2: '총',
  3: '매',
  4: '#',
}


export const RenderAgent = (level) => {
  if (level === 0) return <span className="p-tag text-bold text-danger">본사</span>
  if (level === 1) return <span className="p-tag p-tag-warning text-bold text-danger">에이전트</span>
  if (level === 2) return <span className="p-tag p-tag-success text-bold text-danger">총판</span>

  return <span className="p-tag p-tag-danger text-bold text-danger">매장</span>
}


export const RenderAgentByTree = (tree) => {
  const level = tree.split('|').length - 1
  if (level === 0) return <span className="p-tag text-bold text-danger">본사</span>
  if (level === 1) return <span className="p-tag p-tag-warning text-bold text-danger">에이전트</span>
  if (level === 2) return <span className="p-tag p-tag-success text-bold text-danger">총판</span>

  return <span className="p-tag p-tag-danger text-bold text-danger">매장</span>
}


export const ConvertNick = (agent_tree, agent_tree_ko) => {
  if (agent_tree_ko == null || agent_tree_ko === '') {
    return ''
  }
  let trees = ''
  for (var index in agent_tree_ko.split('|')) {

    // if (Number(index) <= (agent_tree_ko.split('|').length - 2)) {
    //   trees = trees + `<span class="${levellables[Number(index)]}">${agents[Number(index)]}</span>`
    // }
    if (agent_tree_ko.split('|')[index] === null || agent_tree_ko.split('|')[index] === '') {
      continue
    }
    7
    trees = trees + `<span class="${levellables[Number(index)]}" style="margin : 2px; font-size : 14px">${agent_tree_ko.split('|')[index]}</span>`
    // if (Number(index) < (agent_tree_ko.split('|').length - 2)) {
    //   trees = trees + '>>'
    // }
  }

  return trees;
};





export const ConvertBalanceType = (agentId, targetId, target, give, status: any) => {
  if (status == "sportwin")
    return <span className="p-message-success">스포츠적중</span>;
  if (status == "sportlose")
    return <span className="p-message-success">스포츠낙점</span>;
  if (status == "fxwin") return <span className="p-message-success">FX적중</span>;
  if (status == "fxlose")
    return <span className="p-message-success">FX낙점</span>;
  if (status == "deposit")
    return `<span className="p-message-success">유저충전</span>`;
  if (status == "withdraw")
    return `<span className="p-message-success">유저환전</span>`;
  if (status === "admin_deposit_cancel")
    return `<span className="p-message.p-message-warn">관리자 충전취소</span>`;

  if (status == "deposit_agent" || status == "agentdeposit")
    return `<string style="color : red">[파트너 유저지급]</string>&nbsp;&nbsp;&nbsp;<string>${target} => ${give}</string>`;
  if (status == "withdraw_agent")
    return `<string style="color : blue">[파트너 유저회수]</string>&nbsp;&nbsp;&nbsp;<string>${target} => ${give}</string>`;

  // trees = trees + `<string style="color : ${colors[Number(index)]}">${agent_tree_ko.split('|')[index]}</string>`



  if (status == "adminwithdraw")
    return `<string style="color : red">[충전완료]</string>&nbsp;&nbsp;&nbsp;`;
  if (status == "admindeposit")
    return `<string style="color : blue">[괸리자지급]</string>&nbsp;&nbsp;&nbsp;`;

  if (status == "depositcancel")
    return `<string style="color : #4caf50">[]</string>&nbsp;&nbsp;&nbsp;`;



  if (status == "withdraw_agent")
    return `<string style="color : blue">[파트너 유저회수]</string>&nbsp;&nbsp;&nbsp;<string>${target} => ${give}</string>`;

  if (status == "admin_do_user_deposit")
    return `<string style="color : red">[센터 유저지급]</string>&nbsp;&nbsp;&nbsp;<string>${targetId}</string>`;

  if (status == "admin_do_user_withdraw")
    return `<string style="color : blue">[센터 유저회수]</string>&nbsp;&nbsp;&nbsp;<string> ${targetId}</string>`;
  if (status == "admin_do_deposit")
    return `<string style="color : red">[에이전트 충전]</string>&nbsp;&nbsp;&nbsp;<string></string>`;

  if (status == "admin_do_withdraw")
    return `<string style="color : blue">[에이전트 환전]</string>&nbsp;&nbsp;&nbsp;<string></string>`;

  if (status == "admin_give_agent_balance_deposit")
    return `<string style="color : red">[센터 지급]</string>&nbsp;&nbsp;&nbsp;<string>${agentId}</string>`;

  if (status == "admin_give_agent_balance_withdraw")
    return `<string style="color : blue">[센터 회수]</string>&nbsp;&nbsp;&nbsp;<string> ${agentId}</string>`;

  if (status == "exchange_point_to_balance")
    return `<string style="color : blue">[포인트변환]</string>&nbsp;&nbsp;&nbsp;<string>${targetId} => ${agentId}</string>`;

  // return <span style={{color : 'red'}}>포인트변환</span>;
  if (status == "exchange_losing_to_balance")
    return `<string style="color : #4caf50">[루징변환]</string>&nbsp;&nbsp;&nbsp;<string>${agentId}</string>`;

  // return <span style={{color : 'red'}}>루징변환</span>;
  if (status == "exchange_rolling_to_balance")
    return `<string style="color : #ff9800">[롤링전환]</string>&nbsp;&nbsp;&nbsp;<string>${agentId}</string>`;

  if (status == "admin_deposit_cancel")
    return `<span style={{color : 'blue'}}>관리자충전취소</span>`;
  if (status == "exchange_to_balance")
    return `<span style={{color : 'blue'}}>에이젼트 환전</span>`;
  if (status == "exchange_to_cancel")
    return `<span style={{color : 'red'}}>환전취소</span>`;
  if (status == "agent_withdraw_agent")
    return `<string style="color : blue">[파트너 머니 회수]</string>&nbsp;&nbsp;&nbsp;<string>${target} => ${give}</string>`;
  if (status == "agent_deposit_agent")
    return `<string style="color : red">[파트너 머니 지급]</string>&nbsp;&nbsp;&nbsp;<string>${target} => ${give}</string>`;

  if (status == "admin_withdraw_cancel")
    return `<string style="color : green">[에이전트 환전취소]</string>&nbsp;&nbsp;&nbsp;<string></string>`;

  return status;
};



export const convertMessage = (status: any, message) => {

  if (status == "deposit_agent" || status == "admin_do_user_deposit" || status == "agent_withdraw_agent")
    return `<string style="color : blue"></string>`;
  if (status == "withdraw_agent" || status == "admin_do_user_withdraw" || status == "agent_deposit_agent")
    return `<string style="color : red"></string>`;

  return message;
};


export const ConvertCalendar = () => {
  return {
    firstDayOfWeek: 1,
    dayNames: ["일", "월", "화", "수", "목", "금", "토"],
    dayNamesShort: ["일", "월", "화", "수", "목", "금", "토"],
    dayNamesMin: ["일", "월", "화", "수", "목", "금", "토"],
    monthNames: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
    monthNamesShort: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
    today: "Hoy",
    clear: "Claro"
  };
}


export const IsConnection = (serverTime, inTime) => {

  return (new Date(serverTime)).getTime() - (new Date(inTime)).getTime() < ((60 * 1000) * 10) * 1000;
}


const benefitName = {
  'balance_losing': '정산금 루징',
  'agent_casino_losing': '카지노 루징',
  'agent_slot_losing': '슬롯 루징',
  'agent_slot_rolling': '슬롯 롤링',
  'agent_casino_rolling': '카지노 롤링',
  'agent_mini_double': '미니게임 2배당이상롤링',
  'agent_mini_nomal': '미니게임 일반롤링',

}



export const ConvertBenefitName = (benefitType) => {
  return benefitName[benefitType] == null ? '미정의' : benefitName[benefitType]
}




export const CalculateTotalBalance = (rowData) => {

  if (rowData.calcuateType === 'A'||rowData.calcuateType == null) {
    return (rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit -
      rowData.totalWithdraw - rowData.totalGiveWithdraw - rowData.totalAdminwithdraw +
      rowData.totalYDInBalance - rowData.totalInBalance)
  }
  else if (rowData.calcuateType === 'B') {
    return (rowData.totalDeposit + rowData.totalGiveDeposit + rowData.totalAdmindeposit -
      rowData.totalWithdraw - rowData.totalGiveWithdraw - rowData.totalAdminwithdraw +
      rowData.totalYDInBalance - rowData.totalInBalance)
  }

  return 0
}

export const ConvertCalculate = (rowData) => {

  const balance = CalculateTotalBalance(rowData)

  if (rowData.calcuateType === 'A' || rowData.calcuateType == null) {
    return ((balance
      - (Number.isNaN((rowData.totalSlotBet / 100) * rowData.benefitSlotRolling) ? 0 : (rowData.totalSlotBet / 100) * rowData.benefitSlotRolling)
      - (Number.isNaN((rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling) ? 0 : (rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling))
      / 100)
      * rowData.benefitLosing
  }

  else if (rowData.calcuateType === 'B') {

    return ((balance / 100) * rowData.benefitLosing)
      - (Number.isNaN((rowData.totalSlotBet / 100) * rowData.benefitSlotRolling) ? 0 : (rowData.totalSlotBet / 100) * rowData.benefitSlotRolling)
      - (Number.isNaN((rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling) ? 0 : (rowData.totalCasinoBet / 100) * rowData.benefitCasinoRolling)

  }

  return 0
}


export const ConvertTreeToAgents = (tree) => {
  if (tree == null) { return "" }

  const agents: any = []
  for (const agent of tree.split('|')) {
    if (agent !== '') {
      agents.push(agent)
    }
  }
  return agents
}


const logType = {

  'admin_do_login': '어드민 로그인',
  'admin_update_user_detail': '유저 정보변경',
  'admin_agent_benefit_edit': '에이전트 요율변경',
  'admin_save_agnet_detail': '에이전트 정보변경',

}


export const ConvertLogType = (type) => {

  return logType[type]
}



export const GetCalcuateOptionType = () => {
  return [
    { label: "(총회원루징-롤링합계)*루징요율=정산금(바카라루징방식)", value: "A" },
    { label: "총회원루징*루징요율-롤링합계=정산금(슬롯루징방식)", value: "B" },
  ]
}

export const GetRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}




export const ConvertBalanceMove = (paramer) => {
  const { logType, company, game, inBalance } = paramer
  if (logType == "balancetoslot")
    return <div style={{ fontWeight: 'bold' }}>
      <span style={{ color: 'red' }}>슬롯 실행  {company}</span>
      <span style={{ color: '#4caf50' }}> [{game}]</span>
      <span style={{ color: '#673ab7' }}> 보유금 {numTomoeny(inBalance)}</span>
    </div>;
  else if (logType == "agentwithdraw") {
    return <div style={{ fontWeight: 'bold' }}>에이전트 회수</div>
  }
  else if (logType == "agentdeposit") {
    return <div style={{ fontWeight: 'bold' }}>에이전트 지급</div>
  } else if (logType == "admindeposit") {
    return <div style={{ fontWeight: 'bold' }}>센터지급</div>
  } else if (logType == "adminwithdraw") {
    return <div style={{ fontWeight: 'bold' }}>환전 완료</div>
  } else if (logType == "withdraw") {
    return <div style={{ fontWeight: 'bold' }}>유저 환전</div>
  } else if (logType == "deposit") {
    return <div style={{ fontWeight: 'bold' }}>유저 충전</div>
  } else if (logType == "depositcancel") {

    return <div style={{ fontWeight: 'bold' }}>충전 취소</div>
  } else if (logType == "withdrawcancel") {
    return <div style={{ fontWeight: 'bold' }}>횐전 취소</div>
  } else if (logType == "wthdrawcashback") {
    return <div style={{ fontWeight: 'bold' }}>환전 취소</div>
  } else if (logType == "gametobalance") {
    return <div style={{ fontWeight: 'bold' }}>게임 to 통합머니</div>
  } else if (logType == "balancetopoint") {
    return <div style={{ fontWeight: 'bold' }}>통합머니 to 금고</div>
  } else if (logType == "pointtobalance") {
    return <div style={{ fontWeight: 'bold' }}>금고 to 통합머니</div>
  }



  return logType;
};



export const UUID = () => {
  let date = new Date();

  const text = date.getFullYear() +
    pad2(date.getMonth() + 1) +
    pad2(date.getDate()) +
    pad2(date.getHours()) +
    pad2(date.getMinutes()) +
    pad2(date.getSeconds())
  return `${text}-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx`.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


// bonus: "충전보너스"
  // deposit: "충전",
  // withdraw: "환전",,
// sportlose: "스포츠낙점",
// minilose: "미니게임낙점",
// miniBetcancel: "미니게임취소",
// pointtobalance: "포인트전환",
// admindeposit: "센터지급",
// adminwithdraw: "센터회수",

// deposit: "충전",
// withdraw: "환전",
// friend: "친구추천",
// withdrawcancel: "환전취소",

// agentdeposit: "총판지급",
// agentwithdraw: "총판회수",