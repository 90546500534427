import React, { Component } from "react";
import { Button } from "primereact/button";
import { BetService } from "../service/bet.service";
import { UserService } from "../service/user.service";
import { FileService } from "../service/file.service";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import {
  GetToday,
  ConvertCalendar,
  numTomoeny,
  ConvertDate,
} from "../utility/help";
import { SubPaging } from "../share/sub-paging";

import { CCalendarTime } from "./custom/ccalendartime";
import { CDropdown } from "./custom/cdropdown";

import { SlotService } from "../service/slot.service";

import { ShareSlotBetTable, ShareCasinoBetTable } from "./share.historylog.table";

interface State {
  page: number;
  maxCount: number;
  selectSort: any;

  begin: any;
  end: any;

  casinos: any[];
  slots: any[];

  dropdownItem: string;
  dropdownItem2: string;
  viewType: string;

  findText: string;
  searchText: string;

  summary: any
  totalSummary: any
}

export class HistoryGame extends Component<any, State> {
  userService = new UserService();
  fileService = new FileService();
  betService = new BetService();


  menu: any;

  constructor(props: any) {
    super(props);

    let today = GetToday();

    this.state = {
      page: 1,
      maxCount: 1,
      selectSort: "betTime",

      begin: today.begin,
      end: today.end,

      casinos: [],
      slots: [],

      dropdownItem: "id",
      dropdownItem2: "",
      viewType: 'slot',

      findText: '',
      searchText: '',
      summary: [],
      totalSummary: {}
    };

    this.GetGameList(1);
    this.GetGameSummary();

  }

  selectData = [
    { label: "아이디", value: "userID" },
    { label: "에이전트", value: "agentID" },
    // { label: "에이전트(하부포함)", value: "tree" },
  ];


  componentDidMount() { }

  //조회
  SearchData = () => {
    this.setState({ page: 1 });
    this.GetGameList(1)
    this.GetGameSummary();
  };

  //게임 내역
  GetGameList = async (
    page: number,
  ) => {

    if (this.state.viewType === "slot") {
      const data = await this.betService
        .get_slot_bets({
          skip: page,
          begin: this.state.begin.toLocaleString("sv-SE"),
          end: this.state.end.toLocaleString("sv-SE"),
          searchText: this.state.findText, // sort,
          findText: this.state.searchText, // sort,
          selectSort: this.state.selectSort, // sort,
          
        })


      if (data.status === 'success') {
        this.setState({
          casinos: [],
          slots: data.slots,
          maxCount: data.maxCount,
          page: page
        });
      }

    }
    else if (this.state.viewType === "casino") {
      const data = await this.betService
        .get_casino_bets({
          skip: page,
          begin: this.state.begin.toLocaleString("sv-SE"),
          end: this.state.end.toLocaleString("sv-SE"),
          selectSort: this.state.selectSort, // sort,
          searchText: this.state.findText, // sort,
          findText: this.state.searchText, // sort,
        })


      if (data.status === 'success') {
        this.setState({
          casinos: data.casino,
          slots: [],
          maxCount: data.maxCount,
          page: page
        });
      }
    }
  };


  //게임 내역
  GetGameSummary = async () => {
    if (this.state.viewType === "slot") {
      const data = await this.betService
        .get_slot_bets_summary({
          begin: this.state.begin.toLocaleString("sv-SE"),
          end: this.state.end.toLocaleString("sv-SE"),
          selectSort: this.state.selectSort, // sort,
          searchText: this.state.findText, // sort,
          findText: this.state.searchText, // sort,
        })


      if (data.status === 'success') {
        this.setState({ summary: data.summary });
      }
    }
    else if (this.state.viewType === "casino") {
      const data = await this.betService
        .get_casin_bets_summary({
          begin: this.state.begin.toLocaleString("sv-SE"),
          end: this.state.end.toLocaleString("sv-SE"),
          selectSort: this.state.selectSort, // sort,
          searchText: this.state.findText, // sort,
          findText: this.state.searchText, // sort,
        })


      if (data.status === 'success') {
        this.setState({ summary: data.summary });
      }
    };
  }

  render() {
    const totalSummary = this.state.summary

    console.log(totalSummary)
    return (
      <>
        <section className="content-header">
          <h1>게임 내역</h1>
        </section>

        <section className="content">

          <ul className="nav nav-tabs" style={{ marginBottom: "20px" }}>

            <li className={this.state.viewType === "slot" ? "active" : ""}>
              <a
                onClick={() => {
                  this.setState({ viewType: "slot", });
                  setTimeout(() => this.SearchData())
                }}
              >
                슬롯
              </a>
            </li>
            <li className={this.state.viewType === "casino" ? "active" : ""}>
              <a
                onClick={() => {
                  this.setState({ viewType: "casino" });

                  setTimeout(() => this.SearchData())
                }}
              >
                카지노
              </a>
            </li>
          </ul>
          <div className="form-inline">
            <div className="form-group">
              <CCalendarTime
                placeholder="시작일"
                value={this.state.begin}
                onChange={(e) => this.setState({ begin: e })}
              />
            </div>

            <div className="form-group">
              <CCalendarTime
                placeholder="종료일"
                value={this.state.end}
                onChange={(e) => this.setState({ end: e })}
              />
            </div>

            <div className="form-group">
              <Button
                label="기간재입력"
                onClick={() => {

                  let today = GetToday();

                  this.setState({
                    begin: today.begin,
                    end: today.end,
                  });
                }}
                className="btn btn-default btn-sm"
              />
            </div>
            {this.state.viewType === "slot" && (
            <div className="form-group">
              <CDropdown
                options={[
                    { label: "아이디", value: "userID" },
                    { label: "에이전트", value: "agentID" },
                    // { label: "에이전트(하부포함)", value: "tree" },
                  ]}
                value={this.state.searchText}
                onChange={(event) =>
                  this.setState({ searchText: event.value })
                }
              />
            </div>)}
            {this.state.viewType === "casino" && (
            <div className="form-group">
              <CDropdown
                options={[
                  { label: "아이디", value: "userID" },
                  { label: "에이전트", value: "agent" },
                  // { label: "에이전트(하부포함)", value: "tree" },
                ]}
              value={this.state.searchText}
                onChange={(event) =>
                  this.setState({ searchText: event.value })
                }
              />
            </div>)}
            <div className="form-group">
              <InputText
                value={this.state.findText}
                onChange={(e: any) => this.setState({ findText: e.target.value })}
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    this.SearchData();
                  }
                }}
                className="form-control input-sm"
                style={{ width: "200px" }}
                placeholder="검색어를 입력해 주세요"
              />
            </div>



            <div className="form-group">
              <Button
                label="조회"
                onClick={() => {
                  this.SearchData();
                }}
                className="btn btn-primary btn-sm"
              />
            </div>
            <div className="form-group">
              <Button
                label="재입력"
                onClick={() => { }}
                className="btn btn-default btn-sm"
              />
            </div>

          </div>

          <div className="table-responsive list_view" style={{ marginTop: '10px' }}>
            <table className="table table-bordered table-condensed">
              <tbody><tr>
                <th colSpan={5}> 이익금 합계 요약 정보</th>
              </tr>
              {this.state.viewType === "casino" && (
                                <tr>
                                <th>게임 이름</th>
                                <th>BET 금액 </th>
                                <th>WIN 금액</th> 
                                <th>이익 금액</th> 
                              </tr>
              )}
              {this.state.viewType === "slot" && (
                                <tr>
                                <th>게임 이름</th>
                                <th>BET 금액 </th>
                              </tr>
              )}


              {this.state.viewType === "casino" && (
                 Object.values(totalSummary).map((ss: any) => (
                   <tr style={{ backgroundColor: ss.gameType === 'casino' ? '#D0E4FF' : '#F8E0E0' }}>
                     <th>총 합계</th>
                     {/* <td className="a_number" >{numTomoeny(ss.count)}</td> */}
                     <td className="a_number" style={{ color: 'blue' }}>{numTomoeny(ss.bet)}</td>
                      <td className="a_number" style={{ color: 'red' }}>{numTomoeny(ss.win)}</td> 
                      <td className="a_number" style={{ color: ss.bet - ss.win > 0 ? 'blue' : 'red' }}>{numTomoeny(ss.bet - ss.win )}</td> 
                   </tr>
                 ))
              )}
     
              {this.state.viewType === "slot" && (
                 Object.values(totalSummary).map((ss: any) => (
                   <tr style={{ backgroundColor: ss.gameType === 'casino' ? '#D0E4FF' : '#F8E0E0' }}>
                     <th>총 합계</th>
                     {/* <td className="a_number" >{numTomoeny(ss.count)}</td> */}
                      <td className="a_number" style={{ color: 'red' }}>{numTomoeny(ss.bet)}</td> 
                   </tr>
                 ))
              )}
     

              </tbody>
            </table>
          </div>

          <div className="table-responsive list_view" style={{ marginTop: "10px" }}>
            {this.state.viewType === "slot" && <ShareSlotBetTable logs={this.state.slots}></ShareSlotBetTable>}
            {this.state.viewType === "casino" && <ShareCasinoBetTable logs={this.state.casinos}></ShareCasinoBetTable>}
          </div>

          <div className="text-center">
            <SubPaging
              activePage={this.state.page}
              maxCount={this.state.maxCount}
              limit={20}
              MoveSel={(page: number) => {
                this.GetGameList(page)
              }}
            ></SubPaging>
          </div>
        </section>
      </>
    );
  }
}
